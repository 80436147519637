import { CustomerTypeValue, MemberGroupValue, PlatformValue } from "@api/ruleApi";
import { Checkbox, Form, Select } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { FormInstance } from "antd/lib/form";
import { SelectValue } from "antd/lib/select";
import React, { FC } from "react";
import styled from "styled-components";
import type { LocalFormType } from ".";

const Wrapper = styled.div`
  height: 180px;
  border: 1px solid #d9d9d9;
  grid-row-start: 4;
`;
const Header = styled.div`
  display: flex;
  align-items: center;

  height: 36px;
  padding: 0 20px;

  font-size: 14px;
  color: ${({ theme }) => theme.colorNeutral600};
  background-color: #fafafa;
`;
const Red = styled.span`
  color: red;
`;
const Body = styled.div`
  padding: 12px 20px 20px 20px;
  display: inline-grid;
  grid-auto-flow: column;
  grid-template-columns: auto;
  grid-template-rows: auto auto auto;
  column-gap: 48px;
  row-gap: 16px;
`;
const Title = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.colorNeutral600};
`;
const StyledCheckbox = styled(Checkbox)`
  && {
    margin-left: 0;
  }
`;
const SelectBlock = styled.div`
  grid-row: span 2;
  width: 100px;
`;
const StyledSelect = styled(Select)`
  width: 100%;
`;

const customerTypeOptions = [
  {
    label: "不限制",
    value: CustomerTypeValue.ALL,
  },
  {
    label: "新客",
    value: CustomerTypeValue.NEW_CUSTOMER,
  },
  {
    label: "舊客",
    value: CustomerTypeValue.OLD_CUSTOMER,
  },
];

const platformTypeOptions = [
  {
    label: "不限制",
    value: PlatformValue.ALL,
  },
  {
    label: "網頁",
    value: PlatformValue.WEB,
  },
  {
    label: "App",
    value: PlatformValue.APP,
  },
];

interface Props {
  form: FormInstance<LocalFormType>;
}

const TargetSetting: FC<Props> = (props) => {
  const { form } = props;

  const handleMemberCheck = (memberGroupType: MemberGroupValue) => (e: CheckboxChangeEvent) => {
    const { checked } = e.target;
    const values = form.getFieldsValue();
    const { memberGroups } = values.bindMethod;

    if (checked) {
      const updatedMemberGroups = memberGroups?.concat(memberGroupType);
      form.setFieldsValue({
        bindMethod: {
          ...values.bindMethod,
          memberGroups: updatedMemberGroups,
        },
      });
    } else {
      const updatedMemberGroups = memberGroups?.filter((grp) => grp !== memberGroupType);
      form.setFieldsValue({
        bindMethod: {
          ...values.bindMethod,
          memberGroups: updatedMemberGroups,
        },
      });
    }
  };

  const handleCustomerTypeChange = (value: SelectValue) => {
    form.setFieldsValue({
      useMethod: {
        maxUseTimes: value === CustomerTypeValue.NEW_CUSTOMER ? 1 : undefined,
      },
    });
  };

  return (
    <Wrapper>
      <Header>
        對象設定
        <Red>*</Red>
      </Header>
      <Body>
        <Title>新舊客</Title>
        <SelectBlock>
          <Form.Item name={["bindMethod", "customerType"]}>
            <StyledSelect options={customerTypeOptions} onChange={handleCustomerTypeChange} />
          </Form.Item>
        </SelectBlock>
        <Title>會員</Title>
        <Form.Item name={["bindMethod", "memberGroups"]} noStyle>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) => prevValues.bindMethod !== currentValues.bindMethod}
          >
            {({ getFieldValue }) => {
              const { memberGroups }: LocalFormType["bindMethod"] = getFieldValue("bindMethod") || {};
              const isGeneralChecked = memberGroups?.includes(MemberGroupValue.GENERAL);
              const isVipChecked = memberGroups?.includes(MemberGroupValue.VIP);

              return (
                <>
                  <StyledCheckbox checked={isGeneralChecked} onChange={handleMemberCheck(MemberGroupValue.GENERAL)}>
                    一般會員
                  </StyledCheckbox>
                  <StyledCheckbox checked={isVipChecked} onChange={handleMemberCheck(MemberGroupValue.VIP)}>
                    黑卡會員
                  </StyledCheckbox>
                </>
              );
            }}
          </Form.Item>
        </Form.Item>
        <Title>平台</Title>
        <SelectBlock>
          <Form.Item name={["bindMethod", "platform"]}>
            <StyledSelect options={platformTypeOptions} />
          </Form.Item>
        </SelectBlock>
      </Body>
    </Wrapper>
  );
};

export default TargetSetting;
