/* eslint-disable @typescript-eslint/no-unused-vars */
import downloadCSV from "@utils/downloadCSV";
import { OnlineDistributor } from "@page/Finance/OnlineDistributorStatistics/constant";
import CoreAPI from "./CoreAPI";
import { transformCamelToSnake } from "./utils/transformCamelToSnake";
import { transformAPIKeyToCamel } from "./utils/transformAPIKeyToCamel";

interface ListResult {
  count: number;
  next: string;
  previous: string;
}

interface ListParams {
  limit: number;
  offset: number;
}

export interface InvoiceFilter {
  invoiceStartDate?: string;
  invoiceEndDate?: string;
  pickupStartDate?: string;
  pickupEndDate?: string;
  capturedStartDate?: string;
  capturedEndDate?: number;
  shippingMethod?: number;
  paymentMethod?: number;
  shippingStatus?: number;
  sku?: string;
  limit: number;
  offset: number;
}
export interface InvoiceList {
  count: number;
  next: string | null;
  previous: string | null;
  results: InvoiceListResult[];
}

export interface InvoiceListResult {
  orderNumber: number;
  invoiceNumber: string;
  ubn: string;
  buyer: string;
  tax: number;
  salesAmount: number;
  invoiceAmount: number;
  currency: string;
  createdAt: string;
}

export interface CostWareHouseAndTransferList extends ListResult {
  results: CostWareHouseAndTransferListItem[];
}

export interface CostWareHouseAndTransferListItem {
  contract: number;
  vendorName: string;
  totalCost: number;
}

export interface costStatementListParams extends ListParams {
  contract?: number;
  dateAfter?: string;
  dateBefore?: string;
}

export interface CostBuyOutList extends ListResult {
  results: CostBuyOutListItem[];
}

export interface CostBuyOutListItem {
  productId: number;
  sku: string;
  pic: string;
  productName: string;
  salesQty: number;
  productCost: number;
  contract: number;
}

export interface ReturnListParams extends ListParams {
  createdDateAfter?: string;
  createdDateBefore?: string;
  checkedDateAfter?: string;
  checkedDateBefore?: string;
  orderNumber?: string;
  approvalStatus?: number;
}
export interface ReturnListItem {
  allowanceNumber: string;
  allowanceCreatedAt: string;
  allowanceCheckedAt: string;
  invoiceNumber: string;
  orderNumber: string;
  ubn: string;
  buyer: string;
  buyerEmail: string;
  approvalStatus: number;
  uploadStatus: number;
  informInfo: string;
  salesDetails: {
    salesAmount: number;
    tax: number;
    invoiceAmount: number;
    salesDetails: ReturnListSalesDetailsItem[];
  };
  status: number;
  currency: string;
}
export interface ReturnListSalesDetailsItem {
  title: string;
  qty: number;
  unitPrice: number;
  totalPrice: number;
}

export interface ReturnList extends ListResult {
  results: ReturnListItem[];
}

export interface ExcessInventoryItem {
  sku: string;
  owner: string;
  productName: string;
  brand: string;
  vendor: string;
  canSaleDate: string;
  effectiveStock: number;
  amountOfTheMonth: number;
}

export interface ExcessInventoryList {
  totalAmount: number;
  count: number;
  details: ExcessInventoryItem[];
}

export interface ExcessInventoryFilter {
  dateAfter?: string;
  dateBefore?: string;
  sku?: string;
  brand?: string;
  month?: string;
  limit: number;
  offset: number;
}
export interface ScrapInventoryList {
  count: number;
  results: {
    totalAmt: number;
    detail: ScrapInventoryItem[];
  };
}

export interface ScrapInventoryItem {
  sku: string;
  ownerName: string;
  productName: string;
  brand: string;
  vendorName: string;
  scrapCount: number;
  contractMode: number;
  scrapAmount: number;
}
export interface ScrapInventoryListParams {
  dateAfter?: string;
  dateBefore?: string;
  brandId?: string;
  limit: number;
  offset: number;
}
export interface OnlineDistributorList {
  count: number;
  next: null | string;
  previous: null | string;
  amount: number;
  amountWithTax: number;
  cost: number;
  charge: number;
  results: OnlineDistributorItem[];
}
export interface OnlineDistributorItem {
  id: number;
  distributorName: string;
  date: string;
  shippingType: string;
  contractMode: string;
  sku: string;
  productName: string;
  orderNumber: string;
  qty: number;
  amount: number;
  tax: number;
  totalAmount: number;
  paymentDate: string;
  invoiceNumber: null | string;
  allowance: number;
  cost: number;
  chargeAmount: number;
  charges: ChargeItem[];
}
export interface ChargeItem {
  title: string;
  fee: number;
}
export interface OnlineDistributorChargeList {
  count: number;
  totalAmount: number;
  results: ChargeListItem[];
}
export interface AddChargeListItem {
  distributorName: string;
  date: string;
  title: string;
  amount: number;
}
export interface ChargeListItem extends AddChargeListItem {
  id: number;
}
export interface ODImportFileForm {
  distributor: number;
  formType: number;
  date: string;
  password: string;
}
export interface OnlineDistributorListParams {
  distributor?: OnlineDistributor;
  date?: string;
  limit: number;
  offset: number;
}
export interface exportCSVParams {
  reportType: string;
  // payment
  orderStartDt?: string;
  orderEndDt?: string;
  capturedStartDt?: string;
  capturedEndDt?: string;
  refundStartDt?: string;
  refundEndDt?: string;
  // vender
  invoiceStartDt?: string;
  invoiceEndDt?: string;
  invalidStartDt?: string;
  invalidEndDt?: string;
  deductedStartDt?: string;
  deductedEndDt?: string;
  arrivedStartDt?: string;
  arrivedEndDt?: string;
  // vip
  payStartDt?: string;
  payEndDt?: string;
  cancelStartDt?: string;
  cancelEndDt?: string;
  // deduct
  yearMonth?: string;
}
export interface TryallProductDailyAggregationsResult {
  date: string;
  id: number;
  sku: string;
  name: string;
  cost: number;
  goodInitialInventory: number;
  badInitialInventory: number;
  goodFinalInventory: number;
  badFinalInventory: number;
  stockValue: number;
}
export interface TryallProductDailyAggregationsParams {
  limit: number;
  offset: number;
  no?: string;
  nameQ?: string;
  statsDateBefore?: string;
  statsDateAfter?: string;
}
export interface TryallProductDailyAggregationsList {
  count: number;
  next: string | null;
  previous: string | null;
  results: TryallProductDailyAggregationsResult[];
}

interface FinanceApi {
  fetchInvoiceList: (param?: InvoiceFilter) => Promise<InvoiceList>;
  fetchInvoiceListBySubOrder: (subOrderId?: number) => Promise<any>;
  fetchCostWareHouseAndTransferList: (param: costStatementListParams) => Promise<CostWareHouseAndTransferList>;
  fetchCostBuyOutList: (param: costStatementListParams) => Promise<CostBuyOutList>;
  fetchReturnList: (param: ReturnListParams) => Promise<ReturnList>;
  exportReturnList: (param: ReturnListParams) => Promise<void>;
  fetchExcessInventoryList: (param: ExcessInventoryFilter) => Promise<ExcessInventoryList>;
  fetchScrapInventoryList: (param: ScrapInventoryListParams) => Promise<ScrapInventoryList>;
  // 線上通路
  fetchOnlineDistributorList: (param: OnlineDistributorListParams) => Promise<OnlineDistributorList>;
  batchImportODListCSV: (file: File, params: ODImportFileForm) => Promise<void>;
  exportODListCSV: (filter: OnlineDistributorListParams) => Promise<void>;
  batchModifyODItem: (ids: number[], value: string, type: string) => Promise<void>;
  fetchOnlineDistributorChargeList: (param: OnlineDistributorListParams) => Promise<OnlineDistributorChargeList>;
  addODCharge: (payload: AddChargeListItem) => Promise<void>;
  deleteODCharge: (id: number) => Promise<void>;
  exportODChargeListCSV: (filter: OnlineDistributorListParams) => Promise<void>;
  exportVPCSalesList: (params: any) => Promise<void>;
  // 報表下載
  exportCSV: (params: exportCSVParams) => Promise<void>;
  // 藍田庫存異動數
  fetchTryallProductDailyAggregations: (
    params: TryallProductDailyAggregationsParams,
  ) => Promise<TryallProductDailyAggregationsList>;
  exportCSVTryallProductDailyAggregations: (params: any) => Promise<void>;
}

const apiClient = new CoreAPI();

const financeApi: FinanceApi = {
  fetchInvoiceList: async (param) => {
    const response = await apiClient.getData("/manage/invoice-income/", param ? transformCamelToSnake(param) : {});
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchInvoiceListBySubOrder: async (subOrderId) => {
    const response = await apiClient.getData(`/manage/invoice-income/${subOrderId}`, {});
    return transformAPIKeyToCamel(response.data.result.details);
  },
  exportVPCSalesList: async (params) => {
    const getParams = transformCamelToSnake(params);
    const response = await apiClient.getData("/manage/invoice-income/csv-download/", getParams);
  },
  fetchCostWareHouseAndTransferList: async (param) => {
    const getParams = transformCamelToSnake(param);
    const response = await apiClient.getData("/manage/cost-cnt/", getParams);
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchCostBuyOutList: async (param) => {
    const getParams = transformCamelToSnake(param);
    const response = await apiClient.getData("/manage/cost-buyout/", getParams);
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchReturnList: async (param) => {
    const getParams = transformCamelToSnake(param);
    const response = await apiClient.getData("/manage/return-goods/", getParams);
    return transformAPIKeyToCamel(response.data.result);
  },
  exportReturnList: async (params) => {
    const getParams = transformCamelToSnake(params);
    const response = await apiClient.getData("/manage/return-goods/csv-download", getParams);
    downloadCSV(response);
  },
  fetchExcessInventoryList: async (params) => {
    const getParams = transformCamelToSnake(params);
    const response = await apiClient.getData("/manage/excess-inventory/", getParams);
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchScrapInventoryList: async (params) => {
    const getParams = transformCamelToSnake(params);
    const response = await apiClient.getData("/manage/scrap-inventory/", getParams);
    return transformAPIKeyToCamel(response.data.result);
  },
  // 線上通路銷售報表
  fetchOnlineDistributorList: async (params) => {
    const getParams = transformCamelToSnake(params);
    const response = await apiClient.getData("/manage/ec-platform/orders/", getParams);
    return transformAPIKeyToCamel(response.data.result);
  },
  batchImportODListCSV: async (file: File, params: ODImportFileForm) => {
    const formData = new FormData();
    formData.append("distributor", params.distributor.toString());
    formData.append("form_type", params.formType.toString());
    formData.append("date", params.date);
    formData.append("password", params.password || "");
    formData.append("file", file);
    const response = await apiClient.postData("/manage/ec-platform/orders/upload/", formData);
    return response.data;
  },

  exportODListCSV: async (filter: OnlineDistributorListParams) => {
    const getParams = transformCamelToSnake(filter);
    const response = await apiClient.getData("/manage/ec-platform/orders/csv-download/", getParams);
  },

  batchModifyODItem: async (ids: number[], value: string, type: string) => {
    const payload = transformCamelToSnake({ ids, value, type });
    const response = await apiClient.putData("/manage/ec-platform/orders/batch-update/", payload);
    return transformAPIKeyToCamel(response.data);
  },

  fetchOnlineDistributorChargeList: async (params: OnlineDistributorListParams) => {
    const getParams = transformCamelToSnake(params);
    const response = await apiClient.getData("/manage/ec-platform/charges/", getParams);
    return transformAPIKeyToCamel(response.data.result);
  },

  addODCharge: async (payload: AddChargeListItem) => {
    const data = transformCamelToSnake(payload);
    const response = await apiClient.postData("/manage/ec-platform/charges/", data);
    return transformAPIKeyToCamel(response.data.result);
  },
  deleteODCharge: async (id: number) => {
    const response = await apiClient.deleteData(`/manage/ec-platform/charges/${id}/`, {});
  },
  exportODChargeListCSV: async (filter: OnlineDistributorListParams) => {
    const getParams = transformCamelToSnake(filter);
    const response = await apiClient.getData("/manage/ec-platform/charges/csv-download/", getParams);
  },

  exportCSV: async (params) => {
    const getParams = transformCamelToSnake(params);
    await apiClient.getData("/manage/finance-export/csv-download/", getParams);
  },
  exportCSVTryallProductDailyAggregations: async (params) => {
    const getParams = transformCamelToSnake(params);
    await apiClient.getData("/tryall/manage/product/tryall-product-daily-aggregations/csv-download/", getParams);
  },
  fetchTryallProductDailyAggregations: async (params) => {
    const getParams = transformCamelToSnake(params);
    const response = await apiClient.getData("/tryall/manage/product/tryall-product-daily-aggregations", getParams);
    return transformAPIKeyToCamel(response.data.result);
  },
};

export default financeApi;
