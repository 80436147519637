import { Button, Row, Table, Typography, Select, Pagination, Modal } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { PlusCircleOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { SelectValue } from "antd/lib/select";
import { RootState } from "@redux/rootReducer";
import { useAppDispatch } from "src/store";
import { VendorContactListParams, VendorContact } from "@page/VendorInfoPage/interface";
import { fetchVendorContactList, updateContactListParams, updateContact, deleteContact } from "@redux/vendorSlice";
import { useParams } from "react-router-dom";
import ContactModal from "./ContactModal";

const Wrapper = styled.div``;
const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 10px;
`;
const TablePageInfo = styled.div``;
const TableContainer = styled.div``;
const TablePagination = styled(Pagination)`
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
`;
const PageSelect = styled(Select)`
  margin: 0 10px;
`;
const MajorLable = styled.div`
  margin-left: 8px;
  font-size: 12px;
  line-height: 1.5;
  border: 0.5px solid #1890ff;
  box-sizing: border-box;
  border-radius: 2px;
  padding: 2px 6px;
  color: #1890ff;
`;

const pageSizeOptions = [20, 50, 100];

export default function VendorContacts() {
  const { vendorId } = useParams();

  const dispatch = useAppDispatch();
  const { isFetchingContactList, vendorContactListResult, vendorContactListParams } = useSelector(
    (state: RootState) => state.vendorSlice,
  );
  const { limit, offset } = vendorContactListParams;
  const { count, results: contactList } = vendorContactListResult;

  const [contactModal, setContactModal] = useState(false);
  const [currentContact, setCurrentContact] = useState<VendorContact>();

  const currentPage = useMemo(() => {
    return Math.floor(offset / limit) + 1;
  }, [limit, offset]);

  useEffect(() => {
    const initialParams: VendorContactListParams = {
      limit: 20,
      offset: 0,
      vendorId: Number(vendorId),
    };

    dispatch(updateContactListParams(initialParams));
    dispatch(fetchVendorContactList());
  }, [vendorId, dispatch]);

  const handleOnPageSizeChange = (value: SelectValue) => {
    const filter = {
      ...vendorContactListParams,
      limit: value as number,
    };

    dispatch(updateContactListParams(filter));
    dispatch(fetchVendorContactList());
  };

  const handleOnPageChange = (page: number) => {
    const filter = {
      ...vendorContactListParams,
      offset: (page - 1) * limit,
    };

    dispatch(updateContactListParams(filter));
    dispatch(fetchVendorContactList());
  };

  function openPopup(contact: VendorContact) {
    handleCurrentContact(contact);
    toggleContactModal();
  }

  function handleMajor(contact: VendorContact) {
    const newContact = {
      ...contact,
      isMajor: true,
    };
    dispatch(updateContact(newContact));
  }

  function toggleContactModal() {
    setContactModal((prev) => !prev);
  }

  function handleCurrentContact(contact?: VendorContact) {
    if (contact) {
      setCurrentContact({ ...contact });
    } else {
      toggleContactModal();
    }
  }

  function handleOnDeleteContact(contactId: number) {
    Modal.confirm({
      title: "你確定要刪除這筆資料?",
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: "是",
      cancelText: "否",
      onOk: () => dispatch(deleteContact(contactId)),
    });
  }

  const columns = [
    {
      title: "聯絡人姓名",
      dataIndex: "name",
      render: (name: string, record: VendorContact) => {
        return (
          <Row>
            <Typography>{name}</Typography>
            {record.isMajor ? <MajorLable>主要聯絡人</MajorLable> : null}
          </Row>
        );
      },
    },
    {
      title: "聯絡人電話",
      dataIndex: "phone",
    },
    {
      title: "聯絡人信箱",
      dataIndex: "email",
    },
    {
      title: "聯絡人職稱",
      dataIndex: "title",
    },
    {
      title: "",
      dataIndex: "id",
      render: (id: number, record: VendorContact) => (
        <Button type="default" onClick={() => handleMajor(record)} disabled={record.isMajor}>
          設為主要聯絡人
        </Button>
      ),
    },
    {
      title: "",
      dataIndex: "id",
      width: 20,
      render: (id: number, record: VendorContact) => (
        <Button type="link" onClick={() => openPopup(record)}>
          編輯
        </Button>
      ),
    },
    {
      title: "",
      dataIndex: "id",
      width: 20,
      render: (id: number) => (
        <Button type="link" onClick={() => handleOnDeleteContact(id)}>
          刪除
        </Button>
      ),
    },
  ];
  return (
    <Wrapper>
      <Header>
        <Button type="primary" icon={<PlusCircleOutlined />} onClick={() => handleCurrentContact()}>
          新增聯絡人
        </Button>
        <TablePageInfo>
          總共
          {count}
          筆, 每頁顯示
          <PageSelect value={limit} onChange={handleOnPageSizeChange}>
            {pageSizeOptions.map((option) => (
              <Select.Option key={option} value={option}>
                {option}
              </Select.Option>
            ))}
          </PageSelect>
          筆
        </TablePageInfo>
      </Header>
      <TableContainer>
        <Table
          columns={columns}
          rowKey="id"
          dataSource={contactList}
          scroll={{ x: "max-content", y: 400 }}
          pagination={false}
          loading={isFetchingContactList}
        />
        <TablePagination
          current={currentPage}
          total={count}
          onChange={handleOnPageChange}
          showSizeChanger={false}
          pageSize={limit}
        />
      </TableContainer>
      <ContactModal
        isModalVisible={contactModal}
        toggleContactModal={toggleContactModal}
        currentContact={currentContact}
      />
    </Wrapper>
  );
}
