import { FilterTwoTone } from "@ant-design/icons";
import { ReturnListItem, ReturnListSalesDetailsItem } from "@api/financeApi";
import PageTitle from "@component/PageTitle";
import {
  exportReturnList,
  fetchReturnList,
  resetReturnListPageSlice,
  setReturnListParam,
} from "@redux/finance/returnListPageSlice";
import { RootState } from "@redux/rootReducer";
import { Button, Row, Select, Table } from "antd";
import { SelectValue } from "antd/lib/select";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import fontStyle from "src/styles/fontStyle";
import styled from "styled-components";
import Filter from "./Filter";

const Wrapper = styled.div`
  padding: 18px 14px 0px 14px;

  .ant-tabs {
    overflow: visible;
  }
`;
const HeaderButtonsWrapper = styled.div`
  > button {
    margin-right: 8px;
  }
`;
const PageText = styled.span`
  ${fontStyle("14px", "20px")};
`;
const SelectPageSize = styled(Select)`
  margin: 0 10px;
`;
const TableWrapper = styled.div`
  border: solid 1px #f0f0f0;
  border-radius: 2px;
  padding: 20px 10px 22px 17px;
  margin-top: 20px;
  .ant-table-expanded-row-level-1 {
  }
  .ant-table-expanded-row {
  }
`;
const LighterTitle = styled.div`
  color: ${({ theme }) => theme.colorNeutral500};
`;
const TablePadding = styled.div`
  /* 明細表格內推前列欄位寬度之總和 */
  padding-left: 1260px;
`;
const ReturnListPage = () => {
  const dispatch = useDispatch();
  const isFetching = useSelector((state: RootState) => state.returnListPageSlice.isFetching);
  const returnList = useSelector((state: RootState) => state.returnListPageSlice.returnList);
  const listParams = useSelector((state: RootState) => state.returnListPageSlice.returnListParam);
  const { count, results: listResult } = returnList;
  const { offset, limit } = listParams;

  const [showFilter, setShowFilter] = useState<boolean>(false);

  const currentPage = useMemo(() => {
    return Math.floor(offset / limit) + 1;
  }, [limit, offset]);

  const handleOnExport = () => {
    dispatch(exportReturnList());
  };

  const handleOnPageSizeChange = (value: SelectValue) => {
    const filter = {
      ...listParams,
      limit: value as number,
    };
    dispatch(setReturnListParam(filter));
  };
  const handleOnPageChange = (page: number) => {
    const filter = {
      ...listParams,
      offset: (page - 1) * limit,
    };
    dispatch(setReturnListParam(filter));
  };

  useEffect(() => {
    dispatch(fetchReturnList());
  }, [dispatch, listParams]);

  useEffect(() => {
    return () => {
      dispatch(resetReturnListPageSlice());
    };
  }, [dispatch]);

  const tableColumns: ColumnsType<ReturnListItem> = [
    {
      title: "折讓單號",
      key: "allowanceNumber",
      dataIndex: "allowanceNumber",
      width: 170,
    },
    {
      title: "開立折讓日期",
      key: "allowanceCreatedAt",
      dataIndex: "allowanceCreatedAt",
      width: 160,
      render: (value) => moment(value).format("YYYY-MM-DD HH:mm"),
    },
    {
      title: "確認折讓日期",
      key: "allowanceCheckedAt",
      dataIndex: "allowanceCheckedAt",
      width: 160,
      render: (value) => moment(value).format("YYYY-MM-DD HH:mm"),
    },
    {
      title: "發票號碼",
      key: "invoiceNumber",
      dataIndex: "invoiceNumber",
      width: 135,
    },
    {
      title: "訂單編號",
      key: "orderNumber",
      dataIndex: "orderNumber",
      width: 135,
    },
    {
      title: "買受人統編",
      key: "ubn",
      dataIndex: "ubn",
      width: 135,
      render: (value) => value || "-",
    },
    {
      title: "買受人名稱",
      key: "buyer",
      dataIndex: "buyer",
      width: 135,
    },
    {
      title: "買受人email",
      key: "buyerEmail",
      dataIndex: "buyerEmail",
      width: 180,
      render: (value) => value || "-",
    },
    {
      title: "銷售明細",
      key: "salesDetails",
      dataIndex: "salesDetails",
      width: 600,
      render: (value, record, index) => {
        const {
          salesDetails: { salesDetails },
        } = record;
        if (salesDetails.length > 1) {
          return (
            <>
              <div>{salesDetails[0].title}</div>
              <LighterTitle>更多商品資訊...</LighterTitle>
            </>
          );
        }
        return salesDetails[0].title;
      },
    },
    {
      title: "銷售額（不含稅）",
      key: "salesAmount",
      dataIndex: ["salesDetails", "salesAmount"],
    },
    {
      title: "營業稅額",
      key: "tax",
      dataIndex: ["salesDetails", "tax"],
    },
    {
      title: "發票金額",
      key: "invoiceAmount",
      dataIndex: ["salesDetails", "invoiceAmount"],
    },
    {
      title: "確認狀態",
      key: "approvalStatus",
      dataIndex: "approvalStatus",
    },
    {
      title: "上傳狀態",
      key: "uploadStatus",
      dataIndex: "uploadStatus",
    },
    {
      title: "折讓通知",
      key: "informInfo",
      dataIndex: "informInfo",
    },
  ];
  const tableSalesDetailsColumns: ColumnsType<ReturnListSalesDetailsItem> = [
    {
      title: "品名",
      dataIndex: "title",
      key: "title",
      width: 600,
    },
    {
      title: "數量",
      dataIndex: "qty",
      key: "qty",
    },
    {
      title: "單價",
      dataIndex: "unitPrice",
      key: "unitPrice",
    },
    {
      title: "金額(不含稅之進貨額)",
      dataIndex: "totalPrice",
      key: "totalPrice",
    },
  ];

  return (
    <Wrapper>
      <PageTitle title="退貨清單" />
      <Row justify="space-between">
        <HeaderButtonsWrapper>
          <Button onClick={handleOnExport}>匯出</Button>
          <Button icon={<FilterTwoTone />} onClick={() => setShowFilter(!showFilter)}>
            篩選
          </Button>
        </HeaderButtonsWrapper>
        <Row align="middle">
          <PageText>{`總共${count}筆, 每頁顯示`}</PageText>
          <SelectPageSize defaultValue="20" onChange={handleOnPageSizeChange}>
            <Select.Option value="20">20</Select.Option>
            <Select.Option value="50">50</Select.Option>
            <Select.Option value="100">100</Select.Option>
          </SelectPageSize>
          <PageText>筆</PageText>
        </Row>
      </Row>
      {showFilter && <Filter />}
      <TableWrapper>
        <Table
          scroll={{ x: "max-content" }}
          loading={isFetching}
          columns={tableColumns}
          dataSource={listResult}
          pagination={{
            pageSize: listParams.limit,
            current: currentPage,
            showSizeChanger: false,
            total: count,
            onChange: handleOnPageChange,
          }}
          rowKey={(record) => record.allowanceNumber}
          expandable={{
            expandIconColumnIndex: 8,
            expandedRowRender: (record) => (
              <TablePadding>
                <Table
                  columns={tableSalesDetailsColumns}
                  dataSource={record.salesDetails?.salesDetails}
                  pagination={false}
                />
              </TablePadding>
            ),
            rowExpandable: (record) => record.salesDetails?.salesDetails.length > 1,
          }}
        />
      </TableWrapper>
    </Wrapper>
  );
};
export default ReturnListPage;
