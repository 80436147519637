import PopupBackground from "@component/PopupBackground";
import { RootState } from "@redux/rootReducer";
import { fetchCreatePurchase, fetchUpdatePurchase } from "@redux/warehouseSlice";
import { Button, DatePicker, Form, Row, Select } from "antd";
import locale from "antd/es/date-picker/locale/zh_TW";
import moment from "moment";
import "moment/locale/zh-tw";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import fontStyle from "src/styles/fontStyle";
import styled from "styled-components";

const Wrapper = styled(Form)`
  width: 650px;
  height: 400px;
  padding: 30px 28px 20px 32px;
  border-radius: 2px;
  background: ${({ theme }) => theme.colorNeutral100};
  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08),
    0px 9px 28px 8px rgba(0, 0, 0, 0.05);

  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    order: 1;
  }
`;

const CustomDatePicker = styled(DatePicker)`
  width: 100%;
`;

const Notice = styled.span`
  margin-bottom: 24px;
  margin-left: 10px;
  color: rgba(0, 0, 0, 0.25);
  ${fontStyle("14px", "22px")};
`;

const CustomRow = styled(Row)`
  .ant-form-item-label {
    width: 123px;
    text-align: left;
  }
`;

const CustomSelect = styled(Select)`
  &&& {
    width: 246px;
  }
`;

const CustomButton = styled(Button)`
  margin-right: 14px;
`;

const ButtonWrapper = styled.div`
  position: absolute;
  right: 28px;
  bottom: 0;
`;

type Props = {
  purchaseId?: number;
  close: () => void;
};

const warehouseSlice = (state: RootState) => state.warehouseSlice;

const timePeriod = [
  {
    id: 9,
    value: "9-10點",
  },
  {
    id: 10,
    value: "10-11點",
  },
  {
    id: 13,
    value: "13-14點",
  },
  {
    id: 14,
    value: "14-15點",
  },
  {
    id: 15,
    value: "15-16點",
  },
];

export default function IntoWarehouseForm(props: Props) {
  const { purchaseId, close } = props;

  const dispatch = useDispatch();
  const { contractVendors, purchaseInfo } = useSelector(warehouseSlice);

  const [form] = Form.useForm();

  const setOwner = () => {
    const values = form.getFieldsValue();
    const vendor = contractVendors.find((item) => item.id === values.vendor);

    if (vendor) {
      form.setFieldsValue({
        ...values,
        ownerId: vendor.ownerId,
      });
    } else {
      form.setFieldsValue({
        ...values,
        ownerId: -1,
      });
    }
  };

  const onSubmit = (values: any) => {
    const payload = {
      specDate: moment(values.specDate).format("YYYY-MM-DD"),
      specPeriod: values.specPeriod,
      vendor: values.vendor,
    };

    if (purchaseId) {
      dispatch(fetchUpdatePurchase({ ...payload, purchaseId }));
    } else {
      dispatch(fetchCreatePurchase(payload));
    }

    close();
  };

  return (
    <PopupBackground close={close} fixed>
      <Wrapper
        colon={false}
        labelAlign="left"
        form={form}
        initialValues={{
          vendor: purchaseInfo?.vendor?.id,
          ownerId: purchaseInfo?.vendor?.owner?.id,
          specDate: moment(purchaseInfo?.specDate),
          specPeriod: purchaseInfo?.specPeriod || 10,
        }}
        onFinish={onSubmit}
      >
        <Form.Item
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 10 }}
          label="廠商"
          name="vendor"
          rules={[{ required: true, message: "必填" }]}
        >
          <Select
            showSearch
            defaultValue={-1}
            onChange={setOwner}
            optionFilterProp="children"
            filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            <Select.Option value={-1}>請選擇</Select.Option>
            {contractVendors.map((vendor) => (
              <Select.Option key={vendor.id} value={vendor.id}>
                {vendor.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <CustomRow align="middle">
          <Form.Item label="負責人" name="ownerId" rules={[{ required: true, message: "必填" }]}>
            <CustomSelect defaultValue={-1} disabled>
              <Select.Option value={-1}>請選擇</Select.Option>
              {contractVendors.map((vendor) => (
                <Select.Option key={vendor.ownerId} value={vendor.ownerId}>
                  {vendor.ownerName}
                </Select.Option>
              ))}
            </CustomSelect>
          </Form.Item>
          <Notice>*選擇廠商後自動帶入</Notice>
        </CustomRow>
        <Form.Item
          label="預計進貨日期"
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 10 }}
          name="specDate"
          rules={[{ required: true, message: "必填" }]}
        >
          <CustomDatePicker locale={locale} />
        </Form.Item>
        <Form.Item
          label="預計進貨時段"
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 10 }}
          name="specPeriod"
          rules={[{ required: true, message: "必填" }]}
        >
          <Select>
            {timePeriod.map((time) => (
              <Select.Option key={time.value} value={time.id}>
                {time.value}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <ButtonWrapper>
          <Form.Item>
            <CustomButton onClick={close}>取消</CustomButton>
            <Button type="primary" htmlType="submit">
              確定
            </Button>
          </Form.Item>
        </ButtonWrapper>
      </Wrapper>
    </PopupBackground>
  );
}
