import React, { useEffect, useState } from "react";
import PageTitle from "@component/PageTitle";
import styled from "styled-components";
import { PlusCircleTwoTone, ExclamationCircleOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { Button, Table, Row, Select, Modal, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import fontStyle from "src/styles/fontStyle";
import { SelectValue } from "antd/lib/select";
import { CooperationResult } from "@api/cooperationApi";
import {
  cooperationState,
  fetchCooperationList,
  fetchSendInvoice,
  fetchDeleteInvoice,
  setCooperationFilter,
} from "@redux/finance/cooperationSlice";
import EditInvoicePopup from "./EditInvoicePopup";

const Wrapper = styled.div`
  padding: 20px 20px 20px 25px;
`;
const TableWrapper = styled.div`
  border: solid 1px #f0f0f0;
  border-radius: 2px;
  padding: 20px 10px 22px 17px;
  margin-top: 20px;
`;
const SelectPageSize = styled(Select)`
  margin: 0 10px;
`;
const PageText = styled.span`
  ${fontStyle("14px", "20px")};
`;

export default function Cooperation() {
  const dispatch = useDispatch();
  const { cooperationList, cooperationFilter, isFetching } = useSelector(cooperationState);
  const [openPopup, setOpenPopup] = useState<boolean>(false);
  const [action, setAction] = useState<string>("");
  const [currentData, setCurrentData] = useState<CooperationResult | undefined>();
  const [page, setPage] = useState<number>(1);

  const setPageLimit = (value: SelectValue) => {
    dispatch(setCooperationFilter({ ...cooperationFilter, limit: parseInt(value as string, 10) }));
  };

  const fetchPageChange = (value: number) => {
    window.scrollTo(0, 0);
    setPage(value);
    dispatch(setCooperationFilter({ ...cooperationFilter, offset: (value - 1) * cooperationFilter.limit }));
  };

  const columns: ColumnsType<any> = [
    {
      key: "id",
      dataIndex: "id",
      title: "合作編號",
    },
    {
      key: "name",
      dataIndex: "name",
      title: "廠商名稱",
    },
    {
      key: "startAt",
      dataIndex: "startAt",
      title: "合作期間",
      render: (value: CooperationResult["startAt"], data: CooperationResult) => {
        const { startAt, endAt } = data;
        if (startAt === null && endAt === null) return "";
        return `${startAt} ～ ${endAt}`;
      },
    },
    {
      key: "recognizeAt",
      dataIndex: "recognizeAt",
      title: "收入年月",
    },
    {
      key: "amount",
      dataIndex: "amount",
      title: "金額",
    },
    {
      key: "amount",
      dataIndex: "amount",
      title: "合作模式",
    },
    {
      title: "",
      key: "sendInvoice",
      dataIndex: "",
      fixed: "right",
      render: (data: CooperationResult) => {
        return (
          <Typography.Link disabled={data.invoiceNumber !== null} onClick={() => onHandleInvoice(data)}>
            開立發票
          </Typography.Link>
        );
      },
    },
    {
      title: "",
      key: "",
      dataIndex: "",
      fixed: "right",
      render: (value: any, data: any) => {
        return data.invoiceNumber ? (
          <Typography.Link onClick={() => toggleEditPopup(data, "view")}>檢視</Typography.Link>
        ) : (
          <Typography.Link onClick={() => toggleEditPopup(data, "edit")}>編輯/檢視</Typography.Link>
        );
      },
    },
    {
      title: "",
      key: "",
      dataIndex: "",
      fixed: "right",
      render: (value: any, data: any) => {
        return (
          <Typography.Link disabled={data.invoiceNumber !== null} onClick={() => onDeleteInvoice(data)}>
            刪除
          </Typography.Link>
        );
      },
    },
  ];

  const onHandleInvoice = (data: CooperationResult) => {
    dispatch(fetchSendInvoice(data.id));
  };
  const toggleEditPopup = (data: CooperationResult, actionType: string) => {
    setOpenPopup(true);
    setAction(actionType);
    setCurrentData(data);
  };

  const onDeleteInvoice = (data: CooperationResult) => {
    Modal.confirm({
      title: "你確定要刪除嗎？",
      icon: <ExclamationCircleOutlined />,
      okText: "是",
      cancelText: "否",
      onOk() {
        dispatch(fetchDeleteInvoice(data.id));
      },
    });
  };
  const handleOpenPopup = () => {
    setOpenPopup(true);
    setAction("new");
  };

  useEffect(() => {
    dispatch(fetchCooperationList(cooperationFilter));
  }, [cooperationFilter, dispatch]);

  return (
    <Wrapper>
      <PageTitle title="異業合作清單" />
      <Row justify="space-between">
        <Button type="primary" icon={<PlusCircleTwoTone />} onClick={handleOpenPopup}>
          新增合作關係
        </Button>

        <Row align="middle">
          <PageText>{`總共${cooperationList.count}筆, 每頁顯示`}</PageText>
          <SelectPageSize defaultValue="20" onChange={setPageLimit}>
            <Select.Option value="20">20</Select.Option>
            <Select.Option value="50">50</Select.Option>
            <Select.Option value="100">100</Select.Option>
          </SelectPageSize>
          <PageText>筆</PageText>
        </Row>
      </Row>
      <TableWrapper>
        <Table
          scroll={{ x: "max-content" }}
          loading={isFetching}
          columns={columns}
          dataSource={cooperationList.results}
          pagination={{
            pageSize: cooperationFilter.limit,
            current: page,
            showSizeChanger: false,
            total: cooperationList.count,
            onChange: fetchPageChange,
          }}
        />
      </TableWrapper>
      {openPopup && <EditInvoicePopup currentData={currentData} action={action} onClose={() => setOpenPopup(false)} />}
    </Wrapper>
  );
}
