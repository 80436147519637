import AttributeManagementPage from "@page/AttributeManagement";
import AttributeInfoEditPage from "@page/AttributeManagement/AttributeInfoEdit";
import BrandPage from "@page/Brand";
import BrandSinglePage from "@page/BrandPage";
import Cohort from "@page/Cohort";
import ContractPage from "@page/Contract";
import EditContractPage from "@page/Contract/EditContractPage";
import Dashboard from "@page/Dashboard";
import MarketStatistics from "@page/MarketStatistics";
import MemberListPage from "@page/Member/MemberListPage";
import OrderContentPage from "@page/Order/OrderContent";
import OrderInformationPage from "@page/Order/OrderContent/OrderInformation";
import ReplenishReturnPage from "@page/Order/ReplenishReturn";
import ReplenishReturnInformationPage from "@page/Order/ReplenishReturn/ReplenishReturnInformation";
import ProductPage from "@page/Product/ProductPage";
import ProductCategoryPage from "@page/ProductCategory";
import PromotionPage from "@page/Promotion";
import ReturnOrderDetailPage from "@page/ReturnOrder/ReturnOrderDetailPage";
import ReturnOrderListPage from "@page/ReturnOrder/ReturnOrderListPage";
import ReviewManagement from "@page/ReviewManagement";
import RulePage from "@page/Rule";
import SalePage from "@page/Sale";
import SaleInformationPage from "@page/Sale/SaleInformation";
import SalesCategoryPage from "@page/SalesCategory";
import MenuMangement from "@page/MenuMangement";
import SalesStatistics from "@page/SalesStatistics";
import ShelfLifePage from "@page/ShelfLife";
import SlotPage from "@page/Slot/SlotPage";
import StockManagementPage from "@page/StockManagement";
import SupplChainList from "@page/SupplyChain";
import SupplyChainProductList from "@page/SupplyChain/SupplyChainProductList";
import SupplChainTabs from "@page/SupplyChain/SupplyChainTabs";
import System from "@page/System";
import DealerPage from "@page/ThroughShipment/Dealer";
import DealerInformation from "@page/ThroughShipment/Dealer/DealerInformation";
import ExternalOrderPage from "@page/ThroughShipment/ExternalOrder";
import ExternalOrderInformationPage from "@page/ThroughShipment/ExternalOrder/ExternalOrderInformation";
import OrderReviewPage from "@page/ThroughShipment/ExternalOrder/OrderReview";
import TopicPage from "@page/TopicPage";
import EditPage from "@page/TopicPage/EditPage";
import MarginManagementViewPage from "@page/VendorInfoPage/MarginManagementView";
import NewVendorPage from "@page/VendorInfoPage/NewVendorPage";
import VendorInfoPage from "@page/VendorInfoPage/VendorInfoPage";
import VendorListPage from "@page/VendorListPage";
import MarginBalanceListPage from "@page/VendorListPage/MarginBalanceList";
import VendorReportRegenPage from "@page/VendorReportRegenPage";
import VendorReportRegenDetailPage from "@page/VendorReportRegenPage/Detail";
import VendorSupplementPage from "@page/VendorSupplementPage";
import VendorSupplementDetailPage from "@page/VendorSupplementPage/Detail";
import VipPromotionPage from "@page/VipPromotion";
import VipBulletinBoardPage from "@page/VipPromotion/VipBulletinBoard";
import VipGiftPage from "@page/VipPromotion/VipGift";
import VipRulePage from "@page/VipRule";
import VipRuleContentPage from "@page/VipRule/VipRuleContent";
import VipStatistics from "@page/VipStatistics";
import CustomsClearancePage from "@page/Warehouse/CustomsClearance";
import Recost from "@page/Cost";
import Preview from "@page/Cost/Preview";
import CustomsClearanceEditPage from "@page/Warehouse/CustomsClearance/CustomsClearanceEdit";
import IntoWarehousePage from "@page/Warehouse/IntoWarehouse";
import IntoWarehouseEditPage from "@page/Warehouse/IntoWarehouse/IntoWarehouseEdit";
import { fetchCityRegion } from "@redux/cityRegionSlice";
import { fecthBdStaffs, fetchAllStaffs } from "@redux/staffSlice";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import DiscountFrameList from "@page/DiscountFrame/DiscountFrameList";
import DiscountFrameInformation from "@page/DiscountFrame/DiscountFrameInformation";
// 品運後台
import BrandOperationProduct from "@page/BrandOperation/Product";
import AddProduct from "@page/BrandOperation/Product/AddProduct";
import BOstockList from "@page/BrandOperation/BOstockList";
import IntoWarehouse from "@page/BrandOperation/IntoWarehouseBrand/IntoWarehouse";
import IntoWarehouseReview from "@page/BrandOperation/IntoWarehouseBrand/IntoWarehouseReview";
import ManufacturerList from "@page/BrandOperation/Manufacturer/ManufacturerList";
import ManufacturerInfo from "@page/BrandOperation/Manufacturer/ManufacturerInfo";
import PurchaseOrder from "@page/BrandOperation/Procurement";
import PurchaseOrderPreview from "@page/BrandOperation/Procurement/Preview/index";
// 財務後台
import Invoice from "@page/Finance/Invoice";
import Cooperation from "@page/Finance/Cooperation";
import CostStatement from "@page/Finance/CostStatement/CostStatement";
import ReturnListPage from "@page/Finance/ReturnListPage/ReturnListPage";
import ExcessInventory from "@page/Finance/ExcessInventory";
import ScrapInventory from "@page/Finance/ScrapInventory/ScrapInventory";
import OnlineDistributorStatistics from "@page/Finance/OnlineDistributorStatistics/OnlineDistributorStatistics";
import StatisticsDownload from "@page/Finance/StatisticsDownload";
import TryallStockManagement from "@page/Finance/TryallStockManagement";
import ProcessingApplicationList from "@page/ProcessingApplication/ProcessingApplicationList";
import ProcessingApplicationTab from "@page/ProcessingApplication/ProcessingApplicationTab";
import OrderPaymentList from "@page/Finance/OrderPayment/OrderPaymentList";

export default function RouterList() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCityRegion());
    dispatch(fecthBdStaffs());
    dispatch(fetchAllStaffs());
  }, [dispatch]);

  return (
    <Routes>
      {/* ---- Urmart ----- */}
      <Route path="dashboard/*" element={<Dashboard />} /> {/* 首頁/全站Dashboard */}
      <Route path="sales-statistics" element={<SalesStatistics />} /> {/* 銷售統計表 */}
      <Route path="market-statistics" element={<MarketStatistics />} /> {/* 活動成效表 */}
      <Route path="member-cohort" element={<Cohort />} /> {/* 會員 Cohort 分析表 */}
      <Route path="vip-statistics" element={<VipStatistics />} /> {/* 黑卡會員分析表 */}
      <Route path="salespage" element={<Outlet />}>
        <Route path="/" element={<SalePage />} />
        <Route path="edit/new" element={<SaleInformationPage />} />
        <Route path="edit/:salesId" element={<SaleInformationPage />} />
        銷售頁
      </Route>
      <Route path="salespage-category" element={<SalesCategoryPage />} /> {/* 分類頁 */}
      <Route path="menu-mangement" element={<MenuMangement />} /> {/* 目錄管理頁 */}
      <Route path="topic" element={<Outlet />}>
        <Route path="/" element={<TopicPage />} />
        <Route path="edit" element={<EditPage />} />
        <Route path="edit/:topicId" element={<EditPage />} />
        主題館
      </Route>
      <Route path="brand-page" element={<Outlet />}>
        <Route path="/" element={<BrandSinglePage />} />
        <Route path="edit/:brandPageId" element={<BrandSinglePage />} />
        品牌頁
      </Route>
      <Route path="promotion/*" element={<PromotionPage />} /> {/* 活動 */}
      <Route path="rule/*" element={<RulePage />} /> {/* 優惠案型 */}
      <Route path="vip-rule" element={<Outlet />}>
        <Route path="/" element={<VipRulePage />} />
        <Route path="/edit/new" element={<VipRuleContentPage />} />
        <Route path="/edit/:vipRuleId" element={<VipRuleContentPage />} />
        VIP 優惠
      </Route>
      <Route path="vip-gift" element={<VipGiftPage />} /> {/* VIP 贈品 */}
      <Route path="vip-bulletin-board" element={<VipBulletinBoardPage />} /> {/* VIP 推廣 */}
      <Route path="slot-management/*" element={<SlotPage />} /> {/* 版位管理 */}
      <Route path="discount-frame" element={<Outlet />}>
        <Route path="/" element={<DiscountFrameList />} />
        <Route path="/add" element={<DiscountFrameInformation />} />
        <Route path="/edit/:frameId" element={<DiscountFrameInformation />} />
        宣傳框
      </Route>
      <Route path="vip-promotion" element={<VipPromotionPage />} /> {/* 黑卡推廣頁 */}
      <Route path="product/*" element={<ProductPage />} /> {/* 商品 */}
      <Route path="contract" element={<Outlet />}>
        <Route path="/" element={<ContractPage />} />
        <Route path="edit/new" element={<EditContractPage />} />
        <Route path="edit/:contractId" element={<EditContractPage />} />
        合約
      </Route>
      <Route path="shelf-life-management" element={<ShelfLifePage />} /> {/* 效期管理 */}
      <Route path="stock-management" element={<StockManagementPage />} /> {/* 庫存變動數 */}
      <Route path="brand" element={<BrandPage />} /> {/* 品牌 */}
      <Route path="product-category" element={<ProductCategoryPage />} /> {/* 品類 */}
      <Route path="attribute-management" element={<Outlet />}>
        <Route path="/" element={<AttributeManagementPage />} />
        <Route path="edit/new" element={<AttributeInfoEditPage />} />
        <Route path="edit/:attributeId" element={<AttributeInfoEditPage />} />
        屬性管理
      </Route>
      <Route path="vendor" element={<Outlet />}>
        廠商
        <Route path="deposit-balance" element={<MarginBalanceListPage />} /> {/* 保證金餘額 */}
        <Route path="list" element={<VendorListPage />} /> {/* 廠商列表 */}
        <Route path="info" element={<Outlet />}>
          <Route path=":vendorId" element={<VendorInfoPage />} />
          <Route path="new" element={<NewVendorPage />} />
        </Route>
        <Route path=":vendorId" element={<Outlet />}>
          <Route path="deposit/:depositId" element={<MarginManagementViewPage />} />
          <Route path="profits/:profitsId" element={<MarginManagementViewPage />} />
        </Route>
      </Route>
      <Route path="supplement" element={<Outlet />}>
        補單
        <Route path="/" element={<VendorSupplementPage />} />
        <Route path="/:supplementId" element={<VendorSupplementDetailPage />} />
      </Route>
      <Route path="report-regen" element={<Outlet />}>
        重跑
        <Route path="/" element={<VendorReportRegenPage />} />
        <Route path="/:reportRegenId" element={<VendorReportRegenDetailPage />} />
      </Route>
      <Route path="into-warehouse" element={<Outlet />}>
        進倉
        <Route path="/" element={<IntoWarehousePage />} />
        <Route path="/:warehouseId/:showDestroy" element={<IntoWarehouseEditPage />} />
      </Route>
      <Route path="return-inventory" element={<Outlet />}>
        還貨
        <Route path="/" element={<ReturnOrderListPage />} />
        <Route path="edit" element={<Outlet />}>
          <Route path="/" element={<div>Error Id</div>} />
          <Route path=":returnOrderId" element={<ReturnOrderDetailPage />} />
        </Route>
      </Route>
      <Route path="/member-data" element={<MemberListPage />} /> {/* 會員資料 */}
      <Route path="review-management/*" element={<ReviewManagement />} /> {/* 評分評論 */}
      <Route path="order-content" element={<Outlet />}>
        訂單內容
        <Route path="/" element={<OrderContentPage />} />
        <Route path="/:orderId" element={<OrderInformationPage />} />
      </Route>
      <Route path="replenish-return" element={<Outlet />}>
        補退貨
        <Route path="/" element={<ReplenishReturnPage />} />
        <Route path="/:orderId" element={<ReplenishReturnInformationPage />} />
      </Route>
      <Route path="system-environment" element={<System />} /> {/* 系統 */}
      {/* ---- Urmart end ----- */}
      {/* ----共用功能 ---- */}
      <Route path="customs-clearance" element={<Outlet />}>
        報關
        <Route path="/" element={<CustomsClearancePage />} />
        <Route path="/:customsClearanceId" element={<CustomsClearanceEditPage />} />
      </Route>
      <Route path="recost" element={<Outlet />}>
        成本回壓
        <Route path="/" element={<Recost />} />
        <Route path="/:id/preview" element={<Preview />} />
      </Route>
      {/* ----共用功能 end---- */}
      {/* ---- 品運後台 ---- */}
      <Route path="operation-of-brand" element={<Outlet />}>
        <Route path="product" element={<Outlet />}>
          <Route path="/" element={<BrandOperationProduct />} />
          <Route path="/add" element={<AddProduct mode="add" />} />
          <Route path="/edit/:productId" element={<AddProduct mode="edit" />} />
          商品
        </Route>
        <Route path="stock" element={<BOstockList />}>
          庫存效期
        </Route>
        <Route path="into-warehouse" element={<Outlet />}>
          <Route path="/" element={<IntoWarehouse />} />
          <Route path="/:id" element={<IntoWarehouseReview />} />
          進倉
        </Route>
        <Route path="dealer" element={<Outlet />}>
          <Route path="/" element={<DealerPage />} />
          <Route path="/new" element={<DealerInformation />} />
          <Route path="/:dealerId" element={<DealerInformation />} />
          通路/經銷商
        </Route>
        <Route path="supply-chain" element={<Outlet />}>
          供應商管理
          <Route path="/" element={<SupplChainList />} />
          <Route path="/add" element={<SupplChainTabs mode="add" />} />
          <Route path="/edit/:supplyChainId" element={<SupplChainTabs mode="edit" />} />
          <Route
            path="/edit/:supplyChainId/:supplyChainRelationId/:currencyNumber"
            element={<SupplyChainProductList />}
          />
        </Route>
        <Route path="external-order" element={<Outlet />}>
          出貨訂單管理
          <Route path="/" element={<ExternalOrderPage />} />
          <Route path="/new" element={<ExternalOrderInformationPage />} />
          <Route path="/:externalOrderId/review" element={<OrderReviewPage />} />
          <Route path="/:externalOrderId" element={<ExternalOrderInformationPage />} />
        </Route>
        <Route path="manufacturer" element={<Outlet />}>
          製造商
          <Route path="/" element={<ManufacturerList />} />
          <Route path="/add" element={<ManufacturerInfo mode="add" />} />
          <Route path="/:id" element={<ManufacturerInfo mode="edit" />} />
        </Route>
      </Route>
      <Route path="delivery-fee-management">運費及運費門檻</Route>
      <Route path="tag-management">標籤管理</Route>
      <Route path="system-management">系統設定</Route>
      {/* 財務後台 */}
      <Route path="fiance" element={<Outlet />}>
        <Route path="order-payment" element={<OrderPaymentList />}>
          訂單請款清單
        </Route>
      </Route>
      <Route path="cooperation" element={<Cooperation />}>
        異業合作
      </Route>
      <Route path="invoice" element={<Invoice />}>
        收入(發票)清單
      </Route>
      <Route path="cooperation" element={<Cooperation />}>
        異業合作
      </Route>
      <Route path="cost-statement" element={<CostStatement />}>
        成本清單
      </Route>
      <Route path="return-list" element={<ReturnListPage />}>
        退貨清單
      </Route>
      <Route path="excess-inventory" element={<ExcessInventory />}>
        存貨呆滯提列
      </Route>
      <Route path="scrap-Inventory" element={<ScrapInventory />}>
        報廢
      </Route>
      <Route path="online-distributor-statistics" element={<OnlineDistributorStatistics />}>
        線上通路銷售報表
      </Route>
      <Route path="tryall-stock-management" element={<TryallStockManagement />}>
        藍田庫存異動數
      </Route>
      <Route path="statistics-download" element={<StatisticsDownload />}>
        報表下載
      </Route>
      {/* ---- 品運後台 ---- */}
      <Route path="operation-of-brand" element={<Outlet />}>
        <Route path="product" element={<Outlet />}>
          商品
          <Route path="/" element={<BrandOperationProduct />} />
          <Route path="/add" element={<AddProduct mode="add" />} />
          <Route path="/edit/:productId" element={<AddProduct mode="edit" />} />
        </Route>
        <Route path="stock" element={<BOstockList />}>
          庫存效期
        </Route>
        <Route path="into-warehouse" element={<Outlet />}>
          <Route path="/" element={<IntoWarehouse />} />
          <Route path="/:id" element={<IntoWarehouseReview />} />
          進倉
        </Route>
        <Route path="manufacturer" element={<Outlet />}>
          <Route path="/" element={<ManufacturerList />} />
          <Route path="/add" element={<ManufacturerInfo mode="add" />} />
          <Route path="/:id" element={<ManufacturerInfo mode="edit" />} />
          製造商
        </Route>
        <Route path="processing-application" element={<Outlet />}>
          <Route path="/" element={<ProcessingApplicationList />} />
          <Route path="/:id" element={<ProcessingApplicationTab />} />
          加工單
        </Route>
        <Route path="procurement" element={<Outlet />}>
          <Route path="/" element={<PurchaseOrder />} />
          <Route path="/:id/preview" element={<PurchaseOrderPreview />} />
          採購單
        </Route>
      </Route>
      {/* ---- 品運後台 ---- */}
      <Route element={<Navigate to="/dashboard" />} />
    </Routes>
  );
}
