import React, { FC, useMemo, useEffect, useState, ChangeEvent, KeyboardEvent } from "react";
import styled from "styled-components";
import { Button, Select, Table, Pagination, Input, Modal } from "antd";
import { PlusCircleFilled, ExclamationCircleOutlined } from "@ant-design/icons";
import { SelectValue } from "antd/lib/select";
import { useAppDispatch } from "src/store";
import { useSelector } from "react-redux";
import { RootState } from "@redux/rootReducer";
import { useParams } from "react-router-dom";
import { fetchMemberList, updateMemberListParams, singleDeleteMember, openImportCsvPopup } from "@redux/ruleSlice";
import { MemberListItem } from "@api/ruleApi";
import ImportCSV from "./ImportCSV";
import AddMember from "./AddMember";

const Wrapper = styled.div`
  grid-column: 2 span;
  width: 870px;
  grid-row-start: 6;
`;
const TableHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;
const AddButton = styled(Button)`
  margin-right: 10px;
`;
const ImportButton = styled(Button)`
  margin-right: 10px;
`;
const TableToolWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const TablePageInfo = styled.div`
  font-size: 14px;
`;
const PageSelect = styled(Select)`
  margin: 0 10px;
`;
const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;
const DeleteText = styled.p`
  font-size: 13px;
  color: ${({ theme }) => theme.colorSuccess500};
  cursor: pointer;
  margin: 0;
`;
const SearchInput = styled(Input)`
  width: 280px;
`;
const TableNoWrap = styled.div`
  white-space: nowrap;
`;

const pageSizeOptions = [20, 50, 100];

const ManageMember: FC = () => {
  const dispatch = useAppDispatch();
  const { isFetchingMemberList, memberListResult, memberListParams, showImportCsvPopup } = useSelector(
    (state: RootState) => state.rule,
  );
  const { id: ruleId } = useParams();

  const [searchPhone, setSearchPhone] = useState<string>("");
  const [showAddMember, setShowAddMember] = useState<boolean>(false);

  const { count, results: memberList } = memberListResult;
  const { limit, offset } = memberListParams;

  const currentPage = useMemo(() => {
    return Math.floor(offset / limit) + 1;
  }, [limit, offset]);

  useEffect(() => {
    if (ruleId) {
      dispatch(fetchMemberList(Number(ruleId)));
    }
  }, [dispatch, ruleId]);

  const toggleAddMember = () => setShowAddMember((prev) => !prev);

  const handleOnSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchPhone(value);
  };

  const checkIsRuleExisting = (action: () => void) => () => {
    if (ruleId) {
      action();
    } else {
      Modal.warning({
        title: "須先建立此筆資料，才能加入會員",
        okText: "我知道了",
      });
    }
  };

  const handleOnSearch = (e: KeyboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (ruleId) {
      const filter = {
        ...memberListParams,
        phone: searchPhone,
      };
      dispatch(updateMemberListParams(filter));
      dispatch(fetchMemberList(Number(ruleId)));
    }
  };

  const handleOnPageSizeChange = (value: SelectValue) => {
    if (ruleId) {
      const filter = {
        ...memberListParams,
        limit: value as number,
      };
      dispatch(updateMemberListParams(filter));
      dispatch(fetchMemberList(Number(ruleId)));
    }
  };

  const handleOnPageChange = (page: number) => {
    if (ruleId) {
      const filter = {
        ...memberListParams,
        offset: (page - 1) * limit,
      };
      dispatch(updateMemberListParams(filter));
      dispatch(fetchMemberList(Number(ruleId)));
    }
  };

  const handleOnSingleDelete = (id: number) => () => {
    Modal.confirm({
      title: "你確定要刪除這些資料?",
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: "是",
      cancelText: "否",
      onOk() {
        dispatch(singleDeleteMember(id));
      },
    });
  };

  const handleOnOpenImportCSV = () => {
    dispatch(openImportCsvPopup());
  };

  const tableColumns = [
    {
      title: <TableNoWrap>會員ID</TableNoWrap>,
      dataIndex: ["member", "id"],
    },
    {
      title: <TableNoWrap>會員名稱</TableNoWrap>,
      dataIndex: ["member", "name"],
    },
    {
      title: <TableNoWrap>電話</TableNoWrap>,
      dataIndex: ["member", "phone"],
    },
    {
      title: <TableNoWrap>綁定時間</TableNoWrap>,
      dataIndex: "lastBoundAt",
      width: 250,
    },
    {
      title: "",
      dataIndex: "",
      render: (value: any, data: MemberListItem) => (
        <DeleteText onClick={handleOnSingleDelete(data.member.id)}>刪除</DeleteText>
      ),
    },
  ];

  return (
    <Wrapper>
      <TableHeader>
        <TableToolWrapper>
          <AddButton type="primary" icon={<PlusCircleFilled />} onClick={checkIsRuleExisting(toggleAddMember)}>
            新增
          </AddButton>
          <ImportButton onClick={checkIsRuleExisting(handleOnOpenImportCSV)}>批次匯入</ImportButton>
          <SearchInput
            placeholder="搜尋電話"
            value={searchPhone}
            onChange={handleOnSearchInputChange}
            onPressEnter={handleOnSearch}
          />
        </TableToolWrapper>
        <TablePageInfo>
          總共
          {count}
          筆, 每頁顯示
          <PageSelect value={limit} onChange={handleOnPageSizeChange}>
            {pageSizeOptions.map((option) => (
              <Select.Option key={option} value={option}>
                {option}
              </Select.Option>
            ))}
          </PageSelect>
          筆
        </TablePageInfo>
      </TableHeader>
      <Table
        dataSource={memberList}
        columns={tableColumns}
        scroll={{ x: "max-content", y: 400 }}
        tableLayout="auto"
        pagination={false}
        loading={isFetchingMemberList}
        rowKey="id"
      />
      <Footer>
        <Pagination
          current={currentPage}
          total={count}
          onChange={handleOnPageChange}
          showSizeChanger={false}
          pageSize={limit}
        />
      </Footer>
      {showImportCsvPopup && <ImportCSV />}
      {showAddMember && <AddMember onClose={toggleAddMember} />}
    </Wrapper>
  );
};

export default ManageMember;
