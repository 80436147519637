import React, { FC, useEffect, useMemo } from "react";
import styled from "styled-components";
import { Select, Table, Button } from "antd";
import { SelectValue } from "antd/lib/select";
import { useSelector } from "react-redux";
import { RootState } from "@redux/rootReducer";
import { useAppDispatch } from "src/store";
import { updateVPCSalesPlanListParams, fetchVPCSalesPlanList } from "@redux/salesReportSlice";
import { VPCSalesPlanListItem } from "@api/salesReportApi";
import PopupBackground from "@component/PopupBackground";

const Wrapper = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 1100px;
  background-color: white;
  border-radius: 2px;
`;
const TablePageInfo = styled.div`
  font-size: 14px;
  margin-bottom: 16px;
`;
const PageSelect = styled(Select)`
  margin: 0 10px;
`;
const TableContainer = styled.div`
  border: 1px solid #f0f0f0;
  margin-bottom: 15px;
  width: 100%;
`;
const Link = styled.a`
  color: ${({ theme }) => theme.colorSuccess500};
`;

interface Props {
  vpcId: number;
  onClose: () => void;
}

const pageSizeOptions = [20, 50, 100];

const SalePlanModal: FC<Props> = (props) => {
  const { vpcId, onClose } = props;

  const dispatch = useAppDispatch();
  const { isFetchingVPCSalesPlanList, VPCSalesPlanListParams, VPCSalesPlanListResult } = useSelector(
    (state: RootState) => state.salesReport,
  );

  const { count, results: VPCSubOrderList } = VPCSalesPlanListResult;
  const { offset, limit } = VPCSalesPlanListParams;

  const currentPage = useMemo(() => {
    return Math.floor(offset / limit) + 1;
  }, [limit, offset]);

  useEffect(() => {
    dispatch(fetchVPCSalesPlanList(vpcId));
  }, [dispatch, vpcId]);

  const handleOnPageSizeChange = (value: SelectValue) => {
    const filter = {
      ...VPCSalesPlanListParams,
      limit: value as number,
    };

    dispatch(updateVPCSalesPlanListParams(filter));
    dispatch(fetchVPCSalesPlanList(vpcId));
  };

  const handleOnPageChange = (page: number) => {
    const filter = {
      ...VPCSalesPlanListParams,
      offset: (page - 1) * limit,
    };

    dispatch(updateVPCSalesPlanListParams(filter));
    dispatch(fetchVPCSalesPlanList(vpcId));
  };

  const tableColumns = [
    {
      title: "ID",
      dataIndex: "orderSalesPlanPageId",
      width: 65,
      render: (value: VPCSalesPlanListItem["orderSalesPlanPageId"]) => (
        <Link href={`/salespage/edit/${value}`} target="_blank">
          {value}
        </Link>
      ),
    },
    {
      title: "銷售頁名稱",
      dataIndex: "pageName",
      width: 200,
    },
    {
      title: "方案名稱",
      dataIndex: "planName",
      width: 100,
    },
    {
      title: "目前方案價格",
      dataIndex: "planPrice",
      width: 120,
    },
    {
      title: "項目成本",
      dataIndex: "cost",
      width: 100,
    },
    {
      title: "項目售價",
      dataIndex: "unitPrice",
      width: 100,
    },
    {
      title: "項目抽成",
      dataIndex: "commissionRate",
      width: 100,
      render: (value: VPCSalesPlanListItem["commissionRate"]) => (value ? `${value}%` : "N/A"),
    },
    {
      title: "銷量",
      dataIndex: "salesVolume",
      width: 80,
    },
    {
      title: "營收",
      dataIndex: "revenue",
      width: 80,
    },
    {
      title: "",
      dataIndex: "id",
      width: 70,
      render: (_: any, other: { orderSalesPlanPageId: VPCSalesPlanListItem["orderSalesPlanPageId"] }) => (
        <Link href={`/salespage/edit/${other.orderSalesPlanPageId}`} target="_blank">
          編輯
        </Link>
      ),
    },
  ];

  return (
    <PopupBackground close={onClose}>
      <Wrapper>
        <TablePageInfo>
          總共
          {count}
          筆, 每頁顯示
          <PageSelect value={limit} onChange={handleOnPageSizeChange}>
            {pageSizeOptions.map((option) => (
              <Select.Option key={option} value={option}>
                {option}
              </Select.Option>
            ))}
          </PageSelect>
          筆
        </TablePageInfo>
        <TableContainer>
          <Table
            dataSource={VPCSubOrderList}
            columns={tableColumns}
            scroll={{ x: "max-content", y: 222 }}
            tableLayout="auto"
            pagination={{
              pageSize: limit,
              current: currentPage,
              total: count,
              onChange: handleOnPageChange,
              showSizeChanger: false,
            }}
            loading={isFetchingVPCSalesPlanList}
          />
        </TableContainer>
        <Button onClick={onClose}>關閉</Button>
      </Wrapper>
    </PopupBackground>
  );
};

export default SalePlanModal;
