/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import { Select } from "antd";
import bankData from "@constant/bank.json";
import { FormInstance } from "antd/lib/form";

const { Option } = Select;

type Props = {
  bankCode: string;
  form: FormInstance;
};

export default function BankBranchList(props: Props) {
  const { form, bankCode } = props;
  const [bankBranchList, setBankBranchList] = useState<{ bankBranchName: string; branchCode: string }[]>();
  const formValue = form.getFieldsValue();

  useEffect(() => {
    const banches = bankData
      .filter((item) => item.bankCode === formValue.bankName)
      .map((item) => ({
        bankBranchName: item.bankName,
        branchCode: item.branchCode,
      }));
    setBankBranchList(banches);
  }, [formValue.bankName]);

  const triggerChange = (branchCode: string) => {
    form.setFieldsValue({ branchName: branchCode });
  };

  const formBranchName = form.getFieldValue("branchName");

  return (
    <Select
      value={formBranchName || -1}
      showSearch
      onChange={triggerChange}
      style={{ width: "100%" }}
      filterOption={(input, option) => option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
    >
      <Option disabled value={-1} key="bank-1">
        請選擇
      </Option>
      {bankBranchList?.map((info: { branchCode: string; bankBranchName: string }) => (
        <Option value={info.bankBranchName} key={`bankBranch${info.branchCode}`}>
          {info.bankBranchName}
        </Option>
      ))}
    </Select>
  );
}
