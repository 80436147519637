import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { ColumnsType } from "antd/lib/table";
import { useParams } from "react-router-dom";
import { Button, Table, Modal, Select, Pagination } from "antd";
import { PlusCircleTwoTone, ExclamationCircleOutlined } from "@ant-design/icons";
import RetailerPopup from "@page/SupplyChain/RetailerPopup";
import { RootState } from "@redux/rootReducer";
import { useSelector } from "react-redux";
import { useAppDispatch } from "src/store";
import { SelectValue } from "antd/lib/select";
import { fetchSupplyChainRetailList, deleteRetailer, updateSupplyChainFilter } from "@redux/supplyChainSlice";
import { Region, SupplyChainRetailerDetail, Currency, Contract } from "@api/supplyChainApi";

const Wrapper = styled.div``;

const Text = styled.div`
  text-align: left;
`;
const Links = styled.div`
  display: flex;
  color: #1790ff;
  cursor: pointer;
`;
const Edit = styled.div`
  white-space: nowrap;
  color: #1790ff;
  cursor: pointer;
`;

const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 21px;
`;
const TablePageInfo = styled.div`
  font-size: 14px;
  margin-right: 10px;
`;
const PageSelect = styled(Select)`
  margin: 0 10px;
`;
const TableContainer = styled.div`
  position: relative;
  padding: 20px 10px 50px 10px;
  border: 1px solid #f0f0f0;
`;
const TablePagination = styled(Pagination)`
  position: absolute;
  right: 21px;
  bottom: 10px;
`;

const Retailer = () => {
  const [openPopup, setOpenPopup] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const supplyChainSlice = useSelector((state: RootState) => state.supplyChain);
  const supplyChainRetailList = supplyChainSlice.supplyChainRetailList.results;
  const { supplyChainId } = useParams();
  const { count } = supplyChainSlice.supplyChainRetailList;
  const { isFetching } = supplyChainSlice;
  const { supplyChainFilter } = supplyChainSlice;
  const { limit, offset } = supplyChainFilter;

  const currentPage = offset && limit && Math.floor(offset / limit) + 1;
  const handleOnPageSizeChange = (value: SelectValue) => {
    const filter = {
      ...supplyChainFilter,
      limit: value as number,
      supplyChain: Number(supplyChainId),
    };
    dispatch(updateSupplyChainFilter(filter));
  };
  const handleOnPageChange = (page: number) => {
    if (limit) {
      const filter = {
        ...supplyChainFilter,
        offset: (page - 1) * limit,
        supplyChain: Number(supplyChainId),
      };
      dispatch(updateSupplyChainFilter(filter));
    }
  };

  const tableColumns: ColumnsType<SupplyChainRetailerDetail> = [
    {
      title: "合作通路類型",
      key: "regionType",
      dataIndex: "regionType",
      render: (data) => {
        switch (data) {
          case Region.Domestic:
            return "台灣本地";
          case Region.Foreign:
            return "境外通路";
          default:
            return "";
        }
      },
    },
    {
      title: "地區",
      key: "regionName",
      dataIndex: "regionName",
    },
    {
      title: "通路名稱",
      key: "retailer",
      dataIndex: "retailer",
    },
    {
      title: "線別",
      key: "productLine",
      dataIndex: "productLine",
    },
    {
      title: "幣別",
      key: "currency",
      dataIndex: "currency",
      render: (data) => {
        switch (data) {
          case Currency.TWD:
            return "TWD";
          case Currency.USD:
            return "USD";
          case Currency.RMB:
            return "RMB";
          default:
            return "";
        }
      },
    },
    {
      title: "檢視合約",
      key: "contract",
      dataIndex: "contract",
      render: (data) => {
        return data ? <Links onClick={() => handleOpenContract(data)}>{data.name}</Links> : <Text>N/A</Text>;
      },
    },
    {
      title: "合約生效時間",
      key: "startAt",
      dataIndex: "startAt",
      render: (data) => {
        return data ? <Text>{data.startAt}</Text> : <Text>N/A</Text>;
      },
    },
    {
      title: "合約結束時間",
      key: "endAt",
      dataIndex: "endAt",
      render: (data) => {
        return data ? <Text>{data.endAt}</Text> : <Text>N/A</Text>;
      },
    },
    {
      title: "",
      key: "",
      dataIndex: "",
      fixed: "right",
      render: (value, data) => {
        return <Edit onClick={() => handleDelete(data.id)}>刪除</Edit>;
      },
    },
    {
      title: "",
      key: "",
      dataIndex: "",
      fixed: "right",
      render: (data) => {
        return <Edit onClick={() => handleOnOpen(data)}>供應商品列表</Edit>;
      },
    },
  ];

  const handleOpenContract = (data: Contract) => {
    if (data.path) {
      window.open(data.path, "_blank");
    }
  };
  const handleDelete = (deleteId: number) => {
    Modal.confirm({
      title: "你確定要刪除這些資料?",
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: "是",
      cancelText: "否",
      onOk: () => {
        const filter = {
          id: deleteId,
          supplyChain: Number(supplyChainId),
        };
        dispatch(deleteRetailer(filter));
      },
    });
  };
  const handlePopup = () => {
    setOpenPopup((prev) => !prev);
  };

  const handleOnOpen = (data: SupplyChainRetailerDetail) => {
    window.open(`/operation-of-brand/supply-chain/edit/${supplyChainId}/${data.id}/${data.currency}`, "_blank");
  };
  useEffect(() => {
    const filter = {
      ...supplyChainFilter,
      supplyChain: Number(supplyChainId),
    };
    dispatch(fetchSupplyChainRetailList(filter));
  }, [dispatch, supplyChainFilter, supplyChainId]);

  return (
    <Wrapper>
      <Header>
        <Button type="primary" icon={<PlusCircleTwoTone />} onClick={handlePopup}>
          選擇合作通路
        </Button>
        {openPopup && <RetailerPopup onClose={handlePopup} />}
        <TablePageInfo>
          總共
          {count}
          筆, 每頁顯示
          <PageSelect defaultValue="20" onChange={handleOnPageSizeChange}>
            <Select.Option value={20}>20</Select.Option>
            <Select.Option value={50}>50</Select.Option>
            <Select.Option value={100}>100</Select.Option>
          </PageSelect>
          筆
        </TablePageInfo>
      </Header>
      <TableContainer>
        <Table
          loading={isFetching}
          scroll={{ x: "max-content" }}
          dataSource={supplyChainRetailList}
          columns={tableColumns}
          rowKey="id"
          pagination={false}
        />
        <TablePagination
          current={currentPage}
          total={count}
          onChange={handleOnPageChange}
          showSizeChanger={false}
          pageSize={limit}
        />
      </TableContainer>
    </Wrapper>
  );
};

export default Retailer;
