/* eslint-disable react/jsx-props-no-spreading */
import { MenuOutlined, PlusCircleTwoTone } from "@ant-design/icons";
import { Option } from "@api/saleApi";
import { modeList } from "@api/utils/normalizeContract";
import {
  fetchDeleteOption,
  fetchOptionList,
  fetchUpdateOptionRanks,
  SaleState,
  setCurrentOption,
} from "@redux/saleSlice";
import { Button, Row, Select, Table } from "antd";
import { SelectValue } from "antd/lib/select";
import { arrayMoveImmutable } from "array-move";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";
import fontStyle from "src/styles/fontStyle";
import styled from "styled-components";
import ModalComplex, { ModalType } from "./ModalComplex";

const DragHandle = SortableHandle(() => <MenuOutlined style={{ cursor: "grab", color: "#999" }} />);

const Wrapper = styled.div``;

const PageText = styled.span`
  ${fontStyle("14px", "20px")};
`;

const SelectPageSize = styled(Select)`
  &&& {
    margin: 0 10px;
  }
`;

const TableWrapper = styled.div`
  border: solid 1px #f0f0f0;
  border-radius: 2px;
  padding: 20px 10px 22px 17px;
  margin-top: 20px;
`;

const Notice = styled.span`
  margin-left: 9px;
  color: ${({ theme }) => theme.colorNeutral500};
  ${fontStyle("13px", "22px")};
`;

export default function PlanContent() {
  const dispatch = useDispatch();
  const { isFetching, optionListResult, isEditOptionDone } = useSelector(SaleState);

  const { salesId } = useParams();

  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(20);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState<Option[]>([]);

  const setPageLimit = (value: SelectValue) => {
    setPageSize(parseInt(value as string, 10));
  };

  const setModalOpen = (option?: Option) => {
    dispatch(setCurrentOption(option));
    setOpenModal(true);
  };

  const fetchPageChange = (value: number) => {
    window.scrollTo(0, 0);
    setPage(value);

    dispatch(fetchOptionList({ salesPage: parseInt(salesId, 10), limit: pageSize, offset: (value - 1) * pageSize }));
  };

  const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
    if (oldIndex !== newIndex) {
      const optionMap = new Map<any, any>();
      const afterMoveArray = arrayMoveImmutable([].concat(dataSource as any), oldIndex, newIndex);
      const changesOldItem = dataSource[oldIndex];
      const changesNewItemNext: Option = afterMoveArray[newIndex + 1];
      changesOldItem.optionIds.forEach((optionId) => {
        if (!optionMap.has(optionId)) {
          optionMap.set(optionId, { optionId, rank: newIndex });
        }
      });

      if (changesNewItemNext && oldIndex > newIndex) {
        changesNewItemNext.optionIds.forEach((optionId) => {
          if (!optionMap.has(optionId)) {
            optionMap.set(optionId, { optionId, rank: newIndex + 1 });
          }
        });
      }

      arrayMoveImmutable([].concat(dataSource as any), oldIndex, newIndex).forEach((item: Option, index) => {
        item.optionIds.forEach((optionId) => {
          if (!optionMap.has(optionId)) {
            optionMap.set(optionId, { optionId, rank: index });
          }
        });
      });

      dispatch(
        fetchUpdateOptionRanks({
          salesOptionRankData: Array.from(optionMap.values()),
        }),
      );
    }
  };

  const SortableItem = SortableElement((props: any) => <tr {...props} />);
  const SortableContainers = SortableContainer((props: any) => <tbody {...props} />);

  const DraggableContainer = (props: any) => (
    <SortableContainers useDragHandle disableAutoscroll helperClass="row-dragging" onSortEnd={onSortEnd} {...props} />
  );

  const DraggableBodyRow = (props: any) => {
    const index = dataSource.findIndex((x: any) => x.index === props["data-row-key"]);
    return <SortableItem index={index} {...props} />;
  };

  const columns = [
    {
      title: "",
      dataIndex: "sort",
      width: 30,
      className: "drag-visible",
      render: () => <DragHandle />,
    },
    {
      key: "productName",
      dataIndex: "productName",
      title: "商品名稱",
      render: (_: any, others: Option) => <div>{others.productInfo.name}</div>,
    },
    {
      key: "optionName",
      dataIndex: "optionName",
      title: "選項名稱",
    },
    {
      key: "sku",
      dataIndex: "sku",
      title: "SKU",
      render: (_: any, others: Option) => <div>{others.productInfo.sku}</div>,
    },
    {
      key: "brand",
      dataIndex: "brand",
      title: "品牌",
      render: (_: any, others: Option) => <div>{others.productInfo.brand}</div>,
    },
    {
      key: "cooperation",
      dataIndex: "cooperation",
      title: "合作模式",
      render: (_: any, others: Option) => <div>{modeList[others.vpcInfo.contractMode]}</div>,
    },
    {
      key: "price",
      dataIndex: "price",
      title: "售價",
      render: (_: any, others: Option) => <div>{others.vpcInfo.price}</div>,
    },
    {
      key: "cost",
      dataIndex: "cost",
      title: "成本",
      render: (_: any, others: Option) => <div>{others.vpcInfo.cost}</div>,
    },
    {
      key: "commissionRate",
      dataIndex: "commissionRate",
      title: "抽成",
      render: (_: any, others: Option) => <div>{others.vpcInfo.commissionRate}</div>,
    },
    {
      key: "availableForSale",
      dataIndex: "availableForSale",
      title: "可賣量",
      render: (_: any, others: Option) => <div>{others.productInfo.canBuyCount}</div>,
    },
    {
      key: "buttons",
      dataIndex: "buttons",
      title: "",
      render: (_: any, others: Option) => (
        <>
          <Button type="link" onClick={() => setModalOpen(others)}>
            編輯
          </Button>
          <Button
            type="link"
            onClick={() =>
              dispatch(
                fetchDeleteOption({
                  salePageId: parseInt(salesId, 10),
                  payload: {
                    optionIds: others.optionIds,
                  },
                }),
              )
            }
          >
            刪除
          </Button>
        </>
      ),
    },
  ];

  useEffect(() => {
    setPage(1);
    dispatch(fetchOptionList({ salesPage: parseInt(salesId, 10), limit: pageSize, offset: 0 }));
  }, [dispatch, salesId, pageSize]);

  useEffect(() => {
    if (isEditOptionDone) {
      setPage(1);
      dispatch(fetchOptionList({ salesPage: parseInt(salesId, 10), limit: pageSize, offset: 0 }));
    }
  }, [isEditOptionDone, dispatch, salesId, pageSize]);

  useEffect(() => {
    const sortOptionList = optionListResult.results.map((item, index) => ({ ...item, index })); // for drag library
    sortOptionList.sort((a, b) => a.optionRank - b.optionRank);
    setDataSource(sortOptionList);
  }, [optionListResult.results]);

  return (
    <Wrapper>
      <Row justify="space-between">
        <div>
          <Button
            type="primary"
            icon={<PlusCircleTwoTone twoToneColor="#1890FF" />}
            onClick={() => setModalOpen(undefined)}
          >
            新增選項
          </Button>
          <Notice>*建立後顯示方案內容頁面</Notice>
        </div>
        <Row align="middle">
          <PageText>{`總共${optionListResult.count}筆, 每頁顯示`}</PageText>
          <SelectPageSize defaultValue="20" onChange={setPageLimit}>
            <Select.Option value="20">20</Select.Option>
            <Select.Option value="50">50</Select.Option>
            <Select.Option value="100">100</Select.Option>
          </SelectPageSize>
          <PageText>筆</PageText>
        </Row>
      </Row>
      <TableWrapper>
        <Table
          loading={isFetching}
          columns={columns}
          dataSource={dataSource}
          rowKey="index"
          scroll={{ x: "max-content", y: 400 }}
          pagination={{
            current: page,
            total: optionListResult.count,
            pageSize,
            showSizeChanger: false,
            onChange: fetchPageChange,
          }}
          components={{
            body: {
              wrapper: DraggableContainer,
              row: DraggableBodyRow,
            },
          }}
        />
      </TableWrapper>
      {openModal && <ModalComplex sequence={[ModalType.OPTION]} close={() => setOpenModal(false)} />}
    </Wrapper>
  );
}
