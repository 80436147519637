import React, { FC, useEffect, useMemo, ChangeEvent, useCallback } from "react";
import styled from "styled-components";
import { Button, Input, Select } from "antd";
import { SelectValue } from "antd/lib/select";
import { useAppDispatch } from "src/store";
import { RootState } from "@redux/rootReducer";
import { useSelector } from "react-redux";
import useDebounce from "@hooks/useDebounce";
import {
  fetchSalePageListExcludeCategory,
  updateSalePageListFilter,
  fetchBrandList,
  loadMoreBrandList,
} from "@redux/salesCategoryAddSalesSlice";
import InfiniteScrollObserver from "@component/InfiniteScrollObserver";

const Wrapper = styled.div`
  position: relative;
  display: grid;
  padding-bottom: 26px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto auto;
  column-gap: 50px;
  row-gap: 12px;
  border-bottom: 1px solid #f2f2f2;
  margin-bottom: 14px;
`;
const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Title = styled.div`
  flex: 1;
  font-size: 14px;
  line-height: 1.5;
  color: ${({ theme }) => theme.colorNeutral600};
`;
const Content = styled.div`
  flex: 3;
`;
const PriceInput = styled(Input)`
  width: calc(50% - 10px);
  &:first-child {
    margin-right: 20px;
  }
`;
const MaxWidthSelect = styled(Select)`
  width: 100%;
`;
const SearchButton = styled(Button)`
  position: absolute;
  bottom: 18px;
  right: 0;
`;

const Filter: FC = () => {
  const filter = useSelector((state: RootState) => state.salesCategoryAddSales.salePageListFilter);
  const brandListResult = useSelector((state: RootState) => state.salesCategoryAddSales.brandListResult);
  const isFetching = useSelector((state: RootState) => state.salesCategoryAddSales.isFetching);
  const dispatch = useAppDispatch();

  const { pageIds, pageName, sku, brandNames, minPrice, maxPrice } = filter;

  const handleOnBrandLoadMore = useCallback(() => {
    dispatch(loadMoreBrandList());
  }, [dispatch]);

  const brandOptions = useMemo(() => {
    const { next, results } = brandListResult;
    const options = results.map((brand) => (
      <Select.Option key={brand.id} value={brand.name}>
        {brand.name}
      </Select.Option>
    ));

    if (next) {
      options.push(
        <Select.Option value="loading..." disabled>
          loading...
          <InfiniteScrollObserver callback={handleOnBrandLoadMore} />
        </Select.Option>,
      );
    }

    return options;
  }, [brandListResult, handleOnBrandLoadMore]);

  useEffect(() => {
    dispatch(fetchBrandList(""));
  }, [dispatch]);

  const handleOnBrandSearch = useDebounce((value: string) => {
    dispatch(fetchBrandList(value));
  }, 300);

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, title } = e.target;

    const updatedFilter = {
      ...filter,
      [title]: value,
    };

    dispatch(updateSalePageListFilter(updatedFilter));
  };

  const handleOnPriceChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, title } = e.target;
    const targetValue = value === "" ? "" : Number(value);

    const updatedFilter = {
      ...filter,
      [title]: targetValue,
    };

    dispatch(updateSalePageListFilter(updatedFilter));
  };

  const handleOnBrandChange = (values: SelectValue) => {
    const updatedFilter = {
      ...filter,
      brandNames: values as string[],
    };

    dispatch(updateSalePageListFilter(updatedFilter));
  };

  const handleOnSearch = () => {
    dispatch(fetchSalePageListExcludeCategory());
  };

  return (
    <Wrapper>
      <Row>
        <Title>銷售頁ID</Title>
        <Content>
          <Input value={pageIds} title="pageIds" onChange={handleOnChange} />
        </Content>
      </Row>
      <Row>
        <Title>銷售頁名稱</Title>
        <Content>
          <Input value={pageName} title="pageName" onChange={handleOnChange} />
        </Content>
      </Row>
      <Row>
        <Title>SKU</Title>
        <Content>
          <Input value={sku} title="sku" onChange={handleOnChange} />
        </Content>
      </Row>
      <Row>
        <Title>品牌</Title>
        <Content>
          <MaxWidthSelect
            mode="multiple"
            allowClear
            value={brandNames}
            filterOption={false}
            loading={isFetching}
            onSearch={handleOnBrandSearch}
            onChange={handleOnBrandChange}
          >
            {brandOptions}
          </MaxWidthSelect>
        </Content>
      </Row>
      <Row>
        <Title>價錢</Title>
        <Content>
          <PriceInput
            value={minPrice}
            title="minPrice"
            type="number"
            onChange={handleOnPriceChange}
            placeholder="From"
          />
          <PriceInput value={maxPrice} title="maxPrice" type="number" onChange={handleOnPriceChange} placeholder="To" />
        </Content>
      </Row>
      <SearchButton type="primary" onClick={handleOnSearch}>
        搜尋
      </SearchButton>
    </Wrapper>
  );
};

export default Filter;
