/**
 * 還貨列表篩選
 */
export enum ReturnOrderSortMethod {
  ByEstimateShippingDate = "estimated_shipping_date",
  ByActualShippingDate = "shipped_date",
  ByCreateOrderDate = "created_at",
  ByCost = "total_cost",
  ByQty = "total_qty",
}

export const temp = {};
