export enum ChargingMethod {
  NO_CHARGE = 1,
  CASH_ON_DELIVERY = 2,
  SELF_PICKUP = 3,
  CHARTER = 4,
  HCT_CHARTER = 5,
  T_CAT = 6,
}

export const chargingMethodOptions: { [key: number]: { label: string; value: ChargingMethod } } = {
  [ChargingMethod.NO_CHARGE]: { label: "黑貓宅配", value: ChargingMethod.NO_CHARGE },
  [ChargingMethod.CASH_ON_DELIVERY]: { label: "運費到付", value: ChargingMethod.CASH_ON_DELIVERY },
  [ChargingMethod.SELF_PICKUP]: { label: "廠商自取", value: ChargingMethod.SELF_PICKUP },
  [ChargingMethod.CHARTER]: { label: "通路配送 (專車)", value: ChargingMethod.CHARTER },
  [ChargingMethod.HCT_CHARTER]: { label: "通路配送 (新竹)", value: ChargingMethod.HCT_CHARTER },
  [ChargingMethod.T_CAT]: { label: "通路配送 (黑貓)", value: ChargingMethod.T_CAT },
};
