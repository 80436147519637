import React, { useState, useEffect, ReactText, useMemo } from "react";
import { Button, Table, Select, Modal, Pagination } from "antd";
import { PlusCircleTwoTone, ExclamationCircleOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { RootState } from "@redux/rootReducer";
import { useSelector } from "react-redux";
import { useAppDispatch } from "src/store";
import {
  fetchShippingFeeList,
  updateSelectedProductIds,
  deleteShippingFeeItem,
  updateShippingFeeListFilter,
} from "@redux/shippingFeeSlice";
import { ShippingFeeListItem } from "@api/shippingFeeApi";
import { ColumnsType } from "antd/lib/table";
import { SelectValue } from "antd/lib/select";
import AddPopup, { logisticMethod } from "./AddPopup";

const Wrapper = styled.div`
  padding-left: 16px;
`;
const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 21px;
`;
const TableContainer = styled.div`
  position: relative;
  padding: 20px 10px 50px 10px;
  border: 1px solid #f0f0f0;
`;
const TableNoWrap = styled.div`
  white-space: nowrap;
`;
const TablePageInfo = styled.div`
  font-size: 14px;
  margin-right: 10px;
`;
const TablePagination = styled(Pagination)`
  position: absolute;
  right: 21px;
  bottom: 10px;
`;
const DeleteButton = styled(Button)`
  position: absolute;
  left: 16px;
  bottom: 10px;
`;
const PageSelect = styled(Select)`
  margin: 0 10px;
`;
const Edit = styled.div`
  white-space: nowrap;
  color: #1790ff;
  cursor: pointer;
`;
const ShippingFeeSetting = () => {
  const pageSizeOptions = [20, 50, 100];
  const dispatch = useAppDispatch();
  const [showAddPopup, setShowAddPopup] = useState<boolean>(false);
  const [currentData, setCurrentData] = useState<ShippingFeeListItem | null>(null);
  const shippingFeeListFilter = useSelector((state: RootState) => state.shippingFee.shippingFeeListFilter);
  const selectedProductIds = useSelector((state: RootState) => state.shippingFee.selectedProductIds);
  const shippingFeeListResult = useSelector((state: RootState) => state.shippingFee);
  const shippingList = shippingFeeListResult.shippingFeeListResult.results;
  const handleOnCheck = (values: ReactText[]) => {
    dispatch(updateSelectedProductIds(values as number[]));
  };

  const { count } = shippingFeeListResult.shippingFeeListResult;
  const { limit, offset } = shippingFeeListFilter;
  const currentPage = useMemo(() => {
    return Math.floor(offset / limit) + 1;
  }, [limit, offset]);

  const handleOnPageSizeChange = (value: SelectValue) => {
    const filter = {
      ...shippingFeeListFilter,
      limit: value as number,
    };
    dispatch(updateShippingFeeListFilter(filter));
    dispatch(fetchShippingFeeList());
  };

  const handleOnPageChange = (page: number) => {
    const filter = {
      ...shippingFeeListFilter,
      offset: (page - 1) * limit,
    };
    dispatch(updateShippingFeeListFilter(filter));
    dispatch(fetchShippingFeeList());
  };

  const handleDelete = (ids: any) => {
    Modal.confirm({
      title: "你確定要刪除這些資料?",
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: "是",
      cancelText: "否",
      onOk: () => {
        dispatch(deleteShippingFeeItem(ids));
      },
    });
  };
  const tableColumns: ColumnsType<any> = [
    {
      title: <TableNoWrap>規則名稱</TableNoWrap>,
      dataIndex: "name",
    },
    {
      title: <TableNoWrap>狀態</TableNoWrap>,
      dataIndex: "isActive",
      width: 100,
      render: (value: ShippingFeeListItem["isActive"]) => (value ? "上架" : "下架"),
    },
    {
      title: <TableNoWrap>開始時間</TableNoWrap>,
      key: "startTime",
      dataIndex: "startAt",
      width: 140,
    },
    {
      title: <TableNoWrap>結束時間</TableNoWrap>,
      key: "endTime",
      dataIndex: "endAt",
      width: 140,
    },
    {
      title: <TableNoWrap>運費</TableNoWrap>,
      dataIndex: "shippingFee",
      width: 100,
    },
    {
      title: <TableNoWrap>免運門檻</TableNoWrap>,
      dataIndex: "shippingThreshold",
      width: 100,
    },
    {
      title: <TableNoWrap>物流</TableNoWrap>,
      dataIndex: "logisticMethod",
      width: 100,
      render: (value: ShippingFeeListItem["logisticMethod"]) => {
        const logisticOption = logisticMethod.find((mtd: { label: string; value: number }) => mtd.value === value);
        return logisticOption?.label || "N/A";
      },
    },
    {
      title: "",
      key: "",
      dataIndex: "",
      fixed: "right",
      render: (value: any, data: ShippingFeeListItem) => {
        return <Edit onClick={() => toggleEditPopup(data)}>編輯</Edit>;
      },
    },
  ];

  const handleOnAdd = () => {
    toggleAddPopup();
  };
  const toggleAddPopup = () => {
    setShowAddPopup((prev) => !prev);
  };
  const toggleEditPopup = (data: ShippingFeeListItem) => {
    setShowAddPopup(true);
    setCurrentData(data);
  };
  useEffect(() => {
    dispatch(fetchShippingFeeList());
  }, [dispatch]);

  return (
    <Wrapper>
      <Header>
        <Button type="primary" icon={<PlusCircleTwoTone />} onClick={() => setShowAddPopup(true)}>
          新增規則
          {showAddPopup && (
            <AddPopup
              onClose={() => setShowAddPopup(false)}
              onConfirm={handleOnAdd}
              currentData={currentData}
              setCurrentData={setCurrentData}
            />
          )}
        </Button>
        <TablePageInfo>
          總共
          {count}
          筆, 每頁顯示
          <PageSelect value={limit} onChange={handleOnPageSizeChange}>
            {pageSizeOptions.map((option) => (
              <Select.Option value={option}>{option}</Select.Option>
            ))}
          </PageSelect>
          筆
        </TablePageInfo>
      </Header>
      <TableContainer>
        <Table
          scroll={{ x: "max-content", y: 400 }}
          dataSource={shippingList}
          columns={tableColumns}
          rowSelection={{
            onChange: handleOnCheck,
            selectedRowKeys: selectedProductIds,
          }}
          rowKey="id"
          pagination={false}
        />
        <TablePagination
          current={currentPage}
          total={count}
          onChange={handleOnPageChange}
          showSizeChanger={false}
          pageSize={limit}
        />
        <DeleteButton onClick={() => handleDelete(selectedProductIds)}>刪除</DeleteButton>
      </TableContainer>
    </Wrapper>
  );
};

export default ShippingFeeSetting;
