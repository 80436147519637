import React, { FC, useState, useEffect } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { ChartParams, GroupByValue } from "@api/dashboardApi";
import { Radio, Select, DatePicker, Checkbox, Button } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { SelectValue } from "antd/lib/select";
import { RadioChangeEvent } from "antd/lib/radio";
import { RangeValue } from "rc-picker/lib/interface";
import moment, { Moment } from "moment";
import { useAppDispatch } from "src/store";
import { updateChartParams, DashboardTime } from "@redux/dashboardSlice";

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 20px;
`;
const LeftContainer = styled.div`
  display: flex;
  align-items: center;
`;
const Title = styled.div`
  font-size: 20px;
  color: #374151;
  margin-right: 10px;
`;
const RightContainer = styled.div`
  display: inline-grid;
  grid-template-columns: auto auto auto;
  grid-template-rows: auto auto;
  grid-column-gap: 14px;
  grid-row-gap: 10px;
  align-items: center;
  margin-bottom: 10px;
`;
const RowTitle = styled.div`
  font-size: 12px;
  color: rgba(0, 0, 0, 0.45);
`;
const StyledSelect = styled(Select)`
  width: 134px;
`;
const StyledRangePicker = styled(DatePicker.RangePicker)<{ grayOut: boolean }>`
  background-color: ${({ grayOut }) => (grayOut ? "#F2F2F2" : "unset")};

  .ant-picker-input > input {
    color: ${({ grayOut }) => (grayOut ? "rgba(0, 0, 0, 0.25)" : "unset")};
  }
`;
const StyledCheckbox = styled(Checkbox)`
  font-size: 12px;
  color: rgba(0, 0, 0, 0.45);
`;
const ButtonContainer = styled.div`
  grid-column: 3 span;
  text-align: right;
`;
const ApplyButton = styled(Button)`
  margin-right: 8px;
`;

interface Props {
  title: string;
  fetchAction: () => void;
}

enum DateRangeOptionValue {
  CUSTOM = "CUSTOM",
  TODAY = "TODAY",
  YESTERDAY = "YESTERDAY",
  LAST_WEEK = "LAST_WEEK",
  LAST_MONTH = "LAST_MONTH",
  LAST_7_DAYS = "LAST_7_DAYS",
  LAST_30_DAYS = "LAST_30_DAYS",
  THIS_MONTH = "THIS_MONTH",
}

enum CompareOptionValue {
  CUSTOM = "CUSTOM",
  LAST_DATE_RANGE = "LAST_DATE_RANGE",
  LAST_YEAR = "LAST_YEAR",
}

const dateRangeOptions = [
  {
    value: DateRangeOptionValue.CUSTOM,
    label: "自訂",
  },
  {
    value: DateRangeOptionValue.TODAY,
    label: "今天",
  },
  {
    value: DateRangeOptionValue.YESTERDAY,
    label: "昨天",
  },
  {
    value: DateRangeOptionValue.LAST_WEEK,
    label: "上週",
  },
  {
    value: DateRangeOptionValue.LAST_MONTH,
    label: "上個月",
  },
  {
    value: DateRangeOptionValue.LAST_7_DAYS,
    label: "最近7天",
  },
  {
    value: DateRangeOptionValue.LAST_30_DAYS,
    label: "最近30天",
  },
  {
    value: DateRangeOptionValue.THIS_MONTH,
    label: "本月至今",
  },
];

const compareOptions = [
  {
    value: CompareOptionValue.CUSTOM,
    label: "自訂",
  },
  {
    value: CompareOptionValue.LAST_DATE_RANGE,
    label: "上一個時段",
  },
  {
    value: CompareOptionValue.LAST_YEAR,
    label: "前一年",
  },
];

const ChartFilter: FC<Props> = (props) => {
  const { title, fetchAction } = props;
  const { search } = useLocation();
  const dispatch = useAppDispatch();

  const [timeUnit, setGroupBy] = useState<GroupByValue>(GroupByValue.HOUR);
  const [currentOption, setCurrentOption] = useState<DateRangeOptionValue>(DateRangeOptionValue.TODAY);
  const [compareOption, setCompareOption] = useState<CompareOptionValue>(CompareOptionValue.LAST_DATE_RANGE);
  const [currentRange, setCurrentRange] = useState<[Moment, Moment]>([moment(), moment()]);
  const [compareRange, setCompareRange] = useState<[Moment, Moment]>([moment(), moment()]);
  const [isComparing, setIsComparing] = useState<boolean>(false);
  const [disabledGroupByHour, setDisabledGroupByHour] = useState<boolean>(false);

  useEffect(() => {
    const query = new URLSearchParams(search);
    const time = query.get("time");

    const updateChartParamsToRedux = (params: ChartParams) => {
      dispatch(updateChartParams(params));
    };

    switch (time) {
      case DashboardTime.THIS_MONTH:
        handleOnCurrentOptionChange(DateRangeOptionValue.THIS_MONTH, updateChartParamsToRedux);
        break;
      case DashboardTime.YESTERDAY:
        handleOnCurrentOptionChange(DateRangeOptionValue.YESTERDAY, updateChartParamsToRedux);
        break;
      case DashboardTime.LAST_7_DAYS:
        handleOnCurrentOptionChange(DateRangeOptionValue.LAST_7_DAYS, updateChartParamsToRedux);
        break;
      case DashboardTime.LAST_30_DAYS:
        handleOnCurrentOptionChange(DateRangeOptionValue.LAST_30_DAYS, updateChartParamsToRedux);
        break;
      case DashboardTime.TODAY:
      default:
        handleOnCurrentOptionChange(DateRangeOptionValue.TODAY, updateChartParamsToRedux);
    }

    fetchAction();
  }, [search, dispatch, fetchAction]);

  // 聆聽目前範圍 & 相較於選項: 調整相較於範圍
  useEffect(() => {
    const currentRangeStartDate = currentRange[0];
    const currentRangeEndDate = currentRange[1];

    switch (compareOption) {
      case CompareOptionValue.LAST_DATE_RANGE: {
        const currentRangeInDays = currentRangeEndDate.diff(currentRangeStartDate, "days");
        const compareRangeEndDate = moment(currentRangeStartDate).subtract(1, "day");
        const compareRangeStartDate = moment(compareRangeEndDate).subtract(currentRangeInDays, "day");
        setCompareRange([compareRangeStartDate, compareRangeEndDate]);
        break;
      }
      case CompareOptionValue.LAST_YEAR: {
        const compareRangeStartDate = moment(currentRangeStartDate).subtract(1, "year");
        const compareRangeEndDate = moment(currentRangeEndDate).subtract(1, "year");
        setCompareRange([compareRangeStartDate, compareRangeEndDate]);
        break;
      }
      case CompareOptionValue.CUSTOM:
      default:
    }
  }, [currentRange, compareOption]);

  // 追蹤timeUnit值
  useEffect(() => {
    const currentRangeInDays = currentRange[1].diff(currentRange[0], "days");
    const compareRangeInDays = compareRange[1].diff(compareRange[0], "days");

    if (currentRangeInDays > 1 || compareRangeInDays > 1) {
      setDisabledGroupByHour(true);
      if (timeUnit === GroupByValue.HOUR) setGroupBy(GroupByValue.DAY);
    } else {
      setDisabledGroupByHour(false);
    }
  }, [currentRange, compareRange, timeUnit]);

  const handleOnCurrentOptionChange = (option: DateRangeOptionValue, callback?: (params: ChartParams) => void) => {
    const chartParams: ChartParams = {
      startDate: "",
      endDate: "",
      cStartDate: "",
      cEndDate: "",
      timeUnit: GroupByValue.DAY,
    };

    switch (option) {
      case DateRangeOptionValue.TODAY: {
        const todayStart = moment().startOf("day");
        const now = moment();
        setGroupBy(GroupByValue.HOUR);
        setCurrentOption(DateRangeOptionValue.TODAY);
        setCurrentRange([todayStart, now]);

        chartParams.startDate = todayStart.format("YYYY-MM-DD HH:mm:ss");
        chartParams.endDate = now.format("YYYY-MM-DD HH:mm:ss");
        chartParams.timeUnit = GroupByValue.DAY;
        break;
      }
      case DateRangeOptionValue.THIS_MONTH: {
        const monthStart = moment().startOf("month");
        const now = moment();
        setGroupBy(GroupByValue.DAY);
        setCurrentOption(DateRangeOptionValue.THIS_MONTH);
        setCurrentRange([monthStart, now]);

        chartParams.startDate = monthStart.format("YYYY-MM-DD HH:mm:ss");
        chartParams.endDate = now.format("YYYY-MM-DD HH:mm:ss");
        chartParams.timeUnit = GroupByValue.DAY;
        break;
      }
      case DateRangeOptionValue.YESTERDAY: {
        const yesterdayStart = moment().subtract(1, "days").startOf("day");
        const yesterdayEnd = moment().subtract(1, "days").endOf("day");
        setGroupBy(GroupByValue.HOUR);
        setCurrentOption(DateRangeOptionValue.YESTERDAY);
        setCurrentRange([yesterdayStart, yesterdayEnd]);

        chartParams.startDate = yesterdayStart.format("YYYY-MM-DD HH:mm:ss");
        chartParams.endDate = yesterdayEnd.format("YYYY-MM-DD HH:mm:ss");
        chartParams.timeUnit = GroupByValue.DAY;
        break;
      }
      case DateRangeOptionValue.LAST_7_DAYS: {
        const days7AgoStart = moment().subtract(7, "days").startOf("day");
        const yesterdayEnd = moment().subtract(1, "days").endOf("day");
        setGroupBy(GroupByValue.DAY);
        setCurrentOption(DateRangeOptionValue.LAST_7_DAYS);
        setCurrentRange([days7AgoStart, yesterdayEnd]);

        chartParams.startDate = days7AgoStart.format("YYYY-MM-DD HH:mm:ss");
        chartParams.endDate = yesterdayEnd.format("YYYY-MM-DD HH:mm:ss");
        chartParams.timeUnit = GroupByValue.DAY;
        break;
      }
      case DateRangeOptionValue.LAST_30_DAYS: {
        const days30AgoStart = moment().subtract(30, "days").startOf("day");
        const yesterdayEnd = moment().subtract(1, "days").endOf("day");
        setGroupBy(GroupByValue.DAY);
        setCurrentOption(DateRangeOptionValue.LAST_30_DAYS);
        setCurrentRange([days30AgoStart, yesterdayEnd]);

        chartParams.startDate = days30AgoStart.format("YYYY-MM-DD HH:mm:ss");
        chartParams.endDate = yesterdayEnd.format("YYYY-MM-DD HH:mm:ss");
        chartParams.timeUnit = GroupByValue.DAY;
        break;
      }
      case DateRangeOptionValue.LAST_WEEK: {
        const lastSunday = moment().day(-7).startOf("day");
        const lastSaturday = moment().day(-1).endOf("day");
        setGroupBy(GroupByValue.DAY);
        setCurrentOption(DateRangeOptionValue.LAST_WEEK);
        setCurrentRange([lastSunday, lastSaturday]);

        chartParams.startDate = lastSunday.format("YYYY-MM-DD HH:mm:ss");
        chartParams.endDate = lastSaturday.format("YYYY-MM-DD HH:mm:ss");
        chartParams.timeUnit = GroupByValue.DAY;
        break;
      }
      case DateRangeOptionValue.LAST_MONTH: {
        const lastMonthStart = moment().subtract(1, "month").startOf("month");
        const lastMonthEnd = moment().subtract(1, "month").endOf("month");
        setGroupBy(GroupByValue.DAY);
        setCurrentOption(DateRangeOptionValue.LAST_MONTH);
        setCurrentRange([lastMonthStart, lastMonthEnd]);

        chartParams.startDate = lastMonthStart.format("YYYY-MM-DD HH:mm:ss");
        chartParams.endDate = lastMonthEnd.format("YYYY-MM-DD HH:mm:ss");
        chartParams.timeUnit = GroupByValue.DAY;
        break;
      }
      default:
    }

    setCurrentOption(option);
    if (callback) callback(chartParams);
  };

  const handleOnGroupByChange = (e: RadioChangeEvent) => {
    const { value } = e.target;
    setGroupBy(value as GroupByValue);
  };

  const handleOnCheck = (e: CheckboxChangeEvent) => {
    const { checked } = e.target;
    setIsComparing(checked);
  };

  const handleSelectCompareOption = (value: SelectValue) => {
    setCompareOption(value as CompareOptionValue);
  };

  const handleOnChangeCurrentRange = (rangeValue: RangeValue<Moment>) => {
    if (rangeValue) {
      setCurrentOption(DateRangeOptionValue.CUSTOM);
      setCurrentRange(rangeValue as [Moment, Moment]);
    }
  };

  const handleOnChangeCompareRange = (rangeValue: RangeValue<Moment>) => {
    if (rangeValue) {
      setCompareOption(CompareOptionValue.CUSTOM);
      setCompareRange(rangeValue as [Moment, Moment]);
    }
  };

  const handleOnSubmit = () => {
    const chartParams: ChartParams = {
      startDate: currentRange[0].format("YYYY-MM-DD HH:mm:ss"),
      endDate: currentRange[1].endOf("day").format("YYYY-MM-DD HH:mm:ss"),
      cStartDate: isComparing ? compareRange[0].format("YYYY-MM-DD HH:mm:ss") : "",
      cEndDate: isComparing ? compareRange[1].endOf("day").format("YYYY-MM-DD HH:mm:ss") : "",
      timeUnit,
    };

    dispatch(updateChartParams(chartParams));
    fetchAction();
  };

  return (
    <Wrapper>
      <LeftContainer>
        <Title>{title}</Title>
        <Radio.Group value={timeUnit} onChange={handleOnGroupByChange}>
          <Radio.Button value={GroupByValue.HOUR} disabled={disabledGroupByHour}>
            每小時
          </Radio.Button>
          <Radio.Button value={GroupByValue.DAY}>天</Radio.Button>
          <Radio.Button value={GroupByValue.MONTH}>月</Radio.Button>
          <Radio.Button value={GroupByValue.YEAR}>年</Radio.Button>
        </Radio.Group>
      </LeftContainer>
      <RightContainer>
        <RowTitle>日期範圍</RowTitle>
        <StyledSelect
          options={dateRangeOptions}
          value={currentOption}
          onChange={(value) => handleOnCurrentOptionChange(value as DateRangeOptionValue)}
        />
        <StyledRangePicker
          value={currentRange}
          onChange={handleOnChangeCurrentRange}
          allowClear={false}
          grayOut={currentOption !== DateRangeOptionValue.CUSTOM}
        />
        <StyledCheckbox checked={isComparing} onChange={handleOnCheck}>
          相較於
        </StyledCheckbox>
        <StyledSelect options={compareOptions} value={compareOption} onChange={handleSelectCompareOption} />
        {isComparing && (
          <StyledRangePicker
            value={compareRange}
            allowClear={false}
            onChange={handleOnChangeCompareRange}
            grayOut={compareOption !== CompareOptionValue.CUSTOM}
          />
        )}
        <ButtonContainer>
          <ApplyButton type="primary" onClick={handleOnSubmit}>
            套用
          </ApplyButton>
          <Button>取消</Button>
        </ButtonContainer>
      </RightContainer>
    </Wrapper>
  );
};

export default ChartFilter;
