import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { ColumnsType } from "antd/lib/table";
import { Button, Table, Row, Select } from "antd";
import PageTitle from "@component/PageTitle";
import { SelectValue } from "antd/lib/select";
import { FilterTwoTone } from "@ant-design/icons";
import { TryallProductDailyAggregationsResult } from "@api/financeApi";
import {
  fetchTryallProductDailyAggregations,
  setTryallProductDailyAggregationsFilter,
  exportCSVTryallProductDailyAggregations,
  resetTryallStockManagementSlice,
} from "@redux/finance/tryallStockManagementSlice";
import { RootState } from "@redux/rootReducer";
import Filter from "./Filter";

const Wrapper = styled.div`
  padding: 20px 20px 20px 25px;
`;
const TableWrapper = styled.div`
  border: solid 1px #f0f0f0;
  border-radius: 2px;
  padding: 20px 10px 22px 17px;
  margin-top: 20px;
`;
const PageText = styled.span``;
const SelectPageSize = styled(Select)`
  margin: 0 10px;
`;
const ButtonWrapper = styled.div``;

const TopButton = styled(Button)`
  margin-right: 10px;
`;

export default function RentrapStockManagement() {
  const dispatch = useDispatch();
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const { tryallProductDailyAggregationsFilter, tryallProductDailyAggregationsList, isFetching } = useSelector(
    (state: RootState) => state.tryallStockManagementSlice,
  );

  const fetchPageChange = (value: number) => {
    window.scrollTo(0, 0);
    setPage(value);
    dispatch(
      setTryallProductDailyAggregationsFilter({
        ...tryallProductDailyAggregationsFilter,
        offset: (value - 1) * tryallProductDailyAggregationsFilter.limit,
      }),
    );
  };

  const columns: ColumnsType<TryallProductDailyAggregationsResult> = [
    {
      key: "statsDate",
      dataIndex: "statsDate",
      title: "日期",
    },
    {
      key: "no",
      dataIndex: "no",
      title: "商品編號",
    },
    {
      key: "sku",
      dataIndex: "sku",
      title: "國際條碼",
    },
    {
      key: "productName",
      dataIndex: "productName",
      title: "商品名稱",
    },
    {
      key: "cost",
      dataIndex: "cost",
      title: "當日成本",
    },
    {
      key: "goodInitialInventory",
      dataIndex: "goodInitialInventory",
      title: "期初良品",
    },
    {
      key: "badInitialInventory",
      dataIndex: "badInitialInventory",
      title: "期初壞品",
    },
    {
      key: "goodFinalInventory",
      dataIndex: "goodFinalInventory",
      title: "期末良品",
    },
    {
      key: "badFinalInventory",
      dataIndex: "badFinalInventory",
      title: "期末壞品",
    },
    {
      key: "stockValue",
      dataIndex: "stockValue",
      title: "庫存價值",
    },
  ];

  const setPageLimit = (value: SelectValue) => {
    dispatch(
      setTryallProductDailyAggregationsFilter({
        ...tryallProductDailyAggregationsFilter,
        limit: parseInt(value as string, 10),
      }),
    );
  };
  const handleOnExport = () => {
    dispatch(exportCSVTryallProductDailyAggregations(tryallProductDailyAggregationsFilter));
  };

  useEffect(() => {
    dispatch(fetchTryallProductDailyAggregations(tryallProductDailyAggregationsFilter));
  }, [dispatch, tryallProductDailyAggregationsFilter]);

  useEffect(() => {
    return () => {
      dispatch(resetTryallStockManagementSlice());
    };
  }, [dispatch]);

  return (
    <Wrapper>
      <PageTitle title="藍田庫存變動數" />
      <Row justify="space-between">
        <ButtonWrapper>
          <TopButton icon={<FilterTwoTone />} onClick={() => setShowFilter(!showFilter)}>
            篩選
          </TopButton>
          <TopButton onClick={handleOnExport}>匯出</TopButton>
        </ButtonWrapper>
        <Row align="middle">
          <PageText>{`總共${tryallProductDailyAggregationsList.count}筆, 每頁顯示`}</PageText>
          <SelectPageSize defaultValue="20" onChange={setPageLimit}>
            <Select.Option value="20">20</Select.Option>
            <Select.Option value="50">50</Select.Option>
            <Select.Option value="100">100</Select.Option>
          </SelectPageSize>
          <PageText>筆</PageText>
        </Row>
      </Row>
      {showFilter && <Filter />}
      <TableWrapper>
        <Table
          scroll={{ x: "max-content" }}
          loading={isFetching}
          columns={columns}
          dataSource={tryallProductDailyAggregationsList.results}
          pagination={{
            pageSize: tryallProductDailyAggregationsFilter.limit,
            current: page,
            showSizeChanger: false,
            total: tryallProductDailyAggregationsList.count,
            onChange: fetchPageChange,
          }}
        />
      </TableWrapper>
    </Wrapper>
  );
}
