import { ExclamationCircleOutlined } from "@ant-design/icons";
import { ContractModeValue } from "@api/productApi";
import { SalePlan, SalePlanOption } from "@api/saleApi";
import { SalesChannelType } from "@api/vendorApi";
import salePlanApi, { CreateSalePlanToRuleParams, FetchVendorProductContractParams } from "@api/salePlanApi";
import PopupBackground from "@component/PopupBackground";
import QuillEditor from "@component/QuillEditor";
import UploadImageButton from "@component/UploadImageButton";
import { createSalePlanToRule, reset, updateSalePlanToRule } from "@redux/ruleAddSalePlanSlice";
import { Button, Input, InputNumber, Modal, Table } from "antd";
import React, { ChangeEvent, FC, KeyboardEvent, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "src/store";
import styled from "styled-components";

interface Props {
  mode: "add" | "edit";
  salePlan?: SalePlan;
  salePlanId?: number;
  onClose: () => void;
}

const Wrapper = styled.div`
  height: 80vh;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 52px;
  width: 900px;
  background-color: white;
  padding: 30px 28px 20px 30px;
`;
const FormBody = styled.div`
  overflow: auto;
  overflow: overlay;
`;
const FormGrid = styled.div`
  width: 100%;
  display: inline-grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto;
  column-gap: 25px;
  row-gap: 18px;
  align-items: center;
  margin-bottom: 13px;
  margin-right: auto;
`;
const Title = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.colorNeutral600};
`;
const Red = styled.span`
  color: red;
`;
const StyledInput = styled(Input)<{ width: number; error?: boolean }>`
  width: ${({ width }) => width}px;
  border-color: ${({ error }) => (error ? "red" : "#d9d9d9")};
`;
const UploadWrapper = styled.div<{ error?: boolean }>`
  border-color: ${({ error }) => (error ? "red" : "transparent")};
  border-width: 1px;
  border-style: solid;
  width: fit-content;
`;
const AddSKUButton = styled(Button)`
  margin: 0 8px;
`;
const TableNoWrap = styled.div`
  white-space: nowrap;
`;
const DeleteText = styled.div`
  text-align: right;
  font-size: 13px;
  color: #1890ff;
  cursor: pointer;
`;
const Footer = styled.div`
  margin-top: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
const ButtonContainer = styled.div``;
const CancelButton = styled(Button)`
  margin-right: 10px;
`;
const ErrorText = styled.span`
  color: #ec6922;
`;
const StyledInputNumber = styled(InputNumber)`
  width: 70px;
`;
const TableWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.colorNeutral300};
  padding: 20px;
  margin-bottom: 15px;
`;
const TableTitle = styled.div`
  font-weight: 700;
  margin-bottom: 10px;
`;

interface ErrorType {
  name?: boolean;
  img?: boolean;
}

const EditSalePlan: FC<Props> = (props) => {
  const { salePlan, mode, salePlanId, onClose } = props;
  const dispatch = useAppDispatch();
  const { id: ruleId } = useParams();

  const [name, setName] = useState<string>("");
  const [img, setImg] = useState<string>("");
  const [sku, setSku] = useState<string>("");
  const [errors, setErrors] = useState<ErrorType>({});
  const [options, setOptions] = useState<SalePlanOption[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [shortQuillHtml, setShortQuillHtml] = useState("");

  useEffect(() => {
    return () => {
      dispatch(reset());
    };
  }, [dispatch]);

  useEffect(() => {
    switch (mode) {
      case "add":
        if (salePlan) {
          setImg(salePlan.media?.url || "");
          setName(salePlan.name);
          setOptions(salePlan.options);
          setShortQuillHtml(salePlan.description);
        }
        break;
      case "edit":
        if (salePlanId) fetchSalePlan(salePlanId);
        break;
      default:
    }
  }, [salePlan, mode, salePlanId]);

  const fetchSalePlan = async (id: number) => {
    try {
      const response = await salePlanApi.getSalePlan(id);
      setImg(response.media?.url || "");
      setName(response.name);
      setOptions(response.options);
      setShortQuillHtml(response.description);
      return "success";
    } catch (error: any) {
      return "error";
    }
  };

  const handleOnNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setName(value);
  };

  const handleOnSkuChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSku(value);
  };

  const handleOnDelete = (id: number) => () => {
    Modal.confirm({
      title: "你確定要刪除這筆資料?",
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: "是",
      cancelText: "否",
      onOk() {
        setOptions((prev) => prev.filter((opt) => opt.id !== id));
      },
    });
  };

  const handleOnAddProduct = async () => {
    if (!sku) return; // 空值
    if (options.some((opt) => opt.vpc.sku === sku)) {
      setErrorMessage(`商品不得重複，SKU=${sku}`);
      return;
    } // 已存在

    const params: FetchVendorProductContractParams = {
      excludeRuleId: Number(ruleId),
      sku,
      isActive: true,
      salesChannel: SalesChannelType.URMART,
    };
    try {
      const response = await salePlanApi.fetchVendorProductContract(params);
      const { results } = response;
      if (results.length === 0) setErrorMessage("SKU有誤/搜尋不到商品");
      else {
        const searchedProduct = results[0];
        const {
          id,
          product,
          price,
          cost,
          commissionRate,
          mode: contractMode,
          contract,
          modeName,
          vpcId,
        } = searchedProduct;

        // 轉單商品不可以加
        if (contractMode === ContractModeValue.TRANSFER_ORDER) {
          setErrorMessage("非為倉庫出貨之商品，請重新輸入/無法加入轉單商品");
          return;
        }

        const isAlreadyExist = options.find((opt) => opt.id === id);
        if (!isAlreadyExist) {
          setErrorMessage("");
          setOptions((prev) => [
            ...prev,
            {
              id,
              name: product.name,
              commissionRate,
              vpc: {
                id: vpcId,
                price,
                sku: product.sku,
                commissionRate,
                productName: product.name,
                contractInfo: {
                  id: contract,
                  mode: contractMode,
                  modeName,
                },
              },
              groupQty: 0,
              unitPrice: price,
              unitCost: cost,
              optionDiscount: 0,
              canBuyCount: 0,
            },
          ]);
        }
      }
    } catch {
      // error
    }
  };

  const handleOnChangeQty = (id: number) => (value: string | number | undefined) => {
    const newOptiosn = options.map((opt) => {
      if (opt.id === id) {
        return {
          ...opt,
          groupQty: value === "" ? 0 : Number(value),
        };
      }
      return opt;
    });
    setOptions(newOptiosn);
  };

  const handleOnUploadImg = (imgDir: string) => {
    setImg(imgDir);
    setErrors((prev) => ({
      ...prev,
      img: undefined,
    }));
  };

  const preventDefault = (e: KeyboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const validateForm = (callback?: () => void) => () => {
    const newErrors: ErrorType = {};
    if (!name) newErrors.name = true;
    if (!img) newErrors.img = true;
    setErrors(newErrors);

    if (!newErrors.name && !newErrors.img && callback) callback();
  };

  const handleSubmit = () => {
    if (options.length === 0) {
      Modal.warning({
        title: "此方案無商品內容，請新增後再按下確定",
        okText: "我知道了",
      });
      return;
    }

    const imageFilePath = img && !img.startsWith(process.env.REACT_APP_CLOUDFLARE_DOMAIN!) ? img : undefined;
    const params: CreateSalePlanToRuleParams = {
      name,
      imageDirPrefix: "saleplan/image",
      imageFilePath,
      ruleId: Number(ruleId),
      ruleProductsInfo: options.map((opt) => ({
        vpcId: opt.vpc.id,
        qty: opt.groupQty || 0,
        price: opt.unitPrice || 0,
        commissionRate: opt.vpc.commissionRate || 0,
      })),
      description: shortQuillHtml,
    };
    switch (mode) {
      case "add":
        dispatch(createSalePlanToRule(params));
        onClose();
        break;
      case "edit": {
        if (salePlanId) {
          const updateParams = {
            planId: salePlanId,
            params,
          };
          dispatch(updateSalePlanToRule(updateParams));
          onClose();
        }
        break;
      }
      default:
    }
  };

  const tableColumns = [
    {
      title: <TableNoWrap>SKU</TableNoWrap>,
      dataIndex: ["vpc", "sku"],
      width: 150,
    },
    {
      title: <TableNoWrap>商品名稱</TableNoWrap>,
      dataIndex: ["vpc", "productName"],
      width: 317,
    },
    {
      title: <TableNoWrap>數量</TableNoWrap>,
      key: "groupQty",
      dataIndex: "groupQty",
      render: (value: SalePlanOption["groupQty"], data: SalePlanOption) => (
        <StyledInputNumber value={value} onChange={handleOnChangeQty(data.id)} min={0} onPressEnter={preventDefault} />
      ),
      width: 80,
    },
    {
      title: "",
      key: "",
      dataIndex: "",
      render: (value: any, data: SalePlanOption) => <DeleteText onClick={handleOnDelete(data.id)}>刪除</DeleteText>,
    },
  ];

  return (
    <PopupBackground close={onClose} fixed>
      <Wrapper>
        <FormBody>
          <FormGrid>
            <Title>
              贈品名稱
              <Red>*</Red>
            </Title>
            <StyledInput
              error={errors.name}
              width={395}
              value={name}
              onChange={handleOnNameChange}
              onBlur={validateForm()}
            />
            <Title>
              圖片
              <Red>*</Red>
            </Title>
            <UploadWrapper error={errors.img}>
              <UploadImageButton
                finishUpload={handleOnUploadImg}
                mode="small"
                imageUrl={img}
                onDelete={() => setImg("")}
              />
            </UploadWrapper>
            <Title>方案描述</Title>
            <QuillEditor
              height={120}
              toolbarBlacklist={["background", "link", "video", "image", "imageLink"]}
              toolbarName="short-description-toolbar"
              uploadImagePathname="sales_page_assets"
              defaultHtml={shortQuillHtml}
              onChangeQuill={(html) => {
                setShortQuillHtml(html);
              }}
              customFontList={["14px", "16px"]}
              customColorList={["#464c51", "#ec6922"]}
            />
            <Title>
              SKU
              <Red>*</Red>
            </Title>
            <div>
              <StyledInput width={214} value={sku} onChange={handleOnSkuChange} onPressEnter={preventDefault} />
              <AddSKUButton onClick={handleOnAddProduct}>加入</AddSKUButton>
              <ErrorText>{errorMessage}</ErrorText>
            </div>
          </FormGrid>
          <TableWrapper>
            <TableTitle>贈品組成</TableTitle>
            <Table
              dataSource={options}
              columns={tableColumns}
              scroll={{ x: "max-content", y: 185 }}
              tableLayout="auto"
              pagination={false}
              loading={false}
              rowKey="id"
            />
          </TableWrapper>
        </FormBody>
        <Footer>
          <ButtonContainer>
            <CancelButton onClick={onClose}>取消</CancelButton>
            <Button type="primary" onClick={validateForm(handleSubmit)}>
              確定
            </Button>
          </ButtonContainer>
        </Footer>
      </Wrapper>
    </PopupBackground>
  );
};

export default EditSalePlan;
