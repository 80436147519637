/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useMemo, useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import fontStyle from "src/styles/fontStyle";
import styled from "styled-components";
import { Button, Table, Select } from "antd";
import PageTitle from "@component/PageTitle";
import { FilterTwoTone } from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table";
import { SelectValue } from "antd/lib/select";
import {
  excessInventoryState,
  setExcessInventoryFilter,
  fetchExcessInventoryList,
  resetExcessInventorySlice,
} from "@redux/finance/excessInventorySlice";
import Filter from "./Filter";

const Wrapper = styled.div`
  padding: 20px 20px 20px 25px;
`;
const TableWrapper = styled.div`
  border: solid 1px #f0f0f0;
  border-radius: 2px;
  padding: 20px 10px 22px 17px;
  margin-top: 20px;
`;
const PageText = styled.span`
  ${fontStyle("14px", "20px")};
`;
const SelectPageSize = styled(Select)`
  margin: 0 10px;
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Row = styled.div``;

const AmountDiv = styled.div`
  padding: 10px;
  background-color: #fafafa;
  width: fit-content;
  margin-top: 15px;
`;

const AmountText = styled.div`
  font-size: 13px;
  color: ${({ theme }) => theme.colorNeutral500};
`;

const AmountNumber = styled.div`
  font-size: 20px;
  line-height: 40px;
`;

const ExcessInventory = () => {
  const dispatch = useDispatch();
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const { excessInventoryList, excessInventoryFilter, isFetching } = useSelector(excessInventoryState);
  const { offset, limit } = excessInventoryFilter;
  const { count, details: listResult } = excessInventoryList;

  const columns: ColumnsType<any> = [
    {
      key: "sku",
      dataIndex: "sku",
      title: "SKU",
    },
    {
      key: "owner",
      dataIndex: "owner",
      title: "商開負責人",
    },
    {
      key: "productName",
      dataIndex: "productName",
      title: "商品名稱",
    },
    {
      key: "brand",
      dataIndex: "brand",
      title: "品牌",
    },
    {
      key: "vendor",
      dataIndex: "vendor",
      title: "廠商",
    },
    {
      key: "canSellDate",
      dataIndex: "canSellDate",
      title: "允售日",
      fixed: "right",
    },
    {
      key: "effectiveStock",
      dataIndex: "effectiveStock",
      title: "效期庫存量",
      fixed: "right",
    },
    {
      key: "amountOfTheMonth",
      dataIndex: "amountOfTheMonth",
      title: "呆滯金額",
      fixed: "right",
    },
  ];

  const currentPage = useMemo(() => {
    return Math.floor(offset / limit) + 1;
  }, [limit, offset]);

  const handleOnPageSizeChange = (value: SelectValue) => {
    const filter = {
      ...excessInventoryFilter,
      limit: value as number,
    };
    dispatch(setExcessInventoryFilter(filter));
  };
  const handleOnPageChange = (page: number) => {
    const filter = {
      ...excessInventoryFilter,
      offset: (page - 1) * limit,
    };
    dispatch(setExcessInventoryFilter(filter));
  };

  useEffect(() => {
    const filter = {
      ...excessInventoryFilter,
      dateAfter: excessInventoryFilter.dateAfter || moment().startOf("month").format("YYYY-MM-DD"),
      dateBefore: excessInventoryFilter.dateBefore || moment().endOf("month").format("YYYY-MM-DD"),
      brand: excessInventoryFilter.brand,
      sku: excessInventoryFilter.sku,
    };
    dispatch(fetchExcessInventoryList(filter));
  }, [dispatch, excessInventoryFilter]);

  useEffect(() => {
    return () => {
      dispatch(resetExcessInventorySlice());
    };
  }, [dispatch]);

  return (
    <Wrapper>
      <PageTitle title="存貨呆滯提列" />
      <Header>
        <Button icon={<FilterTwoTone />} onClick={() => setShowFilter(!showFilter)}>
          篩選
        </Button>
        <Row>
          <PageText>{`總共${count}筆, 每頁顯示`}</PageText>
          <SelectPageSize defaultValue="20" onChange={handleOnPageSizeChange}>
            <Select.Option value="20">20</Select.Option>
            <Select.Option value="50">50</Select.Option>
            <Select.Option value="100">100</Select.Option>
          </SelectPageSize>
          <PageText>筆</PageText>
        </Row>
      </Header>
      {showFilter && <Filter />}
      <AmountDiv>
        <AmountText>{excessInventoryFilter.month || moment().format("MM")}月份呆滯金額</AmountText>
        <AmountNumber>{excessInventoryList.totalAmount}</AmountNumber>
      </AmountDiv>
      <TableWrapper>
        <Table
          scroll={{ x: "max-content" }}
          loading={isFetching}
          columns={columns}
          dataSource={listResult}
          pagination={{
            pageSize: limit,
            current: currentPage,
            showSizeChanger: false,
            total: count,
            onChange: handleOnPageChange,
          }}
        />
      </TableWrapper>
    </Wrapper>
  );
};

export default ExcessInventory;
