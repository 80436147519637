import throughShipmentApi, {
  AccessPointListPayload,
  AccessPointListResult,
  AddRetailProductLinePayload,
  ContactInfoListFilter,
  ContactInfoListResult,
  ContractListResult,
  CreateAccessPointPayload,
  CreateContactInfoPayload,
  CreateContractPayload,
  CreateDealerPayload,
  CreateDeductionPayload,
  DealerFilter,
  DealerList,
  DealerListResult,
  DeductionListResult,
  ListFilter,
  RegionListResult,
  RetailListResult,
  RetailPayload,
  RetailProductLineListResult,
  RetailProductLinePayload,
  RetailRegionPayload,
  UpdateAccessPointPayload,
  UpdateContactInfoPayload,
  UpdateContractPayload,
  UpdateDealerPayload,
  UpdateDeductionPayload,
} from "@api/throughShipmentApi";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import type { RootState } from "./rootReducer";

interface IState {
  isFetching: boolean;
  retailRegion: RegionListResult;
  retailList: RetailListResult;
  retailProductLine: RetailProductLineListResult;
  dealerList: DealerListResult;
  dealerInfo?: DealerList;
  contactInfoList: ContactInfoListResult;
  deductionFeeList: DeductionListResult;
  accessPointList: AccessPointListResult;
  contractList: ContractListResult;
  createDealerId?: number;
  dealerFilter: DealerFilter;
  contactInfoFilter: ContactInfoListFilter;
  deductionFilter: ListFilter;
  accessPointFilter: ListFilter;
  contractFilter: ListFilter;
  isContractTimePeriodRepeat: boolean;
  isFinishEditContract: boolean;
}

const initialState: IState = {
  isFetching: false,
  retailRegion: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  retailList: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  retailProductLine: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  dealerList: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  dealerInfo: undefined,
  contactInfoList: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  deductionFeeList: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  accessPointList: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  contractList: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  createDealerId: undefined,
  dealerFilter: {
    regionType: undefined,
    regionName: undefined,
    contractType: undefined,
    retailer: undefined,
    currency: undefined,
    productLine: undefined,
    limit: 20,
    offset: 0,
  },
  contactInfoFilter: {
    limit: 20,
    offset: 0,
    retailerProductline: -1,
    retailer: undefined,
    productlines: undefined,
  },
  deductionFilter: {
    limit: 20,
    offset: 0,
  },
  accessPointFilter: {
    limit: 20,
    offset: 0,
  },
  contractFilter: {
    limit: 20,
    offset: 0,
  },
  isContractTimePeriodRepeat: false,
  isFinishEditContract: false,
};

export const fetchRetailRegion = createAsyncThunk("dealer/fetchRetailRegion", async (payload: RetailRegionPayload) => {
  const response = await throughShipmentApi.fetchRetailRegion(payload);
  return response;
});

export const fetchRetailList = createAsyncThunk("dealer/fetchRetailList", async (payload: RetailPayload) => {
  const response = await throughShipmentApi.fetchRetailList(payload);
  return response;
});

export const fetchRetailProductLine = createAsyncThunk(
  "dealer/fetchRetailProductLine",
  async (payload?: RetailProductLinePayload) => {
    const response = await throughShipmentApi.fetchRetailProductLine(payload);
    return response;
  },
);

export const fetchAddRetailProductLine = createAsyncThunk(
  "dealer/fetchAddRetailProductLine",
  async (payload: AddRetailProductLinePayload, thunkApi) => {
    try {
      await throughShipmentApi.fetchAddRetailProductLine(payload);
      await thunkApi.dispatch(fetchRetailProductLine());
      return "Success";
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);

export const fetchDealerList = createAsyncThunk("dealer/fetchDealerList", async (payload: DealerFilter) => {
  const response = await throughShipmentApi.fetchDealerList(payload);
  return response;
});

export const fetchDealerInfo = createAsyncThunk("dealer/fetchDealerInfo", async (dealerId: number) => {
  const response = await throughShipmentApi.fetchDealerInfo(dealerId);
  return response;
});

export const fetchCreateDealer = createAsyncThunk(
  "dealer/fetchCreateDealer",
  async (payload: CreateDealerPayload, thunkApi) => {
    try {
      const response = await throughShipmentApi.fetchCreateDealer(payload);
      message.success("建立成功");
      return response;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);

export const fetchUpdateDealer = createAsyncThunk(
  "dealer/fetchUpdateDealer",
  async (payload: UpdateDealerPayload & { dealerId: number }, thunkApi) => {
    try {
      const { dealerId, ...otherPayload } = payload;
      await throughShipmentApi.fetchUpdateDealer(dealerId, otherPayload);
      message.success("更新成功");
      return "Success";
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);

export const fetchDeleteDealer = createAsyncThunk("dealer/fetchDeleteDealer", async (dealerId: number, thunkApi) => {
  try {
    await throughShipmentApi.fetchDeleteDealer(dealerId);
    const { dealerSlice } = thunkApi.getState() as RootState;
    await thunkApi.dispatch(fetchDealerList(dealerSlice.dealerFilter));
    message.success("刪除成功");
    return "Success";
  } catch (error: any) {
    return thunkApi.rejectWithValue(error.message);
  }
});

export const fetchContactInfoList = createAsyncThunk(
  "dealer/fetchContactInfoList",
  async (payload: ContactInfoListFilter, thunkApi) => {
    const { dealerSlice } = thunkApi.getState() as RootState;
    const response = await throughShipmentApi.fetchContactInfoList({
      ...payload,
      retailerProductline: dealerSlice.dealerInfo!.id,
    });
    return response;
  },
);

export const fetchCreateContact = createAsyncThunk(
  "dealer/fetchCreateContact",
  async (payload: CreateContactInfoPayload, thunkApi) => {
    try {
      await throughShipmentApi.fetchCreateContact(payload);
      const { dealerSlice } = thunkApi.getState() as RootState;
      await thunkApi.dispatch(fetchContactInfoList(dealerSlice.contactInfoFilter));
      message.success("新增成功");
      return "Success";
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);

export const fetchUpdateContact = createAsyncThunk(
  "dealer/fetchUpdateContact",
  async (payload: UpdateContactInfoPayload & { contactId: number }, thunkApi) => {
    try {
      const { contactId, ...otherPayload } = payload;
      await throughShipmentApi.fetchUpdateContact(contactId, otherPayload);
      const { dealerSlice } = thunkApi.getState() as RootState;
      await thunkApi.dispatch(fetchContactInfoList(dealerSlice.contactInfoFilter));
      message.success("更新成功");
      return "Success";
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);

export const fetchDeleteContact = createAsyncThunk("dealer/fetchDeleteContact", async (contactId: number, thunkApi) => {
  try {
    await throughShipmentApi.fetchDeleteContact(contactId);
    const { dealerSlice } = thunkApi.getState() as RootState;
    await thunkApi.dispatch(fetchContactInfoList(dealerSlice.contactInfoFilter));
    message.success("刪除成功");
    return "Success";
  } catch (error: any) {
    return thunkApi.rejectWithValue(error.message);
  }
});

export const fetchDeductionFeeList = createAsyncThunk(
  "dealer/fetchDeductionFeeList",
  async (payload: ListFilter, thunkApi) => {
    const { dealerSlice } = thunkApi.getState() as RootState;
    const response = await throughShipmentApi.fetchDeductionFeeList(dealerSlice.dealerInfo!.id, payload);
    return response;
  },
);

export const fetchCreateDeductionFee = createAsyncThunk(
  "dealer/fetchCreateDeductionFee",
  async (payload: CreateDeductionPayload, thunkApi) => {
    try {
      await throughShipmentApi.fetchCreateDeductionFee(payload);
      const { dealerSlice } = thunkApi.getState() as RootState;
      await thunkApi.dispatch(fetchDeductionFeeList(dealerSlice.deductionFilter));
      message.success("新增成功");
      return "Success";
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);

export const fetchUpdateDeductionFee = createAsyncThunk(
  "dealer/fetchUpdateDeductionFee",
  async (payload: UpdateDeductionPayload & { deductionId: number }, thunkApi) => {
    try {
      const { deductionId, ...otherPayload } = payload;
      await throughShipmentApi.fetchUpdateDeductionFee(deductionId, otherPayload);
      const { dealerSlice } = thunkApi.getState() as RootState;
      await thunkApi.dispatch(fetchDeductionFeeList(dealerSlice.deductionFilter));
      message.success("更新成功");
      return "Success";
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);

export const fetchDeleteDeductionFee = createAsyncThunk(
  "dealer/fetchDeleteDeductionFee",
  async (deductionId: number, thunkApi) => {
    try {
      await throughShipmentApi.fetchDeleteDeductionFee(deductionId);
      const { dealerSlice } = thunkApi.getState() as RootState;
      await thunkApi.dispatch(fetchDeductionFeeList(dealerSlice.deductionFilter));
      message.success("刪除成功");
      return "Success";
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);

export const fetchAccessPointList = createAsyncThunk(
  "dealer/fetchAccessPointList",
  async (payload: AccessPointListPayload, thunkApi) => {
    const { dealerSlice } = thunkApi.getState() as RootState;
    const response = await throughShipmentApi.fetchAccessPointList({
      ...payload,
      retailerProductline: dealerSlice.dealerInfo!.id,
    });
    return response;
  },
);

export const fetchCreateAccessPoint = createAsyncThunk(
  "dealer/fetchCreateAccessPoint",
  async (payload: CreateAccessPointPayload, thunkApi) => {
    try {
      await throughShipmentApi.fetchCreateAccessPoint(payload);
      const { dealerSlice } = thunkApi.getState() as RootState;
      await thunkApi.dispatch(
        fetchAccessPointList({ ...dealerSlice.accessPointFilter, retailerProductline: dealerSlice.dealerInfo!.id }),
      );
      message.success("新增成功");
      return "Success";
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);

export const fetchUpdateAccessPoint = createAsyncThunk(
  "dealer/fetchUpdateAccessPoint",
  async (payload: UpdateAccessPointPayload & { accessPointId: number }, thunkApi) => {
    try {
      const { accessPointId, ...otherPayload } = payload;
      await throughShipmentApi.fetchUpdateAccessPoint(accessPointId, otherPayload);
      const { dealerSlice } = thunkApi.getState() as RootState;
      await thunkApi.dispatch(
        fetchAccessPointList({ ...dealerSlice.accessPointFilter, retailerProductline: dealerSlice.dealerInfo!.id }),
      );
      message.success("更新成功");
      return "Success";
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);

export const fetchDeleteAccessPoint = createAsyncThunk(
  "dealer/fetchDeleteAccessPoint",
  async (accessPointId: number, thunkApi) => {
    try {
      await throughShipmentApi.fetchDeleteAccessPoint(accessPointId);
      const { dealerSlice } = thunkApi.getState() as RootState;
      await thunkApi.dispatch(
        fetchAccessPointList({ ...dealerSlice.accessPointFilter, retailerProductline: dealerSlice.dealerInfo!.id }),
      );
      message.success("刪除成功");
      return "Success";
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);

export const fetchContractList = createAsyncThunk("dealer/fetchContractList", async (payload: ListFilter, thunkApi) => {
  const { dealerSlice } = thunkApi.getState() as RootState;
  const response = await throughShipmentApi.fetchContractList(dealerSlice.dealerInfo!.id, payload);
  return response;
});

export const fetchCreateContract = createAsyncThunk(
  "dealer/fetchCreateContract",
  async (payload: CreateContractPayload, thunkApi) => {
    try {
      await throughShipmentApi.fetchCreateContract(payload);
      const { dealerSlice } = thunkApi.getState() as RootState;
      await thunkApi.dispatch(fetchContractList(dealerSlice.accessPointFilter));
      message.success("新增成功");
      return "Success";
    } catch (error: any) {
      if (error.message.includes("合約區間重疊")) {
        return thunkApi.rejectWithValue(true);
      }
      return thunkApi.rejectWithValue(false);
    }
  },
);

export const fetchUpdateContract = createAsyncThunk(
  "dealer/fetchUpdateContract",
  async (payload: UpdateContractPayload & { contractId: number }, thunkApi) => {
    try {
      const { contractId, ...otherPayload } = payload;
      await throughShipmentApi.fetchUpdateContract(contractId, otherPayload);
      const { dealerSlice } = thunkApi.getState() as RootState;
      await thunkApi.dispatch(fetchContractList(dealerSlice.accessPointFilter));
      message.success("更新成功");
      return "Success";
    } catch (error: any) {
      if (error.message.includes("合約區間重疊")) {
        return thunkApi.rejectWithValue(true);
      }
      return thunkApi.rejectWithValue(false);
    }
  },
);

export const fetchDeleteContract = createAsyncThunk(
  "dealer/fetchDeleteContract",
  async (contractId: number, thunkApi) => {
    try {
      await throughShipmentApi.fetchDeleteContract(contractId);
      const { dealerSlice } = thunkApi.getState() as RootState;
      await thunkApi.dispatch(fetchContractList(dealerSlice.accessPointFilter));
      message.success("刪除成功");
      return "Success";
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);

const dealerSlice = createSlice({
  name: "dealer",
  initialState,
  reducers: {
    resetDealerSlice: () => initialState,
    setDealerFilter: (state, action) => {
      state.dealerFilter = action.payload;
    },
    setContactInfoFilter: (state, action) => {
      state.contactInfoFilter = action.payload;
    },
    setDeductionFilter: (state, action) => {
      state.deductionFilter = action.payload;
    },
    setAccessPointFilter: (state, action) => {
      state.accessPointFilter = action.payload;
    },
    setContractFilter: (state, action) => {
      state.contractFilter = action.payload;
    },
    setContractIsFinishEdit: (state, action) => {
      state.isFinishEditContract = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDealerList.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchDealerList.fulfilled, (state, action) => {
      state.isFetching = false;
      state.dealerList = action.payload;
    });
    builder.addCase(fetchCreateDealer.fulfilled, (state, action) => {
      state.createDealerId = action.payload.id;
    });
    builder.addCase(fetchDealerInfo.fulfilled, (state, action) => {
      state.dealerInfo = action.payload;
    });
    builder.addCase(fetchRetailRegion.fulfilled, (state, action) => {
      state.retailRegion = action.payload;
    });
    builder.addCase(fetchRetailList.fulfilled, (state, action) => {
      state.retailList = action.payload;
    });
    builder.addCase(fetchRetailProductLine.fulfilled, (state, action) => {
      state.retailProductLine = action.payload;
    });
    builder.addCase(fetchContactInfoList.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchContactInfoList.fulfilled, (state, action) => {
      state.isFetching = false;
      state.contactInfoList = action.payload;
    });
    builder.addCase(fetchDeductionFeeList.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchDeductionFeeList.fulfilled, (state, action) => {
      state.deductionFeeList = action.payload;
      state.isFetching = false;
    });
    builder.addCase(fetchAccessPointList.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchAccessPointList.fulfilled, (state, action) => {
      state.accessPointList = action.payload;
      state.isFetching = false;
    });
    builder.addCase(fetchContractList.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchContractList.fulfilled, (state, action) => {
      state.contractList = action.payload;
      state.isFetching = false;
    });
    builder.addCase(fetchCreateContract.fulfilled, (state) => {
      state.isFinishEditContract = true;
      state.isContractTimePeriodRepeat = false;
    });
    builder.addCase(fetchUpdateContract.fulfilled, (state) => {
      state.isFinishEditContract = true;
      state.isContractTimePeriodRepeat = false;
    });
    builder.addCase(fetchCreateContract.rejected, (state, action) => {
      state.isFinishEditContract = true;
      state.isContractTimePeriodRepeat = action.payload as boolean;
    });
    builder.addCase(fetchUpdateContract.rejected, (state, action) => {
      state.isFinishEditContract = true;
      state.isContractTimePeriodRepeat = action.payload as boolean;
    });
  },
});

export const dealerState = (state: RootState) => state.dealerSlice;
export const {
  resetDealerSlice,
  setDealerFilter,
  setContactInfoFilter,
  setDeductionFilter,
  setAccessPointFilter,
  setContractFilter,
  setContractIsFinishEdit,
} = dealerSlice.actions;
export default dealerSlice.reducer;
