/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import { Form, DatePicker, Button, Tooltip, Row, Col } from "antd";
import styled from "styled-components";
import moment, { Moment } from "moment";
import locale from "antd/es/date-picker/locale/zh_TW";
import { exportCSV } from "@redux/finance/statisticsDownloadSlice";
import { useDispatch } from "react-redux";
import { InfoCircleTwoTone } from "@ant-design/icons";

const InfoIcon = styled(InfoCircleTwoTone)`
  margin-left: 4px;
`;

const Wrapper = styled.div`
  padding: 20px;
  margin: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-row-gap: 10px;
  grid-column-gap: 10px;
`;
const BlockForm = styled(Form)`
  position: relative;
  border: 1px solid #f2f2f2;
  padding: 20px;
  width: 100%;
  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    order: 1;
  }
  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
    display: none;
  }
`;
const Title = styled.div`
  margin-bottom: 10px;
`;
const CustomRow = styled(Row)`
  margin-bottom: 16px;
`;

const Footer = styled.div<{ width: number }>`
  width: ${({ width }) => width && `${width}px`};
  display: flex;
  justify-content: flex-end;
`;
const RangePicker = styled(DatePicker.RangePicker)<{ showTime: any }>`
  width: 100%;
`;

type RangeValue = [Moment | null, Moment | null] | null;

const StatisticsDownload = () => {
  const dispatch = useDispatch();
  const [dates, setDates] = useState<RangeValue>(null);
  const [deductForm] = Form.useForm();
  const [paymentForm] = Form.useForm();
  const [vendorForm] = Form.useForm();
  const [vipForm] = Form.useForm();
  const [orderForm] = Form.useForm();

  const onChange = (date: moment.Moment | null) => {
    if (date) {
      deductForm.setFieldsValue({
        startAt: date,
      });
    }
  };
  const reset = (formType: string) => {
    switch (formType) {
      case "payment":
        paymentForm.resetFields();
        break;
      case "vendor":
        vendorForm.resetFields();
        break;
      case "vip":
        vipForm.resetFields();
        break;
      case "order":
        orderForm.resetFields();
        break;
      default:
        break;
    }
  };

  const onSubmit = (type: string) => {
    let formValue;
    let params;

    switch (type) {
      case "deduct":
        formValue = deductForm.getFieldsValue();
        params = {
          reportType: "deducted",
          yearMonth: moment(formValue.month).format("YYYY-MM"),
        };

        dispatch(exportCSV(params));
        break;
      case "payment":
        formValue = paymentForm.getFieldsValue();
        params = {
          reportType: "payment",
          orderStartDt: formValue.orderDate ? moment(formValue.orderDate[0]).format("YYYY-MM-DD") : undefined,
          orderEndDt: formValue.orderDate ? moment(formValue.orderDate[1]).format("YYYY-MM-DD") : undefined,
          invoiceStartDt: formValue.invoiceDate ? moment(formValue.invoiceDate[0]).format("YYYY-MM-DD") : undefined,
          invoiceEndDt: formValue.invoiceDate ? moment(formValue.invoiceDate[1]).format("YYYY-MM-DD") : undefined,
          invalidStartDt: formValue.invalidDate ? moment(formValue.invalidDate[0]).format("YYYY-MM-DD") : undefined,
          invalidEndDt: formValue.invalidDate ? moment(formValue.invalidDate[1]).format("YYYY-MM-DD") : undefined,
          capturedStartDt: formValue.capturedDate ? moment(formValue.capturedDate[0]).format("YYYY-MM-DD") : undefined,
          capturedEndDt: formValue.capturedDate ? moment(formValue.capturedDate[1]).format("YYYY-MM-DD") : undefined,
          refundStartDt: formValue.refundDate ? moment(formValue.refundDate[0]).format("YYYY-MM-DD") : undefined,
          refundEndDt: formValue.refundDate ? moment(formValue.refundDate[1]).format("YYYY-MM-DD") : undefined,
        };
        dispatch(exportCSV(params));

        break;
      case "vendor":
        formValue = vendorForm.getFieldsValue();
        params = {
          reportType: "vendor",
          invoiceStartDt: formValue.invoiceDate ? moment(formValue.invoiceDate[0]).format("YYYY-MM-DD") : undefined,
          invoiceEndDt: formValue.invoiceDate ? moment(formValue.invoiceDate[1]).format("YYYY-MM-DD") : undefined,
          invalidStartDt: formValue.invalidDate ? moment(formValue.invalidDate[0]).format("YYYY-MM-DD") : undefined,
          invalidEndDt: formValue.invalidDate ? moment(formValue.invalidDate[1]).format("YYYY-MM-DD") : undefined,
          deductedStartDt: formValue.deductedDate ? moment(formValue.deductedDate[0]).format("YYYY-MM-DD") : undefined,
          deductedEndDt: formValue.deductedDate ? moment(formValue.deductedDate[1]).format("YYYY-MM-DD") : undefined,
          arrivedStartDt: formValue.arrivedDate ? moment(formValue.arrivedDate[0]).format("YYYY-MM-DD") : undefined,
          arrivedEndDt: formValue.arrivedDate ? moment(formValue.arrivedDate[1]).format("YYYY-MM-DD") : undefined,
        };
        dispatch(exportCSV(params));

        break;
      case "vip":
        formValue = vipForm.getFieldsValue();
        params = {
          reportType: "vip",
          payStartDt: formValue.vipPayDate ? moment(formValue.vipPayDate[0]).format("YYYY-MM-DD") : undefined,
          payEndDt: formValue.vipPayDate ? moment(formValue.vipPayDate[1]).format("YYYY-MM-DD") : undefined,
          cancelStartDt: formValue.vipCancelDate ? moment(formValue.vipCancelDate[0]).format("YYYY-MM-DD") : undefined,
          cancelEndDt: formValue.vipCancelDate ? moment(formValue.vipCancelDate[1]).format("YYYY-MM-DD") : undefined,
        };
        dispatch(exportCSV(params));
        break;
      case "order":
        formValue = orderForm.getFieldsValue();
        params = {
          reportType: "order",
          capturedStartDt: formValue.capturedDate ? moment(formValue.capturedDate[0]).format("YYYY-MM-DD") : undefined,
          capturedEndDt: formValue.capturedDate ? moment(formValue.capturedDate[1]).format("YYYY-MM-DD") : undefined,
          refundStartDt: formValue.refundDate ? moment(formValue.refundDate[0]).format("YYYY-MM-DD") : undefined,
          refundEndDt: formValue.refundDate ? moment(formValue.refundDate[1]).format("YYYY-MM-DD") : undefined,
        };

        dispatch(exportCSV(params));
        break;

      default:
        break;
    }
  };

  const disabledDate = (current: Moment) => {
    if (!dates) {
      return false;
    }

    const over90Days = dates[0] && current.diff(dates[0], "days") > 90;
    return !!over90Days;
  };

  const onOpenChange = (open: boolean) => {
    if (open) {
      setDates([null, null]);
    }
  };

  return (
    <Wrapper>
      <BlockForm
        form={deductForm}
        colon={false}
        labelCol={{ span: 3 }}
        labelAlign="left"
        onFinish={() => onSubmit("deduct")}
      >
        <Title>扣庫未到報表</Title>
        <Row style={{ maxWidth: "400px" }}>
          <Col span={18}>
            <Form.Item label="月份" name="month" rules={[{ required: true, message: "必填" }]} labelCol={{ span: 5 }}>
              <DatePicker style={{ width: "100%" }} placeholder="請選擇" onChange={onChange} picker="month" />
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ maxWidth: "400px" }}>
          <Col span={18}>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Form.Item shouldUpdate noStyle>
                {({ getFieldValue }) => {
                  const selectedMonth = getFieldValue("month");
                  return (
                    <Button type="primary" htmlType="submit" disabled={!selectedMonth}>
                      下載
                    </Button>
                  );
                }}
              </Form.Item>
            </div>
          </Col>
        </Row>
      </BlockForm>
      <BlockForm
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 16 }}
        form={paymentForm}
        colon={false}
        labelAlign="left"
        onFinish={() => onSubmit("payment")}
      >
        {/* payment */}
        <Title>
          付款及發票清單(正負向)
          <Tooltip placement="bottom" title="以下篩選項至少必須填其中一組">
            <InfoIcon />
          </Tooltip>
        </Title>
        <CustomRow>
          <Col span={6}>訂單建立日</Col>
          <Col flex="auto">
            <Form.Item name="orderDate" noStyle>
              <RangePicker
                locale={locale}
                showTime={false}
                format="YYYY-MM-DD"
                value={dates}
                disabledDate={disabledDate}
                onCalendarChange={(value) => setDates(value)}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
        </CustomRow>
        <CustomRow>
          <Col span={6}>發票開立日</Col>
          <Col flex="auto">
            <Form.Item name="invoiceDate" noStyle>
              <RangePicker
                locale={locale}
                showTime={false}
                format="YYYY-MM-DD"
                value={dates}
                disabledDate={disabledDate}
                onCalendarChange={(value) => setDates(value)}
                onOpenChange={onOpenChange}
              />
            </Form.Item>
          </Col>
        </CustomRow>
        <CustomRow>
          <Col span={6}>發票作廢折讓日</Col>
          <Col flex="auto">
            <Form.Item name="invalidDate" noStyle>
              <RangePicker
                locale={locale}
                showTime={false}
                format="YYYY-MM-DD"
                value={dates}
                disabledDate={disabledDate}
                onCalendarChange={(value) => setDates(value)}
                onOpenChange={onOpenChange}
              />
            </Form.Item>
          </Col>
        </CustomRow>
        <CustomRow>
          <Col span={6}>請款日</Col>
          <Col flex="auto">
            <Form.Item name="capturedDate" noStyle>
              <RangePicker
                locale={locale}
                showTime={false}
                format="YYYY-MM-DD"
                value={dates}
                disabledDate={disabledDate}
                onCalendarChange={(value) => setDates(value)}
                onOpenChange={onOpenChange}
              />
            </Form.Item>
          </Col>
        </CustomRow>
        <CustomRow>
          <Col span={6}>退款日</Col>
          <Col flex="auto">
            <Form.Item name="refundDate" noStyle>
              <RangePicker
                locale={locale}
                showTime={false}
                format="YYYY-MM-DD"
                value={dates}
                disabledDate={disabledDate}
                onCalendarChange={(value) => setDates(value)}
                onOpenChange={onOpenChange}
              />
            </Form.Item>
          </Col>
        </CustomRow>
        <CustomRow justify="end">
          <Form.Item shouldUpdate noStyle>
            {({ getFieldsValue }) => {
              const selected = getFieldsValue();
              let hasSelect;
              Object.values(selected).forEach((item) => {
                if (item) {
                  hasSelect = true;
                }
              });
              return (
                <>
                  <Button style={{ marginRight: "10px" }} onClick={() => reset("payment")}>
                    清除篩選資料
                  </Button>
                  <Button type="primary" htmlType="submit" disabled={!hasSelect}>
                    下載
                  </Button>
                </>
              );
            }}
          </Form.Item>
        </CustomRow>
      </BlockForm>
      <BlockForm
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 16 }}
        form={vendorForm}
        colon={false}
        labelAlign="left"
        onFinish={() => onSubmit("vendor")}
      >
        {/* vendor */}
        <Title>
          廠商結算商品清單(正負向)
          <Tooltip placement="bottom" title="以下篩選項至少必須填其中一組">
            <InfoIcon />
          </Tooltip>
        </Title>
        <CustomRow>
          <Col span={6}>發票開立日</Col>
          <Col flex="auto">
            <Form.Item label="" name="invoiceDate" noStyle>
              <RangePicker
                locale={locale}
                showTime={false}
                format="YYYY-MM-DD"
                value={dates}
                disabledDate={disabledDate}
                onCalendarChange={(value) => setDates(value)}
                onOpenChange={onOpenChange}
              />
            </Form.Item>
          </Col>
        </CustomRow>
        <CustomRow>
          <Col span={6}>發票作廢折讓日</Col>
          <Col flex="auto">
            <Form.Item name="invalidDate" noStyle>
              <RangePicker
                locale={locale}
                showTime={false}
                format="YYYY-MM-DD"
                value={dates}
                disabledDate={disabledDate}
                onCalendarChange={(value) => setDates(value)}
                onOpenChange={onOpenChange}
              />
            </Form.Item>
          </Col>
        </CustomRow>
        <CustomRow>
          <Col span={6}>扣庫日</Col>
          <Col flex="auto">
            <Form.Item name="deductedDate" noStyle>
              <RangePicker
                locale={locale}
                showTime={false}
                format="YYYY-MM-DD"
                value={dates}
                disabledDate={disabledDate}
                onCalendarChange={(value) => setDates(value)}
                onOpenChange={onOpenChange}
              />
            </Form.Item>
          </Col>
        </CustomRow>
        <CustomRow>
          <Col span={6}>到貨日</Col>
          <Col flex="auto">
            <Form.Item name="arrivedDate" noStyle>
              <RangePicker
                locale={locale}
                showTime={false}
                format="YYYY-MM-DD"
                value={dates}
                disabledDate={disabledDate}
                onCalendarChange={(value) => setDates(value)}
                onOpenChange={onOpenChange}
              />
            </Form.Item>
          </Col>
        </CustomRow>
        <CustomRow justify="end">
          <Form.Item shouldUpdate noStyle>
            {({ getFieldsValue }) => {
              const selected = getFieldsValue();
              let hasSelect;
              Object.values(selected).forEach((item) => {
                if (item) {
                  hasSelect = true;
                }
              });
              return (
                <>
                  <Button style={{ marginRight: "10px" }} onClick={() => reset("vendor")}>
                    清除篩選資料
                  </Button>
                  <Button type="primary" htmlType="submit" disabled={!hasSelect}>
                    下載
                  </Button>
                </>
              );
            }}
          </Form.Item>
        </CustomRow>
      </BlockForm>
      <BlockForm
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 17 }}
        form={vipForm}
        colon={false}
        labelAlign="left"
        onFinish={() => onSubmit("vip")}
      >
        {/* vip */}
        <Title>
          VIP會籍購買紀錄(正負向)
          <Tooltip placement="bottom" title="以下篩選項至少必須填其中一組">
            <InfoIcon />
          </Tooltip>
        </Title>
        <CustomRow>
          <Col span={6}>會籍購買日</Col>
          <Col flex="auto">
            <Form.Item name="vipPayDate" noStyle>
              <RangePicker
                locale={locale}
                showTime={false}
                format="YYYY-MM-DD"
                value={dates}
                disabledDate={disabledDate}
                onCalendarChange={(value) => setDates(value)}
                onOpenChange={onOpenChange}
              />
            </Form.Item>
          </Col>
        </CustomRow>
        <CustomRow>
          <Col span={6}>會籍取消日</Col>
          <Col flex="auto">
            <Form.Item name="vipCancelDate" noStyle>
              <RangePicker
                locale={locale}
                showTime={false}
                format="YYYY-MM-DD"
                value={dates}
                disabledDate={disabledDate}
                onCalendarChange={(value) => setDates(value)}
                onOpenChange={onOpenChange}
              />
            </Form.Item>
          </Col>
        </CustomRow>

        <CustomRow justify="end">
          <Form.Item shouldUpdate noStyle>
            {({ getFieldsValue }) => {
              const selected = getFieldsValue();
              let hasSelect;
              Object.values(selected).forEach((item) => {
                if (item) {
                  hasSelect = true;
                }
              });
              return (
                <>
                  <Button style={{ marginRight: "10px" }} onClick={() => reset("vip")}>
                    清除篩選資料
                  </Button>
                  <Button type="primary" htmlType="submit" disabled={!hasSelect}>
                    下載
                  </Button>
                </>
              );
            }}
          </Form.Item>
        </CustomRow>
      </BlockForm>
      <BlockForm
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 17 }}
        form={orderForm}
        colon={false}
        labelAlign="left"
        onFinish={() => onSubmit("order")}
      >
        {/* order */}
        <Title>
          訂單金額清單(正負向)
          <Tooltip placement="bottom" title="以下篩選項至少必須填其中一組">
            <InfoIcon />
          </Tooltip>
        </Title>
        <CustomRow>
          <Col span={6}>請款日</Col>
          <Col flex="auto">
            <Form.Item name="capturedDate" noStyle>
              <RangePicker
                locale={locale}
                showTime={false}
                format="YYYY-MM-DD"
                value={dates}
                disabledDate={disabledDate}
                onCalendarChange={(value) => setDates(value)}
                onOpenChange={onOpenChange}
              />
            </Form.Item>
          </Col>
        </CustomRow>
        <CustomRow>
          <Col span={6}>退款日</Col>
          <Col flex="auto">
            <Form.Item name="refundDate" noStyle>
              <RangePicker
                locale={locale}
                showTime={false}
                format="YYYY-MM-DD"
                value={dates}
                disabledDate={disabledDate}
                onCalendarChange={(value) => setDates(value)}
                onOpenChange={onOpenChange}
              />
            </Form.Item>
          </Col>
        </CustomRow>

        <CustomRow justify="end">
          <Form.Item shouldUpdate noStyle>
            {({ getFieldsValue }) => {
              const selected = getFieldsValue();
              let hasSelect;
              Object.values(selected).forEach((item) => {
                if (item) {
                  hasSelect = true;
                }
              });
              return (
                <>
                  <Button style={{ marginRight: "10px" }} onClick={() => reset("order")}>
                    清除篩選資料
                  </Button>
                  <Button type="primary" htmlType="submit" disabled={!hasSelect}>
                    下載
                  </Button>
                </>
              );
            }}
          </Form.Item>
        </CustomRow>
      </BlockForm>
    </Wrapper>
  );
};

export default StatisticsDownload;
