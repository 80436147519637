import { ReturnOrderItem } from "@page/ReturnOrder/interfaces";
import reasons from "@page/ReturnOrder/reasonData";
import moment from "moment";

/* eslint-disable camelcase */
interface Item {
  id: number;
  estimated_shipping_date: string | null; // "2021-03-10" |
  shipped_date: string | null;
  staff: {
    id: number;
    name: string;
  };
  purpose: number;
  distributor: number;
  is_submitted: boolean;
  total_cost: number;
  total_qty: number;
  status: number;
  description: string;
  lading_number: string;
  shipping_status: number;
  address: string;
  zipcode: string;
  recipient: string;
  recipient_tel: string;
  created_at: string; // "2021-03-02T14:43:38.661092"
  section_manager_approval: number;
  manager_approval: number;
  finance_approval: number;
  ceo_approval: number;
  shipper_number: string | null;
  batch_number: string | null;
  destroyed_at: string | null; // "2021-03-02T14:43:38.661092"
  only_record: boolean;
  warehouse_id: string;
  charging_method: number;
  estimated_arrived_date: string | null;
  package_number: string;
}

function getResonText(purposeId: number) {
  try {
    return reasons.filter((reason) => reason.value === purposeId)[0].label;
  } catch (error: any) {
    return `${purposeId}`;
  }
}

export default function normalizeReturnedOrders(results: Item[], totalCount: number): ReturnOrderItem[] {
  return results.map((ele: Item) => {
    return {
      id: ele.id || -1,
      orderNumber: ele.lading_number || "",
      reviewStateByPosition: {
        ceo: ele.ceo_approval,
        manager: ele.manager_approval,
        sectionManager: ele.section_manager_approval,
        finance: ele.finance_approval,
      },
      reviewState: ele.status,
      shipmentState: ele.shipping_status,
      reasonId: ele.purpose,
      reasonLabel: getResonText(ele.purpose),
      createOrderDate: ele.created_at ? moment(ele.created_at).format("YYYY-MM-DD HH:mm:ss") : "",
      estimateShippingDate: ele.estimated_shipping_date
        ? moment(ele.estimated_shipping_date).format("YYYY-MM-DD HH:mm:ss")
        : "",
      actualShippingDate: ele.shipped_date ? moment(ele.shipped_date).format("YYYY-MM-DD HH:mm:ss") : "",
      orderedQty: ele.total_qty,
      totalCost: ele.total_cost,
      distributionId: ele.distributor,
      distributionLabel: `${ele.distributor}`,
      ordererId: ele.staff?.id || -1,
      ordererName: ele.staff?.name || "",
      totalCount,
      recipient: ele.recipient || "",
      recipientTel: ele.recipient_tel || "",
      shipperNumber: ele.shipper_number || "",
      packageNumber: ele.package_number || "",
      address: ele.address || "",
      zipCode: ele.zipcode || "",
      batchNumber: ele.batch_number || "",
      description: ele.description || "",
      isSubmitted: ele.is_submitted || false,
      // 如果已作廢, API 會回壓日期, 否則回傳 null
      destroyed: typeof ele.destroyed_at === "string",
      onlyRecord: ele.only_record || false,
      warehouseId: ele.warehouse_id,
      chargingMethod: ele.charging_method,
      estimatedArrivedDate: ele.estimated_arrived_date,
    };
  });
}
