import { FilterTwoTone, PlusCircleTwoTone } from "@ant-design/icons";
import PageTitle from "@component/PageTitle";
import {
  externalOrderState,
  fetchExternalOrdersList,
  resetExternalOrderSlice,
  setExternalOrdersFilter,
} from "@redux/externalOrderSlice";
import { Button, Row, Select, Table } from "antd";
import { SelectValue } from "antd/lib/select";
import { ColumnsType } from "antd/lib/table";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "src/store";
import fontStyle from "src/styles/fontStyle";
import styled from "styled-components";
import ExternalOrderFilter from "./ExternalOrderFilter";

const Wrapper = styled.div`
  padding: 20px 20px 20px 25px;
`;
const PageText = styled.span`
  ${fontStyle("14px", "20px")};
`;
const SelectPageSize = styled(Select)`
  &&& {
    margin: 0 10px;
  }
`;
const TableWrapper = styled.div`
  border: solid 1px #f0f0f0;
  border-radius: 2px;
  padding: 20px 10px 22px 17px;
  margin-top: 20px;
`;
const CustomButton = styled(Button)`
  margin-right: 10px;
`;

export default function ExternalOrder() {
  const { externalOrdersListResult, externalOrdersFilter } = useSelector(externalOrderState);

  const dispatch = useAppDispatch();
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);

  const setPageLimit = (value: SelectValue) => {
    dispatch(
      setExternalOrdersFilter({
        ...externalOrdersFilter,
        limit: parseInt(value as string, 10),
      }),
    );
  };

  const fetchPageChange = (value: number) => {
    setPage(value);
    dispatch(
      setExternalOrdersFilter({
        ...externalOrdersFilter,
        offset: (value - 1) * externalOrdersFilter.limit,
      }),
    );
  };

  useEffect(() => {
    dispatch(fetchExternalOrdersList(externalOrdersFilter));
  }, [dispatch, externalOrdersFilter]);

  useEffect(() => {
    return () => {
      dispatch(resetExternalOrderSlice());
    };
  }, [dispatch]);

  const columns: ColumnsType<any> = [
    {
      key: "id",
      dataIndex: "id",
      title: "編號",
    },
    {
      key: "orderNumber",
      dataIndex: "orderNumber",
      title: "訂單編號",
    },
    {
      key: "approvalStatus",
      dataIndex: "approvalStatus",
      title: "審核狀態",
    },
    {
      key: "retailerName",
      dataIndex: "retailerName",
      title: "合作通路",
    },
    {
      key: "shippingStatus",
      dataIndex: "shippingStatus",
      title: "退倉單狀態",
    },
    {
      key: "staff",
      dataIndex: "staff",
      title: "訂單申請人",
      render: (_, values) => <div>{values?.staff?.name}</div>,
    },
    {
      key: "createdAt",
      dataIndex: "createdAt",
      title: "訂單建立時間",
    },
    {
      key: "shippingAt",
      dataIndex: "shippingAt",
      title: "實際出貨時間",
    },
    {
      key: "buttons",
      dataIndex: "buttons",
      title: "",
      render: (_, values) => {
        return (
          <Row justify="end">
            <Button type="link" onClick={() => window.open(`${window.location.href}/${values.id}/review`)}>
              檢視
            </Button>
            <Button
              type="link"
              onClick={() => window.open(`${window.location.href}/${values.id}`)}
              disabled={values.shippingStatus === "訂單處理中(已拋單)" || values.approvalStatus === "審核完成"}
            >
              編輯
            </Button>
          </Row>
        );
      },
    },
  ];

  return (
    <Wrapper>
      <PageTitle title="出貨訂單管理" />
      <Row justify="space-between">
        <div>
          <CustomButton
            type="primary"
            icon={<PlusCircleTwoTone twoToneColor="#1890FF" />}
            onClick={() => window.open(`${window.location.href}/new`)}
          >
            建立出貨訂單
          </CustomButton>
          <Button icon={<FilterTwoTone />} onClick={() => setShowFilter(!showFilter)}>
            篩選
          </Button>
        </div>
        <Row align="middle">
          <PageText>{`總共${externalOrdersListResult.count}筆, 每頁顯示`}</PageText>
          <SelectPageSize defaultValue="20" onChange={setPageLimit}>
            <Select.Option value="20">20</Select.Option>
            <Select.Option value="50">50</Select.Option>
            <Select.Option value="100">100</Select.Option>
          </SelectPageSize>
          <PageText>筆</PageText>
        </Row>
      </Row>
      {showFilter && <ExternalOrderFilter />}
      <TableWrapper>
        <Table
          scroll={{ x: "max-content" }}
          columns={columns}
          dataSource={externalOrdersListResult.results}
          pagination={{
            pageSize: externalOrdersFilter.limit,
            current: page,
            showSizeChanger: false,
            total: externalOrdersListResult.count,
            onChange: fetchPageChange,
          }}
        />
      </TableWrapper>
    </Wrapper>
  );
}
