/* eslint-disable jsx-a11y/control-has-associated-label */
import ImageLinkIcon from "@assets/imageLink.svg";
import { uploadPhotoToS3 } from "@utils/AWS";
import Quill from "quill";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import ReactQuill from "react-quill";
import styled from "styled-components";
import IndentStyle from "./IndentStyle";

const Wrapper = styled.div`
  flex: 1;

  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    order: 1;
  }

  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
    margin: 0;
  }

  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="12px"]::before {
    content: "12px";
    font-size: 12px;
  }

  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="14px"]::before {
    content: "14px";
    font-size: 14px;
  }

  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="16px"]::before {
    content: "16px";
    font-size: 16px;
  }

  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="18px"]::before {
    content: "18px";
    font-size: 18px;
  }

  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="24px"]::before {
    content: "24px";
    font-size: 24px;
  }

  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="30px"]::before {
    content: "30px";
    font-size: 30px;
  }

  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="36px"]::before {
    content: "36px";
    font-size: 36px;
  }

  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="48px"]::before {
    content: "48px";
    font-size: 48px;
  }

  .ql-snow {
    .ql-picker {
      &.ql-size {
        .ql-picker-label,
        .ql-picker-item {
          &::before {
            content: attr(data-value);
          }
        }
      }
    }
  }

  .ql-editor > .ql-video {
    width: 100%;
    height: 315px;
  }

  .quill {
    height: 600px;
  }

  strong {
    font-weight: bold;

    em {
      font-weight: bold;
    }
  }

  em {
    font-style: italic;
  }

  .ql-snow .ql-editor img {
    width: 100%;
    height: max-content;
  }
`;
const ImageLinkInsert = styled.div`
  position: absolute;
  left: 50%;
  top: 100%;
  transform: translateX(-50%);
  width: 200px;
  height: 100px;
  background: white;
  border: solid 1px black;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  z-index: 1;
  padding: 10px;
`;
const ImageButton = styled.button`
  &&& {
    float: initial;
    width: auto;
    border: solid 1px black;
  }
`;
const ImageFormat = styled.span`
  position: relative;
`;
const ImageLink = styled.img`
  width: 14px;
`;

const Size = Quill.import("attributors/style/size");
const Align = Quill.import("attributors/style/align");
const defaultFontList = ["12px", "14px", "16px", "18px", "24px", "30px", "36px", "48px"];
const alignList = ["left", "center", "right", "justify"];
const defaultColorList = [
  "#e60000",
  "#ff9900",
  "#ffff00",
  "#008a00",
  "#0066cc",
  "#9933ff",
  "#ffffff",
  "#facccc",
  "#ffebcc",
  "#ffffcc",
  "#cce8cc",
  "#cce0f5",
  "#ebd6ff",
  "#bbbbbb",
  "#f06666",
  "#ffc266",
  "#ffff66",
  "#66b966",
  "#66a3e0",
  "#c285ff",
  "#888888",
  "#a10000",
  "#b26b00",
  "#b2b200",
  "#006100",
  "#0047b2",
  "#6b24b2",
  "#444444",
  "#5c0000",
  "#663d00",
  "#666600",
  "#003700",
  "#002966",
  "#3d1466",
];
Size.whitelist = defaultFontList;
Align.whitelist = alignList;
Quill.register(Size, true);
Quill.register(Align, true);
Quill.register(IndentStyle, true);

type Props = {
  toolbarName: string;
  uploadImagePathname: string;
  defaultHtml: string;
  height?: number;
  toolbarBlacklist?: string[];
  onChangeQuill: (html: string) => void;
  customFontList?: string[];
  customColorList?: string[];
};

export default function QuillEditor(props: Props) {
  const {
    toolbarName,
    uploadImagePathname,
    defaultHtml,
    height,
    toolbarBlacklist = [],
    onChangeQuill,
    customFontList,
    customColorList,
  } = props;

  const [quillHtml, setQuillHtml] = useState<string>("");
  const [showImageLink, setShowImageLink] = useState<boolean>(false);
  const [insertImageLink, setInsertImageLink] = useState<string>("");
  const fontList = customFontList || defaultFontList;
  const colorList = customColorList || defaultColorList;
  const quillRef = useRef<any>(null);

  const uploadImageCallBack = useCallback(
    async (file: File) => {
      try {
        const awsObject = await uploadPhotoToS3({
          file,
          name: `${Date.now()}-${file.name}`,
          pathname: uploadImagePathname,
        });
        const hostDomain = awsObject.Location.split(uploadImagePathname)[0];
        return awsObject.Location.replace(hostDomain, process.env.REACT_APP_CLOUDFLARE_DOMAIN as string);
      } catch (error: any) {
        return "";
      }
    },
    [uploadImagePathname],
  );

  const onHandleImageLink = () => {
    const range = quillRef.current.getEditor().getSelection(true);
    if (range) {
      quillRef.current.getEditor().setSelection(range.index + 1);
      quillRef.current.getEditor().insertEmbed(range.index, "image", insertImageLink);
    }
    setInsertImageLink("");
    setShowImageLink(false);
  };

  const onHandleQuillImageUpload = useCallback(() => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();
    input.onchange = async () => {
      const file = input.files && input.files[0];
      if (file && quillRef?.current) {
        const range = quillRef.current.getEditor().getSelection(true);
        if (range) {
          quillRef.current.getEditor().setSelection(range.index + 1);
          const imageUrl = await uploadImageCallBack(file);
          quillRef.current.getEditor().insertEmbed(range.index, "image", imageUrl);
        }
      }
    };
  }, [uploadImageCallBack]);

  const quillModule = useMemo(
    () => ({
      toolbar: {
        container: `#${toolbarName}`,
        handlers: {
          image: onHandleQuillImageUpload,
          imageLink: () => setShowImageLink((prevState) => !prevState),
        },
      },
    }),
    [onHandleQuillImageUpload, toolbarName],
  );

  useEffect(() => {
    if (defaultHtml) {
      setQuillHtml(defaultHtml);
    } else {
      setQuillHtml("");
    }
  }, [defaultHtml]);

  const CustomToolbar = () => (
    <div id={toolbarName}>
      <span className="ql-formats">
        <select className="ql-size">
          {fontList.map((item) => (
            <option value={item} />
          ))}
        </select>
      </span>
      <span className="ql-formats">
        <button className="ql-bold" />
        <button className="ql-italic" />
        <button className="ql-underline" />
        <button className="ql-strike" />
        {!toolbarBlacklist.includes("blockquote") && <button className="ql-blockquote" />}
      </span>
      <span className="ql-formats">
        <button className="ql-list" value="ordered" />
        <button className="ql-list" value="bullet" />
        {!toolbarBlacklist.includes("indent") && <button className="ql-indent" value="-1" />}
        {!toolbarBlacklist.includes("indent") && <button className="ql-indent" value="+1" />}
        {!toolbarBlacklist.includes("align") && (
          <select className="ql-align">
            <option selected />
            <option value="center" />
            <option value="right" />
            <option value="justify" />
          </select>
        )}
        <select className="ql-color">
          {colorList.map((item) => (
            <option value={item} />
          ))}
          <option selected />
        </select>
        {!toolbarBlacklist.includes("background") && (
          <select className="ql-background">
            {colorList.map((item) => (
              <option value={item} />
            ))}
            <option selected />
          </select>
        )}
      </span>
      <ImageFormat className="ql-formats">
        {!toolbarBlacklist.includes("link") && <button className="ql-link" />}
        {!toolbarBlacklist.includes("video") && <button className="ql-video" />}
        {!toolbarBlacklist.includes("image") && <button className="ql-image" />}
        {!toolbarBlacklist.includes("imageLink") && (
          <button className="ql-imageLink">
            <ImageLink src={ImageLinkIcon} alt="" />
          </button>
        )}
        {showImageLink && (
          <ImageLinkInsert>
            <input
              type="text"
              placeholder="圖片網址"
              value={insertImageLink}
              onChange={(e) => setInsertImageLink(e.target.value)}
            />
            <ImageButton type="button" onClick={onHandleImageLink}>
              插入圖片連結
            </ImageButton>
          </ImageLinkInsert>
        )}
      </ImageFormat>
    </div>
  );

  return (
    <Wrapper>
      {CustomToolbar()}
      <ReactQuill
        style={{ height: height || 598 }}
        theme="snow"
        ref={quillRef}
        modules={quillModule}
        formats={[
          "bold",
          "italic",
          "underline",
          "strike",
          "blockquote",
          "size",
          "list",
          "indent",
          "align",
          "link",
          "image",
          "video",
          "color",
          "background",
        ]}
        value={quillHtml}
        onChange={(html) => {
          setQuillHtml(html || "");
          onChangeQuill(html || "");
        }}
      />
      {showImageLink && (
        <ImageLinkInsert>
          <input
            type="text"
            placeholder="圖片網址"
            value={insertImageLink}
            onChange={(e) => setInsertImageLink(e.target.value)}
          />
          <ImageButton type="button" onClick={onHandleImageLink}>
            插入圖片連結
          </ImageButton>
        </ImageLinkInsert>
      )}
    </Wrapper>
  );
}
