import { ExclamationCircleOutlined, FilterTwoTone, PlusCircleFilled } from "@ant-design/icons";
import { RuleListItem } from "@api/ruleApi";
import { configState } from "@redux/configSlice";
import { RootState } from "@redux/rootReducer";
import {
  batchDeleteRules,
  fetchRuleList,
  reset,
  singleDeleteRule,
  updateRuleIsActive,
  updateRuleListParams,
  updateSelectedRuleIds,
} from "@redux/ruleSlice";
import { Button, Modal, Pagination, Select, Switch, Table } from "antd";
import { SelectValue } from "antd/lib/select";
import { SwitchChangeEventHandler } from "antd/lib/switch";
import { ColumnsType } from "antd/lib/table";
import React, { FC, ReactText, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "src/store";
import styled from "styled-components";
import PageTitle from "@component/PageTitle";
import actionTypeValue from "./actionTypeValue";
import conditionTypeValue from "./conditionTypeValue";
import Filter from "./Filter";

const Wrapper = styled.div`
  padding: 20px 20px 0 25px;
`;
const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 21px;
`;
const TopButtonContainer = styled.div``;
const TablePageInfo = styled.div``;
const TopButton = styled(Button)`
  margin-right: 10px;
`;
const PageSelect = styled(Select)`
  margin: 0 10px;
`;
const TableContainer = styled.div`
  position: relative;
  padding: 20px 10px 100px 10px;
  border: 1px solid #f0f0f0;
`;
const DeleteButton = styled(Button)`
  position: absolute;
  left: 26px;
  bottom: 47px;
`;
const TablePagination = styled(Pagination)`
  position: absolute;
  right: 21px;
  bottom: 47px;
`;
const TableNoWrap = styled.div`
  white-space: nowrap;
`;
const EditTextContainer = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
`;
const Link = styled.a`
  font-size: 13px;
  color: ${({ theme }) => theme.colorSuccess500};
  cursor: pointer;
  margin: 0;
  text-decoration: underline;
`;
const EditText = styled.a`
  font-size: 13px;
  color: #1890ff;
  cursor: pointer;
  margin: 0;
  margin-right: 22px;
`;
const DeleteText = styled.p`
  font-size: 13px;
  color: #1890ff;
  cursor: pointer;
  margin: 0;
  margin-right: 22px;
`;
const EllipsisText = styled.div`
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const pageSizeOptions = [20, 50, 100];

const RuleList: FC = () => {
  const dispatch = useAppDispatch();
  const { webDomain } = useSelector(configState);
  const { isFetchingRuleList, ruleListResult, ruleListParams, selectedRuleIds } = useSelector(
    (state: RootState) => state.rule,
  );

  const [showFilter, setShowFilter] = useState<boolean>(false);

  const { count, results: ruleList } = ruleListResult;
  const { limit, offset } = ruleListParams;

  const currentPage = useMemo(() => {
    return Math.floor(offset / limit) + 1;
  }, [limit, offset]);

  useEffect(() => {
    dispatch(fetchRuleList());

    return () => {
      dispatch(reset());
    };
  }, [dispatch]);

  const toggleFilter = () => setShowFilter((prev) => !prev);

  const handleOnSingleDelete = (id: number) => () => {
    Modal.confirm({
      title: "你確定要刪除這些資料?",
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: "是",
      cancelText: "否",
      onOk: () => dispatch(singleDeleteRule(id)),
    });
  };

  const handleOnSwitch = (id: number): SwitchChangeEventHandler => (checked) => {
    const params = {
      id,
      isActive: checked,
    };
    dispatch(updateRuleIsActive(params));
  };

  const handleOnDelete = () => {
    Modal.confirm({
      title: "你確定要刪除這些資料?",
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: "是",
      cancelText: "否",
      onOk: () => dispatch(batchDeleteRules()),
    });
  };

  const handleOnCheck = (values: ReactText[]) => {
    dispatch(updateSelectedRuleIds(values as number[]));
  };

  const handleOnPageSizeChange = (value: SelectValue) => {
    const filter = {
      ...ruleListParams,
      limit: value as number,
    };

    dispatch(updateRuleListParams(filter));
    dispatch(fetchRuleList());
  };

  const handleOnPageChange = (page: number) => {
    const filter = {
      ...ruleListParams,
      offset: (page - 1) * limit,
    };

    dispatch(updateRuleListParams(filter));
    dispatch(fetchRuleList());
  };

  const tableColumns: ColumnsType<RuleListItem> = [
    {
      title: <TableNoWrap>ID</TableNoWrap>,
      key: "id",
      dataIndex: "id",
      width: 54,
    },
    {
      title: <TableNoWrap>啟用</TableNoWrap>,
      key: "isActive",
      dataIndex: "isActive",
      render: (value: RuleListItem["isActive"], data: RuleListItem) => (
        <Switch checked={value} onChange={handleOnSwitch(data.id)} />
      ),
      width: 64,
    },
    {
      title: <TableNoWrap>名稱</TableNoWrap>,
      key: "title",
      dataIndex: "title",
      width: 170,
      render: (value: RuleListItem["title"]) => <EllipsisText title={value}>{value}</EllipsisText>,
    },
    {
      title: <TableNoWrap>頁面資訊</TableNoWrap>,
      key: "destinationLink",
      dataIndex: "destinationLink",
      render: (value: RuleListItem["destinationLink"]) =>
        value ? (
          <Link href={`${webDomain}/${value}`} target="_blank">
            {value}
          </Link>
        ) : (
          "N/A"
        ),
      width: 85,
    },
    {
      title: <TableNoWrap>條件</TableNoWrap>,
      key: "conditionType",
      dataIndex: "conditionType",
      width: 80,
      render: (value: RuleListItem["conditionType"]) => conditionTypeValue[value] || "無條件",
    },
    {
      title: <TableNoWrap>金額/件數</TableNoWrap>,
      key: "conditionAmount",
      dataIndex: "conditionAmount",
      width: 83,
      render: (value: RuleListItem["conditionAmount"]) => value || "N/A",
    },
    {
      title: <TableNoWrap>案型</TableNoWrap>,
      key: "actionType",
      dataIndex: "actionType",
      width: 102,
      render: (value: RuleListItem["actionType"]) => actionTypeValue[value] || "無條件",
    },
    {
      title: <TableNoWrap>折抵上限</TableNoWrap>,
      key: "discountLimit",
      dataIndex: "discountLimit",
      width: 86,
      render: (value: RuleListItem["discountLimit"]) => value || "N/A",
    },
    {
      title: <TableNoWrap>領取數量</TableNoWrap>,
      key: "maxBindTimes",
      dataIndex: "maxBindTimes",
      width: 86,
      render: (value: RuleListItem["maxBindTimes"]) => value || "N/A",
    },
    {
      title: <TableNoWrap>開放領取</TableNoWrap>,
      key: "bindOnceStartAt",
      dataIndex: "bindOnceStartAt",
      width: 150,
      render: (value: RuleListItem["maxBindTimes"]) => <EllipsisText>{value || "N/A"}</EllipsisText>,
    },
    {
      title: <TableNoWrap>使用時間規則</TableNoWrap>,
      key: "useRuleDescription",
      dataIndex: "useRuleDescription",
      width: 150,
      render: (value: RuleListItem["useRuleDescription"]) => <EllipsisText>{value || "N/A"}</EllipsisText>,
    },
    {
      title: "",
      width: 75,
      fixed: "right",
      render: (value: any, data: RuleListItem) => {
        return (
          <EditTextContainer>
            <EditText href={`/rule/edit/${data.id}`} target="_blank">
              編輯
            </EditText>
            <DeleteText onClick={handleOnSingleDelete(data.id)}>刪除</DeleteText>
          </EditTextContainer>
        );
      },
    },
  ];

  return (
    <Wrapper>
      <PageTitle title="優惠案型" />
      <Header>
        <TopButtonContainer>
          <a href="/rule/add" target="_blank">
            <TopButton type="primary" icon={<PlusCircleFilled />}>
              新增優惠
            </TopButton>
          </a>
          <TopButton icon={<FilterTwoTone />} onClick={toggleFilter}>
            篩選
          </TopButton>
        </TopButtonContainer>
        <TablePageInfo>
          總共
          {count}
          筆, 每頁顯示
          <PageSelect value={limit} onChange={handleOnPageSizeChange}>
            {pageSizeOptions.map((option) => (
              <Select.Option key={option} value={option}>
                {option}
              </Select.Option>
            ))}
          </PageSelect>
          筆
        </TablePageInfo>
      </Header>
      {showFilter && <Filter />}
      <TableContainer>
        <Table
          rowKey="id"
          dataSource={ruleList}
          columns={tableColumns}
          scroll={{ x: "max-content", y: 400 }}
          rowSelection={{
            onChange: handleOnCheck,
            selectedRowKeys: selectedRuleIds,
          }}
          tableLayout="auto"
          pagination={false}
          loading={isFetchingRuleList}
        />
        <DeleteButton onClick={handleOnDelete} disabled={selectedRuleIds.length === 0}>
          刪除
        </DeleteButton>
        <TablePagination
          current={currentPage}
          total={count}
          onChange={handleOnPageChange}
          showSizeChanger={false}
          pageSize={limit}
        />
      </TableContainer>
    </Wrapper>
  );
};

export default RuleList;
