/* eslint-disable react/destructuring-assignment */
import React, { FC, useEffect, ReactText, useMemo } from "react";
import styled from "styled-components";
import { CloseOutlined } from "@ant-design/icons";
import { Button, Table, Select } from "antd";
import { useAppDispatch } from "src/store";
import { RootState } from "@redux/rootReducer";
import { useSelector } from "react-redux";
import { SaleListItem } from "@api/saleApi";
import { SelectValue } from "antd/lib/select";
import { batchAddSalePages, fetchSaleList, reset, updateSaleListFilter, updateSelectedSalePageIds } from "@redux/slotAddSalesSlice";
import Filter from "./Filter";

interface Props {
  onClose: () => void;
}

const Wrapper = styled.div`
  width: 870px;
  background-color: #ffffff;
`;
const CloseIcon = styled(CloseOutlined)`
  position: absolute;
  right: 22px;
  top: 22px;
`;
const TableNoWrap = styled.div`
  white-space: nowrap;
`;
const TablePageInfo = styled.div`
  font-size: 14px;
  text-align: right;
  margin: 10px 0;
`;
const PageSelect = styled(Select)`
  margin: 0 10px;
`;
const SaveButton = styled(Button)`
  position: absolute;
  right: 28px;
  bottom: 20px;
`;

const pageSizeOptions = [50, 100, 200];

const AddSalePage: FC<Props> = (props) => {
  const { onClose } = props;
  const { saleListResult, selectedSalePageIds, isFetchingSaleList, saleListFilter } = useSelector(
    (state: RootState) => state.slotAddSalesSlice,
  );
  const dispatch = useAppDispatch();

  const { count, results: saleList } = saleListResult;
  const { limit, offset } = saleListFilter;

  const currentPage = useMemo(() => {
    return Math.floor(offset / limit) + 1;
  }, [limit, offset]);

  useEffect(() => {
    return () => {
      dispatch(reset());
    };
  }, [dispatch]);

  const handleOnCheck = (value: ReactText[]) => {
    dispatch(updateSelectedSalePageIds(value as number[]));
  };

  const handleOnPageSizeChange = (value: SelectValue) => {
    const filter = {
      ...saleListFilter,
      limit: value as number,
    };

    dispatch(updateSaleListFilter(filter));
    dispatch(fetchSaleList());
  };

  const handleOnPageChange = (page: number) => {
    const filter = {
      ...saleListFilter,
      offset: (page - 1) * limit,
    };

    dispatch(updateSaleListFilter(filter));
    dispatch(fetchSaleList());
  };

  const handleOnSubmit = () => {
    dispatch(batchAddSalePages());
    onClose();
  };

  const tableColumns = useMemo(
    () => [
      {
        title: <TableNoWrap>ID</TableNoWrap>,
        key: "id",
        dataIndex: "id",
      },
      {
        title: <TableNoWrap>SKU</TableNoWrap>,
        key: "skuList",
        dataIndex: "skuList",
        render: (value: SaleListItem["skuList"]) => {
          return (
            <div>
              {value.map((sku) => (
                <p>{sku}</p>
              ))}
            </div>
          );
        },
      },
      {
        title: <TableNoWrap>名稱</TableNoWrap>,
        key: "name",
        dataIndex: "name",
      },
      {
        title: <TableNoWrap>品牌</TableNoWrap>,
        key: "brands",
        dataIndex: "brands",
        width: 120,
        render: (value: SaleListItem["brands"]) => {
          return (
            <div>
              {value.map((brand) => (
                <p>{brand}</p>
              ))}
            </div>
          );
        },
      },
      {
        title: <TableNoWrap>價錢</TableNoWrap>,
        key: "planSpecialPriceRange",
        dataIndex: "planSpecialPriceRange",
        render: (value: SaleListItem["planSpecialPriceRange"]) => {
          const { minPrice, maxPrice } = value;
          return `$${minPrice} - $${maxPrice}`;
        },
      },
    ],
    [],
  );

  return (
      <Wrapper>
        <CloseIcon onClick={onClose} />
        <Filter />
        <TablePageInfo>
          總共
          {count}
          筆, 每頁顯示
          <PageSelect value={limit} onChange={handleOnPageSizeChange}>
            {pageSizeOptions.map((option) => (
              <Select.Option key={option} value={option}>
                {option}
              </Select.Option>
            ))}
          </PageSelect>
          筆
        </TablePageInfo>
        <Table
          dataSource={saleList}
          columns={tableColumns}
          scroll={{ y: 170 }}
          rowSelection={{
            onChange: handleOnCheck,
            selectedRowKeys: selectedSalePageIds,
          }}
          tableLayout="auto"
          pagination={{
            pageSize: limit,
            current: currentPage,
            showSizeChanger: false,
            total: count,
            onChange: handleOnPageChange,
          }}
          loading={isFetchingSaleList}
          rowKey="id"
        />
        <SaveButton type="primary" onClick={handleOnSubmit} disabled={selectedSalePageIds.length === 0}>
          儲存
        </SaveButton>
      </Wrapper>
  );
};

export default AddSalePage;
