/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useEffect, useState, ChangeEvent, useMemo, useCallback } from "react";
import styled from "styled-components";
import InfiniteScrollObserver from "@component/InfiniteScrollObserver";
import { Input, Select, Button, DatePicker } from "antd";
import { SelectValue } from "antd/lib/select";
import { loadMoreBrandList, fetchBrandList } from "@redux/productSlice";
import { updatePromotionListParams, fetchPromotionList, clearPromotionListParams } from "@redux/promotionSlice";
import { RangeValue } from "rc-picker/lib/interface";
import { FetchPromotionListParams } from "@api/promotionApi";
import { useSelector } from "react-redux";
import { RootState } from "@redux/rootReducer";
import { useAppDispatch } from "src/store";
import moment, { Moment } from "moment";
import useDebounce from "@hooks/useDebounce";

const Wrapper = styled.form`
  padding: 0 24px;
  margin-bottom: 20px;
`;
const FilterWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  grid-template-rows: auto;
  column-gap: 60px;
  row-gap: 18px;
`;
const Row = styled.div`
  display: flex;
  align-items: center;
  min-width: 0;
  justify-content: space-between;
`;
const RowTitle = styled.div<{ width?: number }>`
  min-width: 0;
  width: ${({ width }) => (width ? `${width}px` : "")};
  font-size: 14px;
  color: ${({ theme }) => theme.colorNeutral600};
  white-space: nowrap;
`;
const RowContent = styled.div<{ width?: number }>`
  min-width: 0;
  width: ${({ width }) => (width ? `${width}px` : "")};
  margin-left: 18px;
`;
const StyledSelect = styled(Select)`
  width: 100%;
`;
const RangePicker = styled(DatePicker.RangePicker)`
  width: 100%;
`;
const ButtonContainer = styled.div`
  margin-top: 13px;
  display: flex;
  justify-content: flex-end;
`;
const ClearButton = styled(Button)`
  margin-right: 8px;
`;

type LocalFilter = Omit<FetchPromotionListParams, "startDate" | "endDate"> & {
  startDate: Moment | null;
  endDate: Moment | null;
};

const activeOptions = [
  {
    label: "請選擇",
    value: -1,
  },
  {
    label: "是",
    value: 1,
  },
  {
    label: "否",
    value: 0,
  },
];

const defaultFilter = {
  limit: 20,
  offset: 0,
  isActive: -1,
  startDate: null,
  endDate: null,
};

const Filter: FC = () => {
  const dispatch = useAppDispatch();
  const { promotionListParams } = useSelector((state: RootState) => state.promotion);
  const brandListResult = useSelector((state: RootState) => state.product.brandListResult);
  const [localFilter, setLocalFilter] = useState<LocalFilter>(defaultFilter);
  const { id, salesPageId, isActive, titleQ, destinationLinkQ, startDate, endDate, brandId } = localFilter;

  useEffect(() => {
    setLocalFilter({
      ...promotionListParams,
      startDate: promotionListParams.startDate ? moment(promotionListParams.startDate) : null,
      endDate: promotionListParams.endDate ? moment(promotionListParams.endDate) : null,
    });
  }, [promotionListParams]);

  const handleOnChange = (title: string) => (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    setLocalFilter((prev) => ({
      ...prev,
      [title]: value,
    }));
  };

  const handleRangePick = (dates: RangeValue<Moment>) => {
    if (dates) {
      const [rangeStartDate, rangeEndDate] = dates;
      setLocalFilter((prev) => ({
        ...prev,
        startDate: rangeStartDate,
        endDate: rangeEndDate,
      }));
    } else {
      setLocalFilter((prev) => ({
        ...prev,
        startDate: null,
        endDate: null,
      }));
    }
  };

  const handleOnSelect = (value: SelectValue) => {
    setLocalFilter((prev) => ({
      ...prev,
      isActive: value as number,
    }));
  };
  const handleOnselectBrand = (value: SelectValue) => {
    setLocalFilter((prev) => ({
      ...prev,
      brandId: value as number,
    }));
  };

  const handleOnClearFilter = () => {
    setLocalFilter(defaultFilter);
    dispatch(clearPromotionListParams());
    dispatch(fetchPromotionList());
  };

  const handleOnSaveFilter = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const filter: FetchPromotionListParams = {
      ...promotionListParams,
      id,
      titleQ,
      startDate: startDate === null ? undefined : moment(startDate).format("YYYY-MM-DD"),
      endDate: endDate === null ? undefined : moment(endDate).format("YYYY-MM-DD"),
      isActive,
      destinationLinkQ,
      brandId,
      salesPageId,
    };

    dispatch(updatePromotionListParams(filter));
    dispatch(fetchPromotionList());
  };
  const handleOnBrandLoadMore = useCallback(() => {
    dispatch(loadMoreBrandList());
  }, [dispatch]);

  const brandOptions = useMemo(() => {
    const { next, results } = brandListResult;
    const options = results.map((brd) => (
      <Select.Option key={brd.id} value={brd.id}>
        {brd.name}
      </Select.Option>
    ));

    options.unshift(
      <Select.Option key={-1} value={-1}>
        請選擇
      </Select.Option>,
    );

    if (next) {
      options.push(
        <Select.Option value="loading..." disabled>
          loading...
          <InfiniteScrollObserver callback={handleOnBrandLoadMore} />
        </Select.Option>,
      );
    }

    return options;
  }, [brandListResult, handleOnBrandLoadMore]);

  const handleOnBrandSearch = useDebounce((value: string) => {
    dispatch(fetchBrandList(value));
  }, 300);

  useEffect(() => {
    dispatch(fetchBrandList());
  }, [dispatch]);

  return (
    <Wrapper onSubmit={handleOnSaveFilter}>
      <FilterWrapper>
        <Row>
          <RowTitle width={28}>ID</RowTitle>
          <RowContent width={300}>
            <Input value={id} onChange={handleOnChange("id")} />
          </RowContent>
        </Row>
        <Row>
          <RowTitle width={40}>銷售頁ID</RowTitle>
          <RowContent width={250}>
            <Input value={salesPageId} onChange={handleOnChange("salesPageId")} />
          </RowContent>
        </Row>
        <Row>
          <RowTitle>名稱</RowTitle>
          <RowContent width={250}>
            <Input value={titleQ} onChange={handleOnChange("titleQ")} />
          </RowContent>
        </Row>
        <Row>
          <RowTitle>網址</RowTitle>
          <RowContent width={300}>
            <Input value={destinationLinkQ} onChange={handleOnChange("destinationLinkQ")} />
          </RowContent>
        </Row>
        <Row>
          <RowTitle>品牌</RowTitle>
          <RowContent width={250}>
            <StyledSelect
              showSearch
              filterOption={false}
              value={brandId}
              onChange={handleOnselectBrand}
              onSearch={handleOnBrandSearch}
            >
              {brandOptions}
            </StyledSelect>
          </RowContent>
        </Row>
        <Row>
          <RowTitle>活動時間</RowTitle>
          <RowContent width={250}>
            <RangePicker value={[startDate, endDate]} onChange={handleRangePick} />
          </RowContent>
        </Row>
        <Row>
          <RowTitle>啟用</RowTitle>
          <RowContent width={300}>
            <StyledSelect options={activeOptions} value={isActive} onChange={handleOnSelect} />
          </RowContent>
        </Row>
      </FilterWrapper>
      <ButtonContainer>
        <ClearButton onClick={handleOnClearFilter}>清除篩選條件</ClearButton>
        <Button type="primary" htmlType="submit">
          套用
        </Button>
      </ButtonContainer>
    </Wrapper>
  );
};

export default Filter;
