import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { Button, Pagination, Select, Table } from "antd";
import { RootState } from "@redux/rootReducer";
import { SelectValue } from "antd/lib/select";
import { ColumnsType } from "antd/lib/table";
import { useAppDispatch } from "src/store";
import { ProductListResult } from "@api/brandOperationApi";
import {
  fetchProductList,
  resetBrandOperationProductSlice,
  setProductFilter,
} from "@redux/brandOperation/productSlice";
import { FilterTwoTone, PlusCircleFilled } from "@ant-design/icons";
import PageTitle from "@component/PageTitle";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Filter from "./Filter";

const Wrapper = styled.div`
  padding: 20px 20px 0 25px;
`;
const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 21px;
`;
const TopButtonContainer = styled.div``;
const TablePageInfo = styled.div``;
const TopButton = styled(Button)`
  margin-right: 10px;
`;
const PageSelect = styled(Select)`
  margin: 0 10px;
`;
const TableContainer = styled.div`
  position: relative;
  padding: 20px 10px 100px 10px;
  border: 1px solid #f0f0f0;
`;
const TablePagination = styled(Pagination)`
  position: absolute;
  right: 21px;
  bottom: 47px;
`;
const Row = styled.div<{ justify?: string }>`
  display: flex;
  align-items: center;
  justify-content: ${({ justify }) => justify};
`;

const pageSizeOptions = [20, 50, 100];

const BrandOperationProduct = () => {
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { productList, pruductFilter, isFetching } = useSelector(
    (state: RootState) => state.brandOperationProductSlice,
  );
  const { count } = productList;
  const { limit, offset } = pruductFilter;
  const toggleFilter = () => setShowFilter((prev) => !prev);

  const currentPage = useMemo(() => {
    return Math.floor(offset / limit) + 1;
  }, [limit, offset]);

  const handleOnAdd = () => {
    window.open("/operation-of-brand/product/add", "_blank");
  };

  const handleOnPageSizeChange = (value: SelectValue) => {
    const filter = {
      ...pruductFilter,
      limit: value as number,
    };

    dispatch(setProductFilter(filter));
    dispatch(fetchProductList(pruductFilter));
  };

  const handleOnPageChange = (page: number) => {
    const filter = {
      ...pruductFilter,
      offset: (page - 1) * limit,
    };
    dispatch(setProductFilter(filter));
    dispatch(fetchProductList(pruductFilter));
  };

  useEffect(() => {
    dispatch(fetchProductList(pruductFilter));
  }, [dispatch, pruductFilter]);

  useEffect(() => {
    return () => {
      dispatch(resetBrandOperationProductSlice());
    };
  }, [dispatch]);

  const tableColumns: ColumnsType<ProductListResult> = [
    {
      title: "商品品號",
      key: "no",
      dataIndex: "no",
    },
    {
      title: "商品條碼",
      key: "sku",
      dataIndex: "sku",
    },
    {
      title: "商品名稱",
      key: "name",
      dataIndex: "name",
    },
    {
      title: "商品英文名稱",
      key: "nameEn",
      dataIndex: "nameEn",
    },
    {
      title: "商品成本",
      key: "cost",
      dataIndex: "cost",
    },
    {
      title: "庫存",
      key: "stockSum",
      dataIndex: "stockSum",
    },
    {
      key: "buttons",
      dataIndex: "buttons",
      title: "",
      render: (_, values) => (
        <Row justify="flex-end">
          <Button type="link" onClick={() => navigate(`/operation-of-brand/product/edit/${values.id}`)}>
            編輯
          </Button>
        </Row>
      ),
    },
  ];

  return (
    <Wrapper>
      <PageTitle title="品運商品" />
      <Header>
        <TopButtonContainer>
          <TopButton type="primary" icon={<PlusCircleFilled />} onClick={handleOnAdd}>
            新增商品
          </TopButton>
          <TopButton icon={<FilterTwoTone />} onClick={toggleFilter}>
            篩選
          </TopButton>
        </TopButtonContainer>
        <TablePageInfo>
          總共
          {count}
          筆, 每頁顯示
          <PageSelect value={limit} onChange={handleOnPageSizeChange}>
            {pageSizeOptions.map((option) => (
              <Select.Option value={option}>{option}</Select.Option>
            ))}
          </PageSelect>
          筆
        </TablePageInfo>
      </Header>
      {showFilter && <Filter />}
      <TableContainer>
        <Table
          dataSource={productList.results}
          columns={tableColumns}
          scroll={{ x: "max-content" }}
          tableLayout="auto"
          pagination={false}
          loading={isFetching}
          rowKey="id"
        />
        <TablePagination
          current={currentPage}
          total={count}
          onChange={handleOnPageChange}
          showSizeChanger={false}
          pageSize={limit}
        />
      </TableContainer>
    </Wrapper>
  );
};

export default BrandOperationProduct;
