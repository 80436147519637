import { ReturnOrderSortMethod } from "@constant/ReturnOrderSortMethod";
import { ReviewState, ReviewStateLabel } from "@constant/ReviewState";
import { ShippingStatus, ShippingStatusLabel } from "@constant/ShippingStatus";
import { fetchDeleteReturnOrder, returnOrderState, setReturnOrderListFilter } from "@redux/returnOrderSlice";
import { Button, Modal, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Distributor, Filters, ReturnOrderItem } from "./interfaces";

export default function ReturnOrderList() {
  const dispatch = useDispatch();
  const { returnOrderList, returnOrderListFilter, isFetching, distributionList } = useSelector(returnOrderState);

  const distributionListMap = useMemo(() => {
    const listMap: {
      [id: string]: Distributor;
    } = {};
    distributionList.forEach((dt) => {
      listMap[dt.id] = dt;
    });

    return listMap;
  }, [distributionList]);

  const applyTableSort = (tableFilters: Filters) => {
    dispatch(
      setReturnOrderListFilter({
        ...tableFilters,
        page: 1,
      }),
    );
  };

  const handlePageChange = (currentPage: number) => {
    dispatch(
      setReturnOrderListFilter({
        page: currentPage,
      }),
    );
  };

  const deleteOrder = (orderId: number) => {
    Modal.warn({
      title: "你確定要作廢這筆資料？",
      okText: "是",
      onOk() {
        dispatch(fetchDeleteReturnOrder(orderId));
      },
      maskClosable: true,
    });
  };

  const columns: ColumnsType<ReturnOrderItem> = [
    {
      title: "訂單編號",
      dataIndex: "orderNumber",
    },
    {
      title: "審核狀態",
      dataIndex: "reviewState",
      render: (state: ReviewState, record: ReturnOrderItem) => {
        if (record.isSubmitted) {
          return <p>{ReviewStateLabel[state]}</p>;
        }
        return <p>未送出審核</p>;
      },
    },
    {
      title: "運送狀態",
      dataIndex: "shipmentState",
      render: (state: ShippingStatus) => <p>{ShippingStatusLabel[state]}</p>,
    },
    {
      title: "訂購緣由",
      dataIndex: "reasonLabel",
    },
    {
      title: "訂購時間",
      dataIndex: "createOrderDate",
      sorter: true,
    },
    {
      title: "預期拋單日期",
      dataIndex: "estimateShippingDate",
      sorter: true,
    },
    {
      title: "實際出貨時間",
      dataIndex: "actualShippingDate",
      sorter: true,
    },
    {
      title: "訂購商品總數",
      dataIndex: "orderedQty",
      sorter: true,
    },
    {
      title: "總成本",
      dataIndex: "totalCost",
      sorter: true,
    },
    {
      title: "通路/倉別",
      dataIndex: "distributionLabel",
      render: (value: ReturnOrderItem["distributionLabel"]) => distributionListMap[value]?.name,
    },
    {
      title: "訂購人",
      dataIndex: "ordererName",
    },
    {
      title: "",
      dataIndex: "id",
      fixed: "right",
      render: (id: number, record: any) => {
        return record.destroyed ? (
          <Link to={`/return-inventory/edit/${id}`} target="_blank">
            檢視
          </Link>
        ) : (
          <Link to={`/return-inventory/edit/${id}`} target="_blank">
            編輯
          </Link>
        );
      },
    },
    {
      title: "",
      dataIndex: "delete",
      fixed: "right",
      render: (orderId: number, record: ReturnOrderItem) => {
        return (
          <Button
            type="link"
            onClick={() => {
              if (!record.destroyed) {
                deleteOrder(orderId);
              }
            }}
            disabled={record.destroyed}
          >
            作廢
          </Button>
        );
      },
    },
  ];
  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    // check is page change
    if (pagination.current !== returnOrderListFilter.page) {
      handlePageChange(pagination.current);
    } else {
      const { field, order } = sorter;
      const params: Filters = {};

      switch (field) {
        case "createOrderDate":
          params.sortMethod = ReturnOrderSortMethod.ByCreateOrderDate;
          break;
        case "estimateShippingDate":
          params.sortMethod = ReturnOrderSortMethod.ByEstimateShippingDate;
          break;
        case "actualShippingDate":
          params.sortMethod = ReturnOrderSortMethod.ByActualShippingDate;
          break;
        case "orderedQty":
          params.sortMethod = ReturnOrderSortMethod.ByQty;
          break;
        case "totalCost":
          params.sortMethod = ReturnOrderSortMethod.ByCost;
          break;
        default:
          break;
      }
      params.sortOrder = order === "ascend" ? "asc" : "desc";
      applyTableSort(params);
    }
  };

  function checkDisabledOrder(record: ReturnOrderItem) {
    return {
      className: record.destroyed ? "ur-disabled-text" : "",
    };
  }

  return (
    <Table
      loading={isFetching}
      columns={columns}
      dataSource={returnOrderList}
      onChange={handleTableChange}
      pagination={{
        pageSize: returnOrderListFilter.pageSize,
        current: returnOrderListFilter.page,
        total: returnOrderList[0]?.totalCount || 0,
        showSizeChanger: false,
        disabled: isFetching,
      }}
      scroll={{ x: "max-content" }}
      rowKey={(record) => record.id}
      onRow={checkDisabledOrder}
    />
  );
}
