import { CloseOutlined } from "@ant-design/icons";
import useDebounce from "@hooks/useDebounce";
import { fetchCategoriesTag, setIsSearching, topicState } from "@redux/topicSlice";
import { Select, Spin } from "antd";
import { SelectValue } from "antd/lib/select";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import fontStyle from "src/styles/fontStyle";
import styled from "styled-components";
import { useManageTagContext } from "./ManageTagContext";

const TagTitle = styled.div`
  height: 32px;
  padding: 7px 8px;
  border-radius: 2px;
  margin-right: 12px;
  ${fontStyle("13px", "16px")};
  background: ${({ theme }) => theme.colorNeutral300};
`;

const Row = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
`;

const Tag = styled(Row)`
  justify-content: space-between;
  align-items: center;
  min-width: 94px;
  padding: 7px 8px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  background: #fafafa;
  margin-right: 8px;
  ${fontStyle("14px", "16px")};
`;

const DeleteIcon = styled(CloseOutlined)`
  margin-left: 6px;
  cursor: pointer;
`;

const CustomSelect = styled(Select)`
  margin-bottom: 10px;
  width: 300px;
`;

const Flex = styled.div`
  flex: 1;
  position: relative;
`;

const { Option } = Select;

export default function StepChooseTag() {
  const dispatch = useDispatch();
  const { categoriesTag, isFetching } = useSelector(topicState);

  const { checkCategories, tagList, setTagList } = useManageTagContext();

  const [searchText, setSearchText] = useState<string>("");

  const onSearch = (value: string) => {
    setSearchText(value);
  };

  const fetchSearchTag = useDebounce(() => {
    dispatch(setIsSearching(true));
    dispatch(fetchCategoriesTag({ categoryIds: checkCategories, config: { nameq: searchText } }));
  }, 1500);

  const onChange = (value: SelectValue, option: any) => {
    setSearchText("");
    setTagList(new Map(tagList.set(value as number, { name: option.children, id: value as number })));
  };

  const removeTag = (value: number) => {
    const cloneMap = new Map(tagList);
    cloneMap.delete(value);
    setTagList(cloneMap);
  };

  useEffect(() => {
    if (searchText) {
      fetchSearchTag(searchText);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, dispatch]);

  return (
    <Spin spinning={isFetching}>
      <Row>
        <TagTitle>標籤包含</TagTitle>
        <Flex>
          <CustomSelect
            showSearch
            value={searchText}
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            notFoundContent={null}
            onSearch={onSearch}
            onChange={onChange}
          >
            {categoriesTag
              .filter((tag) => !tagList.has(tag.id))
              .map((tag) => (
                <Option key={tag.id} value={tag.id}>
                  {tag.name}
                </Option>
              ))}
          </CustomSelect>
          <Row>
            {Array.from(tagList.values()).map((tag) => (
              <Tag key={tag.name}>
                {tag.name}
                <DeleteIcon onClick={() => removeTag(tag.id)} />
              </Tag>
            ))}
          </Row>
        </Flex>
      </Row>
    </Spin>
  );
}
