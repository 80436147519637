import React, { FC, Key, useMemo } from "react";
import styled from "styled-components";
import { Table, Select } from "antd";
import { MemberOptionValue, SalesRankOrderingValue } from "@api/dashboardApi";
import { TablePaginationConfig } from "antd/lib/table";
import { SortOrder } from "antd/lib/table/interface";
import { useAppDispatch } from "src/store";
import { useSelector } from "react-redux";
import { RootState } from "src/redux/rootReducer";
import { updateSalesRankParams, fetchSalesRank } from "@redux/dashboardSlice";
import { SelectValue } from "antd/lib/select";

const Wrapper = styled.div`
  margin-top: 40px;
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;
const HeaderTitle = styled.div`
  font-size: 20px;
  color: rgba(0, 0, 0, 0.85);
  margin-right: 12px;
`;
const StyledSelect = styled(Select)`
  width: 80px;
`;
const TableContainer = styled.div`
  padding: 20px;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
`;

const RankingOptions = [
  {
    value: MemberOptionValue.ALL,
    label: "全部",
  },
  {
    value: MemberOptionValue.NEW,
    label: "新客",
  },
  {
    value: MemberOptionValue.OLD,
    label: "舊客",
  },
  {
    value: MemberOptionValue.VIP,
    label: "黑卡",
  },
];

const SalesRanking: FC = () => {
  const dispatch = useAppDispatch();

  const { memberType, ordering } = useSelector((state: RootState) => state.dashboard.salesRankParams);
  const salesRank = useSelector((state: RootState) => state.dashboard.salesRank);
  const isFetchingSalesRank = useSelector((state: RootState) => state.dashboard.isFetchingSalesRank);

  // sorting
  const handleOnChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, (Key | boolean)[] | null>,
    sorter: any,
  ) => {
    const { field, order } = sorter;
    let updatedOrdering: SalesRankOrderingValue;

    switch (order) {
      case "descend":
        updatedOrdering = field;
        break;
      case "ascend":
        updatedOrdering = `-${field}` as SalesRankOrderingValue;
        break;
      default:
        updatedOrdering = undefined;
    }

    const newParams = {
      memberType,
      ordering: updatedOrdering,
    };
    dispatch(updateSalesRankParams(newParams));
    dispatch(fetchSalesRank());
  };

  const handleOnSelect = (value: SelectValue) => {
    const newParams = {
      memberType: value as MemberOptionValue,
      ordering,
    };
    dispatch(updateSalesRankParams(newParams));
    dispatch(fetchSalesRank());
  };

  const revenueSortOrder: SortOrder = useMemo(() => {
    if (ordering?.includes("revenue")) {
      return ordering === "revenue" ? "descend" : "ascend";
    }

    return null;
  }, [ordering]);

  const salesVolumeSortOrder: SortOrder = useMemo(() => {
    if (ordering?.includes("salesVolume")) {
      return ordering === "salesVolume" ? "descend" : "ascend";
    }

    return null;
  }, [ordering]);

  const grossMarginSortOrder: SortOrder = useMemo(() => {
    if (ordering?.includes("grossMargin")) {
      return ordering === "grossMargin" ? "descend" : "ascend";
    }

    return null;
  }, [ordering]);

  const tableColumns = [
    {
      title: "商品名稱",
      dataIndex: "name",
      width: 350,
    },
    {
      title: "營收",
      dataIndex: "revenue",
      width: 90,
      sorter: true,
      sortOrder: revenueSortOrder,
    },
    {
      title: "銷售數量",
      dataIndex: "salesVolume",
      width: 125,
      sorter: true,
      sortOrder: salesVolumeSortOrder,
    },
    {
      title: "銷售毛利率",
      dataIndex: "grossMargin",
      width: 140,
      sorter: true,
      sortOrder: grossMarginSortOrder,
    },
    {
      title: "國際條碼",
      dataIndex: "sku",
      width: 140,
    },
  ];

  return (
    <Wrapper>
      <Header>
        <HeaderTitle>銷售排行榜</HeaderTitle>
        <StyledSelect options={RankingOptions} value={memberType} onChange={handleOnSelect} />
      </Header>
      <TableContainer>
        <Table
          dataSource={salesRank}
          columns={tableColumns}
          scroll={{ x: "max-content", y: 400 }}
          tableLayout="auto"
          pagination={false}
          loading={isFetchingSalesRank}
          onChange={handleOnChange}
        />
      </TableContainer>
    </Wrapper>
  );
};

export default SalesRanking;
