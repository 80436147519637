import { ExclamationCircleOutlined, PlusCircleTwoTone } from "@ant-design/icons";
import { ContactInfoList } from "@api/throughShipmentApi";
import PageTitle from "@component/PageTitle";
import {
  dealerState,
  fetchContactInfoList,
  fetchDeleteContact,
  fetchUpdateContact,
  setContactInfoFilter,
} from "@redux/dealerSlice";
import { Button, Modal, Row, Select, Table } from "antd";
import { SelectValue } from "antd/lib/select";
import { ColumnsType } from "antd/lib/table";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import fontStyle from "src/styles/fontStyle";
import styled from "styled-components";
import EditContactPopup from "./EditContactPopup";

const Wrapper = styled.div`
  padding: 20px 20px 20px 25px;

  .ant-table-tbody {
    .ant-table-placeholder > td {
      border: 0;
    }
  }
`;
const PageText = styled.span`
  ${fontStyle("14px", "20px")};
`;
const SelectPageSize = styled(Select)`
  &&& {
    margin: 0 10px;
  }
`;
const TableWrapper = styled.div`
  border: solid 1px #f0f0f0;
  border-radius: 2px;
  padding: 20px 10px 22px 17px;
  margin-top: 20px;
`;
const CustomButton = styled(Button)`
  margin-right: 10px;
`;
const MainContract = styled.div`
  border: 0.5px solid #1890ff;
  color: #1890ff;
  border-radius: 2px;
  padding: 2px 6px;
  margin-left: 8px;
  font-size: 12px;
`;

export default function ContactInformation() {
  const dispatch = useDispatch();
  const { isFetching, contactInfoList, contactInfoFilter } = useSelector(dealerState);

  const [page, setPage] = useState<number>(1);
  const [editContactPopup, setEditContactPopup] = useState<boolean>(false);
  const [editContact, setEditContact] = useState<ContactInfoList | undefined>();

  const setPageLimit = (value: SelectValue) => {
    dispatch(setContactInfoFilter({ ...contactInfoFilter, limit: parseInt(value as string, 10) }));
  };

  const fetchPageChange = (value: number) => {
    window.scrollTo(0, 0);
    setPage(value);
    dispatch(setContactInfoFilter({ ...contactInfoFilter, offset: (value - 1) * contactInfoFilter.limit }));
  };

  const onDeleteContact = (contactId: number) => {
    Modal.confirm({
      title: "你確定要刪除嗎?",
      icon: <ExclamationCircleOutlined />,
      okText: "是",
      cancelText: "否",
      onOk() {
        dispatch(fetchDeleteContact(contactId));
      },
    });
  };

  const columns: ColumnsType<ContactInfoList> = [
    {
      key: "name",
      dataIndex: "name",
      title: "聯絡人姓名",
      render: (name: string, values) => (
        <Row align="middle">
          <div>{name}</div>
          {values.isMajor && <MainContract>主要聯絡人</MainContract>}
        </Row>
      ),
    },
    {
      key: "phone",
      dataIndex: "phone",
      title: "聯絡人電話",
    },
    {
      key: "email",
      dataIndex: "email",
      title: "聯絡人信箱",
    },
    {
      key: "title",
      dataIndex: "title",
      title: "聯絡人職稱",
    },
    {
      key: "buttons",
      dataIndex: "buttons",
      title: "",
      render: (_, values) => (
        <Row justify="end">
          {!values.isMajor && (
            <Button onClick={() => dispatch(fetchUpdateContact({ contactId: values.id, isMajor: true }))}>
              設為主要聯絡人
            </Button>
          )}
          <Button
            type="link"
            onClick={() => {
              setEditContact(values);
              setEditContactPopup(true);
            }}
          >
            編輯
          </Button>
          <Button type="link" onClick={() => onDeleteContact(values.id)}>
            刪除
          </Button>
        </Row>
      ),
    },
  ];

  useEffect(() => {
    dispatch(fetchContactInfoList(contactInfoFilter));
  }, [dispatch, contactInfoFilter]);

  return (
    <Wrapper>
      <PageTitle title="通路/經銷商 - 聯絡資訊" />
      <Row justify="space-between">
        <div>
          <CustomButton
            type="primary"
            icon={<PlusCircleTwoTone twoToneColor="#1890FF" />}
            onClick={() => setEditContactPopup(true)}
          >
            新增聯絡人
          </CustomButton>
        </div>
        <Row align="middle">
          <PageText>{`總共${contactInfoList.count}筆, 每頁顯示`}</PageText>
          <SelectPageSize defaultValue="20" onChange={setPageLimit}>
            <Select.Option value="20">20</Select.Option>
            <Select.Option value="50">50</Select.Option>
            <Select.Option value="100">100</Select.Option>
          </SelectPageSize>
          <PageText>筆</PageText>
        </Row>
      </Row>
      <TableWrapper>
        <Table
          loading={isFetching}
          columns={columns}
          dataSource={contactInfoList.results}
          pagination={{
            pageSize: contactInfoFilter.limit,
            current: page,
            showSizeChanger: false,
            total: contactInfoList.count,
            onChange: fetchPageChange,
          }}
        />
      </TableWrapper>
      {editContactPopup && (
        <EditContactPopup
          contact={editContact}
          onClose={() => {
            setEditContact(undefined);
            setEditContactPopup(false);
          }}
        />
      )}
    </Wrapper>
  );
}
