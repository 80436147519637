import { ContactInfoList } from "@api/throughShipmentApi";
import PopupBackground from "@component/PopupBackground";
import { fetchCreateContact, fetchUpdateContact } from "@redux/dealerSlice";
import { Button, Form, Input, Row, Select } from "antd";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";

const Wrapper = styled.div`
  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08),
    0px 9px 28px 8px rgba(0, 0, 0, 0.05);
  background: ${({ theme }) => theme.colorNeutral100};
  border-radius: 2px;
  width: 620px;
  padding: 23px 14px 20px 20px;

  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    order: 1;
  }

  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
    margin: 0;
  }
`;
const CustomButton = styled(Button)`
  margin-right: 10px;
`;

type Props = {
  contact?: ContactInfoList;
  onClose: () => void;
};

interface FormState {
  name: string;
  email: string;
  phone: string;
  title: string;
}

export default function EditContactPopup(props: Props) {
  const { contact, onClose } = props;

  const dispatch = useDispatch();

  const { dealerId } = useParams();

  const [form] = Form.useForm();

  const onSubmit = (values: FormState) => {
    if (contact) {
      dispatch(fetchUpdateContact({ contactId: contact.id, ...values }));
    } else {
      dispatch(fetchCreateContact({ ...values, retailerProductline: parseInt(dealerId, 10) }));
    }
    onClose();
  };

  useEffect(() => {
    if (contact) {
      form.setFieldsValue({
        name: contact.name,
        phone: contact.phone,
        email: contact.email,
        title: contact.title,
      });
    }
  }, [form, contact]);

  return (
    <PopupBackground close={onClose} fixed>
      <Wrapper>
        <Form colon={false} labelAlign="left" labelCol={{ span: 4 }} form={form} initialValues={{}} onFinish={onSubmit}>
          <Form.Item label="姓名" name="name" wrapperCol={{ span: 18 }} rules={[{ required: true, message: "必填" }]}>
            <Input />
          </Form.Item>
          <Form.Item label="電話" name="phone" wrapperCol={{ span: 18 }} rules={[{ required: true, message: "必填" }]}>
            <Input />
          </Form.Item>
          <Form.Item label="信箱" name="email" wrapperCol={{ span: 18 }} rules={[{ required: true, message: "必填" }]}>
            <Input />
          </Form.Item>
          <Form.Item label="職稱" name="title" wrapperCol={{ span: 18 }} rules={[{ required: true, message: "必填" }]}>
            <Select>
              <Select.Option value="採購">採購</Select.Option>
              <Select.Option value="PM">PM</Select.Option>
              <Select.Option value="收貨單位">收貨單位</Select.Option>
              <Select.Option value="財務">財務</Select.Option>
            </Select>
          </Form.Item>
          <Row justify="end">
            <CustomButton onClick={onClose}>取消</CustomButton>
            <Button type="primary" htmlType="submit">
              確定
            </Button>
          </Row>
        </Form>
      </Wrapper>
    </PopupBackground>
  );
}
