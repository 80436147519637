import InfiniteScrollObserver from "@component/InfiniteScrollObserver";
import useDebounce from "@hooks/useDebounce";
import {
  fetchVendorList,
  IWBrandState,
  loadMoreVendorList,
  resetIntoWarehouseParams,
  updateIntoWarehouseParams,
} from "@redux/brandOperation/intoWarehouseBrandSlice";
import { Button, Col, DatePicker, Form, Input, message, Row, Select, Tag } from "antd";
import React, { useState, KeyboardEvent, useCallback, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FilterWrapper, FlexBox } from "src/styles/common";
import styled from "styled-components";

const CustomButton = styled(Button)`
  margin-right: 10px;
`;

const Filter = (props: { show: boolean }) => {
  const { show } = props;
  const dispatch = useDispatch();
  const { intoWarehouseParams, vendorListResult } = useSelector(IWBrandState);

  const [form] = Form.useForm();
  const [inputValue, setInputValue] = useState(""); // sku input
  const [tags, setTags] = useState<string[]>([]); // multiple sku tags

  const onSubmit = () => {
    const formValues = form.getFieldsValue();

    let valid = false;
    Object.keys(formValues).forEach((key) => {
      const item = formValues[key];
      if (item) valid = true;
      if (key === "skus" && inputValue) {
        valid = true;
      }
    });
    if (!valid) return;

    let skusParsed;
    const skus = form.getFieldValue("skus");
    if (inputValue && skus) skusParsed = `${inputValue},${skus}`;
    if (inputValue && !skus) skusParsed = `${inputValue}`;
    if (!inputValue) skusParsed = skus;

    const { stoNumber, specDate, supplyChain } = formValues;
    const params = {
      ...intoWarehouseParams,
      skus: skusParsed,
      stoNumber,
      specDateAfter: specDate && specDate[0]?.format("YYYY-MM-DD"),
      specDateBefore: specDate && specDate[1]?.format("YYYY-MM-DD"),
      supplyChain,
    };
    dispatch(updateIntoWarehouseParams(params));
  };

  const onReset = () => {
    form.resetFields();
    setInputValue("");
    setTags([]);
    dispatch(resetIntoWarehouseParams());
  };

  const handleSkuInputPressEnter = (e: KeyboardEvent<HTMLInputElement>) => {
    const { value } = e.target as HTMLInputElement;
    if (tags.some((tag) => tag === value)) {
      message.warning("此商品條碼已加入");
      return;
    }
    setTags([...tags, inputValue]);
    setInputValue("");
  };

  const handleClose = (removedTag: string) => {
    const newTags = tags.filter((tag) => tag !== removedTag);
    setTags(newTags);
  };

  const handleOnVendorLoadMore = useCallback(() => {
    dispatch(loadMoreVendorList());
  }, [dispatch]);

  const handleOnVendorSearch = useDebounce((value: string) => {
    dispatch(fetchVendorList(value));
  }, 300);

  const vendorOptions = useMemo(() => {
    const { next, results } = vendorListResult;
    const options = results.map((vdr) => (
      <Select.Option key={vdr.id} value={vdr.id}>
        {vdr.name}
      </Select.Option>
    ));

    options.unshift(
      <Select.Option key={-1} value={-1}>
        請選擇
      </Select.Option>,
    );

    if (next) {
      options.push(
        <Select.Option value="loading..." disabled>
          loading...
          <InfiniteScrollObserver callback={handleOnVendorLoadMore} />
        </Select.Option>,
      );
    }

    return options;
  }, [vendorListResult, handleOnVendorLoadMore]);

  useEffect(() => {
    form.setFieldsValue({ skus: tags.toString() });
  }, [form, tags]);

  useEffect(() => {
    dispatch(fetchVendorList(""));
  }, [dispatch]);

  return (
    <FilterWrapper show={show}>
      <Form colon={false} labelAlign="left" form={form} initialValues={{}}>
        <Row gutter={[16, 8]}>
          <Col span={6} />
          <Col span={8} />
          <Col flex="0 0 66px" style={{ height: "31px" }} />
          <Col flex="auto">
            {tags.map((tag) => (
              <span key={tag}>
                <Tag
                  closable
                  onClose={(e) => {
                    e.preventDefault();
                    handleClose(tag);
                  }}
                >
                  {tag}
                </Tag>
              </span>
            ))}
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={6}>
            <Form.Item label="WMS編號" name="stoNumber">
              <Input allowClear />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="供應商名稱" name="supplyChain">
              <Select showSearch filterOption={false} onSearch={handleOnVendorSearch}>
                {vendorOptions}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="國際條碼">
              <Input
                onChange={(e) => setInputValue(e.target.value)}
                onPressEnter={handleSkuInputPressEnter}
                placeholder="可用Enter加入多筆"
                value={inputValue}
                allowClear
              />
            </Form.Item>
            <Form.Item name="skus" noStyle />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={9}>
            <Form.Item label="預期進倉日期" name="specDate">
              <DatePicker.RangePicker />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <FlexBox>
        <CustomButton onClick={onReset}>清除篩選條件</CustomButton>
        <Button type="primary" onClick={onSubmit}>
          套用
        </Button>
      </FlexBox>
    </FilterWrapper>
  );
};
export default Filter;
