import React, { FC, useState, useEffect, ChangeEvent } from "react";
import styled from "styled-components";
import { Input, Select, Button, InputNumber } from "antd";
import { SelectValue } from "antd/lib/select";
import { useSelector } from "react-redux";
import { RootState } from "@redux/rootReducer";
import { useAppDispatch } from "src/store";
import { ReviewStatus, ReviewStatusMap, FetchReviewListParams } from "@api/reviewApi";
import { clearFilter, updateReviewListParams, fetchReviewList } from "@redux/reviewManagementSlice";

const Wrapper = styled.form`
  position: relative;
`;
const Section = styled.div`
  display: flex;
  align-items: center;
  margin-right: 40px;
`;
const SectionTitle = styled.div`
  width: 100px;
  margin-right: 15px;
  font-size: 14px;
`;
const StyledInput = styled(Input)`
  width: 200px;
`;
const StyledInputNumber = styled(InputNumber)`
  width: 200px;
`;
const StyledSelect = styled(Select)`
  width: 200px;
`;
const Row = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;
const ButtonContainer = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
`;
const ClearButton = styled(Button)`
  margin-right: 10px;
`;

export const StatusOptions = [
  {
    value: ReviewStatus.NO_REVIEW,
    label: ReviewStatusMap[ReviewStatus.NO_REVIEW],
  },
  {
    value: ReviewStatus.PEDNING,
    label: ReviewStatusMap[ReviewStatus.PEDNING],
  },
  {
    value: ReviewStatus.APPROVED,
    label: ReviewStatusMap[ReviewStatus.APPROVED],
  },
  {
    value: ReviewStatus.REJECTED,
    label: ReviewStatusMap[ReviewStatus.REJECTED],
  },
];

type LocalFilter = Omit<FetchReviewListParams, "limit" | "offset">;
const initialFilter = {
  approvalStatus: ReviewStatus.PEDNING,
};

const Filter: FC = () => {
  const dispatch = useAppDispatch();

  const { reviewListParams } = useSelector((state: RootState) => state.reviewManagement);

  const [localFilter, setLocalFilter] = useState<LocalFilter>(initialFilter);
  const { id, orderNumber, approvalStatus, memberPhone } = localFilter;

  useEffect(() => {
    setLocalFilter({
      id: reviewListParams.id,
      orderNumber: reviewListParams.orderNumber,
      approvalStatus: reviewListParams.approvalStatus,
      memberPhone: reviewListParams.memberPhone,
    });
  }, [reviewListParams]);

  const handleOnChange = (title: string) => (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    setLocalFilter((prev) => ({
      ...prev,
      [title]: value,
    }));
  };

  const handeOnIdChange = (value: string | number | undefined) => {
    setLocalFilter((prev) => ({
      ...prev,
      id: value as number,
    }));
  };

  const handleOnSelect = (value: SelectValue) => {
    setLocalFilter((prev) => ({
      ...prev,
      approvalStatus: value as number,
    }));
  };

  const handleOnClear = () => {
    setLocalFilter(initialFilter);
    dispatch(clearFilter());
  };

  const handleOnApply = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const filter = {
      limit: 20,
      offset: 0,
      ...localFilter,
    };

    dispatch(updateReviewListParams(filter));
    dispatch(fetchReviewList());
  };

  return (
    <Wrapper onSubmit={handleOnApply}>
      <Row>
        <Section>
          <SectionTitle>ID</SectionTitle>
          <StyledInputNumber value={id} onChange={handeOnIdChange} />
        </Section>
        <Section>
          <SectionTitle>狀態</SectionTitle>
          <StyledSelect options={StatusOptions} value={approvalStatus} onChange={handleOnSelect} />
        </Section>
      </Row>
      <Row>
        <Section>
          <SectionTitle>訂單編號</SectionTitle>
          <StyledInput value={orderNumber} onChange={handleOnChange("orderNumber")} />
        </Section>
        <Section>
          <SectionTitle>會員電話</SectionTitle>
          <StyledInput value={memberPhone} onChange={handleOnChange("memberPhone")} />
        </Section>
      </Row>
      <ButtonContainer>
        <ClearButton onClick={handleOnClear}>清除篩選條件</ClearButton>
        <Button type="primary" htmlType="submit">
          套用
        </Button>
      </ButtonContainer>
    </Wrapper>
  );
};

export default Filter;
