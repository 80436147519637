import { FilterTwoTone } from "@ant-design/icons";
import { Button, Row, Select, Table } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import fontStyle from "src/styles/fontStyle";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@redux/rootReducer";
import { SelectValue } from "antd/lib/select";
import {
  fetchCostWareHouseAndTransferList,
  resetCostWareHouseAndTransferListParam,
  setCostWareHouseAndTransferListParam,
} from "@redux/finance/costStatementSlice";
import { ColumnsType } from "antd/lib/table";
import { CostWareHouseAndTransferListItem } from "@api/financeApi";
import Filter from "./Filter";
import { CooperationList, TabList } from "./constant";

const Wrapper = styled.div`
  padding: 20px 20px 20px 25px;
`;
const PageText = styled.span`
  ${fontStyle("14px", "20px")};
`;
const SelectPageSize = styled(Select)`
  margin: 0 10px;
`;
const TableWrapper = styled.div`
  border: solid 1px #f0f0f0;
  border-radius: 2px;
  padding: 20px 10px 22px 17px;
  margin-top: 20px;
`;

const CostWareHouseAndTransfer = () => {
  const dispatch = useDispatch();
  const isFetching = useSelector((state: RootState) => state.costStatementSlice.isFetching);
  const costWareHouseAndTransferList = useSelector(
    (state: RootState) => state.costStatementSlice.costWareHouseAndTransferList,
  );

  const listParams = useSelector((state: RootState) => state.costStatementSlice.costWareHouseAndTransferListParam);
  const [showFilter, setShowFilter] = useState<boolean>(false);

  const { count, results: listResult } = costWareHouseAndTransferList;
  const { offset, limit } = listParams;
  const currentPage = useMemo(() => {
    return Math.floor(offset / limit) + 1;
  }, [limit, offset]);

  const handleOnPageSizeChange = (value: SelectValue) => {
    const filter = {
      ...listParams,
      limit: value as number,
    };
    dispatch(setCostWareHouseAndTransferListParam(filter));
  };

  const handleOnPageChange = (page: number) => {
    const filter = {
      ...listParams,
      offset: (page - 1) * limit,
    };

    dispatch(setCostWareHouseAndTransferListParam(filter));
  };

  const handleFilterSubmit = (params: { contract: number; dateAfter: string; dateBefore: string }) => {
    dispatch(
      setCostWareHouseAndTransferListParam({
        ...listParams,
        contract: params.contract,
        dateAfter: params.dateAfter,
        dateBefore: params.dateBefore,
      }),
    );
  };

  const handleFilterReset = () => {
    dispatch(resetCostWareHouseAndTransferListParam());
  };

  useEffect(() => {
    dispatch(fetchCostWareHouseAndTransferList());
  }, [dispatch, listParams]);

  const tableColumns: ColumnsType<CostWareHouseAndTransferListItem> = [
    {
      title: "合作模式",
      key: "contract",
      dataIndex: "contract",
      width: 100,
      render: (value: any) => {
        return <span>{CooperationList[value]}</span>;
      },
    },
    {
      title: "公司名稱",
      key: "vendorName",
      dataIndex: "vendorName",
      width: 600,
    },
    {
      title: "總進貨價(貨款加總)",
      key: "totalCost",
      dataIndex: "totalCost",
      width: "20%",
    },
  ];
  return (
    <Wrapper>
      <Row justify="space-between">
        <Button icon={<FilterTwoTone />} onClick={() => setShowFilter(!showFilter)}>
          篩選
        </Button>
        <Row align="middle">
          <PageText>{`總共${count}筆, 每頁顯示`}</PageText>
          <SelectPageSize defaultValue="20" onChange={handleOnPageSizeChange}>
            <Select.Option value="20">20</Select.Option>
            <Select.Option value="50">50</Select.Option>
            <Select.Option value="100">100</Select.Option>
          </SelectPageSize>
          <PageText>筆</PageText>
        </Row>
      </Row>
      {showFilter && (
        <Filter
          type={TabList.WAREHOUSE_CONSIGN_AND_TRANSFER}
          onSubmit={handleFilterSubmit}
          onReset={handleFilterReset}
        />
      )}
      <TableWrapper>
        <Table
          scroll={{ x: "max-content" }}
          loading={isFetching}
          columns={tableColumns}
          dataSource={listResult}
          pagination={{
            pageSize: listParams.limit,
            current: currentPage,
            showSizeChanger: false,
            total: count,
            onChange: handleOnPageChange,
          }}
        />
      </TableWrapper>
    </Wrapper>
  );
};

export default CostWareHouseAndTransfer;
