import React, { FC, useEffect, useMemo } from "react";
import { Select, Table, Pagination } from "antd";
import { SelectValue } from "antd/lib/select";
import styled from "styled-components";
import { fetchSaleMethodList, updateSaleMethodListParam } from "@redux/productEditSlice";
import { SaleMethodListItem, ContractModeValue } from "@api/productApi";
import { useSelector } from "react-redux";
import { RootState } from "@redux/rootReducer";
import { useAppDispatch } from "src/store";
import { useParams } from "react-router-dom";

const Wrapper = styled.div`
  padding: 20px 20px 20px 25px;
`;
const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  margin-bottom: 21px;
`;
const PageSizeSelect = styled(Select)`
  margin: 0 10px;
`;
const TableContainer = styled.div`
  padding-left: 16px;
  margin-bottom: 16px;
`;
const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 16px;
`;
const TableNoWrap = styled.div`
  white-space: nowrap;
`;
const LinkButton = styled.a`
  color: ${({ theme }) => theme.colorSuccess500};
`;
const EditButton = styled.p`
  font-size: 13px;
  color: ${({ theme }) => theme.colorSuccess500};
  cursor: pointer;
  margin: 0;
`;

const pageSizeOptions = [20, 50, 100];

const SaleMethod: FC = () => {
  const dispatch = useAppDispatch();
  const { id: productId } = useParams();

  const productVendorDetail = useSelector((state: RootState) => state.productEdit.productVendorDetail);
  const saleMethodListResult = useSelector((state: RootState) => state.productEdit.saleMethodListResult);
  const saleMethodListParam = useSelector((state: RootState) => state.productEdit.saleMethodListParam);
  const isFetchingSaleMethodList = useSelector((state: RootState) => state.productEdit.isFetchingSaleMethodList);

  const { count, results: saleMethodList } = saleMethodListResult;
  const { offset, limit } = saleMethodListParam;

  const currentPage = useMemo(() => {
    return Math.floor(offset / limit) + 1;
  }, [limit, offset]);

  // useEffect(() => {
  //   dispatch(fetchProductVendorDetail(Number(productId)));
  // }, [productId, dispatch]);

  useEffect(() => {
    const filter = {
      limit: 20,
      offset: 0,
    };

    dispatch(updateSaleMethodListParam(filter));
    dispatch(fetchSaleMethodList(Number(productId)));
  }, [dispatch, productId]);

  const handleOnPageSizeChange = (value: SelectValue) => {
    const filter = {
      ...saleMethodListParam,
      limit: value as number,
    };

    dispatch(updateSaleMethodListParam(filter));
    dispatch(fetchSaleMethodList(Number(productId)));
  };

  const handleOnPageChange = (page: number) => {
    const filter = {
      ...saleMethodListParam,
      offset: (page - 1) * limit,
    };
    dispatch(updateSaleMethodListParam(filter));
    dispatch(fetchSaleMethodList(Number(productId)));
  };

  const handleOnEditMethod = (salePageId: number) => () => {
    window.open(`/salespage/edit/${salePageId}`, "_blank");
  };

  const tableColumns = [
    {
      title: <TableNoWrap>ID</TableNoWrap>,
      dataIndex: ["salesPageInfo", "id"],
      render: (value: SaleMethodListItem["salesPageInfo"]["id"]) => (
        <LinkButton href={`/salespage/edit/${value}`} target="_blank">
          {value}
        </LinkButton>
      ),
    },
    {
      title: <TableNoWrap>銷售頁名稱</TableNoWrap>,
      dataIndex: ["salesPageInfo", "name"],
      width: 260,
    },
    {
      title: <TableNoWrap>狀態</TableNoWrap>,
      dataIndex: ["salesPageInfo", "isActive"],
      width: 100,
      render: (value: SaleMethodListItem["salesPlanInfo"]["isActive"]) => (value ? "上架" : "下架"),
    },
    {
      title: <TableNoWrap>方案名稱</TableNoWrap>,
      dataIndex: ["salesPlanInfo", "name"],
      width: 120,
    },
    {
      title: <TableNoWrap>目前方案價格</TableNoWrap>,
      dataIndex: ["salesPlanInfo", "specialPrice"],
      width: 120,
    },
    {
      title: <TableNoWrap>可賣量</TableNoWrap>,
      dataIndex: ["salesPlanInfo", "canBuyCount"],
      width: 100,
    },
    {
      title: <TableNoWrap>項目成本</TableNoWrap>,
      dataIndex: ["productInfo", "cost"],
      width: 110,
      render: (value: SaleMethodListItem["productInfo"]["cost"]) => {
        switch (productVendorDetail.mode) {
          case ContractModeValue.TRANSFER_ORDER:
          case ContractModeValue.WAREHOUSE_CONSIGN:
            return "N/A";
          case ContractModeValue.DOMESTIC_BUY_OUT:
          case ContractModeValue.FOREIGN_BUY_OUT:
          default:
            return value ?? "N/A";
        }
      },
    },
    {
      title: <TableNoWrap>項目售價</TableNoWrap>,
      dataIndex: ["productInfo", "price"],
      width: 110,
    },
    {
      title: <TableNoWrap>項目抽成</TableNoWrap>,
      dataIndex: ["productInfo", "commissionRate"],
      width: 110,
    },
    {
      title: "",
      key: "",
      dataIndex: "",
      render: (value: any, data: SaleMethodListItem) => (
        <EditButton onClick={handleOnEditMethod(data.salesPageInfo.id)}>編輯</EditButton>
      ),
    },
  ];

  return (
    <Wrapper>
      <Header>
        總共
        {count}
        筆, 每頁顯示
        <PageSizeSelect value={limit} onChange={handleOnPageSizeChange}>
          {pageSizeOptions.map((option) => (
            <Select.Option value={option}>{option}</Select.Option>
          ))}
        </PageSizeSelect>
        筆
      </Header>
      <TableContainer>
        <Table
          dataSource={saleMethodList}
          columns={tableColumns}
          scroll={{ x: "max-content", y: 400 }}
          tableLayout="auto"
          pagination={false}
          loading={isFetchingSaleMethodList}
        />
      </TableContainer>
      <Footer>
        <Pagination
          current={currentPage}
          total={count}
          onChange={handleOnPageChange}
          showSizeChanger={false}
          pageSize={limit}
        />
      </Footer>
    </Wrapper>
  );
};

export default SaleMethod;
