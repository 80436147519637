import React, { useState, useEffect, FC } from "react";
import styled from "styled-components";
import { ColumnsType } from "antd/lib/table";
import { Button, Table, Modal, Tag, Select, Pagination } from "antd";
import { PlusCircleTwoTone, ExclamationCircleOutlined } from "@ant-design/icons";
import ContactPopup from "@page/SupplyChain/ContactPopup";
import { fetchSupplyChainContactList, deleteSupplyChainContact, updateMajorContact } from "@redux/supplyChainSlice";
import { RootState } from "@redux/rootReducer";
import { useSelector } from "react-redux";
import { useAppDispatch } from "src/store";
import { SelectValue } from "antd/lib/select";
import { SupplyChainContactDetail, UpdateMajorContactParam } from "@api/supplyChainApi";

const { Option } = Select;
const Wrapper = styled.div``;
const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 21px;
`;
const TablePageInfo = styled.div`
  font-size: 14px;
  margin-right: 10px;
`;
const PageSelect = styled(Select)`
  margin: 0 10px;
`;
const TableContainer = styled.div`
  position: relative;
  padding: 20px 10px 50px 10px;
  border: 1px solid #f0f0f0;
`;
const TablePagination = styled(Pagination)`
  position: absolute;
  right: 21px;
  bottom: 10px;
`;

const Edit = styled.div`
  white-space: nowrap;
  color: #1790ff;
  cursor: pointer;
`;
const MajorBtn = styled(Button)``;

interface Props {
  supplyChainId: number;
}

const ContactInfo: FC<Props> = (props) => {
  const { supplyChainId } = props;
  const dispatch = useAppDispatch();
  const [currentData, setCurrentData] = useState<SupplyChainContactDetail | null>(null);
  const [showIsMajor, setShowIsMajor] = useState<boolean>(false);
  const supplyChainSlice = useSelector((state: RootState) => state.supplyChain);
  const supplyChainContactList = supplyChainSlice.supplyChainContactList.results;
  const [openPopup, setOpenPopup] = useState<boolean>(false);
  const { isFetching } = supplyChainSlice;
  const { supplyChainFilter } = supplyChainSlice;
  const { limit, offset } = supplyChainFilter;
  const { count } = supplyChainSlice.supplyChainContactList;

  const currentPage = offset && limit && Math.floor(offset / limit) + 1;

  const handleOnPageChange = (page: number) => {
    if (limit) {
      const filter = {
        ...supplyChainFilter,
        offset: (page - 1) * limit,
        supplyChain: supplyChainId,
      };

      dispatch(fetchSupplyChainContactList(filter));
    }
  };

  const handleSelectChange = (value: SelectValue) => {
    const filter = {
      ...supplyChainFilter,
      limit: value as number,
      supplyChain: supplyChainId,
    };
    dispatch(fetchSupplyChainContactList(filter));
  };

  const tableColumns: ColumnsType<SupplyChainContactDetail> = [
    {
      title: "聯絡人姓名",
      key: "name",
      dataIndex: "name",
    },
    {
      title: "",
      dataIndex: "",
      render: (data) => {
        return data.isMajor && <Tag color="processing">主要聯絡人</Tag>;
      },
    },
    {
      title: "聯絡人電話",
      key: "phone",
      dataIndex: "phone",
    },
    {
      title: "聯絡人信箱",
      key: "email",
      dataIndex: "email",
    },
    {
      title: "聯絡人職稱",
      key: "title",
      dataIndex: "title",
    },
    {
      title: "",
      key: "",
      render: (data) => {
        return (
          <MajorBtn onClick={() => toggleMajor(data)} disabled={data.isMajor === true}>
            設為主要聯絡人
          </MajorBtn>
        );
      },
    },
    {
      title: "",
      key: "",
      dataIndex: "",
      fixed: "right",
      render: (data) => {
        return <Edit onClick={() => toggleEditPopup(data)}>編輯</Edit>;
      },
    },
    {
      title: "",
      key: "",
      dataIndex: "",
      fixed: "right",
      render: (data) => {
        return <Edit onClick={() => handleDelete(data.id)}>刪除</Edit>;
      },
    },
  ];

  const toggleEditPopup = (data: SupplyChainContactDetail) => {
    setOpenPopup(true);
    setCurrentData(data);
  };
  const handleDelete = (deleteid: number) => {
    Modal.confirm({
      title: "你確定要刪除這些資料?",
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: "是",
      cancelText: "否",
      onOk: () => {
        const filter = {
          id: deleteid,
          supplyChain: supplyChainId,
        };
        dispatch(deleteSupplyChainContact(filter));
      },
    });
  };

  useEffect(() => {
    const filter = {
      supplyChain: supplyChainId,
    };
    dispatch(fetchSupplyChainContactList(filter));
  }, [dispatch, supplyChainId]);

  const toggleMajor = (data: UpdateMajorContactParam) => {
    setShowIsMajor(true);
    const params: UpdateMajorContactParam = {
      supplyChain: supplyChainId,
      isMajor: showIsMajor,
      id: data.id,
    };
    dispatch(updateMajorContact(params));
  };

  const handlePopup = () => {
    setOpenPopup((prev) => !prev);
    setCurrentData(null);
  };

  return (
    <Wrapper>
      <Header>
        <Button type="primary" icon={<PlusCircleTwoTone />} onClick={() => handlePopup()}>
          新增聯絡人
        </Button>
        {openPopup && <ContactPopup currentData={currentData} onClose={handlePopup} supplyChainId={supplyChainId} />}
        <TablePageInfo>
          總共
          {count}
          筆, 每頁顯示
          <PageSelect defaultValue={limit} onChange={handleSelectChange}>
            <Option value={20}>20</Option>
            <Option value={50}>50</Option>
            <Option value={100}>100</Option>
          </PageSelect>
          筆
        </TablePageInfo>
      </Header>
      <TableContainer>
        <Table
          loading={isFetching}
          scroll={{ x: "max-content" }}
          dataSource={supplyChainContactList}
          columns={tableColumns}
          pagination={false}
          rowKey="id"
        />
        <TablePagination
          current={currentPage}
          total={count}
          onChange={handleOnPageChange}
          showSizeChanger={false}
          pageSize={limit}
        />
      </TableContainer>
    </Wrapper>
  );
};

export default ContactInfo;
