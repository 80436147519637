import { ReviewStateByPositionLabel } from "@constant/ReviewState";
import React from "react";
import styled from "styled-components";
import { ReviewStateByPosition } from "./interfaces";

const columns: {
  title: string;
  dataIndex: keyof ReviewStateByPosition;
  key: keyof ReviewStateByPosition;
}[] = [
  {
    title: "一階主管",
    dataIndex: "sectionManager",
    key: "sectionManager",
  },
  {
    title: "二階主管",
    dataIndex: "manager",
    key: "manager",
  },
  {
    title: "財務",
    dataIndex: "finance",
    key: "finance",
  },
  {
    title: "CEO",
    dataIndex: "ceo",
    key: "ceo",
  },
];

const GrayText = styled.p`
  color: ${({ theme }) => theme.colorNeutral500};
`;

export default function ReviewStateTable(props: { data: ReviewStateByPosition }) {
  const { data } = props;

  return (
    <table className="review-table">
      {/* 職位名稱 */}
      <tbody>
        <tr>
          {columns.map((column) => (
            <th key={column.key}>{column.title}</th>
          ))}
        </tr>
        {/* Review State */}
        <tr>
          {columns.map((column) => {
            const reviewState = data[column.dataIndex];
            const stateLabel = ReviewStateByPositionLabel[reviewState];
            return (
              <>
                {stateLabel === "不需審核" ? (
                  <td key={column.key}>
                    <GrayText>{stateLabel}</GrayText>
                  </td>
                ) : (
                  <td key={column.key}>{stateLabel}</td>
                )}
              </>
            );
          })}
        </tr>
      </tbody>
    </table>
  );
}
