import { ExclamationCircleOutlined, PlusCircleTwoTone } from "@ant-design/icons";
import { AmountRateType, ApprovalStatus, OrderDeductionsList, OrderFeeList } from "@api/throughShipmentApi";
import {
  externalOrderState,
  fetchBatchDeleteOrderFee,
  fetchBatchUpdateOrderDeductions,
  fetchBatchUpdateOrderFee,
  fetchOrderDeductions,
  fetchOrderFeeList,
} from "@redux/externalOrderSlice";
import { Button, Input, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import CreateOrderFeePopup from "./CreateOrderFeePopup";

const Wrapper = styled.div`
  padding: 20px 20px 42px 20px;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
  margin-bottom: 12px;
`;
const Title = styled.div<{ marginBottom?: string }>`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: ${({ marginBottom }) => marginBottom || ""};
`;
const SubTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 20px;
`;
const SubWrapper = styled.div`
  margin-top: 40px;
`;
const Row = styled.div<{ marginRight?: string; marginBottom?: string; width?: string }>`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: ${({ marginBottom }) => marginBottom || "10px"};
  margin-right: ${({ marginRight }) => marginRight};
  width: ${({ width }) => width || ""};
`;
const RowTitle = styled.div<{ bold?: boolean }>`
  align-self: flex-start;
  width: 115px;
  line-height: 32px;
  margin-right: 15px;
  font-size: 14px;
  font-weight: ${({ bold }) => (bold ? "bold" : "normal")};
`;
const RowContent = styled.div<{ width?: number }>`
  width: ${({ width }) => width}px;
  display: flex;
  align-items: center;
`;
const Percent = styled.div<{ isShow?: boolean }>`
  margin-left: 4px;
  opacity: ${({ isShow }) => (isShow ? 1 : 0)};
  width: 27px;
`;
const CustomButton = styled(Button)`
  margin-top: 20px;
`;
const Footer = styled.div`
  position: fixed;
  right: 25px;
  bottom: 0;
  width: 100%;
  display: flex;
  padding: 13px 12px;
  align-items: center;
  justify-content: flex-end;
  background-color: #fafafa;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.05);
`;
const FooterCustomButton = styled(Button)<{ marginRight?: string; width?: string }>`
  margin-right: ${({ marginRight }) => marginRight || ""};
  width: ${({ width }) => width || ""};
`;
const CustomInput = styled(Input)<{ hasError?: boolean }>`
  border: solid 1px ${({ hasError, theme }) => (hasError ? theme.colorSecondary500 : "#d9d9d9")};
`;

type Props = {
  onNextStep: () => void;
  onBackStep: () => void;
};

export default function OrderFee(props: Props) {
  const { onNextStep, onBackStep } = props;

  const dispatch = useDispatch();
  const { orderDeductionsList, orderFeeList, isEditOrderFeeSuccess, externalOrderInfo } = useSelector(
    externalOrderState,
  );

  const { externalOrderId } = useParams();

  const [displayOrderDeductionMap, setDisplayOrderDeductionMap] = useState<Map<string, OrderDeductionsList[]>>(
    new Map(),
  );
  const [editOrderDeductionMap, setEditOrderDeductionMap] = useState<Map<number, OrderDeductionsList>>(new Map());
  const [orderFeeMap, setOrderFeeMap] = useState<Map<number, OrderFeeList>>(new Map());
  const [createOderFeePopup, setCreateOderFeePopup] = useState<boolean>(false);
  const [errorDeductions, setErrorDeductions] = useState<number[]>([]);
  const [errorOrderFees, setErrorOrderFees] = useState<number[]>([]);

  const onSubmit = () => {
    const deductionMapList = Array.from(editOrderDeductionMap.values());
    const orderFeeMapList = Array.from(orderFeeMap.values());
    const errorDeductionList: number[] = [];
    const errorOrderFeeList: number[] = [];
    deductionMapList.forEach((item) => {
      if (item.rate === undefined && item.amount === undefined) {
        errorDeductionList.push(item.id);
      }
    });
    orderFeeMapList.forEach((item) => {
      if (item.rate === undefined && item.amount === undefined) {
        errorOrderFeeList.push(item.id);
      }
    });
    setErrorDeductions(errorDeductionList);
    setErrorOrderFees(errorOrderFeeList);

    if (errorDeductionList.length > 0 || errorOrderFeeList.length > 0) {
      Modal.warning({
        title: "欄位未填寫",
        content: "若無數數值請填「0",
        okText: "我知道了",
      });
      return;
    }

    const deductionPayload = deductionMapList.map((item) => ({
      id: item.id,
      rate: item.rate,
      amount: item.amount,
    }));
    const orderFeePayload = orderFeeMapList.map((item) => ({
      id: item.id,
      rate: item.rate,
      amount: item.amount,
    }));

    dispatch(fetchBatchUpdateOrderDeductions(deductionPayload));
    dispatch(fetchBatchUpdateOrderFee(orderFeePayload));
  };

  useEffect(() => {
    const displayMap = new Map<string, OrderDeductionsList[]>();
    const itemMap = new Map<number, OrderDeductionsList>();

    orderDeductionsList.results.forEach((deduction) => {
      if (displayMap.has(deduction.productlineName)) {
        const prevDisplayItem = displayMap.get(deduction.productlineName);
        displayMap.set(deduction.productlineName, [...(prevDisplayItem as OrderDeductionsList[]), deduction]);
      } else {
        displayMap.set(deduction.productlineName, [deduction]);
      }

      itemMap.set(deduction.id, deduction);
    });

    setDisplayOrderDeductionMap(displayMap);
    setEditOrderDeductionMap(itemMap);
  }, [orderDeductionsList]);

  useEffect(() => {
    const orderFee = new Map<number, OrderFeeList>();
    orderFeeList.results.forEach((item) => {
      orderFee.set(item.id, item);
    }, []);
    setOrderFeeMap(orderFee);
  }, [orderFeeList]);

  useEffect(() => {
    if (isEditOrderFeeSuccess) {
      onNextStep();
    }
  }, [isEditOrderFeeSuccess, onNextStep]);

  useEffect(() => {
    dispatch(fetchOrderDeductions(parseInt(externalOrderId, 10)));
    dispatch(fetchOrderFeeList(parseInt(externalOrderId, 10)));
  }, [dispatch, externalOrderId]);

  return (
    <div>
      <Wrapper>
        <Title>出貨商品後扣項目</Title>
        <SubWrapper>
          {Array.from(displayOrderDeductionMap.keys()).map((productlinesName) => (
            <div>
              <SubTitle>{productlinesName}</SubTitle>
              <Row marginBottom="0px">
                {displayOrderDeductionMap.get(productlinesName)!.map((item) => (
                  <Row marginRight="101px">
                    <RowTitle>{item.name}</RowTitle>
                    <RowContent>
                      <CustomInput
                        hasError={errorDeductions.includes(item.id)}
                        value={
                          editOrderDeductionMap.get(item.id)?.type === AmountRateType.AMOUNT
                            ? editOrderDeductionMap.get(item.id)?.amount
                            : editOrderDeductionMap.get(item.id)?.rate
                        }
                        onChange={(e) => {
                          const map = new Map(editOrderDeductionMap);
                          map.set(item.id, {
                            ...map.get(item.id)!,
                            amount:
                              e.target.value &&
                              item.type === AmountRateType.AMOUNT &&
                              !Number.isNaN(Number(e.target.value))
                                ? Number(e.target.value)
                                : undefined,
                            rate:
                              e.target.value &&
                              item.type === AmountRateType.RATE &&
                              !Number.isNaN(Number(e.target.value))
                                ? Number(e.target.value)
                                : undefined,
                          });
                          setEditOrderDeductionMap(map);
                        }}
                      />
                      <Percent isShow={item.type === AmountRateType.RATE}>%</Percent>
                    </RowContent>
                  </Row>
                ))}
              </Row>
            </div>
          ))}
        </SubWrapper>
      </Wrapper>
      <Wrapper>
        <Title marginBottom="20px">訂單費用</Title>
        {orderFeeMap.size > 0 &&
          orderFeeList.results.map((item) => (
            <Row>
              <RowTitle>{item.name}</RowTitle>
              <RowContent>
                <CustomInput
                  hasError={errorOrderFees.includes(item.id)}
                  value={
                    orderFeeMap.get(item.id)?.type === AmountRateType.AMOUNT
                      ? orderFeeMap.get(item.id)?.amount
                      : orderFeeMap.get(item.id)?.rate
                  }
                  onChange={(e) => {
                    const map = new Map(orderFeeMap);
                    map.set(item.id, {
                      ...map.get(item.id)!,
                      amount:
                        e.target.value && item.type === AmountRateType.AMOUNT && !Number.isNaN(Number(e.target.value))
                          ? Number(e.target.value)
                          : undefined,
                      rate:
                        e.target.value && item.type === AmountRateType.RATE && !Number.isNaN(Number(e.target.value))
                          ? Number(e.target.value)
                          : undefined,
                    });
                    setOrderFeeMap(map);
                  }}
                />
                <Percent isShow={item.type === AmountRateType.RATE}>%</Percent>
                <Button
                  type="link"
                  onClick={() =>
                    Modal.confirm({
                      title: "你確定要刪除嗎?",
                      icon: <ExclamationCircleOutlined />,
                      okText: "是",
                      cancelText: "否",
                      onOk() {
                        dispatch(fetchBatchDeleteOrderFee({ orderFeeIds: [item.id] }));
                      },
                    })
                  }
                >
                  刪除
                </Button>
              </RowContent>
            </Row>
          ))}
        <CustomButton
          type="primary"
          icon={<PlusCircleTwoTone twoToneColor="#1890FF" />}
          onClick={() => setCreateOderFeePopup(true)}
        >
          新增訂單費用
        </CustomButton>
        {createOderFeePopup && <CreateOrderFeePopup onClose={() => setCreateOderFeePopup(false)} />}
        <Footer>
          <FooterCustomButton
            marginRight="12px"
            disabled={
              externalOrderInfo?.financeApproval === ApprovalStatus.APPROVED &&
              externalOrderInfo.managerApproval === ApprovalStatus.APPROVED &&
              externalOrderInfo.sectionManagerApproval === ApprovalStatus.APPROVED &&
              externalOrderInfo.ceoApproval === ApprovalStatus.APPROVED
            }
            onClick={onBackStep}
          >
            返回
          </FooterCustomButton>
          <FooterCustomButton type="primary" onClick={onSubmit}>
            儲存此分頁
          </FooterCustomButton>
        </Footer>
      </Wrapper>
    </div>
  );
}
