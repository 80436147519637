import { UpdateProductDetailParam } from "@api/productApi";
import { fetchCategoryDetail, fetchProductCategoryList, updateProduct } from "@redux/productEditSlice";
import { RootState } from "@redux/rootReducer";
import { Button, Select } from "antd";
import { SelectValue } from "antd/lib/select";
import React, { FC, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "src/store";
import styled from "styled-components";

const Wrapper = styled.div`
  min-height: 300px;
  padding-left: 16px;
  padding-right: 44px;
  padding-bottom: 40px;
`;
const StyledSelect = styled(Select)`
  width: 100%;
`;
const RequiredIcon = styled.span`
  color: red;
`;
const Row = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`;
const RowTitle = styled.div`
  margin-right: 47px;
`;
const RowContent = styled.div`
  width: 300px;
`;
const TagTypeRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 18px;
  width: 100%;

  &:last-child {
    margin-bottom: 0;
  }
`;
const TagTypeRowTitle = styled.div`
  margin-right: 47px;
  min-width: 75px;
  font-size: 14px;
`;
const TagTypeRowContent = styled.div`
  width: 100%;
`;
const Footer = styled.div`
  position: fixed;
  right: 25px;
  bottom: 0;
  width: 100%;
  display: flex;
  padding: 13px 12px;
  align-items: center;
  justify-content: flex-end;
  background-color: #fafafa;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.05);
`;

const CategoryTagType: FC = () => {
  const dispatch = useAppDispatch();
  const { id: productId } = useParams();

  const { categories } = useSelector((state: RootState) => state.productEdit.productDetail);
  const productDetail = useSelector((state: RootState) => state.productEdit.productDetail);
  const productCategoryList = useSelector((state: RootState) => state.productEdit.productCategoryList);
  const productCategoryDetail = useSelector((state: RootState) => state.productEdit.productCategoryDetail);

  const [selectedCategory, setSelectedCategory] = useState<{
    categoryLevel1: number;
    categoryLevel2: number;
    categoryLevel3: number;
  }>({
    categoryLevel1: -1,
    categoryLevel2: -1,
    categoryLevel3: -1,
  });
  const [selectTags, setSelectTags] = useState<Map<string, number[]>>(new Map());
  const { categoryLevel1, categoryLevel2, categoryLevel3 } = selectedCategory;

  useEffect(() => {
    if (productDetail.tags.length > 0) {
      const map = new Map();
      productDetail.tags.forEach((item) => {
        if (map.has(item.typeName)) {
          map.set(item.typeName, [...map.get(item.typeName), item.tag]);
        } else {
          map.set(item.typeName, [item.tag]);
        }
      });
      setSelectTags(map);
    }
  }, [productDetail]);

  useEffect(() => {
    dispatch(fetchProductCategoryList());
  }, [dispatch]);

  useEffect(() => {
    if (categories) {
      const initialCategoryLevel1 = categories.find((ctgy) => ctgy.level === 1);
      const initialCategoryLevel2 = categories.find((ctgy) => ctgy.level === 2);
      const initialCategoryLevel3 = categories.find((ctgy) => ctgy.level === 3);

      setSelectedCategory({
        categoryLevel1: initialCategoryLevel1 ? initialCategoryLevel1.id : -1,
        categoryLevel2: initialCategoryLevel2 ? initialCategoryLevel2.id : -1,
        categoryLevel3: initialCategoryLevel3 ? initialCategoryLevel3.id : -1,
      });

      if (initialCategoryLevel3) {
        dispatch(fetchCategoryDetail(initialCategoryLevel3.id));
      }
    }
  }, [categories, productCategoryList, dispatch]);

  const categoryLevel1Options = useMemo(() => {
    const options = Object.values(productCategoryList.level1).map((ctgryItem) => (
      <Select.Option value={ctgryItem.id}>{ctgryItem.name}</Select.Option>
    ));

    options.unshift(
      <Select.Option key={-1} value={-1}>
        請選擇第一層品類
      </Select.Option>,
    );
    return options;
  }, [productCategoryList]);

  const categoryLevel2Options = useMemo(() => {
    if (categoryLevel1 > 0) {
      const level2Options = productCategoryList.level1[categoryLevel1];
      const options = level2Options
        ? level2Options.children.map((ctgryItem) => (
            <Select.Option value={ctgryItem.id}>{ctgryItem.name}</Select.Option>
          ))
        : [];
      options.unshift(
        <Select.Option key={-1} value={-1}>
          請選擇
        </Select.Option>,
      );
      return options;
    }
    return (
      <Select.Option key={-1} value={-1}>
        請先選擇第一層品類，再選擇第二層品類
      </Select.Option>
    );
  }, [productCategoryList, categoryLevel1]);

  const categoryLevel3Options = useMemo(() => {
    if (categoryLevel2 > 0) {
      const level3Options = productCategoryList.level2[categoryLevel2];
      const options = level3Options
        ? level3Options.children.map((ctgryItem) => (
            <Select.Option value={ctgryItem.id}>{ctgryItem.name}</Select.Option>
          ))
        : [];
      options.unshift(
        <Select.Option key={-1} value={-1}>
          請選擇
        </Select.Option>,
      );
      return options;
    }
    return (
      <Select.Option key={-1} value={-1}>
        請先選擇第二層品類，再選擇第三層品類
      </Select.Option>
    );
  }, [productCategoryList, categoryLevel2]);

  const handleOnSelect = (title: string) => (value: SelectValue) => {
    switch (title) {
      case "categoryLevel1":
        // 選第一品類 刷新第二三層品類
        setSelectedCategory((prev) => ({
          ...prev,
          [title]: value as number,
          categoryLevel2: -1,
          categoryLevel3: -1,
        }));
        break;
      case "categoryLevel2":
        // 選第二品類 刷新第三層品類
        setSelectedCategory((prev) => ({
          ...prev,
          [title]: value as number,
          categoryLevel3: -1,
        }));
        break;
      case "categoryLevel3":
        setSelectedCategory((prev) => ({
          ...prev,
          [title]: value as number,
        }));

        dispatch(fetchCategoryDetail(value as number));
        break;
      default:
    }
  };

  const handleOnSave = () => {
    if (categoryLevel3 > -1) {
      const tags = [];
      for (const value of selectTags.values()) {
        tags.push(...value);
      }

      const params: UpdateProductDetailParam = {
        owner: productDetail.owner.id,
        name: productDetail.name,
        sku: productDetail.sku,
        brand: productDetail.brand.id,
        shelfLife: productDetail.shelfLife,
        syncOption: productDetail.syncStockOption,
        isSyncWarehouse: productDetail.isSyncWarehouse,
        isActive: productDetail.isActive,
        stockQty: productDetail.stockQty,
        productCategory: categoryLevel3,
        tags,
        length: productDetail.length,
        width: productDetail.width,
        height: productDetail.height,
        weight: productDetail.weight,
        cartonLength: productDetail.cartonLength,
        cartonWidth: productDetail.cartonWidth,
        cartonHeight: productDetail.cartonHeight,
      };

      const updateParams = {
        id: Number(productId),
        params,
      };

      dispatch(updateProduct(updateParams));
    }
  };

  return (
    <Wrapper>
      <Row>
        <RowTitle>
          第一層品類
          <RequiredIcon>*</RequiredIcon>
        </RowTitle>
        <RowContent>
          <StyledSelect value={categoryLevel1} onChange={handleOnSelect("categoryLevel1")}>
            {categoryLevel1Options}
          </StyledSelect>
        </RowContent>
      </Row>
      <Row>
        <RowTitle>
          第二層品類
          <RequiredIcon>*</RequiredIcon>
        </RowTitle>
        <RowContent>
          <StyledSelect
            value={categoryLevel2}
            disabled={categoryLevel1 < 0}
            onChange={handleOnSelect("categoryLevel2")}
          >
            {categoryLevel2Options}
          </StyledSelect>
        </RowContent>
      </Row>
      <Row>
        <RowTitle>
          第三層品類
          <RequiredIcon>*</RequiredIcon>
        </RowTitle>
        <RowContent>
          <StyledSelect
            value={categoryLevel3}
            disabled={categoryLevel2 < 0}
            onChange={handleOnSelect("categoryLevel3")}
          >
            {categoryLevel3Options}
          </StyledSelect>
        </RowContent>
      </Row>
      {/* 屬性暫時隱藏  */}
      {categoryLevel3 > 0 &&
        Object.keys(productCategoryDetail.tagTypesAttributes).map((attrName) => (
          <TagTypeRow>
            <TagTypeRowTitle>{attrName}</TagTypeRowTitle>
            <TagTypeRowContent>
              <Select
                value={selectTags.get(attrName)}
                mode="multiple"
                style={{ width: "100%" }}
                showArrow
                filterOption={(input, option) => ((option!.children as unknown) as string).includes(input)}
                onChange={(tags) => {
                  setSelectTags((prev) => new Map(prev.set(attrName, tags)));
                }}
              >
                {productCategoryDetail.tagTypesAttributes[attrName].map((tag) => (
                  <Select.Option value={tag.id}>{tag.name}</Select.Option>
                ))}
              </Select>
            </TagTypeRowContent>
          </TagTypeRow>
        ))}
      <Footer>
        <Button type="primary" onClick={handleOnSave}>
          儲存此分頁
        </Button>
      </Footer>
    </Wrapper>
  );
};

export default CategoryTagType;
