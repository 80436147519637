import { FetchPromotionListParams } from "@api/promotionApi";
import InfiniteScrollObserver from "@component/InfiniteScrollObserver";
import PopupBackground from "@component/PopupBackground";
import { RootState } from "@redux/rootReducer";
import {
  fetchPromotionList,
  loadMorePromotionList,
  reset,
  updatePromotionListParams,
} from "@redux/ruleAddPromotionSlice";
import { Button, Input, Radio, Select } from "antd";
import { FormInstance } from "antd/lib/form";
import { RadioChangeEvent } from "antd/lib/radio";
import { SelectValue } from "antd/lib/select";
import React, { ChangeEvent, FC, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "src/store";
import styled from "styled-components";
import type { LocalFormType } from "..";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  width: 700px;
  height: 425px;
  background-color: white;
`;
const Header = styled.form`
  display: flex;
  align-items: center;
  padding: 18px 28px;
`;
const SearchInputWrapper = styled(Input.Group)`
  width: 400px;
`;
const SearchSelect = styled(Select)`
  width: 25%;
`;
const SearchInput = styled(Input)`
  && {
    width: 75%;
  }
`;
const SearchButton = styled(Button)`
  margin-left: 8px;
`;
const Body = styled.div`
  flex: auto;
  overflow: auto;
  padding: 0 28px;
`;
const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 20px 28px;
`;
const CancelButton = styled(Button)`
  margin-right: 10px;
`;
const PromotionRow = styled.div`
  display: flex;
  align-items: center;
  height: 36px;
  border-bottom: 1px solid #f2f2f2;
  padding: 0 10px;
`;
const PromotionId = styled.div`
  flex: 0 0 10%;
  min-width: 0;
  margin-left: 10px;
`;
const PromotionTitle = styled.div`
  flex: auto;
  min-width: 0;
  margin-left: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
const RadioGroup = styled(Radio.Group)`
  width: 100%;
`;
const FlexRadio = styled(Radio)`
  display: flex;
  align-items: center;
  width: 100%;

  span.ant-radio + * {
    width: 100%;
  }
`;

interface Props {
  onClose: () => void;
  form: FormInstance<LocalFormType>;
}

enum SearchTypeValue {
  ID,
  NAME,
}

const searchOptions = [
  {
    label: "活動ID",
    value: SearchTypeValue.ID,
  },
  {
    label: "活動名稱",
    value: SearchTypeValue.NAME,
  },
];

const AddExistingPromotion: FC<Props> = (props) => {
  const { onClose, form } = props;
  const dispatch = useAppDispatch();
  const { promotionListResult, promotionListParams } = useSelector((state: RootState) => state.ruleAddPromotion);
  const { next, results: promotionList } = promotionListResult;

  const [searchType, setSearchType] = useState<SearchTypeValue>(SearchTypeValue.ID);
  const [inputValue, setInputValue] = useState<string>("");
  const [selectedPromotionId, setSelectedPromotionId] = useState<number>();

  useEffect(() => {
    return () => {
      dispatch(reset());
    };
  }, [dispatch]);

  const handleOnSelect = (value: SelectValue) => {
    setSearchType(value as SearchTypeValue);
  };

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setInputValue(value);

    e.preventDefault();
    e.stopPropagation();
  };

  const handleOnRadioSelect = (e: RadioChangeEvent) => {
    const { value } = e.target;
    setSelectedPromotionId(value);
    e.stopPropagation();
    e.preventDefault();
  };

  const loadMoreList = useCallback(() => {
    dispatch(loadMorePromotionList());
  }, [dispatch]);

  const handleOnSearch = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();

    const params: FetchPromotionListParams = {
      limit: promotionListParams.limit,
      offset: 0,
      isActive: -1,
    };
    switch (searchType) {
      case SearchTypeValue.ID:
        params.id = inputValue ? Number(inputValue) : undefined;
        params.titleQ = undefined;
        break;
      case SearchTypeValue.NAME:
        params.id = undefined;
        params.titleQ = inputValue;
        break;
      default:
    }
    dispatch(updatePromotionListParams(params));
    dispatch(fetchPromotionList());
  };

  const handleOnSubmit = () => {
    const selectedPromotion = promotionList.find((pmt) => pmt.id === selectedPromotionId)!;

    form.setFieldsValue({
      promotionInfo: {
        id: selectedPromotion.id,
        title: selectedPromotion.title,
        tag: selectedPromotion.tag,
      },
    });
    onClose();
  };

  const renderPromotionRows = () => {
    const results = promotionList.map((pmt) => (
      <FlexRadio value={pmt.id}>
        <PromotionRow>
          <PromotionId>{pmt.id}</PromotionId>
          <PromotionTitle>{pmt.title}</PromotionTitle>
        </PromotionRow>
      </FlexRadio>
    ));

    if (next) {
      results.push(<InfiniteScrollObserver callback={loadMoreList} />);
    }
    return results;
  };

  const handleOnEnter: React.KeyboardEventHandler<HTMLDivElement> = (e) => {
    handleOnSubmit();
    e.stopPropagation();
    e.preventDefault();
  };

  return (
    <PopupBackground close={onClose}>
      <Wrapper>
        <Header>
          <SearchInputWrapper compact>
            <SearchSelect options={searchOptions} value={searchType} onChange={handleOnSelect} />
            <SearchInput value={inputValue} onChange={handleOnChange} />
          </SearchInputWrapper>
          <SearchButton htmlType="submit" onClick={handleOnSearch}>
            搜尋
          </SearchButton>
        </Header>
        <Body onKeyPress={handleOnEnter}>
          <RadioGroup value={selectedPromotionId} onChange={handleOnRadioSelect}>
            {renderPromotionRows()}
          </RadioGroup>
        </Body>
        <Footer>
          <CancelButton onClick={onClose}>取消</CancelButton>
          <Button type="primary" onClick={handleOnSubmit} disabled={!selectedPromotionId}>
            確定
          </Button>
        </Footer>
      </Wrapper>
    </PopupBackground>
  );
};

export default AddExistingPromotion;
