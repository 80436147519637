import React, { FC, useEffect, useState, useMemo, useCallback, ChangeEvent } from "react";
import styled from "styled-components";
import { Button, Input, Select } from "antd";
import { useAppDispatch } from "src/store";
import { RootState } from "@redux/rootReducer";
import { useSelector } from "react-redux";
import { SelectValue } from "antd/lib/select";
import { FetchSaleListParams } from "@api/saleApi";
import {
  fetchBrandList,
  loadMoreBrandList,
  updateSaleListFilter,
  clearSaleListFilter,
  fetchSaleList,
  fetchSalesCategoryList,
} from "@redux/slotAddSalesSlice";
import useDebounce from "@hooks/useDebounce";
import InfiniteScrollObserver from "@component/InfiniteScrollObserver";
import generateCategoryTree, { SalesCategory } from "@page/TopicPage/utils/generateCategoryTree";
import getCategoryBreadcrumb from "@page/TopicPage/utils/getCategoryBreadcrumb";
import { useParams } from "react-router-dom";
import CategoriesModal from "./CategoriesModal";

const Wrapper = styled.div`
  display: inline-grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto;
  column-gap: 36px;
  padding-bottom: 16px;
  border-bottom: 1px solid #f2f2f2;
  width: 100%;
`;
const Grid = styled.div`
  display: inline-grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto;
  column-gap: 0px;
  row-gap: 12px;
`;
const FilterTitle = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  color: ${({ theme }) => theme.colorNeutral600};
`;
const FilterInput = styled(Input)`
  width: 280px;
`;
const FilterButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
const ClearFilterButton = styled(Button)`
  margin-right: 8px;
`;
const BrandSelect = styled(Select)`
  width: 280px;
`;
const CategoriesInputContainer = styled.div`
  position: relative;
`;
const ModalMask = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
`;

const defaultFilter = {
  limit: 50,
  offset: 0,
};

const AddSalePage: FC = () => {
  const { isFetchingBrand, brandListResult, saleListFilter } = useSelector(
    (state: RootState) => state.slotAddSalesSlice,
  );
  const { salesCategories } = useSelector((state: RootState) => state.slotAddSalesSlice);
  const dispatch = useAppDispatch();
  const { id: slotId } = useParams();

  const [tree, setTree] = useState<Map<number, SalesCategory>>(new Map());
  const [level1, setLevel1] = useState<Map<number, SalesCategory>>(new Map());
  const [level2, setLevel2] = useState<Map<number, SalesCategory>>(new Map());
  const [level3, setLevel3] = useState<Map<number, SalesCategory>>(new Map());
  const [searchText, setSearchText] = useState<string>("");
  const [localFilter, setLocalFilter] = useState<FetchSaleListParams>(defaultFilter);
  const [openCategoriesModal, setOpenCategoriesModal] = useState<boolean>(false);

  const { pageIds, pageNameQ, brandNames, skus, categoryIds } = localFilter;
  
  useEffect(() => {
    setLocalFilter(saleListFilter);
  }, [saleListFilter]);

  useEffect(() => {
    if (salesCategories) {
      const { treeMap, level1Map, level2Map, level3Map } = generateCategoryTree(salesCategories);
      setTree(treeMap);
      setLevel1(level1Map);
      setLevel2(level2Map);
      setLevel3(level3Map);
    }
  }, [salesCategories]);

  useEffect(() => {
    dispatch(fetchBrandList(""));
    dispatch(fetchSalesCategoryList());
  }, [dispatch]);

  const handleOnBrandLoadMore = useCallback(() => {
    dispatch(loadMoreBrandList());
  }, [dispatch]);

  const currentCategoryTag = useMemo(() => {
    if (categoryIds) return getCategoryBreadcrumb(level1, level2, level3, categoryIds);
    return "";
  }, [categoryIds, level1, level2, level3]);

  const brandOptions = useMemo(() => {
    const { next, results } = brandListResult;
    const options = results.map((brand) => (
      <Select.Option key={brand.id} value={brand.name}>
        {brand.name}
      </Select.Option>
    ));

    if (next) {
      options.push(
        <Select.Option value="loading..." disabled>
          loading...
          <InfiniteScrollObserver callback={handleOnBrandLoadMore} />
        </Select.Option>,
      );
    }

    return options;
  }, [brandListResult, handleOnBrandLoadMore]);

  const handleOnBrandSearch = useDebounce((value: string) => {
    dispatch(fetchBrandList(value));
  }, 300);

  const handleOnBrandChange = (values: SelectValue) => {
    setLocalFilter((prev) => ({
      ...prev,
      brandNames: values as string[],
    }));
  };

  const handleOnInputChange = (title: string) => (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    switch (title) {
      case "pageIds":
      case "skus":
        setLocalFilter((prev) => ({
          ...prev,
          [title]: value.split(","),
        }));
        break;
      case "pageNameQ":
        setLocalFilter((prev) => ({
          ...prev,
          [title]: value,
        }));
        break;
      default:
    }
  };

  const handleOnClearFilter = () => {
    const newFilter = {
      offset: 0,
      limit: saleListFilter.limit,
    };

    setLocalFilter(newFilter);
    dispatch(clearSaleListFilter());
  };

  const handleOnSearch = () => {
    const localFilterWithExcludeTagId: FetchSaleListParams = {
      ...localFilter,
      slotId,
      excludeAddedPage: true,
    };
    dispatch(updateSaleListFilter(localFilterWithExcludeTagId));
    dispatch(fetchSaleList());
  };

  const handleOnCategoriesSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchText(value);
  };

  const handleOnCategoriesFocus = () => {
    setOpenCategoriesModal(true);
  };

  const closeCategoriesModal = () => setOpenCategoriesModal(false);

  return (
    <Wrapper>
      <Grid>
        <FilterTitle>銷售頁ID</FilterTitle>
        <FilterInput value={pageIds} onChange={handleOnInputChange("pageIds")} />
        <FilterTitle>SKU</FilterTitle>
        <FilterInput value={skus} onChange={handleOnInputChange("skus")} />
        <FilterTitle>分類</FilterTitle>
        <CategoriesInputContainer>
          {openCategoriesModal ? (
            <FilterInput value={searchText} onChange={handleOnCategoriesSearch} />
          ) : (
            <FilterInput value={currentCategoryTag} onFocus={handleOnCategoriesFocus} />
          )}
          {openCategoriesModal && (
            <>
              <ModalMask onClick={closeCategoriesModal} />
              <CategoriesModal
                tree={tree}
                level1={level1}
                level2={level2}
                level3={level3}
                searchText={searchText}
                localFilter={localFilter}
                setLocalFilter={setLocalFilter}
                onClose={closeCategoriesModal}
              />
            </>
          )}
        </CategoriesInputContainer>
      </Grid>
      <Grid>
        <FilterTitle>銷售頁名稱</FilterTitle>
        <FilterInput value={pageNameQ} onChange={handleOnInputChange("pageNameQ")} />
        <FilterTitle>品牌</FilterTitle>
        <BrandSelect
          mode="multiple"
          allowClear
          filterOption={false}
          onSearch={handleOnBrandSearch}
          onChange={handleOnBrandChange}
          loading={isFetchingBrand}
          value={brandNames}
        >
          {brandOptions}
        </BrandSelect>
        <div />
        <FilterButtonContainer>
          <ClearFilterButton onClick={handleOnClearFilter}>清除篩選條件</ClearFilterButton>
          <Button type="primary" onClick={handleOnSearch}>
            搜尋
          </Button>
        </FilterButtonContainer>
      </Grid>
    </Wrapper>
  );
};

export default AddSalePage;
