import React from "react";
import { Select } from "antd";
import bankData from "@constant/bank.json";
import { FormInstance } from "antd/lib/form";

const { Option } = Select;

type Props = {
  value?: any;
  onChange?: (params: any) => void;
  form: FormInstance;
};

export default function BankList(props: Props) {
  const { value, onChange, form } = props;
  const triggerChange = (bankCode: number) => {
    if (onChange) {
      onChange(bankCode);
      form.setFieldsValue({ bankName: bankCode });
      form.setFieldsValue({ branchName: "" });
    }
  };

  const headBankName = () => {
    return bankData
      .filter((item) => !item.branchCode)
      .map((item) => ({
        bankName: item.bankName,
        bankCode: item.bankCode,
      }));
  };

  return (
    <Select
      showSearch
      value={value || -1}
      style={{ width: "100%" }}
      onChange={triggerChange}
      filterOption={(input, option) => option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
    >
      <Option disabled value={-1} key="bank-1">
        請選擇
      </Option>

      {headBankName().map((info: { bankCode: string; bankName: string }) => (
        <Option value={info.bankCode} key={`bank${info.bankCode}`}>
          {info.bankName}
        </Option>
      ))}
    </Select>
  );
}
