import returnOrderApi from "@api/returnOrderApi";
import { notification } from "antd";
import React, { createContext, useContext } from "react";
import { OrderParams, ReturnOrderFormContext, ReturnOrderItem } from "./interfaces";
import { returnOrderReason } from "./reasonData";

export const OrderFormContext = createContext<ReturnOrderFormContext>({
  returnReason: [],
  isFetching: false,
  updateReturnOrder: () => {},
  createReturnOrder: () => {},
});

export const useReturnOrderFormContext = () => useContext(OrderFormContext);

export default function OrderFormProvider(props: { children: React.ReactNode }) {
  const { children } = props;

  const createReturnOrder = async (params: OrderParams, callback: (orderItem: ReturnOrderItem) => void) => {
    try {
      const details = await returnOrderApi.createReturnOrder(params);

      if (details) {
        callback(details);
      }
    } catch (error: any) {
      notification.error({
        message: `無法建立還貨單 ${error}`,
      });
    }
  };

  const updateReturnOrder = async (params: OrderParams, callback: () => void) => {
    try {
      const details = await returnOrderApi.updateReturnOrder(params);

      if (details) {
        callback();
      }
    } catch (error: any) {
      notification.error({
        message: `無法建立還貨單 ${error}`,
      });
    }
  };

  return (
    <OrderFormContext.Provider
      value={{
        createReturnOrder,
        returnReason: [...returnOrderReason],
        isFetching: false,
        updateReturnOrder,
      }}
    >
      {children}
    </OrderFormContext.Provider>
  );
}
