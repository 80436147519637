import AllStaffList from "@component/AllStaffList";
import { returnOrderState, setReturnOrderListFilter } from "@redux/returnOrderSlice";
import { Button, Col, DatePicker, Input, Row, Select, Space } from "antd";
import moment from "moment";
import React, { ChangeEvent, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Filters } from "./interfaces";
import { returnOrderReason } from "./reasonData";

const WidthStyledSpace = styled(Space)`
  width: 80%;
  .ant-space-item:nth-child(2) {
    flex: 1;
  }
`;

const { RangePicker } = DatePicker;
const { Option } = Select;

type FilterState = {
  orderNumber: string;
  sku: string;
  startEstimateShippingDate: moment.Moment | null;
  endEstimateShippingDate: moment.Moment | null;
  startActualShippingDate: moment.Moment | null;
  endActualShippingDate: moment.Moment | null;
  reasonId: number;
  ordererId: number;
  marketingChannelId: number;
};

export default function FilterForm(props: { open: boolean }) {
  const { open } = props;
  const dispatch = useDispatch();
  const { distributionList } = useSelector(returnOrderState);
  const [filterState, setFilterState] = useState<FilterState>({
    orderNumber: "",
    sku: "",
    startEstimateShippingDate: null,
    endEstimateShippingDate: null,
    startActualShippingDate: null,
    endActualShippingDate: null,
    marketingChannelId: -1,
    reasonId: -1,
    ordererId: -1,
  });

  const applyFilters = (filters: Filters) => {
    dispatch(setReturnOrderListFilter(filters));
  };

  const handleOrderNum = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setFilterState((prevState) => ({
      ...prevState,
      orderNumber: value,
    }));
  };
  const handleSku = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setFilterState((prevState) => ({
      ...prevState,
      sku: value,
    }));
  };

  const handleDate = (type: "actual" | "estimate", date: any) => {
    if (type === "actual") {
      setFilterState((prevState) => ({
        ...prevState,
        startActualShippingDate: date ? date[0] : null,
        endActualShippingDate: date ? date[1] : null,
      }));
    } else if (type === "estimate") {
      setFilterState((prevState) => ({
        ...prevState,
        startEstimateShippingDate: date ? date[0] : null,
        endEstimateShippingDate: date ? date[1] : null,
      }));
    }
  };

  const handleSelect = (type: "staff" | "reason" | "channel", value: any) => {
    switch (type) {
      case "staff":
        setFilterState((prevState) => ({
          ...prevState,
          ordererId: value,
        }));
        break;
      case "reason":
        setFilterState((prevState) => ({
          ...prevState,
          reasonId: value,
        }));
        break;
      case "channel":
        setFilterState((prevState) => ({
          ...prevState,
          marketingChannelId: value,
        }));
        break;
      default:
        break;
    }
  };

  const clearFilter = () => {
    const defaultFilter = {
      orderNumber: "",
      sku: "",
      startEstimateShippingDate: null,
      endEstimateShippingDate: null,
      startActualShippingDate: null,
      endActualShippingDate: null,
      marketingChannelId: -1,
      reasonId: -1,
      ordererId: -1,
    };
    setFilterState(defaultFilter);
    applyFilters({
      orderNumber: undefined,
      sku: undefined,
      startEstimateShippingDate: undefined,
      endEstimateShippingDate: undefined,
      startActualShippingDate: undefined,
      endActualShippingDate: undefined,
      marketingChannelId: undefined,
      reasonId: undefined,
      ordererId: undefined,
    });
  };

  const confirmFilter = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const {
      orderNumber,
      sku,
      startEstimateShippingDate,
      endEstimateShippingDate,
      startActualShippingDate,
      endActualShippingDate,
      marketingChannelId,
      reasonId,
      ordererId,
    } = filterState;
    applyFilters({
      orderNumber,
      sku,
      reasonId,
      ordererId,
      marketingChannelId,
      startEstimateShippingDate: startEstimateShippingDate ? startEstimateShippingDate.format("YYYY-MM-DD") : "",
      endEstimateShippingDate: endEstimateShippingDate ? endEstimateShippingDate.format("YYYY-MM-DD") : "",
      startActualShippingDate: startActualShippingDate ? startActualShippingDate.format("YYYY-MM-DD") : "",
      endActualShippingDate: endActualShippingDate ? endActualShippingDate.format("YYYY-MM-DD") : "",
    });
  };

  return (
    <form style={{ display: open ? "block" : "none", padding: "43px 13px" }} onSubmit={confirmFilter}>
      <Row style={{ marginBottom: 24 }}>
        <Col span={9}>
          <WidthStyledSpace size="middle">
            <span>訂單編號</span>
            <Input onChange={handleOrderNum} placeholder="訂單編號" value={filterState.orderNumber} />
          </WidthStyledSpace>
        </Col>
        <Col span={9}>
          <WidthStyledSpace size="middle">
            <span>國際條碼</span>
            <Input onChange={handleSku} placeholder="國際條碼" value={filterState.sku} />
          </WidthStyledSpace>
        </Col>
      </Row>
      <Row style={{ marginBottom: 24 }}>
        <Col span={9}>
          <Space size="middle">
            <span>實際出貨時間</span>
            <RangePicker
              onChange={(date) => handleDate("actual", date)}
              placeholder={["開始日期", "結束日期"]}
              value={[filterState.startActualShippingDate, filterState.endActualShippingDate]}
              allowClear
            />
          </Space>
        </Col>
        <Col span={9}>
          <Space size="middle">
            <span>預期拋單日期</span>
            <RangePicker
              onChange={(date) => handleDate("estimate", date)}
              placeholder={["開始日期", "結束日期"]}
              value={[filterState.startEstimateShippingDate, filterState.endEstimateShippingDate]}
            />
          </Space>
        </Col>
      </Row>
      <Row justify="space-between">
        <Col span={6}>
          <Space size="middle">
            <span>通路</span>
            <Select
              onChange={(value) => handleSelect("channel", value)}
              style={{ width: "15vw", maxWidth: 188 }}
              value={filterState.marketingChannelId}
            >
              <Option value={-1}>請選擇</Option>
              {distributionList.map((distribution) => (
                <Option value={distribution.id} key={distribution.name}>
                  {distribution.name}
                </Option>
              ))}
            </Select>
          </Space>
        </Col>
        <Col span={7}>
          <Space size="middle">
            <span>訂購緣由</span>
            <Select
              placeholder="請選擇"
              onChange={(value) => handleSelect("reason", value)}
              style={{ width: "15vw", maxWidth: 188 }}
              value={filterState.reasonId}
            >
              <Option value={-1}>請選擇</Option>
              {returnOrderReason.map((reason) => (
                <Option value={reason.value} key={reason.label}>
                  {reason.label}
                </Option>
              ))}
            </Select>
          </Space>
        </Col>
        <Col span={6}>
          <Space size="middle">
            <span>訂購人</span>
            <AllStaffList value={filterState.ordererId} onChange={(value) => handleSelect("staff", value)} />
          </Space>
        </Col>
        <Col span={4}>
          <Space size="middle">
            <Button onClick={clearFilter}>清除篩選條件</Button>
            <Button type="primary" htmlType="submit">
              套用
            </Button>
          </Space>
        </Col>
      </Row>
    </form>
  );
}
