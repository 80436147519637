import PopupBackground from "@component/PopupBackground";
import { Button, Form, Row, Space } from "antd";
import styled from "styled-components";
import React, { useMemo, useEffect, useState, ReactText } from "react";
import { FetchSaleListParams, SaleListItem } from "@api/saleApi";
import { RootState } from "@redux/rootReducer";
import { useAppDispatch } from "src/store";
import { useSelector } from "react-redux";
import { CloseIcon } from "src/styles/common";
import { fetchBrandList, updateSaleListFilter, fetchSaleList, resetSaleList } from "@redux/promotionAddSalesSlice";
import {
  fetchSalePlanListResult,
  resetSalePlanList,
  resetSalePlanListParam,
  updateSalePlanListParam,
} from "@redux/discountFrameSlice";
import { DiscountModule, SalePlanListItem, SalePlanListParams } from "@api/discountFrameApi";
import Title from "antd/lib/typography/Title";
import TableIntegrated from "../../component/Table/TableIntegrated";
import AddSaleFilter from "./AddSaleFilter";

const PopupContent = styled.div`
  max-width: 70vw;
  padding: 20px;
  background: ${({ theme }) => theme.colorNeutral100};
  max-height: 90vh;
  overflow: auto;
  width: 860px;
`;

const TableNoWrap = styled.div`
  white-space: nowrap;
`;

type Props = {
  close: () => void;
  submit: (selectedIds: number[]) => void;
  module: DiscountModule;
};

export default function AddSaleModal(props: Props) {
  const { close, submit, module } = props;
  const dispatch = useAppDispatch();
  const [isSearch, setIsSearch] = useState(false);
  const [selectedSalesPageIds, setSelectSalesPageIds] = useState<number[]>();
  const [form] = Form.useForm();

  const { salePlanListResult, salePlanListParam, isFetching } = useSelector(
    (state: RootState) => state.discountFrameSlice,
  );
  const { saleListResult, isFetchingSaleList, saleListFilter: salePageListParam } = useSelector(
    (state: RootState) => state.promotionAddSales,
  );

  const planTableColumns = useMemo(
    () => [
      {
        title: <TableNoWrap>ID</TableNoWrap>,
        key: "id",
        dataIndex: "id",
      },
      {
        title: <TableNoWrap>SKU</TableNoWrap>,
        key: "options",
        dataIndex: "options",
        render: (value: SalePlanListItem["options"]) => {
          const skus = value.map((option) => option.vpc.sku);
          return skus.map((sku) => <div>{sku}</div>);
        },
      },
      {
        title: <TableNoWrap>名稱</TableNoWrap>,
        key: "pageName",
        dataIndex: "pageName",
      },
      {
        title: <TableNoWrap>品牌</TableNoWrap>,
        key: "brands",
        dataIndex: "brands",
        width: 120,
        render: (value: SalePlanListItem["brands"]) => value.join(", "),
      },
      {
        title: <TableNoWrap>價錢</TableNoWrap>,
        key: "price",
        dataIndex: "price",
      },
    ],
    [],
  );
  const pageTableColumns = useMemo(
    () => [
      {
        title: <TableNoWrap>ID</TableNoWrap>,
        key: "id",
        dataIndex: "id",
      },
      {
        title: <TableNoWrap>SKU</TableNoWrap>,
        key: "skuList",
        dataIndex: "skuList",
        render: (value: SaleListItem["skuList"]) => {
          return (
            <TableNoWrap>
              {value.map((sku) => (
                <p>{sku}</p>
              ))}
            </TableNoWrap>
          );
        },
      },
      {
        title: <TableNoWrap>名稱</TableNoWrap>,
        key: "name",
        dataIndex: "name",
      },
      {
        title: <TableNoWrap>品牌</TableNoWrap>,
        key: "brands",
        dataIndex: "brands",
        width: 120,
        render: (value: SaleListItem["brands"]) => {
          return (
            <div>
              {value.map((brand) => (
                <p>{brand}</p>
              ))}
            </div>
          );
        },
      },
      {
        title: <TableNoWrap>價錢</TableNoWrap>,
        key: "planSpecialPriceRange",
        dataIndex: "planSpecialPriceRange",
        render: (value: SaleListItem["planSpecialPriceRange"]) => {
          const { minPrice, maxPrice } = value;
          return `$${minPrice} - $${maxPrice}`;
        },
      },
    ],
    [],
  );

  const listInfo = useMemo(() => {
    switch (module) {
      case DiscountModule.PLAN: {
        const { offset, limit } = salePlanListParam;
        const { count, results } = salePlanListResult;
        return {
          results,
          count,
          limit,
          offset,
          params: salePlanListParam,
          fetchList: fetchSalePlanListResult,
          updateFilterParams: (filter: SalePlanListParams) => dispatch(updateSalePlanListParam(filter)),
          isFetching,
          tableColumns: planTableColumns,
        };
      }
      case DiscountModule.SALEPAGE: {
        const { offset, limit } = salePageListParam;
        const { count, results } = saleListResult;
        return {
          results,
          count,
          limit,
          offset,
          params: salePageListParam,
          fetchList: fetchSaleList,
          updateFilterParams: (filter: FetchSaleListParams) => dispatch(updateSaleListFilter(filter)),
          isFetching: isFetchingSaleList,
          tableColumns: pageTableColumns,
        };
      }
      default:
        return {
          limit: 20,
          offset: 0,
          results: [],
          count: 0,
          params: {},
          fetchList: () => {},
          updateFilterParams: () => {},
        };
    }
  }, [
    dispatch,
    isFetching,
    isFetchingSaleList,
    module,
    pageTableColumns,
    planTableColumns,
    saleListResult,
    salePageListParam,
    salePlanListParam,
    salePlanListResult,
  ]);

  const handleOnCheck = (value: ReactText[]) => {
    setSelectSalesPageIds(value as number[]);
  };

  const handleSearch = (values: any) => {
    setIsSearch(true);
    if (module === DiscountModule.PLAN) {
      dispatch(
        updateSalePlanListParam({
          ...salePlanListParam,
          ...values,
          brandNamesQ: values.brandNamesQ?.length > 0 ? values.brandNamesQ.join(",") : undefined,
          skus: values.skus?.length > 0 ? values.skus.join(",") : undefined,
        }),
      );
    }
    if (module === DiscountModule.SALEPAGE) {
      dispatch(updateSaleListFilter({ ...salePageListParam, ...values }));
    }
  };
  const handleResetSearch = () => {
    dispatch(resetSalePlanListParam());
  };

  const handleSubmit = () => {
    if (selectedSalesPageIds) submit(selectedSalesPageIds);
    close();
  };

  useEffect(() => {
    if (!isSearch) return;
    if (module === DiscountModule.PLAN) {
      dispatch(fetchSalePlanListResult());
    }
    if (module === DiscountModule.SALEPAGE) {
      dispatch(fetchSaleList());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, module, salePlanListParam, salePageListParam]);

  useEffect(() => {
    dispatch(fetchBrandList(""));
    return () => {
      dispatch(resetSalePlanList());
      dispatch(resetSaleList());
    };
  }, [dispatch]);

  return (
    <PopupBackground close={close} fixed>
      <PopupContent>
        <Title level={4}>新增{module === DiscountModule.PLAN ? "方案" : "銷售頁"}</Title>
        <CloseIcon onClick={close} />
        <AddSaleFilter form={form} onSubmit={handleSearch} onReset={handleResetSearch} />
        <TableIntegrated
          isPagination
          paginationConfig={{
            totalCount: listInfo.count,
            pageSizeOptions: [50, 100, 200],
            offset: listInfo.offset,
            limit: listInfo.limit,
            fetchList: listInfo.fetchList,
            updateFilterParams: listInfo.updateFilterParams,
            filterParams: listInfo.params,
          }}
          dataSource={listInfo.results}
          columns={listInfo.tableColumns}
          scroll={{ y: 240 }}
          rowSelection={{
            onChange: handleOnCheck,
            selectedRowKeys: selectedSalesPageIds,
          }}
          tableLayout="auto"
          loading={listInfo.isFetching}
          rowKey="id"
        />
        <Row justify="end" style={{ marginTop: "10px" }}>
          <Space>
            <Button onClick={close}>取消</Button>
            <Button type="primary" onClick={handleSubmit}>
              儲存
            </Button>
          </Space>
        </Row>
      </PopupContent>
    </PopupBackground>
  );
}
