/* eslint-disable @typescript-eslint/no-unused-vars */
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { PageInfo, SalePageCategoryDetail } from "@api/salePageCategoryApi";
import { DeleteModule, removeMedia } from "@api/uploadMediaApi";
import UploadImageButton from "@component/UploadImageButton";
import { RootState } from "@redux/rootReducer";
import { websiteDomain } from "src/configs";
import {
  clearCategoryDetail,
  createSalesCategory,
  fetchSalesCategoryDetail,
  initialState,
  updateSalesCategory,
} from "@redux/salesCategorySlice";
import { Button, Checkbox, Divider, Form, Input, Modal, Select, Switch } from "antd";
import { showRequiredFieldsWarning } from "@utils/commonMessage";
import { SelectValue } from "antd/lib/select";
import React, { FC, useEffect } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "src/store";
import styled from "styled-components";

const Wrapper = styled.div`
  padding: 12px 12px 86px 22px;
`;
const SectionTitle = styled.div`
  font-size: 16px;
  line-height: 1.5;
  color: ${({ theme }) => theme.colorNeutral600};
  margin-top: 25px;
`;
const SectionDivider = styled(Divider)`
  margin: 12px 0;
`;
const Row = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 15px;
`;
const RowTitle = styled.div`
  flex: 2;
  line-height: 32px;
`;
const FlexStartRowTitle = styled(RowTitle)`
  align-self: flex-start;
`;
const RowContent = styled.div`
  flex: 8;
  display: flex;
`;
const RequiredIcon = styled.span`
  color: red;
`;
const Footer = styled.div`
  position: fixed;
  right: 25px;
  bottom: 0;
  width: 100%;
  display: flex;
  padding: 13px 12px;
  align-items: center;
  justify-content: flex-end;
  background-color: #fafafa;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.05);
`;
const NoResizeTextArea = styled(Input.TextArea)`
  resize: none;
`;
const InputWrapper = styled.div`
  width: 100%;
`;
const StyledSelect = styled(Select)`
  width: 112px;
`;

const DefaultSortMethods = [
  {
    id: 3,
    name: "熱銷",
    isDescend: false,
    isDefault: false,
    isActive: true,
  },
  {
    id: 2,
    name: "最新",
    isDescend: false,
    isDefault: true,
    isActive: true,
  },

  {
    id: 4,
    name: "推薦",
    isDescend: false,
    isDefault: false,
    isActive: true,
  },
  {
    id: 1,
    name: "價格(高至低)",
    isDescend: false,
    isDefault: false,
    isActive: true,
  },
  {
    id: 5,
    name: "價格(低至高)",
    isDescend: false,
    isDefault: false,
    isActive: true,
  },
];

const Div = styled.div``;

const EditTab: FC = () => {
  const [form] = Form.useForm<SalePageCategoryDetail>();
  const categoryDetail = useSelector((state: RootState) => state.salesCategory.categoryDetail);
  const selectedCategory = useSelector((state: RootState) => state.salesCategory.selectedCategory);
  const dispatch = useAppDispatch();

  const { id, parent } = selectedCategory;

  useEffect(() => {
    if (id < 0) {
      // 新增分類 id = -1
      // dispatch(clearCategoryDetail());
    } else {
      dispatch(fetchSalesCategoryDetail(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    form.setFieldsValue(categoryDetail);
  }, [form, categoryDetail]);

  const handleOnSubmit = () => {
    const formValues = form.getFieldsValue();
    const { isActive, name, icon, iconAlt, pageInfo } = formValues;
    const { image, metaTitle, metaKeywords, metaDescription, sortMethods } = pageInfo;

    const params = {
      isActive,
      name,
      parent,
      icon,
      iconAlt,
      image: image.url,
      imageAlt: image.alt,
      metaTitle,
      metaKeywords,
      metaDescription,
      sortMethods: sortMethods.map((item) => ({
        ...item,
        isActive: item.name === "熱銷" ? true : item.isActive,
      })),
    };
    if (id < 0) {
      // id = -1 新增的分類
      dispatch(createSalesCategory(params));
    } else {
      dispatch(updateSalesCategory(params));
    }
  };

  function handleImageUpload(path: string) {
    const { pageInfo } = form.getFieldsValue();
    form.setFieldsValue({
      pageInfo: {
        ...pageInfo,
        image: {
          ...pageInfo.image,
          url: path,
        },
      },
    });
  }

  function handleIconUpload(path: string) {
    form.setFieldsValue({
      icon: path,
    });
  }

  function handleOnSortMethodSelect(value: SelectValue) {
    const { pageInfo } = form.getFieldsValue();
    const { sortMethods } = pageInfo;

    form.setFieldsValue({
      pageInfo: {
        ...pageInfo,
        sortMethods: sortMethods.map((mtd) => ({
          ...mtd,
          isDefault: mtd.name === value,
        })),
      },
    });
  }

  return (
    <Wrapper>
      <Form
        form={form}
        initialValues={initialState.categoryDetail}
        onFinish={handleOnSubmit}
        onFinishFailed={showRequiredFieldsWarning}
      >
        <Row>
          <RowTitle>啟用</RowTitle>
          <RowContent>
            <Form.Item name="isActive" valuePropName="checked">
              <Switch />
            </Form.Item>
          </RowContent>
        </Row>
        <Row>
          <RowTitle>分類ID</RowTitle>
          <RowContent>
            <a href={`${websiteDomain}/category/${categoryDetail.id}?page=1`}> {categoryDetail.id}</a>
          </RowContent>
        </Row>
        <Row>
          <RowTitle>
            分類名稱
            <RequiredIcon>*</RequiredIcon>
          </RowTitle>
          <RowContent>
            <Form.Item name="name" rules={[{ required: true, message: "" }]}>
              <Input />
            </Form.Item>
          </RowContent>
        </Row>
        <SectionTitle>內容</SectionTitle>
        <SectionDivider />
        <Row>
          <FlexStartRowTitle>圖片</FlexStartRowTitle>
          <RowContent>
            <Form.Item name={["pageInfo", "image", "url"]} valuePropName="imageUrl">
              <UploadImageButton
                hasPreviewAndDelete={!!categoryDetail?.pageInfo?.image?.id}
                finishUpload={handleImageUpload}
                onDelete={() =>
                  Modal.confirm({
                    title: "確定要刪除此圖片嗎?",
                    icon: <ExclamationCircleOutlined />,
                    okText: "是",
                    cancelText: "否",
                    onOk() {
                      removeMedia({
                        module: DeleteModule.PAGE_INFO,
                        pageInfoId: categoryDetail?.pageInfo.id,
                        mediaId: categoryDetail?.pageInfo.image.id as number,
                      });
                    },
                  })
                }
              />
            </Form.Item>
            <InputWrapper>
              <Form.Item name={["pageInfo", "image", "alt"]}>
                <Input title="imageAlt" placeholder="請輸入alt" />
              </Form.Item>
            </InputWrapper>
          </RowContent>
        </Row>
        <Row>
          <FlexStartRowTitle>Icon</FlexStartRowTitle>
          <RowContent>
            <Form.Item name="icon" valuePropName="imageUrl">
              <UploadImageButton
                hasPreviewAndDelete={!!categoryDetail?.iconId}
                finishUpload={handleIconUpload}
                onDelete={() =>
                  Modal.confirm({
                    title: "確定要刪除此圖片嗎?",
                    icon: <ExclamationCircleOutlined />,
                    okText: "是",
                    cancelText: "否",
                    onOk() {
                      removeMedia({
                        module: DeleteModule.SALES_CATEGORY,
                        salesCategoryId: categoryDetail.id,
                        mediaId: categoryDetail?.iconId as number,
                      });
                    },
                  })
                }
              />
            </Form.Item>
            <InputWrapper>
              <Form.Item name="iconAlt">
                <Input title="iconAlt" placeholder="請輸入alt" />
              </Form.Item>
            </InputWrapper>
          </RowContent>
        </Row>
        <SectionTitle>SEO</SectionTitle>
        <SectionDivider />
        <Row>
          <RowTitle>Meta Title</RowTitle>
          <RowContent>
            <Form.Item name={["pageInfo", "metaTitle"]}>
              <Input title="metaTitle" />
            </Form.Item>
          </RowContent>
        </Row>
        <Row>
          <FlexStartRowTitle>Meta Keywords</FlexStartRowTitle>
          <RowContent>
            <Form.Item name={["pageInfo", "metaKeywords"]}>
              <NoResizeTextArea rows={3} title="metaKeywords" />
            </Form.Item>
          </RowContent>
        </Row>
        <Row>
          <FlexStartRowTitle>Meta Description</FlexStartRowTitle>
          <RowContent>
            <Form.Item name={["pageInfo", "metaDescription"]}>
              <NoResizeTextArea rows={3} title="metaDescription" />
            </Form.Item>
          </RowContent>
        </Row>
        <SectionTitle>Available Product Listing Sort By</SectionTitle>
        <SectionDivider />
        <Row>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) => {
              return prevValues.pageInfo.sortMethods !== currentValues.pageInfo.sortMethods;
            }}
          >
            {({ getFieldValue }) => {
              const pageInfo: PageInfo = getFieldValue("pageInfo");
              const { sortMethods = DefaultSortMethods } = pageInfo;
              return (
                <Form.List name={["pageInfo", "sortMethods"]}>
                  {(fields) => {
                    return fields.map((field) => (
                      <Form.Item
                        name={[field.name, "isActive"]}
                        fieldKey={[field.fieldKey, "name"]}
                        valuePropName="checked"
                      >
                        <Checkbox disabled={sortMethods[field.key].name === "熱銷"}>
                          {sortMethods[field.key].name}
                        </Checkbox>
                      </Form.Item>
                    ));
                  }}
                </Form.List>
              );
            }}
          </Form.Item>
        </Row>
        <Row>
          <RowTitle>商品排序預設為</RowTitle>
          <RowContent>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) => {
                return prevValues.pageInfo.sortMethods !== currentValues.pageInfo.sortMethods;
              }}
            >
              {({ getFieldValue }) => {
                const pageInfo: PageInfo = getFieldValue("pageInfo");
                const { sortMethods = DefaultSortMethods } = pageInfo;
                const isDefaultSortMethod = sortMethods.find((mtd) => mtd.isDefault);
                return (
                  <StyledSelect value={isDefaultSortMethod?.name} onChange={handleOnSortMethodSelect}>
                    {DefaultSortMethods.map((opt) => (
                      <Select.Option value={opt.name}>{opt.name}</Select.Option>
                    ))}
                  </StyledSelect>
                );
              }}
            </Form.Item>
          </RowContent>
        </Row>
        <Footer>
          <Button type="primary" htmlType="submit">
            {id < 0 ? "建立資料" : "儲存此分頁"}
          </Button>
        </Footer>
      </Form>
    </Wrapper>
  );
};

export default EditTab;
