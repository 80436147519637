import React, { useState, useEffect, ReactText } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import PageTitle from "@component/PageTitle";
import { Button, Pagination, Table, Input, Space, Form, Select, Modal } from "antd";
import { PlusCircleTwoTone, SearchOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import SupplyChainProductPopup from "@page/SupplyChain/SupplyChainProductPopup";
import {
  fetchRetailerProductList,
  fetchFilterRetailerProductList,
  deleteRetailerProduct,
  updateSupplyChainProductSelectedIds,
  batchExportSupplyChainProduct,
} from "@redux/supplyChainSlice";
import { RootState } from "@redux/rootReducer";
import { useSelector } from "react-redux";
import { useAppDispatch } from "src/store";
import { SelectValue } from "antd/lib/select";
import {
  RetailerProductDetail,
  DeleteRetailerProductParam,
  SupplyChainProductParams,
  Currency,
} from "@api/supplyChainApi";
import ImportCSV from "./ImportXLS";

interface IProps {
  clearFilters: () => void;
}

const ButtonBox = styled.div`
  display: flex;
  justify-content: center;
`;

const PageSelect = styled(Select)`
  margin: 0 10px;
`;
const TablePageInfo = styled.div`
  font-size: 14px;
  margin-right: 10px;
`;
const Wrapper = styled.div`
  margin: 20px;
`;
const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 21px;
`;
const ButtonContainer = styled.div`
  display: flex;
  width: 300px;
  justify-content: space-between;
`;

const TableContainer = styled.div`
  position: relative;
  padding: 20px 10px 50px 10px;
  border: 1px solid #f0f0f0;
`;
const TablePagination = styled(Pagination)`
  position: absolute;
  right: 21px;
  bottom: 10px;
`;

const Edit = styled.div`
  white-space: nowrap;
  color: #1790ff;
  cursor: pointer;
`;

const Text = styled.div`
  text-align: left;
`;

const SupplyChainProductList = () => {
  const [form] = Form.useForm();
  const [openPopup, setOpenPopup] = useState<boolean>(false);
  const [currentData, setCurrentData] = useState<RetailerProductDetail | null>(null);
  const [searchText, setSearchText] = useState<string>("");
  const [showImportCSV, setShowImportCSV] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { supplyChainRelationId, currencyNumber } = useParams();
  const supplyChainSlice = useSelector((state: RootState) => state.supplyChain);
  const retailerProductList = supplyChainSlice.retailerProductList.results;
  const { isFetching } = supplyChainSlice;

  const { selectedSupplyChainProductSelectedIds } = supplyChainSlice;
  const { supplyChainFilter } = supplyChainSlice;
  const { limit, offset } = supplyChainFilter;
  const { count } = supplyChainSlice.retailerProductList;
  const handleOnCheck = (values: ReactText[]) => {
    dispatch(updateSupplyChainProductSelectedIds(values as number[]));
  };

  let currencyType;
  if (Number(currencyNumber) === Currency.TWD) {
    currencyType = "TWD";
  } else if (Number(currencyNumber) === Currency.USD) {
    currencyType = "USD";
  } else {
    currencyType = "RMB";
  }

  const currentPage = offset && limit && Math.floor(offset / limit) + 1;

  const handleOnPageSizeChange = (value: SelectValue) => {
    const filter = {
      ...supplyChainFilter,
      limit: value as number,
      supplyChainRetailerProductlineRelation: Number(supplyChainRelationId),
    };
    dispatch(fetchRetailerProductList(filter));
  };
  const handleOnPageChange = (page: number) => {
    if (limit) {
      const filter = {
        ...supplyChainFilter,
        offset: (page - 1) * limit,
        supplyChainRetailerProductlineRelation: Number(supplyChainRelationId),
      };
      dispatch(fetchRetailerProductList(filter));
    }
  };

  useEffect(() => {
    const filter = {
      ...supplyChainFilter,
      supplyChainRetailerProductlineRelation: Number(supplyChainRelationId),
    };
    dispatch(fetchRetailerProductList(filter));
  }, [dispatch, supplyChainFilter, supplyChainRelationId]);

  const getColumnSearchProps = (dataIndex: string) => {
    return {
      filterDropdown: ({ clearFilters }: IProps) => (
        <div style={{ padding: 8 }}>
          <Form form={form}>
            <Form.Item name="input">
              <Input
                onChange={(e) => setSearchText(e.target.value)}
                onPressEnter={() => handleSearch(dataIndex)}
                style={{ width: 170, marginBottom: -15, display: "block" }}
              />
            </Form.Item>
          </Form>
          <ButtonBox>
            <Space>
              <Button
                type="primary"
                onClick={() => handleSearch(dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 80 }}
              >
                搜尋
              </Button>
              <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 80 }}>
                重設
              </Button>
            </Space>
          </ButtonBox>
        </div>
      ),
      filterIcon: (filtered: any) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined, fontSize: "20px" }} />
      ),
      onFilter: (value: string, record: { [x: string]: { toString: () => string } }) =>
        record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    };
  };
  const tableColumns: any = [
    {
      title: "編號",
      dataIndex: "id",
    },
    {
      title: "商品品號",
      dataIndex: "no",
    },
    {
      title: "SKU",
      dataIndex: "sku",
      ...getColumnSearchProps("sku"),
    },
    {
      title: "品名",
      key: "name",
      dataIndex: "name",
      ...getColumnSearchProps("name"),
    },
    {
      title: "商品售價(TWD)",
      key: "price",
      dataIndex: "price",
      render: (data: RetailerProductDetail) => {
        return data ? <Text>{data}</Text> : <Text>N/A</Text>;
      },
      sorter: true,
    },
    {
      title: `商品報價(${currencyType})`,
      key: "quotedPrice",
      dataIndex: "quotedPrice",
      render: (data: RetailerProductDetail) => {
        return data ? <Text>{data}</Text> : <Text>N/A</Text>;
      },
      sorter: true,
    },

    {
      title: "箱入數",
      key: "pcsPerCarton",
      dataIndex: "pcsPerCarton",
      render: (data: RetailerProductDetail) => {
        return data ? <Text>{data}</Text> : <Text>N/A</Text>;
      },
    },
    {
      title: "允收天數",
      key: "canAcceptDays",
      dataIndex: "canAcceptDays",
      render: (data: RetailerProductDetail) => {
        return data ? <Text>{data}</Text> : <Text>N/A</Text>;
      },
    },
    {
      title: "",
      key: "",
      dataIndex: "",
      fixed: "right",
      render: (value: any, data: any) => {
        return <Edit onClick={() => toggleEditPopup(data)}>編輯</Edit>;
      },
    },
    {
      title: "",
      key: "",
      dataIndex: "",
      fixed: "right",
      render: (data: RetailerProductDetail) => {
        const params: DeleteRetailerProductParam = {
          id: data.id,
          supplyChainRetailerProductlineRelation: Number(supplyChainRelationId),
        };
        return <Edit onClick={() => handleDelete(params)}>刪除</Edit>;
      },
    },
  ];

  const handleDelete = (params: DeleteRetailerProductParam) => {
    Modal.confirm({
      title: "你確定要刪除這些資料?",
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: "是",
      cancelText: "否",
      onOk: () => {
        dispatch(deleteRetailerProduct(params));
      },
    });
  };
  const toggleEditPopup = (data: any) => {
    setOpenPopup((prev) => !prev);
    setCurrentData(data);
  };
  const handleSearch = (dataIndex: string) => {
    if (searchText) {
      if (dataIndex === "sku") {
        const filter = {
          supplyChainRetailerProductlineRelation: Number(supplyChainRelationId),
          sku: searchText,
        };
        dispatch(fetchFilterRetailerProductList(filter));
      } else if (dataIndex === "name") {
        const filter = {
          supplyChainRetailerProductlineRelation: Number(supplyChainRelationId),
          nameQ: searchText,
        };
        dispatch(fetchFilterRetailerProductList(filter));
      } else if (dataIndex === "nameEng") {
        const filter = {
          supplyChainRetailerProductlineRelation: Number(supplyChainRelationId),
          nameEngQ: searchText,
        };
        dispatch(fetchFilterRetailerProductList(filter));
      }
    }
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    form.setFieldsValue({ input: " " });
    setSearchText("");
    const filter = {
      supplyChainRetailerProductlineRelation: Number(supplyChainRelationId),
    };
    dispatch(fetchRetailerProductList(filter));
  };

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    const { field, order } = sorter;
    switch (field) {
      case "price":
        switch (order) {
          case "descend":
            filters = {
              ordering: field,
              supplyChainRetailerProductlineRelation: Number(supplyChainRelationId),
            };
            break;
          case "ascend":
            filters = {
              ordering: `-${field}`,
              supplyChainRetailerProductlineRelation: Number(supplyChainRelationId),
            };
            break;
          default:
            filters = {
              ordering: undefined,
              supplyChainRetailerProductlineRelation: Number(supplyChainRelationId),
            };
        }
        dispatch(fetchFilterRetailerProductList(filters));
        break;
      case "quote":
        switch (order) {
          case "descend":
            filters = {
              ordering: field,
              supplyChainRetailerProductlineRelation: Number(supplyChainRelationId),
            };
            break;
          case "ascend":
            filters = {
              ordering: `-${field}`,
              supplyChainRetailerProductlineRelation: Number(supplyChainRelationId),
            };
            break;
          default:
            filters = {
              ordering: undefined,
              supplyChainRetailerProductlineRelation: Number(supplyChainRelationId),
            };
        }
        dispatch(fetchFilterRetailerProductList(filters));
        break;
      case "cost":
        switch (order) {
          case "descend":
            filters = {
              ordering: field,
              supplyChainRetailerProductlineRelation: Number(supplyChainRelationId),
            };
            break;
          case "ascend":
            filters = {
              ordering: `-${field}`,
              supplyChainRetailerProductlineRelation: Number(supplyChainRelationId),
            };
            break;
          default:
            filters = {
              ordering: undefined,
              supplyChainRetailerProductlineRelation: Number(supplyChainRelationId),
            };
        }
        dispatch(fetchFilterRetailerProductList(filters));
        break;
      default:
        break;
    }
  };

  const togglePopup = () => {
    setOpenPopup((prev) => !prev);
    setCurrentData(null);
  };
  const toggleImportCSV = () => {
    setShowImportCSV((prev) => !prev);
  };

  const handleExport = () => {
    const filter: SupplyChainProductParams = {
      productIds: selectedSupplyChainProductSelectedIds.toString(),
    };
    dispatch(batchExportSupplyChainProduct(filter));
  };

  return (
    <Wrapper>
      <PageTitle title="供應商列表" />
      <Header>
        <ButtonContainer>
          <Button type="primary" icon={<PlusCircleTwoTone />} onClick={togglePopup}>
            新增商品
          </Button>
          {openPopup && (
            <SupplyChainProductPopup currentData={currentData} setCurrentData={setCurrentData} onClose={togglePopup} />
          )}
          <Button onClick={toggleImportCSV}>批次匯入</Button>
          <Button onClick={handleExport}>批次匯出</Button>
        </ButtonContainer>
        <TablePageInfo>
          總共
          {count}
          筆, 每頁顯示
          <PageSelect defaultValue="20" onChange={handleOnPageSizeChange}>
            <Select.Option value="20">20</Select.Option>
            <Select.Option value="50">50</Select.Option>
            <Select.Option value="100">100</Select.Option>
          </PageSelect>
          筆
        </TablePageInfo>
      </Header>
      <TableContainer>
        <Table
          loading={isFetching}
          onChange={handleTableChange}
          scroll={{ x: "max-content" }}
          columns={tableColumns}
          dataSource={retailerProductList}
          rowSelection={{
            onChange: handleOnCheck,
            selectedRowKeys: selectedSupplyChainProductSelectedIds,
          }}
          rowKey="id"
          pagination={false}
        />
        <TablePagination
          current={currentPage}
          total={count}
          onChange={handleOnPageChange}
          showSizeChanger={false}
          pageSize={limit}
        />
      </TableContainer>
      {showImportCSV && <ImportCSV onClose={toggleImportCSV} />}
    </Wrapper>
  );
};

export default SupplyChainProductList;
