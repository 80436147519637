/* eslint-disable @typescript-eslint/no-unused-vars */
import { UploadOutlined } from "@ant-design/icons";
import { uploadMedia } from "@api/uploadMediaApi";
import { convertProfitsTitle, MarginAddTopList, ProfitsConversionList } from "@api/vendorApi";
import PopupBackground from "@component/PopupBackground";
import {
  fetchCreateMarginAddTop,
  fetchCreateTransferProfits,
  fetchEditMarginAddTop,
  fetchEditTransferProfits,
  fetchMarginBalanceList,
  vendorState,
} from "@redux/vendorSlice";
import { Button, DatePicker, Form, Input, message, Select, Upload } from "antd";
import locale from "antd/es/date-picker/locale/zh_TW";
import { useForm } from "antd/lib/form/Form";
import { SelectValue } from "antd/lib/select";
import { RcFile } from "antd/lib/upload";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";

const Wrapper = styled(Form)`
  width: 620px;
  background: ${({ theme }) => theme.colorNeutral100};
  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08),
    0px 9px 28px 8px rgba(0, 0, 0, 0.05);
  border-radius: 2px;
  padding: 23px 14px 20px 20px;

  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    order: 1;
  }

  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
    margin: 0;
  }
`;
const ButtonRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 53px;
`;
const CustomInput = styled(Input)`
  width: 200px;
`;
const CustomButton = styled(Button)`
  margin-right: 8px;
`;
const CustomLink = styled(Button)`
  padding-left: 0;
  padding-right: 0;
  margin-right: 0;
  margin-left: 0;
`;
const NoticeText = styled.div`
  margin-left: 5px;
  color: ${({ theme }) => theme.colorSecondary500};
`;
const SelectRow = styled.div`
  display: flex;
`;

export enum MarginEditType {
  CREATE_CONTRACT = "createContract",
  EDIT_MARGIN = "editMargin",
  EDIT_PROFITS = "editProfits",
  STOP_COOPERATION = "stopCooperation",
}

type Props = {
  marginEditType: MarginEditType;
  marginData?: MarginAddTopList;
  profitsData?: ProfitsConversionList;
  onClose: () => void;
};

export default function MarginEditModal(props: Props) {
  const { marginEditType, marginData, profitsData, onClose } = props;
  const { marinBalanceListResult } = useSelector(vendorState);

  const dispatch = useDispatch();

  const { vendorId } = useParams();

  const [pdfUpload, setPdfUpload] = useState<string>("");

  const [createForm] = useForm<{
    type: MarginEditType;
    amount: number;
    pdf: File;
    title: string;
  }>();
  const [marginForm] = useForm<MarginAddTopList & { receiptsDateMoment: moment.Moment }>();
  const [profitsForm] = useForm<ProfitsConversionList>();

  const onEditMarginAddTop = () => {
    if (marginData) {
      const values = marginForm.getFieldsValue();
      const payload = {
        title: "保證金儲值",
        amount: values.amount,
        receiptsDate: values.receiptsDateMoment ? values.receiptsDateMoment.format("YYYY-MM-DD") : undefined,
      };
      dispatch(fetchEditMarginAddTop({ vendorId: parseInt(vendorId, 10), addTopId: marginData.id, payload }));
    } else {
      const values = createForm.getFieldsValue();
      const payload = {
        title: "保證金儲值",
        amount: Number(values.amount),
        pdfDirPrefix:
          pdfUpload && !pdfUpload.startsWith(process.env.REACT_APP_CLOUDFLARE_DOMAIN!)
            ? "vendor/margin/image"
            : undefined,
        pdfFilePath:
          pdfUpload && !pdfUpload.startsWith(process.env.REACT_APP_CLOUDFLARE_DOMAIN!) ? pdfUpload : undefined,
      };
      dispatch(fetchCreateMarginAddTop({ vendorId: parseInt(vendorId, 10), payload }));
    }
  };

  const onEditTransferProfits = () => {
    if (profitsData) {
      const values = profitsForm.getFieldsValue();

      const payload = {
        title: convertProfitsTitle(values.title),
        amount: values.amount,
      };
      dispatch(fetchEditTransferProfits({ vendorId: parseInt(vendorId, 10), profitsId: profitsData.id, payload }));
    } else {
      const values = createForm.getFieldsValue();
      const payload = {
        title: convertProfitsTitle(values.title),
        amount: values.amount,
      };
      dispatch(fetchCreateTransferProfits({ vendorId: parseInt(vendorId, 10), payload }));
    }
  };

  const onStopCooperation = () => {
    const values = createForm.getFieldsValue();
    const payload = {
      title: "保證金餘額退回",
      amount: Number(values.amount),
    };
    dispatch(fetchCreateMarginAddTop({ vendorId: parseInt(vendorId, 10), payload }));
  };

  const handleMarginProfitsService = (type: MarginEditType) => {
    switch (type) {
      case MarginEditType.EDIT_MARGIN:
        onEditMarginAddTop();
        break;
      case MarginEditType.EDIT_PROFITS:
        onEditTransferProfits();
        break;
      case MarginEditType.STOP_COOPERATION:
        onStopCooperation();
        break;
      default:
        break;
    }
    onClose();
  };

  const handleUploadImage = async (file: RcFile) => {
    try {
      const result = await uploadMedia(file);
      setPdfUpload(result);
    } catch (error: any) {
      message.error(error.mesaage);
    }
    return "";
  };

  const handleChange = (value: SelectValue) => {
    if (value === "保證金餘額退回") {
      dispatch(fetchMarginBalanceList(Number(vendorId)));
      createForm.setFieldsValue({ amount: marinBalanceListResult?.balanceSum });
    }
  };

  useEffect(() => {
    if (marginData) {
      marginForm.setFieldsValue({
        ...marginData,
        receiptsDateMoment: marginData.receiptsDate ? moment(marginData.receiptsDate) : undefined,
      });
    }
  }, [marginData, marginForm]);

  useEffect(() => {
    if (profitsData) {
      profitsForm.setFieldsValue(profitsData);
    }
  }, [profitsData, profitsForm]);

  useEffect(() => {
    if (marginEditType === MarginEditType.CREATE_CONTRACT) {
      createForm.setFieldsValue({
        type: MarginEditType.EDIT_MARGIN,
      });
    }
  }, [createForm, marginEditType]);

  useEffect(() => {
    if (createForm.getFieldValue("title") === "保證金餘額退回") {
      createForm.setFieldsValue({ amount: marinBalanceListResult?.balanceSum });
    }
  }, [createForm, marinBalanceListResult?.balanceSum]);

  const onChange = () => {
    createForm.resetFields(["title", "amount"]);
  };

  switch (marginEditType) {
    case MarginEditType.CREATE_CONTRACT:
      return (
        <PopupBackground close={onClose} fixed>
          <Wrapper
            colon={false}
            labelCol={{ span: 4 }}
            labelAlign="left"
            form={createForm}
            onFinish={(values: any) => handleMarginProfitsService(values.type)}
          >
            <Form.Item label="類別" name="type" rules={[{ required: true, message: "必填" }]} shouldUpdate>
              <Select defaultValue={MarginEditType.EDIT_MARGIN} style={{ width: 200 }} onChange={onChange}>
                <Select.Option value={MarginEditType.EDIT_MARGIN}>保證金儲值</Select.Option>
                <Select.Option value={MarginEditType.EDIT_PROFITS}>利潤轉換</Select.Option>
                <Select.Option value={MarginEditType.STOP_COOPERATION}>終止合作</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item shouldUpdate noStyle>
              {({ getFieldValue }) =>
                getFieldValue("type") === MarginEditType.EDIT_PROFITS && (
                  <Form.Item label="項目名稱" name="title" rules={[{ required: true, message: "必填" }]}>
                    <Select style={{ width: 200 }}>
                      <Select.Option value="罰款">罰款</Select.Option>
                      <Select.Option value="版位購買">版位購買</Select.Option>
                      <Select.Option value="行銷合作專案">行銷合作專案</Select.Option>
                      <Select.Option value="帳款折抵">帳款折抵</Select.Option>
                    </Select>
                  </Form.Item>
                )
              }
            </Form.Item>
            <Form.Item shouldUpdate noStyle>
              {({ getFieldValue }) =>
                getFieldValue("type") === MarginEditType.STOP_COOPERATION && (
                  <Form.Item label="項目名稱" name="title" rules={[{ required: true, message: "必填" }]}>
                    <Select style={{ width: 200 }} onChange={(value) => handleChange(value)}>
                      <Select.Option value="保證金餘額退回">保證金餘額退回</Select.Option>
                    </Select>
                  </Form.Item>
                )
              }
            </Form.Item>
            <Form.Item label="金額" name="amount" rules={[{ required: true, message: "必填" }]}>
              <CustomInput />
            </Form.Item>
            <Form.Item shouldUpdate noStyle>
              {({ getFieldValue }) =>
                getFieldValue("type") === MarginEditType.EDIT_MARGIN && (
                  <Form.Item label="憑證附件" name="pdf" rules={[{ required: true, message: "必填" }]}>
                    <Upload action={handleUploadImage}>
                      <Button icon={<UploadOutlined />}>點擊上傳</Button>
                    </Upload>
                  </Form.Item>
                )
              }
            </Form.Item>
            <ButtonRow>
              <CustomButton onClick={onClose}>取消</CustomButton>
              <Button htmlType="submit" type="primary">
                送出
              </Button>
            </ButtonRow>
          </Wrapper>
        </PopupBackground>
      );
    case MarginEditType.EDIT_MARGIN:
      return (
        <PopupBackground close={onClose} fixed>
          <Wrapper
            colon={false}
            labelCol={{ span: 4 }}
            labelAlign="left"
            form={marginForm}
            onFinish={() => handleMarginProfitsService(MarginEditType.EDIT_MARGIN)}
          >
            <Form.Item shouldUpdate noStyle>
              {({ getFieldValue }) => (
                <Form.Item label="建立日期" name="createdAt">
                  <div>{getFieldValue("createdAt")}</div>
                </Form.Item>
              )}
            </Form.Item>
            <Form.Item shouldUpdate noStyle>
              {({ getFieldValue }) => (
                <Form.Item label="類別" name="type">
                  <div>{getFieldValue("title") === "保證金餘額退回" ? "終止合作" : "保證金收取"}</div>
                </Form.Item>
              )}
            </Form.Item>

            <Form.Item shouldUpdate noStyle>
              {({ getFieldValue }) => (
                <Form.Item label="項目名稱" name="title">
                  <div>{getFieldValue("title")}</div>
                </Form.Item>
              )}
            </Form.Item>
            <Form.Item label="金額" name="amount" rules={[{ required: true, message: "必填" }]}>
              <CustomInput />
            </Form.Item>
            <Form.Item shouldUpdate noStyle>
              {({ getFieldValue }) => (
                <>
                  {getFieldValue("title") === "保證金餘額退回" && (
                    <Form.Item
                      label="財務確認退回"
                      name="receiptsDateMoment"
                      validateStatus="warning"
                      help={getFieldValue("receiptsDate") ? "已退回" : "未退回"}
                    >
                      <DatePicker locale={locale} />
                    </Form.Item>
                  )}
                </>
              )}
            </Form.Item>
            <Form.Item shouldUpdate noStyle>
              {({ getFieldValue }) => (
                <>
                  {getFieldValue("title") !== "保證金餘額退回" && (
                    <Form.Item label="財務確認收款" name="receiptsDateMoment">
                      <DatePicker locale={locale} />
                    </Form.Item>
                  )}
                </>
              )}
            </Form.Item>

            <Form.Item shouldUpdate noStyle>
              {({ getFieldValue }) => (
                <>
                  {getFieldValue("title") !== "保證金餘額退回" && (
                    <Form.Item label="憑證附件" name="attachment">
                      {getFieldValue("attachment") ? (
                        <CustomLink type="link" href={marginForm.getFieldValue("attachment")} target="_blank">
                          檢視檔案
                        </CustomLink>
                      ) : (
                        <div>N/A</div>
                      )}
                    </Form.Item>
                  )}
                </>
              )}
            </Form.Item>
            <Form.Item shouldUpdate noStyle>
              {({ getFieldValue }) => (
                <>
                  {getFieldValue("title") !== "保證金餘額退回" && (
                    <Form.Item label="收據開立" name="receipts">
                      {marginForm.getFieldValue("receipts") !== "尚未開立" ? (
                        <CustomLink type="link" href={getFieldValue("receipts")} target="_blank">
                          檢視收據
                        </CustomLink>
                      ) : (
                        <div>尚未開立</div>
                      )}
                    </Form.Item>
                  )}
                </>
              )}
            </Form.Item>
            <ButtonRow>
              <CustomButton onClick={onClose}>取消</CustomButton>
              <Button htmlType="submit" type="primary">
                儲存
              </Button>
            </ButtonRow>
          </Wrapper>
        </PopupBackground>
      );
    case MarginEditType.EDIT_PROFITS:
      return (
        <PopupBackground close={onClose} fixed>
          <Wrapper
            colon={false}
            labelCol={{ span: 4 }}
            labelAlign="left"
            form={profitsForm}
            onFinish={() => handleMarginProfitsService(MarginEditType.EDIT_PROFITS)}
          >
            <Form.Item shouldUpdate noStyle>
              {({ getFieldValue }) => (
                <Form.Item label="建立日期" name="createdDate">
                  <div>{getFieldValue("createdDate")}</div>
                </Form.Item>
              )}
            </Form.Item>
            <Form.Item label="類別" name="type">
              <div>收入轉換</div>
            </Form.Item>
            <Form.Item label="項目名稱" name="title">
              <Select>
                <Select.Option value="罰款">罰款</Select.Option>
                <Select.Option value="版位購買">版位購買</Select.Option>
                <Select.Option value="行銷合作專案">行銷合作專案</Select.Option>
                <Select.Option value="帳款折抵">帳款折抵</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item label="金額" name="amount" rules={[{ required: true, message: "必填" }]}>
              <CustomInput />
            </Form.Item>
            <Form.Item shouldUpdate noStyle>
              {({ getFieldValue }) => (
                <Form.Item label="發票開立" name="invoiceNumber">
                  {getFieldValue("invoiceNumber") ? <div>{getFieldValue("invoiceNumber")}</div> : <div>N/A</div>}
                </Form.Item>
              )}
            </Form.Item>
            <ButtonRow>
              <CustomButton onClick={onClose}>取消</CustomButton>
              <Button htmlType="submit" type="primary">
                儲存
              </Button>
            </ButtonRow>
          </Wrapper>
        </PopupBackground>
      );
    default:
      return null;
  }
}
