import { FilterTwoTone, PlusCircleFilled } from "@ant-design/icons";
import { SalePageListItem } from "@api/salePageCategoryApi";
import DeletePopup from "@component/DeletePopup";
import { RootState } from "@redux/rootReducer";
import {
  batchRemoveSalePages,
  batchUpdateSalePageRank,
  fetchSalePageList,
  fetchSalePageListByLevelCollection,
  resetFilter,
  updateSalePageListFilter,
  updateSelectedPageIds,
} from "@redux/salesCategorySlice";
import { Button, Input, Pagination, Select, Table, Tooltip } from "antd";
import { SelectValue } from "antd/lib/select";
import React, { ChangeEvent, FC, ReactText, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "src/store";
import styled from "styled-components";
import Filter from "./Filter";
import ImportCSV from "./ImportCSV";
import NewSalePagePopup from "./NewSalePagePopup";

const Wrapper = styled.div`
  padding: 12px 21px 38px 0;
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 22px;
`;
const HeaderButtonBlock = styled.div``;
const HeaderPageSizeBlock = styled.div``;
const HeaderButton = styled(Button)`
  margin-right: 10px;
`;
const RankInput = styled(Input)`
  width: 60px;
`;
const Footer = styled.div`
  position: fixed;
  right: 25px;
  bottom: 0;
  width: 100%;
  display: flex;
  padding: 13px 12px;
  align-items: center;
  justify-content: flex-end;
  background-color: #fafafa;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.05);
`;
const PageSizeSelect = styled(Select)`
  margin: 0 10px;
`;
const TableFooter = styled.div`
  margin-top: 28px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

type Props = {
  canAddSalespage: boolean;
};

const PageSizeOptions = [20, 50, 100];

const ManageSaleList: FC<Props> = (props) => {
  const { canAddSalespage } = props;

  const salePageListResult = useSelector((state: RootState) => state.salesCategory.salePageListResult);
  const selectedCategory = useSelector((state: RootState) => state.salesCategory.selectedCategory);
  const salePageListFilter = useSelector((state: RootState) => state.salesCategory.salePageListFilter);
  const selectedPageIds = useSelector((state: RootState) => state.salesCategory.selectedPageIds);
  const isFetchingSalePageList = useSelector((state: RootState) => state.salesCategory.isFetchingSalePageList);
  const dispatch = useAppDispatch();

  const [showAddPopup, setShowAddPopup] = useState<boolean>(false);
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [showImportCSV, setShowImportCSV] = useState<boolean>(false);
  const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);
  const [localSalePageList, setLocalSalePageList] = useState<SalePageListItem[]>([]);

  const { count, results: salePageList } = salePageListResult;
  const { limit, offset } = salePageListFilter;
  const { id } = selectedCategory;

  const currentPage = useMemo(() => {
    return Math.floor(offset / limit) + 1;
  }, [limit, offset]);

  useEffect(() => {
    dispatch(resetFilter());

    if (canAddSalespage) {
      dispatch(fetchSalePageList());
    } else {
      dispatch(fetchSalePageListByLevelCollection());
    }
  }, [id, dispatch, canAddSalespage]);

  useEffect(() => {
    setLocalSalePageList(salePageList);
  }, [salePageList]);

  const handleOnRankChange = (index: number) => (e: ChangeEvent<HTMLInputElement>) => {
    const { valueAsNumber } = e.target;
    setLocalSalePageList((prev) => {
      const newList = [...prev];
      newList[index] = {
        ...newList[index],
        rank: valueAsNumber,
      };
      return newList;
    });
  };

  const handleOnDelete = () => {
    toggleDeletePopup();
    dispatch(batchRemoveSalePages());
  };

  const handleOnSaveClick = () => {
    const salePageListHasRank = localSalePageList.filter((item) => !!item.rank);
    dispatch(batchUpdateSalePageRank({ salePageList: salePageListHasRank, canAddSalespage }));
  };

  const handleOnCheck = (values: ReactText[]) => {
    dispatch(updateSelectedPageIds(values as number[]));
  };

  const handleOnPageSizeChange = (value: SelectValue) => {
    const filter = {
      ...salePageListFilter,
      limit: value as number,
    };

    dispatch(updateSalePageListFilter(filter));
    if (canAddSalespage) {
      dispatch(fetchSalePageList());
    } else {
      dispatch(fetchSalePageListByLevelCollection());
    }
  };

  const handleOnPageChange = (page: number) => {
    const filter = {
      ...salePageListFilter,
      offset: (page - 1) * limit,
    };

    dispatch(updateSalePageListFilter(filter));
    if (canAddSalespage) {
      dispatch(fetchSalePageList());
    } else {
      dispatch(fetchSalePageListByLevelCollection());
    }
  };

  const toggleAddPopup = () => {
    setShowAddPopup((prev) => !prev);
  };

  const toggleShowFilter = () => {
    setShowFilter((prev) => !prev);
  };

  const toggleImportCSV = () => {
    setShowImportCSV((prev) => !prev);
  };

  const toggleDeletePopup = () => {
    setShowDeletePopup((prev) => !prev);
  };

  const tableColumns = [
    {
      title: "站長推薦",
      key: "rank",
      dataIndex: "rank",
      width: 126,
      sorter: () => 0, // antd 需要
      onHeaderCell: (column: any) => {
        return {
          onClick: () => {
            if (column.title.props.title === "Click to sort descending") {
              dispatch(updateSalePageListFilter({ ...salePageListFilter, ordering: "-rank" }));
            } else if (column.title.props.title === "Click to sort ascending") {
              dispatch(updateSalePageListFilter({ ...salePageListFilter, ordering: "rank" }));
            }
            if (canAddSalespage) {
              dispatch(fetchSalePageList());
            } else {
              dispatch(fetchSalePageListByLevelCollection());
            }
          },
        };
      },
      render: (value: number, data: SalePageListItem, index: number) => (
        <RankInput value={value} type="number" onChange={handleOnRankChange(index)} />
      ),
    },
    {
      title: "ID",
      key: "id",
      dataIndex: "id",
      width: 75,
    },
    {
      title: "名稱",
      key: "name",
      dataIndex: "name",
      width: 270,
      render: (value: any) => {
        return <div>{value}</div>;
      },
    },
    {
      title: "展示期間",
      key: "timePeriod",
      dataIndex: "timePeriod",
      width: 300,
      render: (_: any, value: any) => {
        return <div>{`${value.startAt || ""}-${value.endAt || ""}`}</div>;
      },
    },
    {
      title: "SKU",
      key: "skuList",
      dataIndex: "skuList",
      width: 115,
      render: (value: SalePageListItem["skuList"]) => {
        const skuText = value.length > 1 ? `${value[0]} ...` : value[0];
        const skuToolTip = (
          <div>
            {value.map((sku) => (
              <p>{sku}</p>
            ))}
          </div>
        );
        return (
          <Tooltip placement="bottom" title={skuToolTip}>
            {skuText}
          </Tooltip>
        );
      },
    },
    {
      title: "品牌",
      key: "brands",
      dataIndex: "brands",
      width: 175,
      render: (value: SalePageListItem["brands"]) => (
        <div>
          {value.map((brand) => (
            <p>{brand}</p>
          ))}
        </div>
      ),
    },
    {
      title: "價錢",
      key: "planSpecialPriceRange",
      dataIndex: "planSpecialPriceRange",
      width: 125,
      render: (value: SalePageListItem["planSpecialPriceRange"]) => {
        const { minPrice, maxPrice } = value;
        if (minPrice === maxPrice) return `$${value.minPrice}`;
        return `$${value.minPrice}-$${value.maxPrice}`;
      },
    },
  ];

  return (
    <Wrapper>
      <Header>
        <HeaderButtonBlock>
          {canAddSalespage && (
            <HeaderButton type="primary" icon={<PlusCircleFilled />} onClick={toggleAddPopup}>
              新增
            </HeaderButton>
          )}
          <HeaderButton icon={<FilterTwoTone />} onClick={toggleShowFilter}>
            篩選
          </HeaderButton>
          {canAddSalespage && <HeaderButton onClick={toggleImportCSV}>批次匯入</HeaderButton>}
        </HeaderButtonBlock>
        <HeaderPageSizeBlock>
          總共
          {count}
          筆, 每頁顯示
          <PageSizeSelect value={limit} onChange={handleOnPageSizeChange}>
            {PageSizeOptions.map((size) => (
              <Select.Option value={size}>{size}</Select.Option>
            ))}
          </PageSizeSelect>
          筆
        </HeaderPageSizeBlock>
      </Header>
      {showFilter && <Filter canAddSalespage={canAddSalespage} />}
      {canAddSalespage ? (
        <Table
          rowKey="id"
          dataSource={localSalePageList}
          columns={tableColumns}
          scroll={{ x: "max-content", y: 400 }}
          rowSelection={{
            onChange: handleOnCheck,
            selectedRowKeys: selectedPageIds,
          }}
          tableLayout="auto"
          pagination={false}
          loading={isFetchingSalePageList}
        />
      ) : (
        <Table
          dataSource={localSalePageList}
          columns={tableColumns}
          scroll={{ x: "max-content", y: 400 }}
          tableLayout="auto"
          pagination={false}
          loading={isFetchingSalePageList}
        />
      )}
      <TableFooter>
        {canAddSalespage && (
          <Button onClick={toggleDeletePopup} disabled={selectedPageIds.length === 0}>
            刪除
          </Button>
        )}
        <Pagination
          current={currentPage}
          total={count}
          onChange={handleOnPageChange}
          showSizeChanger={false}
          pageSize={limit}
        />
      </TableFooter>
      <Footer>
        <Button type="primary" onClick={handleOnSaveClick}>
          儲存此分頁
        </Button>
      </Footer>
      {showAddPopup && <NewSalePagePopup onClose={toggleAddPopup} />}
      {showImportCSV && <ImportCSV onClose={toggleImportCSV} />}
      {showDeletePopup && <DeletePopup onClose={toggleDeletePopup} onConfirm={handleOnDelete} />}
    </Wrapper>
  );
};

export default ManageSaleList;
