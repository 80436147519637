import CustomError from "@api/utils/CustomeError";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "axios";

type CityRegion = {
  [cityKey: string]: {
    [regionKey: string]: string;
  };
};
export const fetchCityRegion = createAsyncThunk<
  CityRegion,
  undefined,
  {
    rejectValue: CustomError;
  }
>("cityRegion/fetchCityRegion", async (_, thunkAPI) => {
  try {
    const resp = await Axios.get(`${process.env.REACT_APP_CLOUDFLARE_DOMAIN}media/web_assets/postcode.json`);
    return resp.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

interface CityRegionState {
  isPending: boolean;
  isFulfilled: boolean;
  cityRegionJson: CityRegion;
}

const cityRegionSlice = createSlice({
  name: "cityRegion",
  initialState: {
    isPending: false,
    isFulfilled: false,
    cityRegionJson: {},
  } as CityRegionState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCityRegion.pending, (state) => {
        state.isPending = true;
      })
      .addCase(fetchCityRegion.fulfilled, (state, action) => {
        state.isFulfilled = true;
        state.isPending = false;
        state.cityRegionJson = { ...action.payload };
      })
      .addCase(fetchCityRegion.rejected, (state) => {
        state.isFulfilled = false;
        state.isPending = false;
      });
  },
});

export default cityRegionSlice.reducer;
