import { ExclamationCircleOutlined, PlusCircleTwoTone } from "@ant-design/icons";
import { ContractList } from "@api/throughShipmentApi";
import PageTitle from "@component/PageTitle";
import { dealerState, fetchContractList, fetchDeleteContract, setContractFilter } from "@redux/dealerSlice";
import { Button, Modal, Row, Select, Table } from "antd";
import { SelectValue } from "antd/lib/select";
import { ColumnsType } from "antd/lib/table";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import fontStyle from "src/styles/fontStyle";
import styled from "styled-components";
import EditContractPopup from "./EditContractPopup";

const Wrapper = styled.div`
  padding: 20px 20px 20px 25px;

  .ant-table-tbody {
    .ant-table-placeholder > td {
      border: 0;
    }
  }
`;
const PageText = styled.span`
  ${fontStyle("14px", "20px")};
`;
const SelectPageSize = styled(Select)`
  &&& {
    margin: 0 10px;
  }
`;
const TableWrapper = styled.div`
  border: solid 1px #f0f0f0;
  border-radius: 2px;
  padding: 20px 10px 22px 17px;
  margin-top: 20px;
`;
const CustomButton = styled(Button)`
  margin-right: 10px;
`;
const PDFWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;
const ClickPDFRange = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
`;

export default function ContractManagement() {
  const dispatch = useDispatch();
  const { contractFilter, contractList, isFetching } = useSelector(dealerState);

  const [page, setPage] = useState<number>(1);
  const [editContractPopup, setEditContractPopup] = useState<boolean>(false);
  const [editContract, setEditContract] = useState<ContractList | undefined>();

  const setPageLimit = (value: SelectValue) => {
    dispatch(setContractFilter({ ...contractFilter, limit: parseInt(value as string, 10) }));
  };

  const fetchPageChange = (value: number) => {
    window.scrollTo(0, 0);
    setPage(value);
    dispatch(setContractFilter({ ...contractFilter, offset: (value - 1) * contractFilter.limit }));
  };

  const onDeleteContract = (contractId: number) => {
    Modal.confirm({
      title: "你確定要刪除嗎?",
      icon: <ExclamationCircleOutlined />,
      okText: "是",
      cancelText: "否",
      onOk() {
        dispatch(fetchDeleteContract(contractId));
      },
    });
  };

  const columns: ColumnsType<ContractList> = [
    {
      key: "id",
      dataIndex: "id",
      title: "編號",
    },
    {
      key: "name",
      dataIndex: "name",
      title: "合約名稱",
    },
    {
      key: "startAt",
      dataIndex: "startAt",
      title: "合約生效時間",
    },
    {
      key: "endAt",
      dataIndex: "endAt",
      title: "合約結束時間",
    },
    {
      key: "media",
      dataIndex: "media",
      title: "檢視檔案",
      render: (media) => (
        <PDFWrapper>
          <embed src={media} width="23px" height="33px" />
          <ClickPDFRange onClick={() => window.open(media)} />
        </PDFWrapper>
      ),
    },
    {
      key: "buttons",
      dataIndex: "buttons",
      title: "",
      render: (_, values) => (
        <Row justify="end">
          <Button
            type="link"
            onClick={() => {
              setEditContract(values);
              setEditContractPopup(true);
            }}
          >
            檢視/編輯
          </Button>
          <Button type="link" onClick={() => onDeleteContract(values.id)}>
            刪除
          </Button>
        </Row>
      ),
    },
  ];

  useEffect(() => {
    dispatch(fetchContractList(contractFilter));
  }, [dispatch, contractFilter]);

  return (
    <Wrapper>
      <PageTitle title="通路/經銷商 - 合約管理" />
      <Row justify="space-between">
        <div>
          <CustomButton
            type="primary"
            icon={<PlusCircleTwoTone twoToneColor="#1890FF" />}
            onClick={() => setEditContractPopup(true)}
          >
            新增合約
          </CustomButton>
        </div>
        <Row align="middle">
          <PageText>{`總共${contractList.count}筆, 每頁顯示`}</PageText>
          <SelectPageSize defaultValue="20" onChange={setPageLimit}>
            <Select.Option value="20">20</Select.Option>
            <Select.Option value="50">50</Select.Option>
            <Select.Option value="100">100</Select.Option>
          </SelectPageSize>
          <PageText>筆</PageText>
        </Row>
      </Row>
      <TableWrapper>
        <Table
          loading={isFetching}
          columns={columns}
          dataSource={contractList.results}
          pagination={{
            pageSize: 20,
            current: page,
            showSizeChanger: false,
            total: contractList.count,
            onChange: fetchPageChange,
          }}
        />
      </TableWrapper>
      {editContractPopup && (
        <EditContractPopup
          contract={editContract}
          onClose={() => {
            setEditContract(undefined);
            setEditContractPopup(false);
          }}
        />
      )}
    </Wrapper>
  );
}
