import { ExclamationCircleOutlined, UploadOutlined } from "@ant-design/icons";
import { ContractList } from "@api/throughShipmentApi";
import { uploadMedia } from "@api/uploadMediaApi";
import PopupBackground from "@component/PopupBackground";
import { dealerState, fetchCreateContract, fetchUpdateContract, setContractIsFinishEdit } from "@redux/dealerSlice";
import { Button, DatePicker, Form, Input, message, Modal, Row, Upload } from "antd";
import locale from "antd/es/date-picker/locale/zh_TW";
import { RcFile } from "antd/lib/upload";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";

const Wrapper = styled.div`
  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08),
    0px 9px 28px 8px rgba(0, 0, 0, 0.05);
  background: ${({ theme }) => theme.colorNeutral100};
  border-radius: 2px;
  width: 620px;
  padding: 23px 14px 20px 20px;

  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    order: 1;
  }

  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
    margin: 0;
  }

  .ant-upload-span {
    margin-top: 20px;
  }

  .ant-upload-list-item-name {
    overflow: visible;
    white-space: normal;
    text-overflow: initial;
  }
`;
const CustomButton = styled(Button)`
  margin-right: 10px;
`;
const UploadNotice = styled.div`
  font-size: 14px;
  margin-left: 16px;
  color: ${({ theme }) => theme.colorSecondary500};
`;
const WarningText = styled.div`
  font-size: 14px;
  align-self: flex-end;
  color: ${({ theme }) => theme.colorSecondary500};
`;

type Props = {
  contract?: ContractList;
  onClose: () => void;
};

interface FormState {
  name: string;
  startAt: string;
  endAt: string;
}

export default function EditContractPopup(props: Props) {
  const { contract, onClose } = props;

  const dispatch = useDispatch();
  const { isContractTimePeriodRepeat, isFinishEditContract } = useSelector(dealerState);

  const { dealerId } = useParams();

  const [form] = Form.useForm();

  const [stashPDF, setStashPDF] = useState<string>("");
  const [uploadFile, setUploadFile] = useState<any[]>([]); // 沒特別限制type
  const [validateWarning, setValidateWarning] = useState<boolean>(false);

  const onUploadPDF = async (file: RcFile) => {
    try {
      const response = await uploadMedia(file);
      setStashPDF(response);
      setUploadFile([file]);
    } catch (error: any) {
      message.warning(`上傳失敗：${error.message}`);
    }
    return "";
  };

  const onCheckDeletePDF = () => {
    Modal.confirm({
      title: "你確定要刪除嗎?",
      icon: <ExclamationCircleOutlined />,
      okText: "是",
      cancelText: "否",
      onOk() {
        setStashPDF("");
        setUploadFile([]);
        form.setFieldsValue({ uploadPDF: "" });
        message.success("刪除成功");
      },
    });
  };

  const onSubmit = (values: FormState) => {
    if (contract) {
      const payload = {
        name: values.name,
        startAt: moment(values.startAt).format("YYYY-MM-DD"),
        endAt: moment(values.endAt).format("YYYY-MM-DD"),
        contractId: contract.id,
        retailerProductline: parseInt(dealerId, 10),
        pdfDirPrefix: stashPDF ? "retailer-contracts/pdf/" : undefined,
        pdfFilePath: stashPDF || undefined,
      };
      dispatch(fetchUpdateContract(payload));
    } else {
      const payload = {
        name: values.name,
        startAt: moment(values.startAt).format("YYYY-MM-DD"),
        endAt: moment(values.endAt).format("YYYY-MM-DD"),
        retailerProductline: parseInt(dealerId, 10),
        pdfDirPrefix: "retailer-contracts/pdf/",
        pdfFilePath: stashPDF,
      };
      dispatch(fetchCreateContract(payload));
    }
  };

  useEffect(() => {
    if (contract) {
      form.setFieldsValue({
        name: contract.name,
        startAt: moment(contract.startAt),
        endAt: moment(contract.endAt),
        uploadPDF: contract.media,
      });
      setUploadFile([
        {
          uid: contract.id,
          name: contract.name,
          status: "done",
          url: contract.media,
        },
      ]);
    }
  }, [form, contract]);

  useEffect(() => {
    if (isFinishEditContract) {
      if (isContractTimePeriodRepeat) {
        Modal.warning({
          title: "與已建立合約時間重疊",
          content: "請重新填寫合約生效與結束時間",
          okText: "我知道了",
          onOk: () => {
            dispatch(setContractIsFinishEdit(false));
            setValidateWarning(true);
          },
        });
      } else {
        onClose();
      }
    }
  }, [isFinishEditContract, isContractTimePeriodRepeat, onClose, dispatch]);

  return (
    <PopupBackground close={onClose} fixed>
      <Wrapper>
        <Form colon={false} labelAlign="left" labelCol={{ span: 5 }} form={form} initialValues={{}} onFinish={onSubmit}>
          <Form.Item
            label="合約名稱"
            name="name"
            wrapperCol={{ span: 18 }}
            rules={[{ required: true, message: "必填" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="合約生效時間"
            name="startAt"
            wrapperCol={{ span: 10 }}
            validateStatus={validateWarning ? "warning" : "validating"}
            rules={[
              { required: true, message: "必填" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (value && getFieldValue("endAt") && moment(getFieldValue("endAt")).isSame(value, "day")) {
                    setValidateWarning(true);
                    return Promise.reject(new Error(" "));
                  }
                  setValidateWarning(false);
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <DatePicker locale={locale} style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            label="合約結束時間"
            name="endAt"
            wrapperCol={{ span: 10 }}
            validateStatus={validateWarning ? "warning" : "validating"}
            rules={[
              { required: true, message: "必填" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (value && getFieldValue("startAt") && moment(getFieldValue("startAt")).isSame(value, "day")) {
                    setValidateWarning(true);
                    return Promise.reject(new Error(" "));
                  }
                  setValidateWarning(false);
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <DatePicker locale={locale} style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item name="uploadPDF" wrapperCol={{ span: 24 }} rules={[{ required: true, message: "必填" }]}>
            <Upload fileList={uploadFile} action={onUploadPDF} onRemove={onCheckDeletePDF}>
              <Row align="middle">
                <Button icon={<UploadOutlined />}>點擊上傳</Button>
                <UploadNotice>*僅能上傳PDF</UploadNotice>
              </Row>
            </Upload>
          </Form.Item>
          <Row justify={validateWarning ? "space-between" : "end"}>
            {validateWarning && <WarningText>* 合約生效時間與合約結束時間不能重疊</WarningText>}
            <Row align="middle">
              <CustomButton onClick={onClose}>取消</CustomButton>
              <Button type="primary" htmlType="submit">
                確定
              </Button>
            </Row>
          </Row>
        </Form>
      </Wrapper>
    </PopupBackground>
  );
}
