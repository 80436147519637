import React, { FC, useMemo, useEffect } from "react";
import styled from "styled-components";
import { Select, Table } from "antd";
import { SelectValue } from "antd/lib/select";
import { useSelector } from "react-redux";
import { RootState } from "@redux/rootReducer";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "src/store";
import PageTitle from "@component/PageTitle";
import { updateShipmentProgressOrderListParams, fetchShipmentProgressOrderList } from "@redux/dashboardSlice";
import { ShipmentProgressOrderType, ShipmentProgressOrderItem } from "@api/dashboardApi";

const Wrapper = styled.div`
  padding: 20px 30px 56px 40px;
`;
const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 21px;
`;
const HeaderTitle = styled.div`
  font-size: 20px;
  line-height: 1.5;
  color: #374151;
`;
const TablePageInfo = styled.div`
  font-size: 14px;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.65);
`;

const PageSelect = styled(Select)`
  margin: 0 10px;
`;
const TableContainer = styled.div`
  padding-left: 16px;
  margin-bottom: 16px;
`;
const Link = styled.a`
  font-size: 13px;
  color: ${({ theme }) => theme.colorSuccess500};
  cursor: pointer;
  margin: 0;
  text-decoration: underline;
`;

const pageSizeOptions = [20, 50, 100];

const TitleMap = {
  [ShipmentProgressOrderType.TRANSFER_SHIPPING_DELAY]: "轉單配送延遲子訂單數",
  [ShipmentProgressOrderType.WARESHOUSE_SHIPPING_DELAY]: "倉庫配送延遲子訂單數",
  [ShipmentProgressOrderType.WAREHOUSE_SHIPMENT_DELAY]: "倉庫出貨延遲子訂單數",
};

const ShipmentOrderList: FC = () => {
  const dispatch = useAppDispatch();
  const { orderType } = useParams();

  const {
    isFetchingShipmentProgressOrderList,
    shipmentProgressOrderListResult,
    shipmentProgressOrderListParams,
  } = useSelector((state: RootState) => state.dashboard);

  const { count, results: orderList } = shipmentProgressOrderListResult;
  const { offset, limit } = shipmentProgressOrderListParams;

  const currentPage = useMemo(() => {
    return Math.floor(offset / limit) + 1;
  }, [limit, offset]);

  useEffect(() => {
    const params = {
      offset: 0,
      limit: 20,
      orderType: Number(orderType),
    };
    dispatch(updateShipmentProgressOrderListParams(params));
    dispatch(fetchShipmentProgressOrderList());
  }, [dispatch, orderType]);

  const handleOnPageSizeChange = (value: SelectValue) => {
    const filter = {
      ...shipmentProgressOrderListParams,
      limit: value as number,
    };

    dispatch(updateShipmentProgressOrderListParams(filter));
    dispatch(fetchShipmentProgressOrderList());
  };

  const handleOnPageChange = (page: number) => {
    const filter = {
      ...shipmentProgressOrderListParams,
      offset: (page - 1) * limit,
    };

    dispatch(updateShipmentProgressOrderListParams(filter));
    dispatch(fetchShipmentProgressOrderList());
  };

  const tableColumns = [
    {
      title: "ID",
      dataIndex: "id",
      width: 65,
      render: (value: ShipmentProgressOrderItem["id"], data: ShipmentProgressOrderItem) => (
        <Link href={`/order-content/${data.orderId}}`} target="_blank">
          {value}
        </Link>
      ),
    },
    {
      title: "子訂單類型",
      dataIndex: "processingType",
      width: 115,
    },
    {
      title: "子訂單狀態",
      dataIndex: "shippingStatus",
      width: 115,
    },
    {
      title: "建立時間",
      dataIndex: "createdAt",
      width: 190,
      render: (value: string) => value.substring(0, 19),
    },
  ];

  return (
    <Wrapper>
      <PageTitle title={TitleMap[Number(orderType) as ShipmentProgressOrderType]} />
      <Header>
        <HeaderTitle>{TitleMap[Number(orderType) as ShipmentProgressOrderType]}</HeaderTitle>
        <TablePageInfo>
          總共
          {count}
          筆, 每頁顯示
          <PageSelect value={limit} onChange={handleOnPageSizeChange}>
            {pageSizeOptions.map((option) => (
              <Select.Option key={option} value={option}>
                {option}
              </Select.Option>
            ))}
          </PageSelect>
          筆
        </TablePageInfo>
      </Header>
      <TableContainer>
        <Table
          rowKey="id"
          dataSource={orderList}
          columns={tableColumns}
          scroll={{ x: "max-content", y: 400 }}
          tableLayout="auto"
          pagination={{
            current: currentPage,
            total: count,
            onChange: handleOnPageChange,
            showSizeChanger: false,
            pageSize: limit,
          }}
          loading={isFetchingShipmentProgressOrderList}
        />
      </TableContainer>
    </Wrapper>
  );
};

export default ShipmentOrderList;
