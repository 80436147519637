import { ExclamationCircleFilled, PlusCircleFilled } from "@ant-design/icons";
import { PaymentTermsInfo, paymentTermsStatusLabel } from "@api/brandOperation/manufacturerApi";
import PopupBackground from "@component/PopupBackground";
import { Row } from "@page/Finance/OnlineDistributorStatistics/style";
import {
  manufacturerState,
  updatePaymentTermsParams,
  deletePaymentTerms,
  addPaymentTerms,
  updatePaymentTerms,
  fetchPaymentTermsList,
} from "@redux/brandOperation/manufacturerSlice";
import { Modal, Button, Select, Table, Form, message, Input, Col, InputNumber } from "antd";
import { FormInstance } from "antd/lib/form";
import { useForm } from "antd/lib/form/Form";
import { SelectValue } from "antd/lib/select";
import { ColumnsType } from "antd/lib/table";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  FlexBox,
  ContentWrapper,
  Header,
  CustomCenterRow,
  PageText,
  SelectPageSize,
  ModalWrapper,
} from "src/styles/common";

const PaymentTerms = () => {
  const dispatch = useDispatch();
  const { confirm } = Modal;
  const { id } = useParams();
  const manufacturerId = Number(id);
  const { isFetching, paymentTermsList, paymentTermsParams } = useSelector(manufacturerState);
  const { results: paymentList, count: totalCount } = paymentTermsList;
  const [page, setPage] = useState<number>(1);
  const [showAddModel, setShowAddModel] = useState<string | undefined>();
  const [AddModalForm] = useForm();

  const handleOnPageSizeChange = (value: SelectValue) => {
    dispatch(
      updatePaymentTermsParams({
        ...paymentTermsParams,
        limit: Number(value),
      }),
    );
  };
  const handlePageChange = (value: number) => {
    setPage(value);
    dispatch(
      updatePaymentTermsParams({
        ...paymentTermsParams,
        offset: paymentTermsParams.limit * (value - 1),
      }),
    );
  };

  const showDeleteConfirm = (record: PaymentTermsInfo) => {
    confirm({
      title: "確定要刪除此筆資料？",
      icon: <ExclamationCircleFilled />,
      content: `狀態：${paymentTermsStatusLabel.find((item) => item.value === record.status)?.label}
       / 天期：${record.days}
       / 比例：${record.ratio}`,
      okText: "確認",
      okType: "danger",
      cancelText: "取消",
      onOk() {
        dispatch(deletePaymentTerms({ paymentTermsId: record.id!, manufacturerId }));
      },
    });
  };
  const handleOnSubmit = () => {
    const formValues = AddModalForm.getFieldsValue();
    if (showAddModel === "add") {
      dispatch(addPaymentTerms({ ...formValues, manufacturerId }));
    }
    if (showAddModel === "edit") {
      dispatch(updatePaymentTerms({ ...formValues, manufacturerId }));
    }
    AddModalForm.resetFields();
    setShowAddModel(undefined);
  };

  const columns: ColumnsType<PaymentTermsInfo> = [
    {
      key: "status",
      dataIndex: "status",
      title: "狀態",
      align: "center",
      render: (value) => paymentTermsStatusLabel.find((item) => item.value === value)?.label,
    },
    {
      key: "days",
      dataIndex: "days",
      align: "center",
      title: "天期",
    },
    {
      key: "ratio",
      dataIndex: "ratio",
      title: "比例",
      align: "center",
    },
    {
      key: "",
      dataIndex: "",
      title: "",
      render: (_, record) => (
        <FlexBox>
          <Button
            type="link"
            onClick={() => {
              AddModalForm.setFieldsValue(record);
              setShowAddModel("edit");
            }}
          >
            編輯
          </Button>
          <Button type="link" onClick={() => showDeleteConfirm(record)}>
            刪除
          </Button>
        </FlexBox>
      ),
    },
  ];

  useEffect(() => {
    dispatch(fetchPaymentTermsList(manufacturerId));
  }, [dispatch, manufacturerId, paymentTermsParams]);

  return (
    <ContentWrapper>
      <Header>
        <FlexBox>
          <Button
            type="primary"
            icon={<PlusCircleFilled />}
            onClick={() => {
              setShowAddModel("add");
            }}
          >
            新增付款方式
          </Button>
        </FlexBox>
        <CustomCenterRow>
          <PageText>{`總共${totalCount}筆, 每頁顯示`}</PageText>
          <SelectPageSize defaultValue="20" onChange={handleOnPageSizeChange}>
            <Select.Option value="20">20</Select.Option>
            <Select.Option value="50">50</Select.Option>
            <Select.Option value="100">100</Select.Option>
          </SelectPageSize>
          <PageText>筆</PageText>
        </CustomCenterRow>
      </Header>
      <Table
        loading={isFetching}
        scroll={{ x: "max-content", y: 500 }}
        columns={columns}
        dataSource={paymentList}
        rowKey="manufacturerName"
        pagination={{
          pageSize: paymentTermsParams.limit,
          current: page,
          showSizeChanger: false,
          total: totalCount,
          onChange: handlePageChange,
        }}
      />
      {showAddModel && (
        <PaymentTermsAddModal
          form={AddModalForm}
          onClose={() => {
            AddModalForm.resetFields();
            setShowAddModel(undefined);
          }}
          onSubmit={handleOnSubmit}
        />
      )}
    </ContentWrapper>
  );
};

export default PaymentTerms;

const PaymentTermsAddModal = (props: { form: FormInstance; onClose: () => void; onSubmit: () => void }) => {
  const { form, onClose, onSubmit } = props;
  const handleClosePopup = () => {
    onClose();
  };
  const handleOnSubmit = () => {
    onSubmit();
  };

  return (
    <PopupBackground close={handleClosePopup} fixed>
      <ModalWrapper width="350px">
        <Form
          form={form}
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 18 }}
          onFinish={handleOnSubmit}
          onFinishFailed={() => message.error("必填欄位未填寫")}
        >
          <Form.Item label="ID" name="id" noStyle>
            <Input hidden />
          </Form.Item>
          <Row>
            <Col span={23}>
              <Form.Item label="狀態" name="status" rules={[{ required: true, message: "請填入 狀態" }]}>
                <Select placeholder="請選擇" options={paymentTermsStatusLabel} />
              </Form.Item>
            </Col>
            <Col span={1} />
          </Row>
          <Row>
            <Col span={23}>
              <Form.Item label="天期" name="days" rules={[{ required: true, message: "請填入 天期" }]}>
                <InputNumber style={{ width: "100%" }} min={0} />
              </Form.Item>
            </Col>
            <Col span={1}>天</Col>
          </Row>
          <Row>
            <Col span={23}>
              <Form.Item label="比例" name="ratio" rules={[{ required: true, message: "請填入 比例" }]}>
                <InputNumber min={0} max={100} style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={1}>%</Col>
          </Row>

          <FlexBox marginTop="12px">
            <Button onClick={handleClosePopup}>取消</Button>
            <Button type="primary" htmlType="submit">
              確定
            </Button>
          </FlexBox>
        </Form>
      </ModalWrapper>
    </PopupBackground>
  );
};
