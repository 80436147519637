import productApi, {
  ContractModeValue,
  CreateProductVendorParam,
  ProductVendorListItem,
  UpdateProductVendorParam,
  VPCAlertItem,
} from "@api/productApi";
import { SalesChannelType } from "@api/vendorApi";
import InfiniteScrollObserver from "@component/InfiniteScrollObserver";
import PopupBackground from "@component/PopupBackground";

import useDebounce from "@hooks/useDebounce";
import useSalesChannelOptions from "@hooks/useSalesChannelOptions";
import {
  createProductVendor,
  fetchContractList,
  fetchVendorList,
  loadMoreContractList,
  loadMoreVendorList,
  updateContractListParam,
  updateProductVendor,
  updateProductVendorEditPopup,
} from "@redux/productEditSlice";
import { RootState } from "@redux/rootReducer";
import { Button, DatePicker, Form, Input, InputNumber, Select } from "antd";
import { SelectValue } from "antd/lib/select";
import moment, { Moment } from "moment";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "src/store";
import styled from "styled-components";
import ReminderPopup from "./ReminderPopup";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 800px;
  height: 550px;
  padding: 40px 28px 20px 32px;

  background-color: white;
`;
const Flex = styled.div`
  display: flex;
  align-items: flex-start;
`;
const Annotation = styled.span`
  margin-left: 11px;
  font-size: 14px;
  line-height: 32px;
  color: rgba(0, 0, 0, 0.25);
`;

const FormContainer = styled.div``;
const Footer = styled.footer`
  display: flex;
  justify-content: flex-end;
`;
const CancelButton = styled(Button)`
  margin-right: 14px;
`;
const Row = styled.div`
  display: flex;
  align-items: center;
`;
const RowTitle = styled.div`
  align-self: flex-start;
  width: 98px;
  font-size: 14px;
  line-height: 32px;
  color: ${({ theme }) => theme.colorNeutral600};
`;
const RequiredIcon = styled.span`
  color: red;
`;
const RowContent = styled.div<{ width: number }>`
  position: relative;
  width: ${({ width }) => width}px;
`;
const PercentText = styled.p`
  position: absolute;
  top: 0;
  font-size: 14px;
  line-height: 32px;
  right: -18px;
`;
const StyledSelect = styled(Select)`
  width: 100%;
`;
const StyledDatepicker = styled(DatePicker)`
  width: 100%;
`;
const DynamicGrid = styled.div<{ collab?: number }>`
  display: grid;
  padding-right: 190px;
  column-gap: 50px;
  grid-template-areas: ${({ collab }) => {
    switch (collab) {
      case 1: // 國內外買斷
      case 2:
        return `
          "channel-type start-time"
          "collab ."
          "amount-per-box end-time"
          ". buy-in-price"
          ". sell-out-price"
        `;
      case 3: // 寄倉
        return `
          "channel-type start-time"
          "collab ."
          "contract end-time"
          "amount-per-box sell-out-price"
          ". commission"
         `;
      case 4: // 轉單
        return `
         "channel-type start-time"
          "collab ."
          "contract end-time"
          "amount-per-box sell-out-price"
          "delivery-time commission"
        `;
      default:
        return `
          "channel-type start-time"
          "collab ."
          "amount-per-box end-time"
        `;
    }
  }};
`;
const DynamicGridItem = styled.div<{ area: string }>`
  grid-area: ${({ area }) => area};
`;

const modeOptions = [
  {
    label: "國內買斷",
    value: ContractModeValue.DOMESTIC_BUY_OUT,
  },
  {
    label: "國外買斷",
    value: ContractModeValue.FOREIGN_BUY_OUT,
  },
  {
    label: "寄倉",
    value: ContractModeValue.WAREHOUSE_CONSIGN,
  },
  {
    label: "轉單",
    value: ContractModeValue.TRANSFER_ORDER,
  },
];

type FormType = Omit<ProductVendorListItem, "startDate" | "endDate" | "mode" | "salesChannel"> & {
  startDate: Moment;
  endDate: Moment;
  mode: number;
  salesChannel: number;
};

const EditPopup: FC = () => {
  const dispatch = useAppDispatch();
  const { id: productId } = useParams();
  const [form] = Form.useForm<FormType>();
  const salesChannelOptions = useSalesChannelOptions();
  const { mode, productVendorListItem } = useSelector((state: RootState) => state.productEdit.productVendorEditPopup);
  const vendorListResult = useSelector((state: RootState) => state.productEdit.vendorListResult);
  const contractListResult = useSelector((state: RootState) => state.productEdit.contractListResult);

  const defaultCollab = productVendorListItem ? productVendorListItem.mode : undefined;

  const [selectedCollab, setSelectedCollab] = useState<number | undefined>(defaultCollab);
  const [showReminderPopup, setShowReminderPopup] = useState<boolean>(false);
  const [hasContactInfo, setHasContactInfo] = useState<boolean>(false);
  const [subTitle, setSubTitle] = useState<string>();
  const [salePageList, setSalePageList] = useState<VPCAlertItem[]>([]);

  useEffect(() => {
    if (productVendorListItem?.mode) {
      dispatch(
        updateContractListParam({
          limit: 50,
          offset: 0,
          mode: productVendorListItem.mode as number,
        }),
      );
    }

    dispatch(fetchVendorList(""));
    dispatch(fetchContractList());

    if (productVendorListItem) {
      form.setFieldsValue({
        ...productVendorListItem,
        startDate: productVendorListItem.startDate === null ? undefined : moment(productVendorListItem.startDate),
        endDate: productVendorListItem.endDate === null ? undefined : moment(productVendorListItem.endDate),
        salesChannel: productVendorListItem.salesChannel.id,
      });
      if (productVendorListItem.vendorInfo.contactName) {
        setHasContactInfo(true);
      }
    } else {
      form.setFieldsValue({
        startDate: moment(),
      });
    }
  }, [dispatch, form, productVendorListItem]);

  const handleOnVendorLoadMore = useCallback(() => {
    dispatch(loadMoreVendorList());
  }, [dispatch]);

  const handleOnContractLoadMore = useCallback(() => {
    dispatch(loadMoreContractList());
  }, [dispatch]);

  const vendorOptions = useMemo(() => {
    const { next, results } = vendorListResult;
    const options = results.map((brd) => (
      <Select.Option key={brd.id} value={brd.id}>
        {brd.name}
      </Select.Option>
    ));

    if (next) {
      options.push(
        <Select.Option value="loading..." disabled>
          loading...
          <InfiniteScrollObserver callback={handleOnVendorLoadMore} />
        </Select.Option>,
      );
    }

    return options;
  }, [vendorListResult, handleOnVendorLoadMore]);

  const contractOptions = useMemo(() => {
    const { next, results: contractList } = contractListResult;

    const filteredOptions = contractList.filter((item) => item.mode === selectedCollab);

    const options = filteredOptions.map((contract) => (
      <Select.Option key={contract.id} value={contract.id}>
        {contract.displayName}
      </Select.Option>
    ));

    if (next) {
      options.push(
        <Select.Option value="loading..." disabled>
          loading...
          <InfiniteScrollObserver callback={handleOnContractLoadMore} />
        </Select.Option>,
      );
    }

    return options;
  }, [contractListResult, handleOnContractLoadMore, selectedCollab]);

  const verifyVPCPrice = async (vpcId: number, price: number) => {
    try {
      const response = await productApi.verifyVPCChangeAlert(vpcId, price);
      return response.result;
    } catch (error: any) {
      return error;
    }
  };

  const handleOnSelectMode = (value: SelectValue) => {
    setSelectedCollab(value as number); // force rerender
    form.setFieldsValue({
      mode: value as number,
    });
    dispatch(
      updateContractListParam({
        limit: 50,
        offset: 0,
        mode: value as number,
      }),
    );
  };

  const handleOnVendorSearch = useDebounce((value: string) => {
    dispatch(fetchVendorList(value));
  }, 300);

  const handleOnSelectVendor = (value: SelectValue) => {
    const { results: vendorList } = vendorListResult;
    const option = vendorList.find((vdr) => vdr.id === value)!;
    const vendorInfo = {
      id: option.id,
      name: option.name,
      phone: option.phone,
      contactName: option?.majorContact?.name,
    };
    form.setFieldsValue({
      vendorInfo,
    });
    if (option?.majorContact?.name) {
      setHasContactInfo(true);
    } else {
      setHasContactInfo(false);
    }
  };

  const toggleReminderPopup = () => setShowReminderPopup((prev) => !prev);

  const handleOnConfirmUpdate = () => {
    const values = form.getFieldsValue();

    const { results: contractList } = contractListResult;
    // 買斷合約自動帶入
    const contract =
      values.mode === 1 || values.mode === 2
        ? contractList.find((ctrt) => ctrt.mode === values.mode)?.id
        : values.contract;
    const vendorListItemId = productVendorListItem?.id!;

    const params: UpdateProductVendorParam = {
      vendor: values.vendorInfo.id,
      product: Number(productId),
      contract,
      startDate: values.startDate ? moment(values.startDate).format("YYYY-MM-DD") : undefined, // can be null or undefined
      endDate: values.endDate ? moment(values.endDate).format("YYYY-MM-DD") : null,
      pcsPerCarton: values.pcsPerCarton,
      price: values.price,
      commissionRate: values.commissionRate,
      cost: values.cost,
      deliveryDays: values.deliveryDays,
      salesChannel: values.salesChannel,
    };

    if (showReminderPopup) setShowReminderPopup(false);
    dispatch(
      updateProductVendor({
        vendorListItemId,
        params,
      }),
    );
  };

  const handleOnSubmit = async () => {
    const values = form.getFieldsValue();
    const { results: contractList } = contractListResult;
    // 買斷合約自動帶入
    const contract =
      values.mode === 1 || values.mode === 2
        ? contractList.find((ctrt) => ctrt.mode === values.mode)?.id
        : values.contract;
    const params: CreateProductVendorParam = {
      vendor: values.vendorInfo.id,
      product: Number(productId),
      contract,
      startDate: values.startDate ? moment(values.startDate).format("YYYY-MM-DD") : undefined, // can be null or undefined
      endDate: values.endDate ? moment(values.endDate).format("YYYY-MM-DD") : null,
      pcsPerCarton: values.pcsPerCarton,
      price: values.price,
      commissionRate: values.commissionRate,
      cost: values.cost,
      deliveryDays: values.deliveryDays,
      salesChannel: values.salesChannel,
    };
    switch (mode) {
      case "add":
        dispatch(createProductVendor(params));
        break;
      case "edit":
        if (!productVendorListItem) return;
        if (productVendorListItem.price !== values.price) {
          verifyVPCPrice(productVendorListItem.id, values.price).then((response) => {
            const { errorMsg, data } = response;
            setSubTitle(errorMsg);
            setSalePageList(data);
          });
          toggleReminderPopup();
          return;
        }
        handleOnConfirmUpdate();
        break;
      default:
    }
  };

  const disabledStartDate = (current: Moment) => {
    switch (mode) {
      case "add":
        return current < moment().subtract(1, "day");
      case "edit": {
        const { startDate } = productVendorListItem!;
        if (moment(startDate) < moment()) return current > moment().subtract(1, "day");
        return current < moment().subtract(1, "day");
      }
      default:
        return false;
    }
  };

  const disabledEndDate = (current: Moment) => {
    return current < moment().subtract(1, "day");
  };

  const closeEditPopup = () => {
    dispatch(
      updateProductVendorEditPopup({
        show: false,
        mode: "add",
      }),
    );
  };

  return (
    <PopupBackground close={closeEditPopup} fixed>
      <Form form={form} onFinish={handleOnSubmit}>
        <Wrapper>
          <FormContainer>
            <Row>
              <RowTitle>
                廠商名稱
                <RequiredIcon>*</RequiredIcon>
              </RowTitle>
              <RowContent width={395}>
                <Form.Item noStyle name={["vendorInfo", "id"]} />
                <Form.Item
                  name={["vendorInfo", "name"]}
                  rules={[
                    () => ({
                      validator(rule, value) {
                        if (value && hasContactInfo) return Promise.resolve();
                        return Promise.reject();
                      },
                      message: "需選擇有主要聯絡人的廠商",
                    }),
                  ]}
                >
                  <StyledSelect
                    showSearch
                    onChange={handleOnSelectVendor}
                    filterOption={false}
                    onSearch={handleOnVendorSearch}
                    disabled={mode === "edit"}
                  >
                    {vendorOptions}
                  </StyledSelect>
                </Form.Item>
              </RowContent>
            </Row>
            <Row>
              <RowTitle>主要聯絡人</RowTitle>
              <Flex>
                <RowContent width={200}>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) => prevValues.vendorInfo !== currentValues.vendorInfo}
                  >
                    <Form.Item name={["vendorInfo", "contactName"]}>
                      <Input disabled />
                    </Form.Item>
                  </Form.Item>
                </RowContent>
                <Annotation>*選擇廠商後自動帶入</Annotation>
              </Flex>
            </Row>
            <Row>
              <RowTitle>廠商電話</RowTitle>
              <Flex>
                <RowContent width={200}>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) => prevValues.vendorInfo !== currentValues.vendorInfo}
                  >
                    <Form.Item name={["vendorInfo", "phone"]}>
                      <Input disabled />
                    </Form.Item>
                  </Form.Item>
                </RowContent>
                <Annotation>*選擇廠商後自動帶入</Annotation>
              </Flex>
            </Row>
            <DynamicGrid collab={selectedCollab}>
              <DynamicGridItem area="channel-type">
                <Row>
                  <RowTitle>
                    通路
                    <RequiredIcon>*</RequiredIcon>
                  </RowTitle>
                  <RowContent width={200}>
                    <Form.Item name="salesChannel" rules={[{ required: true, message: "" }]}>
                      <StyledSelect placeholder="請選擇" options={salesChannelOptions} disabled={mode === "edit"} />
                    </Form.Item>
                  </RowContent>
                </Row>
              </DynamicGridItem>
              <DynamicGridItem area="collab">
                <Row>
                  <RowTitle>
                    合作模式
                    <RequiredIcon>*</RequiredIcon>
                  </RowTitle>
                  <RowContent width={200}>
                    <Form.Item noStyle shouldUpdate={(prev, curr) => prev.salesChannel !== curr.salesChannel}>
                      {({ getFieldsValue }) => {
                        const { salesChannel } = getFieldsValue();
                        return (
                          <Form.Item name="mode" rules={[{ required: true, message: "" }]}>
                            <StyledSelect
                              placeholder="請選擇"
                              onChange={handleOnSelectMode}
                              options={modeOptions.filter((option) => {
                                if (salesChannel === SalesChannelType.STORE_DIRECT) {
                                  return option.value !== ContractModeValue.TRANSFER_ORDER;
                                }
                                return option;
                              })}
                              disabled={mode === "edit" || !salesChannel}
                            />
                          </Form.Item>
                        );
                      }}
                    </Form.Item>
                  </RowContent>
                </Row>
              </DynamicGridItem>
              <DynamicGridItem area="amount-per-box">
                <Row>
                  <RowTitle>
                    箱入數
                    {selectedCollab !== 4 && <RequiredIcon>*</RequiredIcon>}
                  </RowTitle>
                  <RowContent width={200}>
                    <Form.Item name="pcsPerCarton" rules={[{ required: selectedCollab !== 4, message: "" }]}>
                      <InputNumber precision={0} />
                    </Form.Item>
                  </RowContent>
                </Row>
              </DynamicGridItem>
              <DynamicGridItem area="start-time">
                <Row>
                  <RowTitle>起始時間</RowTitle>
                  <RowContent width={200}>
                    <Form.Item
                      name="startDate"
                      rules={[
                        () => ({
                          validator(rule, value) {
                            if (!value) return Promise.resolve();
                            if (
                              (value as Moment).diff(form.getFieldValue("endDate")) <= 0 ||
                              !form.getFieldValue("endDate") // 沒選結束時間
                            ) {
                              return Promise.resolve();
                            }
                            return Promise.reject();
                          },
                        }),
                      ]}
                    >
                      <StyledDatepicker disabledDate={disabledStartDate} />
                    </Form.Item>
                  </RowContent>
                </Row>
              </DynamicGridItem>
              <DynamicGridItem area="end-time">
                <Row>
                  <RowTitle>結束時間</RowTitle>
                  <RowContent width={200}>
                    <Form.Item
                      name="endDate"
                      rules={[
                        () => ({
                          validator(rule, value) {
                            if (
                              !form.getFieldValue("endDate") ||
                              (value as Moment).diff(form.getFieldValue("startDate")) >= 0
                            ) {
                              return Promise.resolve();
                            }
                            return Promise.reject();
                          },
                        }),
                      ]}
                    >
                      <StyledDatepicker disabledDate={disabledEndDate} />
                    </Form.Item>
                  </RowContent>
                </Row>
              </DynamicGridItem>
              {selectedCollab && selectedCollab >= 0 && (
                <DynamicGridItem area="sell-out-price">
                  <Row>
                    <RowTitle>
                      售價
                      <RequiredIcon>*</RequiredIcon>
                    </RowTitle>
                    <RowContent width={200}>
                      <Form.Item name="price" rules={[{ required: true, message: "" }]}>
                        <InputNumber precision={2} />
                      </Form.Item>
                    </RowContent>
                  </Row>
                </DynamicGridItem>
              )}
              {(selectedCollab === 3 || selectedCollab === 4) && (
                <DynamicGridItem area="contract">
                  <Row>
                    <RowTitle>
                      合作合約
                      <RequiredIcon>*</RequiredIcon>
                    </RowTitle>
                    <RowContent width={200}>
                      <Form.Item
                        name={["contract"]}
                        rules={[
                          () => ({
                            validator(rule, value) {
                              if (value >= 0) return Promise.resolve();
                              return Promise.reject();
                            },
                          }),
                        ]}
                      >
                        <StyledSelect>{contractOptions}</StyledSelect>
                      </Form.Item>
                    </RowContent>
                  </Row>
                </DynamicGridItem>
              )}
              {(selectedCollab === 1 || selectedCollab === 2) && (
                <DynamicGridItem area="buy-in-price">
                  <Row>
                    <RowTitle>
                      進價
                      <RequiredIcon>*</RequiredIcon>
                    </RowTitle>
                    <RowContent width={200}>
                      <Form.Item name="cost" rules={[{ required: true, message: "" }]}>
                        <InputNumber precision={2} />
                      </Form.Item>
                    </RowContent>
                  </Row>
                </DynamicGridItem>
              )}
              {(selectedCollab === 3 || selectedCollab === 4) && (
                <DynamicGridItem area="commission">
                  <Row>
                    <RowTitle>
                      抽成
                      <RequiredIcon>*</RequiredIcon>
                    </RowTitle>
                    <RowContent width={200}>
                      <Form.Item
                        name="commissionRate"
                        rules={[
                          { required: true, message: "" },
                          () => ({
                            validator(rule, value) {
                              if (value >= 0 && value <= 100) return Promise.resolve();
                              return Promise.reject();
                            },
                          }),
                        ]}
                      >
                        <InputNumber precision={2} />
                      </Form.Item>
                      <PercentText>%</PercentText>
                    </RowContent>
                  </Row>
                </DynamicGridItem>
              )}
              {selectedCollab === 4 && (
                <DynamicGridItem area="delivery-time">
                  <Row>
                    <RowTitle>
                      出貨時間(天)
                      <RequiredIcon>*</RequiredIcon>
                    </RowTitle>
                    <RowContent width={200}>
                      <Form.Item name="deliveryDays" rules={[{ required: true, message: "" }]}>
                        <InputNumber precision={0} />
                      </Form.Item>
                    </RowContent>
                  </Row>
                </DynamicGridItem>
              )}
            </DynamicGrid>
          </FormContainer>
          <Footer>
            <CancelButton onClick={closeEditPopup}>取消</CancelButton>
            <Button type="primary" htmlType="submit">
              確定
            </Button>
          </Footer>
        </Wrapper>
      </Form>
      {showReminderPopup && (
        <ReminderPopup
          mode="edit"
          subTitle={subTitle}
          salePageList={salePageList}
          onClose={toggleReminderPopup}
          onConfirm={handleOnConfirmUpdate}
        />
      )}
    </PopupBackground>
  );
};

export default EditPopup;
