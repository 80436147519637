import { ExclamationCircleOutlined, FilterTwoTone, PlusCircleTwoTone } from "@ant-design/icons";
import { convertContractType, convertRegionType, DealerList, RegionType } from "@api/throughShipmentApi";
import PageTitle from "@component/PageTitle";
import PopupBackground from "@component/PopupBackground";
import { dealerState, fetchDealerList, fetchDeleteDealer, resetDealerSlice, setDealerFilter } from "@redux/dealerSlice";
import { Button, Modal, Radio, Row, Select, Space, Table } from "antd";
import { SelectValue } from "antd/lib/select";
import { ColumnsType } from "antd/lib/table";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import fontStyle from "src/styles/fontStyle";
import styled from "styled-components";
import DealerFilter from "./DealerFilter";

const Wrapper = styled.div`
  padding: 20px 20px 20px 25px;
`;
const PageText = styled.span`
  ${fontStyle("14px", "20px")};
`;
const SelectPageSize = styled(Select)`
  &&& {
    margin: 0 10px;
  }
`;
const TableWrapper = styled.div`
  border: solid 1px #f0f0f0;
  border-radius: 2px;
  padding: 20px 10px 22px 17px;
  margin-top: 20px;
`;
const CustomButton = styled(Button)`
  margin-right: 10px;
`;
const PopupWrapper = styled.div`
  width: 364px;
  padding: 20px;
  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08),
    0px 9px 28px 8px rgba(0, 0, 0, 0.05);
  border-radius: 2px;
  background: ${({ theme }) => theme.colorNeutral100};
`;
const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 14px;
`;
const ButtonWrapper = styled(Row)`
  margin-top: 24px;
`;

type Props = {
  onClose: () => void;
};

const AskShipmentPopup = (props: Props) => {
  const { onClose } = props;

  const [regionType, setRegionType] = useState<RegionType>(RegionType.DOMESTIC);

  return (
    <PopupBackground close={onClose} fixed>
      <PopupWrapper>
        <Title>請選擇新增合作通路類型</Title>
        <Radio.Group value={regionType} onChange={(e) => setRegionType(e.target.value)}>
          <Space direction="vertical">
            <Radio value={RegionType.DOMESTIC}>台灣本地通路</Radio>
            <Radio value={RegionType.FOREIGN}>境外通路</Radio>
          </Space>
        </Radio.Group>
        <ButtonWrapper justify="end">
          <CustomButton onClick={onClose}>取消</CustomButton>
          <CustomButton
            type="primary"
            onClick={() => {
              window.open(`${window.location.href}/new?regionType=${regionType}`);
              onClose();
            }}
          >
            確認
          </CustomButton>
        </ButtonWrapper>
      </PopupWrapper>
    </PopupBackground>
  );
};

export default function IntoWarehouse() {
  const dispatch = useDispatch();
  const { dealerList, dealerFilter, isFetching } = useSelector(dealerState);

  const [selectShipmentPopup, setSelectShipmentPopup] = useState<boolean>(false);
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);

  const setPageLimit = (value: SelectValue) => {
    dispatch(setDealerFilter({ ...dealerFilter, limit: parseInt(value as string, 10) }));
  };

  const fetchPageChange = (value: number) => {
    window.scrollTo(0, 0);
    setPage(value);
    dispatch(setDealerFilter({ ...dealerFilter, offset: (value - 1) * dealerFilter.limit }));
  };

  const onDeleteDealer = (dealerId: number) => {
    Modal.confirm({
      title: "你確定要刪除嗎?",
      icon: <ExclamationCircleOutlined />,
      okText: "是",
      cancelText: "否",
      onOk() {
        dispatch(fetchDeleteDealer(dealerId));
      },
    });
  };

  const columns: ColumnsType<DealerList> = [
    {
      key: "id",
      dataIndex: "id",
      title: "編號",
    },
    {
      key: "retailerType",
      dataIndex: "retailerType",
      title: "合作通路類型",
      render: (_, values) => <div>{convertRegionType(values.retailer.region.regionType)}</div>,
    },
    {
      key: "region",
      dataIndex: "region",
      title: "地區",
      render: (_, values) => <div>{values.retailer.region.name}</div>,
    },
    {
      key: "retailerName",
      dataIndex: "retailerName",
      title: "通路名稱",
      render: (_, values) => <div>{values.retailer.name}</div>,
    },
    {
      key: "contractType",
      dataIndex: "contractType",
      title: "合作模式",
      render: (_, values) => <div>{convertContractType(values.contractType)}</div>,
    },
    {
      key: "productLineType",
      dataIndex: "productLineType",
      title: "線別",
      render: (_, values) => <div>{values.productLine.name}</div>,
    },
    {
      key: "buttons",
      dataIndex: "buttons",
      title: "",
      render: (_, values) => (
        <Row justify="end">
          <Button type="link" onClick={() => window.open(`${window.location.href}/${values.id}`)}>
            檢視 / 編輯
          </Button>
          <Button type="link" onClick={() => onDeleteDealer(values.id)}>
            刪除
          </Button>
        </Row>
      ),
    },
  ];

  useEffect(() => {
    dispatch(fetchDealerList(dealerFilter));
  }, [dispatch, dealerFilter]);

  useEffect(() => {
    return () => {
      dispatch(resetDealerSlice());
    };
  }, [dispatch]);

  return (
    <Wrapper>
      <PageTitle title="通路/經銷商" />
      <Row justify="space-between">
        <div>
          <CustomButton
            type="primary"
            icon={<PlusCircleTwoTone twoToneColor="#1890FF" />}
            onClick={() => setSelectShipmentPopup(true)}
          >
            新增合作通路
          </CustomButton>
          <Button icon={<FilterTwoTone />} onClick={() => setShowFilter(!showFilter)}>
            篩選
          </Button>
        </div>
        <Row align="middle">
          <PageText>{`總共${dealerList.count}筆, 每頁顯示`}</PageText>
          <SelectPageSize defaultValue="20" onChange={setPageLimit}>
            <Select.Option value="20">20</Select.Option>
            <Select.Option value="50">50</Select.Option>
            <Select.Option value="100">100</Select.Option>
          </SelectPageSize>
          <PageText>筆</PageText>
        </Row>
      </Row>
      {showFilter && <DealerFilter isVisible={showFilter} />}
      <TableWrapper>
        <Table
          loading={isFetching}
          columns={columns}
          dataSource={dealerList.results}
          pagination={{
            pageSize: dealerFilter.limit,
            current: page,
            showSizeChanger: false,
            total: dealerList.count,
            onChange: fetchPageChange,
          }}
        />
      </TableWrapper>
      {selectShipmentPopup && <AskShipmentPopup onClose={() => setSelectShipmentPopup(false)} />}
    </Wrapper>
  );
}
