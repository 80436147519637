import ruleApi from "@api/ruleApi";
import PopupBackground from "@component/PopupBackground";
import { configState } from "@redux/configSlice";
import { RootState } from "@redux/rootReducer";
import { Button, InputNumber } from "antd";
import { FormInstance } from "antd/lib/form";
import React, { FC, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import type { LocalFormType } from "..";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  width: 600px;
  height: 200px;
  background-color: white;
  padding: 32px 28px 20px 28px;
`;
const Row = styled.div`
  display: flex;
  align-items: center;
`;
const RowTitle = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.colorNeutral600};
  margin-right: 33px;
`;
const Red = styled.span`
  color: red;
`;
const RowContent = styled.div`
  width: 395px;
`;
const Footer = styled.div`
  margin-top: auto;
  display: flex;
  justify-content: flex-end;
`;
const CancelButton = styled(Button)`
  margin-right: 10px;
`;
const StyledInputNumber = styled(InputNumber)`
  width: 100%;
`;

interface Props {
  onClose: () => void;
  form: FormInstance<LocalFormType>;
}
const CreateSingleSalePromotion: FC<Props> = (props) => {
  const { onClose, form } = props;
  const {
    ruleDetail: { id: ruleId },
  } = useSelector((state: RootState) => state.rule);
  const { webDomain } = useSelector(configState);

  const [inputValue, setInputValue] = useState<number>();

  const handleOnChange = (value: string | number | undefined) => {
    setInputValue(value as number);
  };

  const handleOnSubmit = async () => {
    if (ruleId && inputValue) {
      try {
        const response = await ruleApi.createSingleSalePromotion(ruleId, inputValue);
        const { id, title, tag } = response;
        form.setFieldsValue({
          promotionInfo: {
            id: id!,
            title,
            tag,
          },
          destinationLink: `${webDomain}/salespage/${inputValue}`,
        });

        onClose();
        return "success";
      } catch (error: any) {
        return "error";
      }
    }
    return "error";
  };

  return (
    <PopupBackground close={onClose} fixed>
      <Wrapper>
        <Row>
          <RowTitle>
            銷售頁id
            <Red>*</Red>
          </RowTitle>
          <RowContent>
            <StyledInputNumber value={inputValue} onChange={handleOnChange} onPressEnter={handleOnSubmit} />
          </RowContent>
        </Row>
        <Footer>
          <CancelButton onClick={onClose}>取消</CancelButton>
          <Button type="primary" onClick={handleOnSubmit}>
            確定
          </Button>
        </Footer>
      </Wrapper>
    </PopupBackground>
  );
};

export default CreateSingleSalePromotion;
