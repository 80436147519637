import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import InfiniteScrollObserver from "@component/InfiniteScrollObserver";
import { Button, Form, Select, Space } from "antd";
import PopupBackground from "@component/PopupBackground";
import useDebounce from "@hooks/useDebounce";
import { useAppDispatch } from "src/store";
import { useSelector } from "react-redux";
import { RootState } from "@redux/rootReducer";
import { fetchBrandList, loadMoreBrandList } from "@redux/productSlice";
import { batchAddSalePages } from "@redux/promotionAddSalesSlice";
import PromotionBindType from "@constant/PromotionBindType";
import { SelectValue } from "antd/lib/select";
import { FetchListParam } from "@api/promotionApi";

type Props = {
  onClose: () => void;
};

const Wrapper = styled(Form)`
  width: 600px;
  padding: 28px 38px;
  background-color: #ffffff;
  && .ant-form-item-required.ant-form-item-no-colon::before {
    display: none;
  }
  && .ant-form-item-required.ant-form-item-no-colon::after {
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: "*";
  }
`;
const ButtonRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 100px;
`;
const RowContent = styled.div`
  flex: 7;
  min-width: 0;

  margin-left: 12px;
`;
const StyledSelect = styled(Select)`
  width: 100%;
`;
const defaultFilter = {
  limit: 20,
  offset: 0,
};

const AddBrandPopup = (props: Props) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { onClose } = props;
  const brandListResult = useSelector((state: RootState) => state.product.brandListResult);
  const { promotionDetail } = useSelector((state: RootState) => state.promotion);
  const { id: promotionId = -1 } = promotionDetail;
  const [localFilter, setLocalFilter] = useState<FetchListParam>(defaultFilter);
  const { objectIds } = localFilter;

  const handleOnBrandLoadMore = useCallback(() => {
    dispatch(loadMoreBrandList());
  }, [dispatch]);

  const handleOnBrandSearch = useDebounce((value: string) => {
    dispatch(fetchBrandList(value));
  }, 300);

  useEffect(() => {
    dispatch(fetchBrandList(""));
  }, [dispatch]);

  const handleOnBrandChange = (values: SelectValue) => {
    setLocalFilter((prev) => ({
      ...prev,
      objectIds: values as number[],
    }));
  };

  const brandOptions = useMemo(() => {
    const { next, results } = brandListResult;
    const options = results.map((brd) => (
      <Select.Option key={brd.id} value={brd.id}>
        {brd.name}
      </Select.Option>
    ));

    if (next) {
      options.push(
        <Select.Option value="loading..." disabled>
          loading...
          <InfiniteScrollObserver callback={handleOnBrandLoadMore} />
        </Select.Option>,
      );
    }

    return options;
  }, [brandListResult, handleOnBrandLoadMore]);

  const onSubmit = () => {
    const params = {
      ...localFilter,
      pageIds: promotionId,
      objectIds: localFilter.objectIds!,
      type: PromotionBindType.TAG,
    };
    dispatch(batchAddSalePages(params));

    onClose();
  };

  return (
    <PopupBackground close={onClose} fixed>
      <Wrapper form={form} colon={false}>
        <Form.Item
          name="estimateShippingDate"
          label="指定品牌"
          rules={[
            {
              required: true,
              message: "請填入指定品牌",
            },
          ]}
        >
          <RowContent>
            <StyledSelect
              mode="multiple"
              placeholder="請選擇"
              filterOption={false}
              value={objectIds}
              onSearch={handleOnBrandSearch}
              onChange={handleOnBrandChange}
            >
              {brandOptions}
            </StyledSelect>
          </RowContent>
        </Form.Item>

        <ButtonRow>
          <Space>
            <Button type="default" onClick={onClose}>
              取消
            </Button>
            <Button type="primary" onClick={onSubmit}>
              確認
            </Button>
          </Space>
        </ButtonRow>
      </Wrapper>
    </PopupBackground>
  );
};

export default AddBrandPopup;
