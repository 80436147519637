import { PlusCircleTwoTone } from "@ant-design/icons";
import { PlanChangePrice } from "@api/saleApi";
import SalePageTypeValue from "@page/Order/SalePageTypeValue";
import { fetchChangePriceList, fetchDeleteChangePrice, SaleState, setCurrentPlanChangePrice } from "@redux/saleSlice";
import { Button, Row, Select, Table } from "antd";
import { SelectValue } from "antd/lib/select";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import fontStyle from "src/styles/fontStyle";
import styled from "styled-components";
import ModalComplex, { ModalType } from "./ModalComplex";

const Wrapper = styled.div``;

const PageText = styled.span`
  ${fontStyle("14px", "20px")};
`;

const SelectPageSize = styled(Select)`
  &&& {
    margin: 0 10px;
  }
`;

const TableWrapper = styled.div`
  border: solid 1px #f0f0f0;
  border-radius: 2px;
  padding: 20px 10px 22px 17px;
  margin-top: 20px;
`;

export default function ChangePriceContent() {
  const dispatch = useDispatch();
  const { isFetching, planChangePriceListResult, needReload, salePageDetail, salePlanListResult } = useSelector(
    SaleState,
  );

  const { salesId } = useParams();

  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(20);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [canCreateNumber, setCanCreateNumber] = useState<number>(0);

  const computeSpecialPrice = (plan: PlanChangePrice) => {
    if (salePageDetail?.pageType === SalePageTypeValue.Any && plan.optionsInfo.length > 0) {
      const optionCount = plan.price / plan.optionsInfo[0].optionUnitPrice;

      return (
        plan.optionsInfo[0].optionUnitPrice * optionCount -
        Number((plan.optionsInfo[0].priceDiscount * optionCount).toFixed(0))
      );
    }

    const changePriceMap = new Map();
    plan.optionsInfo.forEach((el) => {
      changePriceMap.set(el.optionId, el.specialPriceToShow);
    });

    const index = salePlanListResult.results.findIndex((el) => el.id === plan.id);
    if (index > -1) {
      return (
        salePlanListResult.results[index].options.reduce(
          (prev, next) =>
            prev + (changePriceMap.get(next.id) ? changePriceMap.get(next.id) : next.unitPrice * next.groupQty) * 10,
          0,
        ) / 10
      );
    }
    return "N/A";
  };

  const setModalOpen = (planChangePrice?: PlanChangePrice) => {
    dispatch(setCurrentPlanChangePrice(planChangePrice));
    setOpenModal(true);
  };

  const setPageLimit = (value: SelectValue) => {
    setPageSize(parseInt(value as string, 10));
  };

  const fetchPageChange = (value: number) => {
    window.scrollTo(0, 0);
    setPage(value);

    dispatch(
      fetchChangePriceList({ salesPage: parseInt(salesId, 10), limit: pageSize, offset: (value - 1) * pageSize }),
    );
  };

  const onDeleteChangePrice = (planChangePrice: PlanChangePrice) => {
    dispatch(
      fetchDeleteChangePrice({
        optionsDiscountsIds: planChangePrice.optionsInfo.map((item) => item.optionsDiscountsId),
        salesPlan: planChangePrice.id,
      }),
    );
  };

  const columns = [
    {
      key: "name",
      dataIndex: "name",
      title: "方案名稱",
    },
    {
      key: "specialPrice",
      dataIndex: "specialPrice",
      title: "方案優惠價",
      render: (_: any, others: PlanChangePrice) => <div>{computeSpecialPrice(others)}</div>,
    },
    {
      key: "specialPriceDuration",
      dataIndex: "specialPriceDuration",
      title: "方案優惠時間",
      render: (_: any, others: PlanChangePrice) => (
        <div>
          {others.startAt && others.endAt
            ? `${others.startAt.replace("T", " ")} - ${others.endAt.replace("T", " ")}`
            : "預設"}
        </div>
      ),
    },
    {
      key: "buttons",
      dataIndex: "buttons",
      title: "",
      render: (_: any, others: PlanChangePrice) => (
        <>
          <Button type="link" onClick={() => setModalOpen(others)}>
            編輯
          </Button>
          <Button type="link" onClick={() => onDeleteChangePrice(others)}>
            刪除
          </Button>
        </>
      ),
    },
  ];

  useEffect(() => {
    setPage(1);
    dispatch(fetchChangePriceList({ salesPage: parseInt(salesId, 10), limit: pageSize, offset: 0 }));
  }, [dispatch, salesId, pageSize]);

  useEffect(() => {
    if (needReload) {
      setPage(1);
      dispatch(fetchChangePriceList({ salesPage: parseInt(salesId, 10), limit: pageSize, offset: 0 }));
    }
  }, [dispatch, salesId, pageSize, needReload]);

  useEffect(() => {
    if (salePlanListResult.results.length > 0) {
      const set = new Set();
      salePlanListResult.results.forEach((item) => {
        set.add(item.id);
      });
      setCanCreateNumber(set.size);
    }
  }, [salePlanListResult]);

  return (
    <Wrapper>
      <Row justify="space-between">
        <Button
          type="primary"
          icon={<PlusCircleTwoTone twoToneColor="#1890FF" />}
          disabled={planChangePriceListResult.results.length >= canCreateNumber * 6}
          onClick={() => setModalOpen(undefined)}
        >
          新增變價
        </Button>
        <Row align="middle">
          <PageText>{`總共${planChangePriceListResult.count}筆, 每頁顯示`}</PageText>
          <SelectPageSize defaultValue="20" onChange={setPageLimit}>
            <Select.Option value="20">20</Select.Option>
            <Select.Option value="50">50</Select.Option>
            <Select.Option value="100">100</Select.Option>
          </SelectPageSize>
          <PageText>筆</PageText>
        </Row>
      </Row>
      <TableWrapper>
        <Table
          loading={isFetching}
          columns={columns}
          dataSource={planChangePriceListResult.results}
          scroll={{ x: "max-content", y: 400 }}
          pagination={{
            current: page,
            total: planChangePriceListResult.count,
            pageSize,
            showSizeChanger: false,
            onChange: fetchPageChange,
          }}
        />
      </TableWrapper>
      {openModal && (
        <ModalComplex
          sequence={[
            salePageDetail?.pageType === SalePageTypeValue.Any
              ? ModalType.ANY_CHANGE_PRICE
              : ModalType.NORMAL_CHANGE_PRICE,
          ]}
          close={() => setOpenModal(false)}
        />
      )}
    </Wrapper>
  );
}
