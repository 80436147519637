import { ReturnOrderReason, returnOrderReasonLabel } from "@constant/ReturnOrderReason";

export const returnOrderReason = [
  {
    label: returnOrderReasonLabel[ReturnOrderReason.INSPECTION],
    value: ReturnOrderReason.INSPECTION,
  },
  {
    label: returnOrderReasonLabel[ReturnOrderReason.MARKETING],
    value: ReturnOrderReason.MARKETING,
  },
  { label: returnOrderReasonLabel[ReturnOrderReason.ORDER], value: ReturnOrderReason.ORDER },
  { label: returnOrderReasonLabel[ReturnOrderReason.RETURN], value: ReturnOrderReason.RETURN },
  { label: returnOrderReasonLabel[ReturnOrderReason.TRANSFER], value: ReturnOrderReason.TRANSFER },
];

const reasons = [
  {
    label: returnOrderReasonLabel[ReturnOrderReason.FREE_TASTE],
    value: ReturnOrderReason.FREE_TASTE,
  },
  {
    label: returnOrderReasonLabel[ReturnOrderReason.GIVEAWAY],
    value: ReturnOrderReason.GIVEAWAY,
  },
  {
    label: returnOrderReasonLabel[ReturnOrderReason.INSPECTION],
    value: ReturnOrderReason.INSPECTION,
  },
  {
    label: returnOrderReasonLabel[ReturnOrderReason.LADING],
    value: ReturnOrderReason.LADING,
  },
  {
    label: returnOrderReasonLabel[ReturnOrderReason.MARKETING],
    value: ReturnOrderReason.MARKETING,
  },
  {
    label: returnOrderReasonLabel[ReturnOrderReason.ORDER],
    value: ReturnOrderReason.ORDER,
  },
  {
    label: returnOrderReasonLabel[ReturnOrderReason.PRGOODS],
    value: ReturnOrderReason.PRGOODS,
  },
  {
    label: returnOrderReasonLabel[ReturnOrderReason.RESERVE_SAMPLE],
    value: ReturnOrderReason.RESERVE_SAMPLE,
  },
  {
    label: returnOrderReasonLabel[ReturnOrderReason.PROTOTYPE],
    value: ReturnOrderReason.PROTOTYPE,
  },
  {
    label: returnOrderReasonLabel[ReturnOrderReason.WAREHOUSE_TRANSFER],
    value: ReturnOrderReason.WAREHOUSE_TRANSFER,
  },
  {
    label: returnOrderReasonLabel[ReturnOrderReason.RETURN],
    value: ReturnOrderReason.RETURN,
  },
  {
    label: returnOrderReasonLabel[ReturnOrderReason.TRANSFER],
    value: ReturnOrderReason.TRANSFER,
  },
];

export default reasons;
