// 狀態: 尚未審核=1/審核通過=2/不需審核=3/審核不通過=4
export enum ReviewStateByPositionLabel {
  "尚未審核" = 1,
  "審核通過" = 2,
  "審核不通過" = 3,
  "不需審核" = 4,
}
export enum ReviewStateByPosition {
  WAITING = 1,
  PASSED = 2,
  NO_REVIEW = 3,
  NOT_PASSED = 4,
}

export enum ReviewState {
  REVIEW_PENDING = 0,
  REVIEW_APPROVED = 1,
  REVIEW_REJECT = -1,
}

export enum ReviewStateLabel {
  "待審核" = 0,
  "審核已通過" = 1,
  "審核不通過" = -1,
}
