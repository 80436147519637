import React, { FC, useMemo, useState, useEffect, ReactText } from "react";
import styled from "styled-components";
import { Table, Button, Pagination, Select } from "antd";
import { PlusCircleFilled, CaretDownFilled, CaretUpFilled } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { RootState } from "@redux/rootReducer";
import {
  updateProductVendorListParam,
  fetchProductVendorList,
  updateProductVendorEditPopup,
  batchRemoveProductVendors,
  updateSelectedProductVendorIds,
} from "@redux/productEditSlice";
import { useAppDispatch } from "src/store";
import { useParams } from "react-router-dom";
import { SelectValue } from "antd/lib/select";
import { ProductVendorListItem, ContractModeValue } from "@api/productApi";
import { fetchSalesChannelList } from "@redux/vendorSlice";
import ReminderPopup from "./ReminderPopup";
import EditPopup from "./EditPopup";

const Wrapper = styled.div`
  padding-bottom: 25px;
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 14px;
  padding-right: 17px;
`;
const TopButtonContainer = styled.div``;
const TablePageInfo = styled.div``;
const TopButton = styled(Button)`
  margin-right: 10px;
`;
const PageSelect = styled(Select)`
  margin: 0 10px;
`;
const TableContainer = styled.div`
  padding-left: 16px;
  margin-bottom: 16px;
`;
const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 45px 0 16px;
`;
const TableNoWrap = styled.div`
  white-space: nowrap;
`;

const DetailWrapper = styled.div`
  padding: 0 16px;
`;
const DetailRow = styled.div`
  display: flex;
  margin-bottom: 5px;
`;
const DetailTitle = styled.div`
  font-size: 13px;
  margin-right: 30px;
`;
const ExpandWrapper = styled.div`
  display: flex;
  color: #1890ff;
  white-space: nowrap;
  cursor: pointer;
`;
const ExpandIcon = styled.div`
  margin-right: 3px;
`;

const Text = styled.div<{ isValid?: boolean }>`
  color: ${({ isValid, theme }) => (!isValid ? theme.colorNeutral500 : theme.colorNeutral700)};
`;

const pageSizeOptions = [20, 50, 100];

const CollabMethod: FC = () => {
  const dispatch = useAppDispatch();
  const { id: productId } = useParams();

  const {
    isFetchingProductVendorList,
    productVendorListResult,
    productVendorListParam,
    productVendorEditPopup,
    selectedProductVendorIds,
  } = useSelector((state: RootState) => state.productEdit);
  const { show: showEditPopup } = productVendorEditPopup;

  const [showReminderPopup, setShowReminderPopup] = useState<boolean>(false);

  const { count, results: collabList } = productVendorListResult;
  const { limit, offset } = productVendorListParam;

  const currentPage = useMemo(() => {
    return Math.floor(offset / limit) + 1;
  }, [limit, offset]);

  useEffect(() => {
    const initialFetchFilter = {
      limit: 20,
      offset: 0,
    };
    dispatch(updateProductVendorListParam(initialFetchFilter));
    dispatch(fetchProductVendorList());
    dispatch(fetchSalesChannelList());
  }, [dispatch, productId]);

  const handleOnAddNewMethod = () => {
    dispatch(
      updateProductVendorEditPopup({
        show: true,
        mode: "add",
      }),
    );
  };

  const handleOnEditMethod = (method: ProductVendorListItem) => () => {
    dispatch(
      updateProductVendorEditPopup({
        show: true,
        mode: "edit",
        productVendorListItem: method,
      }),
    );
  };

  const toggleReminderPopup = () => setShowReminderPopup((prev) => !prev);

  const handleOnAddVendor = () => {
    window.open("/vendor/info/new", "_blank");
  };

  const handleOnPageSizeChange = (value: SelectValue) => {
    const filter = {
      ...productVendorListParam,
      limit: value as number,
    };
    dispatch(updateProductVendorListParam(filter));
    dispatch(fetchProductVendorList());
  };

  const handleOnPageChange = (page: number) => {
    const filter = {
      ...productVendorListParam,
      offset: (page - 1) * limit,
    };
    dispatch(updateProductVendorListParam(filter));
    dispatch(fetchProductVendorList());
  };

  const handleOnCollabCheck = (values: ReactText[]) => {
    dispatch(updateSelectedProductVendorIds(values as number[]));
  };

  const handleOnConfirmDelete = () => {
    toggleReminderPopup();
    dispatch(batchRemoveProductVendors());
  };

  const tableColumns = [
    {
      title: <TableNoWrap>ID</TableNoWrap>,
      key: "id",
      dataIndex: "id",
      width: 54,
      render: (value: ProductVendorListItem["id"], data: ProductVendorListItem) => {
        return <Text isValid={data.canEdit}>{value}</Text>;
      },
    },
    {
      title: <TableNoWrap>廠商名稱</TableNoWrap>,
      key: "vendorInfo",
      dataIndex: "vendorInfo",
      width: 168,
      render: (value: ProductVendorListItem["vendorInfo"], data: ProductVendorListItem) => {
        return <Text isValid={data.canEdit}>{value.name}</Text>;
      },
    },
    {
      title: <TableNoWrap>主要聯絡人</TableNoWrap>,
      key: "vendorInfo",
      dataIndex: "vendorInfo",
      width: 112,
      render: (value: ProductVendorListItem["vendorInfo"], data: ProductVendorListItem) => (
        <Text isValid={data.canEdit}>{value.contactName || "N/A"}</Text>
      ),
    },
    {
      title: <TableNoWrap>廠商電話</TableNoWrap>,
      key: "vendorInfo",
      dataIndex: "vendorInfo",
      width: 107,
      render: (value: ProductVendorListItem["vendorInfo"], data: ProductVendorListItem) => (
        <Text isValid={data.canEdit}>{value.phone || "N/A"}</Text>
      ),
    },
    {
      title: <TableNoWrap>通路</TableNoWrap>,
      key: "salesChannel",
      dataIndex: ["salesChannel", "name"],
      width: 107,
      render: (value: string, data: ProductVendorListItem) => <Text isValid={data.canEdit}>{value || "N/A"}</Text>,
    },
    {
      title: <TableNoWrap>合作模式</TableNoWrap>,
      key: "modeName",
      dataIndex: "modeName",
      width: 90,
      render: (value: ProductVendorListItem["modeName"], data: ProductVendorListItem) => (
        <Text isValid={data.canEdit}>{value || "N/A"}</Text>
      ),
    },
    {
      title: <TableNoWrap>出貨時間(天)</TableNoWrap>,
      key: "deliveryDays",
      dataIndex: "deliveryDays",
      width: 104,
      render: (value: ProductVendorListItem["deliveryDays"], data: ProductVendorListItem) => (
        <Text isValid={data.canEdit}>{value || "N/A"}</Text>
      ),
    },
    {
      title: <TableNoWrap>箱入數</TableNoWrap>,
      key: "pcsPerCarton",
      dataIndex: "pcsPerCarton",
      width: 78,
      render: (value: ProductVendorListItem["pcsPerCarton"], data: ProductVendorListItem) => (
        <Text isValid={data.canEdit}>{value || "N/A"}</Text>
      ),
    },
    {
      title: <TableNoWrap>進價</TableNoWrap>,
      key: "cost",
      dataIndex: "cost",
      width: 78,
      render: (value: ProductVendorListItem["cost"], data: ProductVendorListItem) => {
        switch (data.mode) {
          case ContractModeValue.DOMESTIC_BUY_OUT:
          case ContractModeValue.FOREIGN_BUY_OUT:
            return <Text isValid={data.canEdit}>{value || "N/A"}</Text>;
          case ContractModeValue.TRANSFER_ORDER:
          case ContractModeValue.WAREHOUSE_CONSIGN:
          default:
            return <Text isValid={data.canEdit}>N/A</Text>;
        }
      },
    },
    {
      title: <TableNoWrap>售價</TableNoWrap>,
      key: "price",
      dataIndex: "price",
      width: 78,
      render: (value: ProductVendorListItem["price"], data: ProductVendorListItem) => (
        <Text isValid={data.canEdit}>{value || "N/A"}</Text>
      ),
    },
    {
      title: <TableNoWrap>抽成</TableNoWrap>,
      key: "commissionRate",
      dataIndex: "commissionRate",
      width: 78,
      render: (value: ProductVendorListItem["commissionRate"], data: ProductVendorListItem) => (
        <Text isValid={data.canEdit}>{value || "N/A"}</Text>
      ),
    },
    {
      title: <TableNoWrap>總費用比例</TableNoWrap>,
      key: "deductionInfo",
      dataIndex: "deductionInfo",
      width: 120,
      render: (value: ProductVendorListItem["deductionInfo"], data: ProductVendorListItem) => (
        <Text isValid={data.canEdit}>{`${value.totalDeductionRate}%` || "N/A"}</Text>
      ),
    },
    {
      title: <TableNoWrap>起始時間</TableNoWrap>,
      key: "startDate",
      dataIndex: "startDate",
      width: 130,
      render: (value: ProductVendorListItem["startDate"], data: ProductVendorListItem) => (
        <Text isValid={data.canEdit}>{value || "N/A"}</Text>
      ),
    },
    {
      title: <TableNoWrap>結束時間</TableNoWrap>,
      key: "endDate",
      dataIndex: "endDate",
      width: 130,
      render: (value: ProductVendorListItem["endDate"], data: ProductVendorListItem) => (
        <Text isValid={data.canEdit}>{value || "N/A"}</Text>
      ),
    },
    {
      title: "",
      key: "",
      dataIndex: "",
      width: 80,
      render: (value: any, data: ProductVendorListItem) => (
        <Button type="link" disabled={!data.canEdit} onClick={handleOnEditMethod(data)}>
          編輯
        </Button>
      ),
    },
  ];

  const expandedRowRender = (item: ProductVendorListItem) => {
    const { deductionInfo } = item;
    const { deductions } = deductionInfo!;
    return (
      <DetailWrapper>
        {deductions.map((deduction) => (
          <DetailRow key={deduction.displayName}>
            <DetailTitle>{`${deduction.displayName}(%): ${deduction.rate}`}</DetailTitle>
          </DetailRow>
        ))}
      </DetailWrapper>
    );
  };

  const expandIcon = ({ expanded, onExpand, record }: any) => {
    return (
      <ExpandWrapper onClick={(e) => onExpand(record, e)}>
        <ExpandIcon>{expanded ? <CaretUpFilled /> : <CaretDownFilled />}</ExpandIcon>
        展開合約
      </ExpandWrapper>
    );
  };

  return (
    <Wrapper>
      <Header>
        <TopButtonContainer>
          <TopButton type="primary" icon={<PlusCircleFilled />} onClick={handleOnAddNewMethod}>
            新增合作方式
          </TopButton>
          <TopButton type="primary" icon={<PlusCircleFilled />} onClick={handleOnAddVendor}>
            新增廠商
          </TopButton>
        </TopButtonContainer>
        <TablePageInfo>
          總共
          {count}
          筆, 每頁顯示
          <PageSelect value={20} onChange={handleOnPageSizeChange}>
            {pageSizeOptions.map((option) => (
              <Select.Option value={option} key={option}>
                {option}
              </Select.Option>
            ))}
          </PageSelect>
          筆
        </TablePageInfo>
      </Header>
      <TableContainer>
        <Table
          rowKey="id"
          dataSource={collabList}
          columns={tableColumns}
          scroll={{ x: "max-content" }}
          rowSelection={{
            onChange: handleOnCollabCheck,
            selectedRowKeys: selectedProductVendorIds,
          }}
          tableLayout="auto"
          pagination={false}
          loading={isFetchingProductVendorList}
          expandable={{
            expandedRowRender,
            expandIcon,
            expandIconColumnIndex: 1,
          }}
        />
      </TableContainer>
      <Footer>
        <Button onClick={toggleReminderPopup} disabled={selectedProductVendorIds.length === 0}>
          刪除
        </Button>
        <Pagination
          current={currentPage}
          total={count}
          onChange={handleOnPageChange}
          showSizeChanger={false}
          pageSize={limit}
        />
      </Footer>
      {showEditPopup && <EditPopup />}
      {showReminderPopup && (
        <ReminderPopup mode="delete" onClose={toggleReminderPopup} onConfirm={handleOnConfirmDelete} />
      )}
    </Wrapper>
  );
};

export default CollabMethod;
