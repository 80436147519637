import productApi, { ProductDetail } from "@api/productApi";
import PopupBackground from "@component/PopupBackground";
import { ReturnOrderReason } from "@constant/ReturnOrderReason";
import useDebounce from "@hooks/useDebounce";
import { Button, DatePicker, Form, Input, Row, Select, Spin } from "antd";
import moment, { Moment } from "moment";
import React, { useEffect, useState } from "react";
import fontStyle from "src/styles/fontStyle";
import styled from "styled-components";
import { ReturnOrderItem, ReturnProduct } from "./interfaces";
import "./style.css";

const Wrapper = styled(Form)`
  position: relative;
  min-width: 650px;
  padding: 30px;
  background: ${({ theme }) => theme.colorNeutral100};
  overflow: auto;

  .ant-form-item-label {
    max-width: 131px;
    min-width: 131px;
    text-align: left;
  }

  .ant-form-item-required::before {
    order: 1;
  }

  .ant-form-item-required::after {
    display: none;
  }

  .ant-input {
    width: 230px;
  }
`;

const Notice = styled.span`
  margin-top: 5px;
  margin-left: 10px;
  color: ${({ theme }) => theme.colorNeutral500};
  ${fontStyle("14px", "22px")};
`;

interface FormState {
  sku: string;
  name: string;
  cost: number;
  orderedQty: number;
  canSalesQty: number;
  effectiveDate: Moment;
}

export default function ReturnProductForm(props: {
  product?: ReturnProduct;
  productList: ReturnProduct[];
  returnOrderDetail?: ReturnOrderItem;
  closeModal: () => void;
  createNewProduct: (payload: { sku: string; orderedQty: number; effectiveDate: string }) => void;
  modifyProduct: (payload: { orderedQty: number; effectiveDate: string; sku: string }) => void;
}) {
  const { product, productList, closeModal, createNewProduct, modifyProduct, returnOrderDetail } = props;

  const [form] = Form.useForm<FormState>();

  const [error, setError] = useState<string>("");
  const [productListSearchByName, setProductListSearchByName] = useState<ProductDetail[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchProductDetailBySku = useDebounce(async (sku: string) => {
    if (!product) {
      const isDuplicated = productList.filter((item) => item.sku === sku).length;
      if (isDuplicated) {
        setError(isDuplicated ? "您已加入此項商品於列表中，請回列表點選編輯" : "");
        return;
      }
    }

    if (sku) {
      setIsLoading(true);
      setError("");
      const response = await productApi.fetchProductDetailListByFilter({ sku });
      const resProduct = response.results[0];
      if (resProduct) {
        form.setFieldsValue({
          sku: resProduct.sku,
          name: resProduct.name,
          cost: resProduct.vpcInfo?.cost,
          canSalesQty: resProduct.canSalesQty,
          effectiveDate: undefined,
          orderedQty: undefined,
        });
      } else {
        setError("查無此商品");
        form.setFieldsValue({
          name: undefined,
          cost: undefined,
          canSalesQty: undefined,
          effectiveDate: undefined,
          orderedQty: undefined,
        });
      }
      setIsLoading(false);
    }
  }, 1000);

  const fetchProductDetailByName = useDebounce(async (nameQ?: string) => {
    if (nameQ) {
      setIsLoading(true);
      const response = await productApi.fetchProductDetailListByFilter({ nameQ });
      if (response.results.length > 0) {
        setProductListSearchByName(response.results);
      } else {
        setProductListSearchByName([]);
        setError("查無此商品");
      }
      setIsLoading(false);
    }
  }, 1000);

  const onFinish = (values: any) => {
    if (!values.sku && !values.name) {
      return;
    }

    if (
      values.sku &&
      returnOrderDetail?.warehouseId !== "壞品" &&
      returnOrderDetail?.reasonId !== ReturnOrderReason.RETURN &&
      values.orderedQty > values.canSalesQty
    ) {
      setError("可賣量數量不足，請更換商品");
      return;
    }

    if (!product) {
      const effectiveDate = values?.effectiveDate ? moment(values?.effectiveDate).format("YYYY-MM-DD") : "";
      createNewProduct({ sku: values.sku, orderedQty: Number(values.orderedQty), effectiveDate });
    } else {
      const effectiveDate = values?.effectiveDate ? moment(values?.effectiveDate).format("YYYY-MM-DD") : "";
      modifyProduct({
        orderedQty: Number(values.orderedQty),
        effectiveDate,
        sku: values.sku,
      });
    }
    closeModal();
  };

  const onErrorCheck = () => {
    const sku = form.getFieldValue("sku");
    const name = form.getFieldValue("name");
    const orderedQty = form.getFieldValue("orderedQty");
    if ((!sku && !name) || !orderedQty) {
      setError("請擇一填寫SKU / 商品名稱 / 商品數量(件)");
    }
  };

  useEffect(() => {
    if (product) {
      form.setFieldsValue({
        sku: product.sku,
        name: product.name,
        cost: product.cost,
        orderedQty: product.orderedQty,
        canSalesQty: product.canSalesQty,
        effectiveDate: product.effectiveDate ? moment(product.effectiveDate) : undefined,
      });
    }
  }, [form, product]);

  return (
    <PopupBackground close={closeModal} fixed>
      <Wrapper
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 8 }}
        labelAlign="left"
        layout="horizontal"
        colon={false}
        onFinish={onFinish}
        onFinishFailed={onErrorCheck}
      >
        <Spin spinning={isLoading}>
          <Form.Item label="SKU" name="sku" labelAlign="left" rules={[{ required: true, message: "" }]}>
            <Input onChange={(e) => fetchProductDetailBySku(e.target.value)} />
          </Form.Item>
          <Row>
            <Form.Item label="商品名稱" name="name" labelAlign="left" rules={[{ required: true, message: "" }]}>
              <Select
                style={{ width: 459 }}
                showSearch
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={fetchProductDetailByName}
                onChange={(value) => {
                  const response = productListSearchByName.find((item) => item.sku === value);
                  if (response) {
                    form.setFieldsValue({
                      sku: response.sku,
                      name: response.name,
                      cost: response.vpcInfo?.cost,
                      orderedQty: 0,
                      canSalesQty: response.canSalesQty,
                      effectiveDate: undefined,
                    });
                  }
                }}
                notFoundContent={null}
                options={productListSearchByName.map((value) => ({
                  value: value.sku,
                  label: value.name,
                }))}
              />
            </Form.Item>
          </Row>
          <Row>
            <Form.Item label="成本" name="cost" labelAlign="left">
              <Input disabled />
            </Form.Item>
            <Notice>*輸入SKU後自動帶入</Notice>
          </Row>
          <Row>
            <Form.Item label="可賣量" name="canSalesQty" labelAlign="left">
              <Input disabled />
            </Form.Item>
            <Notice>*輸入SKU後自動帶入</Notice>
          </Row>
          <Form.Item label="商品效期" name="effectiveDate" labelAlign="left">
            <DatePicker placeholder="請選擇日期" style={{ width: "230px" }} />
          </Form.Item>
          <Form.Item label="商品數量(件)" name="orderedQty" labelAlign="left" rules={[{ required: true, message: "" }]}>
            <Input type="number" />
          </Form.Item>
          <Row justify="space-between" align="middle">
            <span style={{ float: "left", color: "#EC6922" }}>{error}</span>
            <div>
              <Button type="default" style={{ marginRight: 10 }} onClick={closeModal}>
                取消
              </Button>
              <Button type="primary" htmlType="submit">
                確定
              </Button>
            </div>
          </Row>
        </Spin>
      </Wrapper>
    </PopupBackground>
  );
}
