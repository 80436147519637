import React, { FC, useEffect, useMemo } from "react";
import styled from "styled-components";
import { Tree, Tabs } from "antd";
import { produce } from "immer";
import { EventDataNode, DataNode } from "antd/lib/tree";
import { useSelector } from "react-redux";
import { RootState } from "@redux/rootReducer";
import { useAppDispatch } from "src/store";
import { reset, fetchProductCategoryList, addNewCategory, updateSelectedCategory } from "@redux/productCategorySlice";
import { ProductCategoryNode } from "@api/productCategoryApi";
import { PlusCircleOutlined } from "@ant-design/icons";
import EmptyContent from "@component/EmptyContent";
import PageTitle from "@component/PageTitle";
import EditTab from "./EditTab";

const Wrapper = styled.div`
  display: flex;
  padding: 12px 0;
  height: 100%;
`;
const TreeBlock = styled.div`
  flex: 3;
  min-width: 0;
  z-index: 1;
  background-color: white;
  padding-left: 20px;
`;
const ContentBlock = styled.div`
  flex: 7;
  min-width: 0;
  padding-right: 25px;
`;
const TabBlock = styled.div`
  width: 100%;
  min-height: 300px;
  padding: 12px 0 0 20px;
  border: 1px solid ${({ theme }) => theme.colorNeutral400};
  border-radius: 2px;
`;
const AddNodeWrapper = styled.div`
  margin-left: -21px;
  display: flex;
  align-items: center;
  &:hover {
    background-color: #f5f5f5;
  }
`;
const AddNodeText = styled.p`
  font-size: 14px;
  padding: 0 4px;
  margin: 0;
  margin-left: 4px;
`;

interface SelectEventInfo {
  event: "select";
  selected: boolean;
  node: EventDataNode;
  selectedNodes: DataNode[];
  nativeEvent: MouseEvent;
}
type CustomNode = EventDataNode & ProductCategoryNode;

const ProductCategory: FC = () => {
  const categoryList = useSelector((state: RootState) => state.productCategory.categoryList);
  const hasNewNode = useSelector((state: RootState) => state.productCategory.hasNewNode);
  const selectedCategory = useSelector((state: RootState) => state.productCategory.selectedCategory);
  const dispatch = useAppDispatch();

  // 加上新增按鈕到每一層
  const categoryListWithAddButton = useMemo(() => {
    const result = produce(categoryList, (draftCategoryList) => {
      // 新增品類按鈕到第一層
      draftCategoryList.push({
        title: (
          <AddNodeWrapper>
            <PlusCircleOutlined />
            <AddNodeText>新增品類</AddNodeText>
          </AddNodeWrapper>
        ),
        parent: 0,
        key: "add",
        id: "add",
        isLeaf: true,
        productCount: 0,
        isAddButton: true,
        level: 1,
      });
      draftCategoryList.forEach((ctgy) => {
        // 新增品類按鈕到第二層
        const level2AddNode = {
          title: (
            <AddNodeWrapper>
              <PlusCircleOutlined />
              <AddNodeText>新增品類</AddNodeText>
            </AddNodeWrapper>
          ),
          parent: ctgy.key,
          key: `${ctgy.key}-add`,
          id: `${ctgy.key}-add`,
          productCount: 0,
          isLeaf: true,
          isAddButton: true,
          level: 2,
        };
        if (ctgy.children) {
          ctgy.children.push(level2AddNode);
        } else {
          ctgy.children = [level2AddNode];
        }
        ctgy.children.forEach((subCtgy: any) => {
          // 新增品類按鈕到第三層
          const level3AddNode = {
            title: "新增品類",
            parent: subCtgy.key,
            key: `${subCtgy.key}-add`,
            id: `${subCtgy.key}-add`,
            productCount: 0,
            isLeaf: true,
            isAddButton: true,
            level: 3,
            icon: <PlusCircleOutlined />,
          };
          if (subCtgy.children) {
            subCtgy.children.push(level3AddNode);
          } else {
            subCtgy.children = [level3AddNode];
          }
        });
      });
    });
    return result;
  }, [categoryList]);

  useEffect(() => {
    dispatch(fetchProductCategoryList());
    return () => {
      dispatch(reset());
    };
  }, [dispatch]);

  function handleOnSelect(selectedKeys: React.ReactText[], info: SelectEventInfo) {
    const node = info.node as CustomNode;
    const { id, parent, level } = node;
    const numberId = Number(id);
    const numberParent = Number(parent);

    if (node.isAddButton) {
      if (hasNewNode) return;

      const countNumber = level === 3 ? "" : "(0)";
      const addedNode = {
        title: `新品類${countNumber}`,
        parent,
        key: -1,
        id: -1,
        level,
        children: [],
        selectable: false,
        productCount: 0,
      };
      dispatch(addNewCategory(addedNode));
      dispatch(
        updateSelectedCategory({
          id: -1,
          parent: numberParent,
          level,
        }),
      );
    } else {
      dispatch(
        updateSelectedCategory({
          id: numberId,
          parent: numberParent,
          level,
        }),
      );
    }
  }

  return (
    <Wrapper>
      <PageTitle title="品類" />
      <TreeBlock>
        <Tree
          treeData={categoryListWithAddButton}
          onSelect={handleOnSelect}
          showIcon
          selectedKeys={[selectedCategory ? selectedCategory.id : ""]}
        />
      </TreeBlock>
      <ContentBlock>
        {selectedCategory.id !== 0 ? (
          <TabBlock>
            <Tabs defaultActiveKey="edit" type="card">
              <Tabs.TabPane tab="編輯品類" key="edit">
                <EditTab />
              </Tabs.TabPane>
            </Tabs>
          </TabBlock>
        ) : (
          <EmptyContent />
        )}
      </ContentBlock>
    </Wrapper>
  );
};

export default ProductCategory;
