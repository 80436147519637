import { CaretRightOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { TopicCategories } from "@api/utils/normalizeTopic";
import {
  addNewCategory,
  CategoryStatus,
  clearCategoryInfo,
  fetchTopicCategories,
  fetchUpdateCategoriesOrder,
  topicState,
} from "@redux/topicSlice";
import { Checkbox, Tree } from "antd";
import { DataNode, EventDataNode } from "antd/lib/tree";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useSearchParam } from "react-use";
import styled from "styled-components";

const Wrapper = styled.div`
  padding: 20px 0 20px 20px;
  min-width: 300px;

  &&& .ant-tree-node-selected {
    background: transparent;
    color: #1890ff;
  }

  &&& .ant-tree-treenode-selected {
    svg {
      fill: #1890ff;
    }
  }
`;

const AddNodeWrapper = styled.div<{ level: string }>`
  margin-left: ${({ level }) => (level === "level1" ? "-21px" : "0px")};
  display: flex;
  align-items: center;
  &:hover {
    background-color: #f5f5f5;
  }
`;

const AddNodeText = styled.p`
  font-size: 14px;
  padding: 0 4px;
  margin: 0;
  margin-left: 4px;
`;

const RightArrow = styled(CaretRightOutlined)`
  & > svg {
    width: 10px;
  }
`;

const CustomCheckbox = styled(Checkbox)`
  padding-left: 3.5px;
  padding-bottom: 6px;
`;

const generateAddNode = (level: string, parentId: number): Categories => ({
  key: Date.now() * Math.ceil(Math.random() * 100000), // 只是要給他一個 key
  title: (
    <AddNodeWrapper level={level}>
      <PlusCircleOutlined />
      <AddNodeText>新增分類</AddNodeText>
    </AddNodeWrapper>
  ),
  parent: parentId,
  salesPageCount: 0,
  isDisabled: false,
  isAdding: true,
  children: [],
});

const generateNewNode = (parentId: number): Categories => ({
  key: Date.now() * Math.ceil(Math.random() * 100000), // 只是要給他一個 key
  title: "新分類(0)",
  parent: parentId,
  salesPageCount: 0,
  isNew: true,
  isDisabled: false,
  children: [],
});

interface SelectInfo {
  event: "select";
  selected: boolean;
  node: EventDataNode & { isAdding?: boolean; isNew?: boolean; parent?: number };
  selectedNodes: DataNode[];
  nativeEvent: MouseEvent;
}

export type Categories = TopicCategories & {
  isAdding?: boolean;
  isNew?: boolean;
};

export default function CategoryTree() {
  const categoryId = useSearchParam("category");
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { topicInfo, topicCategories, categoryStatus, isTagEdit } = useSelector(topicState);

  const [localCategories, setLocalCategories] = useState<Categories[]>([]);
  const [existNewNode, setExistNewNode] = useState<boolean>(false);
  const [expandKeys, setExpandKeys] = useState<(number | string)[]>([]);
  const [isSelecting, setIsSelecting] = useState<boolean>(true);
  const [selectKeys, setSelectKeys] = useState<React.ReactText[]>([]);
  const [showInActiveCategory, setShowInActiveCategory] = useState<boolean>(true);

  const addNewNode = (info: SelectInfo) => {
    if (existNewNode) return;

    const selectNodeParent = info.node.parent!;

    const node = generateNewNode(selectNodeParent);
    const payload = {
      newNode: node,
      parentId: selectNodeParent,
    };
    dispatch(addNewCategory(payload));

    setExistNewNode(true);
  };

  const onDragCategory = (info: any) => {
    if (info.dragNode.isNew || info.dragNode.isAdding) return;

    if (topicInfo) {
      if (info.dragNode.parent !== info.node.parent) {
        if (info.node.children![0].parent === info.dragNode.parent) {
          dispatch(
            fetchUpdateCategoriesOrder({
              rootCategoryId: topicInfo.rootCategory,
              categoryId: info.dragNode.key as number,
              displayInActive: showInActiveCategory,
              data: {
                topicId: topicInfo.id,
                rightSibling: info.node.children![0].key as number,
              },
            }),
          );
        }
      } else {
        dispatch(
          fetchUpdateCategoriesOrder({
            rootCategoryId: topicInfo.rootCategory,
            categoryId: info.dragNode.key as number,
            displayInActive: showInActiveCategory,
            data: {
              topicId: topicInfo.id,
              leftSibling: info.node.key as number,
            },
          }),
        );
      }
    }
  };

  const onSelectCategory = (selectedKeys: React.ReactText[], info: SelectInfo) => {
    if (!topicInfo) return;

    setSelectKeys(selectedKeys);
    setIsSelecting(false);

    if (info.node.isAdding) {
      addNewNode(info);
    } else if (topicInfo.id === info.node.key) {
      navigate(pathname);
    } else if (info.node.isNew) {
      dispatch(clearCategoryInfo());
      navigate(`${pathname}?category=new`);
    } else {
      navigate(`${pathname}?category=${info.node.key}`);
    }
  };

  const onExpand = (
    values: React.Key[],
    info: {
      node: EventDataNode & { isAdding?: boolean; isNew?: boolean; parent?: number };
      expanded: boolean;
      nativeEvent: MouseEvent;
    },
  ) => {
    if (!info.node.isNew) {
      setExpandKeys(values);
    }
  };

  const fetchToggleInActiveCategory = () => {
    if (topicInfo) {
      dispatch(
        fetchTopicCategories({ rootCategoryId: topicInfo.rootCategory, displayInActive: !showInActiveCategory }),
      );
      setShowInActiveCategory(!showInActiveCategory);
    }
  };

  useEffect(() => {
    if (topicInfo && isSelecting && localCategories.length > 0) {
      setExpandKeys([topicInfo.id]);
    }
  }, [topicInfo, isSelecting, localCategories]);

  useEffect(() => {
    if (topicInfo) {
      // init 插入新增分類
      // 不在 redux 做的原因在 redux-toolkit 會噴他沒辦法比對 value (icon)
      const shallowCategories: TopicCategories[] = JSON.parse(JSON.stringify(topicCategories));

      shallowCategories.forEach((item) => {
        item.title = item.isDisabled ? <div style={{ color: "#D3D3D3" }}>{item.title}</div> : item.title;
        const node = generateAddNode("level2", item.key);

        item.children.forEach((lvl2Item) => {
          const lvl3AddNode = generateAddNode("level3", lvl2Item.key);
          lvl2Item.children.push(lvl3AddNode);

          lvl2Item.title = lvl2Item.isDisabled ? (
            <div style={{ color: "#D3D3D3" }}>{lvl2Item.title}</div>
          ) : (
            lvl2Item.title
          );

          lvl2Item.children.forEach((lvl3Item) => {
            lvl3Item.title = lvl3Item.isDisabled ? (
              <div style={{ color: "#D3D3D3" }}>{lvl3Item.title}</div>
            ) : (
              lvl3Item.title
            );
          });
        });

        item.children.push(node);
      });

      const node = generateAddNode("level1", topicInfo.id);
      shallowCategories.push(node);

      const tree: Categories = {
        key: topicInfo.id,
        title: topicInfo.title,
        parent: -1,
        salesPageCount: 0,
        isDisabled: false,
        children: shallowCategories,
      };

      setLocalCategories([tree]);
    } else {
      const tree = {
        key: -1,
        title: "新主題館",
        parent: -1,
        isDisabled: false,
        salesPageCount: 0,
        icon: () => <RightArrow />,
        children: [],
      };

      setLocalCategories([tree]);
    }
  }, [topicInfo, topicCategories]);

  useEffect(() => {
    if (categoryId) {
      setSelectKeys([parseInt(categoryId, 10)]);
    }
  }, [categoryId]);

  useEffect(() => {
    if (categoryStatus === CategoryStatus.CREATE) {
      setExistNewNode(false);
    }
  }, [categoryStatus]);

  useEffect(() => {
    if ((isTagEdit || categoryStatus === CategoryStatus.UPDATE) && topicInfo) {
      dispatch(fetchTopicCategories({ rootCategoryId: topicInfo.rootCategory, displayInActive: showInActiveCategory }));
    }
  }, [isTagEdit, dispatch, topicInfo, showInActiveCategory, categoryStatus]);

  return (
    <Wrapper>
      <CustomCheckbox checked={showInActiveCategory} onChange={fetchToggleInActiveCategory}>
        顯示未啟用的分類
      </CustomCheckbox>
      <Tree
        treeData={localCategories}
        autoExpandParent
        expandedKeys={expandKeys}
        draggable
        showIcon
        selectedKeys={selectKeys}
        onDrop={onDragCategory}
        onSelect={onSelectCategory}
        onExpand={onExpand}
      />
    </Wrapper>
  );
}
