import { FilterTwoTone, PlusCircleTwoTone } from "@ant-design/icons";
import PageTitle from "@component/PageTitle";
import { RootState } from "@redux/rootReducer";
import { fetchVendorList, resetVendorSlice, updateVendorListParams } from "@redux/vendorSlice";
import { Button, Col, Row, Select, Space } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "src/store";
import styled from "styled-components";
import VendorFilter from "./VendorFilter";
import VendorList from "./VendorList";

const { Option } = Select;

const Wrapper = styled.div`
  padding: 25px 20px;
`;
const Text = styled.div`
  white-space: nowrap;
`;
const CustomButton = styled(Button)`
  margin-left: 10px;
`;

export default function VendorListPage() {
  const dispatch = useAppDispatch();
  const { vendorListResult, vendorListParams } = useSelector((state: RootState) => state.vendorSlice);

  const [showFilter, setShowFilter] = useState<boolean>(false);

  const { limit } = vendorListParams;
  const { count } = vendorListResult;

  useEffect(() => {
    dispatch(fetchVendorList());
    return () => {
      dispatch(resetVendorSlice());
    };
  }, [dispatch]);

  const handleSelectChange = (value: number) => {
    dispatch(
      updateVendorListParams({
        ...vendorListParams,
        limit: value,
      }),
    );
    dispatch(fetchVendorList());
  };

  return (
    <Wrapper>
      <PageTitle title="廠商列表" />
      <Row justify="space-between">
        <Col span={12}>
          <Button
            type="primary"
            icon={<PlusCircleTwoTone twoToneColor="#1890FF" />}
            href="/vendor/info/new"
            target="_blank"
          >
            新增廠商
          </Button>
          <CustomButton icon={<FilterTwoTone />} onClick={() => setShowFilter(!showFilter)}>
            篩選
          </CustomButton>
          <CustomButton href="/vendor/deposit-balance" target="_blank">
            保證金餘額總表
          </CustomButton>
        </Col>
        <Col span={6}>
          <Space>
            <Text>{`總共${count}筆，每頁顯示`}</Text>
            <Select value={limit} style={{ width: "72px", fontSize: "13px" }} onChange={handleSelectChange}>
              <Option value={20}>20</Option>
              <Option value={50}>50</Option>
              <Option value={100}>100</Option>
            </Select>
            <span>筆</span>
          </Space>
        </Col>
      </Row>
      {showFilter && <VendorFilter />}
      <div style={{ border: "1px solid #fffff", marginTop: "15px" }}>
        <VendorList />
      </div>
    </Wrapper>
  );
}
