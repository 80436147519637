import { FilterTwoTone, PlusCircleTwoTone } from "@ant-design/icons";
import { RootState } from "@redux/rootReducer";
import {
  fetchReportRegenList,
  deleteReportRegen,
  exportReportRegen,
  updateVendorReportRegenParams,
  resetVendorSlice,
} from "@redux/vendorSlice";
import { Button, Col, Row, Select, Space, Table } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "src/store";
import styled from "styled-components";
import { ReportRegenItem } from "@page/VendorReportRegenPage/interface";
import { ColumnsType } from "antd/lib/table";
import { vendorApproveStatusMap, VendorApproveStatus, canExecution } from "@constant/vendor";
import PageTitle from "@component/PageTitle";
import VendorFilter from "./Filter";
import EditModal from "./EditModal";

const { Option } = Select;

const Wrapper = styled.div`
  padding: 25px 20px;
`;
const Text = styled.div`
  white-space: nowrap;
`;

export default function SupplementListPage() {
  const dispatch = useAppDispatch();
  const { reportRegenListResult, reportRegenListParams, isFetchingReportRegenList } = useSelector(
    (state: RootState) => state.vendorSlice,
  );
  const { limit, offset } = reportRegenListParams;
  const { count, results: list } = reportRegenListResult;

  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);

  const currentPage = useMemo(() => {
    return Math.floor(offset / limit) + 1;
  }, [limit, offset]);

  useEffect(() => {
    dispatch(fetchReportRegenList());
    return () => {
      dispatch(resetVendorSlice());
    };
  }, [dispatch]);

  const handleSelectChange = (value: number) => {
    dispatch(
      updateVendorReportRegenParams({
        ...reportRegenListParams,
        limit: value,
      }),
    );
    dispatch(fetchReportRegenList());
  };

  const handleTableChangePage = (page: number) => {
    dispatch(
      updateVendorReportRegenParams({
        ...reportRegenListParams,
        offset: (page - 1) * limit,
      }),
    );
    dispatch(fetchReportRegenList());
  };

  const columns: ColumnsType<ReportRegenItem> = [
    {
      title: "編號",
      dataIndex: "id",
    },
    {
      title: "審核狀態",
      dataIndex: "approvalStatus",
      render: (data: number) => vendorApproveStatusMap[data],
    },
    {
      title: "銷售頁方案ID",
      dataIndex: "salesPlanId",
    },
    {
      title: "SKUID",
      dataIndex: "sku",
    },
    {
      title: "抽成調整後比例",
      dataIndex: "adjustedCommissionRate",
    },
    {
      title: "金額差",
      dataIndex: "amountDiff",
    },
    {
      title: "影響訂單時間區間",
      dataIndex: "affectedStartDate",
      render: (data: string, otherData: ReportRegenItem) => {
        return `${data} - ${otherData.affectedEndDate}`;
      },
    },
    {
      title: "申請人",
      dataIndex: ["staff", "name"],
    },
    {
      title: "申請時間",
      dataIndex: "createdAt",
    },

    {
      title: "",
      dataIndex: "action",
      fixed: "right",
      render: (_: any, otherData: ReportRegenItem) => (
        <Row justify="space-between">
          <Button type="link" href={`/report-regen/${otherData.id}`} target="_blank">
            檢視
          </Button>

          <Button
            type="link"
            disabled={
              [VendorApproveStatus.REJECT, VendorApproveStatus.PASS, VendorApproveStatus.DELETE].indexOf(
                otherData.approvalStatus,
              ) > -1 || !canExecution
            }
            onClick={() => {
              dispatch(deleteReportRegen(otherData.id));
            }}
          >
            作廢
          </Button>
        </Row>
      ),
    },
  ];

  return (
    <Wrapper>
      <PageTitle title="月結重跑" />
      <Row justify="space-between">
        <Col span={12}>
          <Space>
            <Button
              disabled={!canExecution}
              type="primary"
              icon={<PlusCircleTwoTone twoToneColor="#1890FF" />}
              onClick={() => setShowModal(true)}
            >
              新增月結重跑
            </Button>
            <Button icon={<FilterTwoTone />} onClick={() => setShowFilter(!showFilter)}>
              篩選
            </Button>
            <Button onClick={() => dispatch(exportReportRegen())}>匯出</Button>
          </Space>
        </Col>
        <Col span={6}>
          <Space>
            <Text>{`總共${count}筆，每頁顯示`}</Text>
            <Select value={limit} style={{ width: "72px", fontSize: "13px" }} onChange={handleSelectChange}>
              <Option value={20}>20</Option>
              <Option value={50}>50</Option>
              <Option value={100}>100</Option>
            </Select>
            <span>筆</span>
          </Space>
        </Col>
      </Row>
      {showFilter && <VendorFilter />}
      <div style={{ border: "1px solid #fffff", marginTop: "15px" }}>
        <Table
          columns={columns}
          rowKey="id"
          dataSource={list}
          loading={isFetchingReportRegenList}
          scroll={{ x: "max-content" }}
          pagination={{
            current: currentPage,
            total: count,
            onChange: handleTableChangePage,
            pageSize: limit,
            showSizeChanger: false,
          }}
        />
      </div>
      <EditModal isModalVisible={showModal} toogleModal={() => setShowModal((prev) => !prev)} isEdit={false} />
    </Wrapper>
  );
}
