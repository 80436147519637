import React, { FC, useEffect, useState, useMemo, ChangeEvent, useCallback } from "react";
import styled from "styled-components";
import { Input, Select, Button, Checkbox } from "antd";
import useDebounce from "@hooks/useDebounce";
import { SelectValue } from "antd/lib/select";
import InfiniteScrollObserver from "@component/InfiniteScrollObserver";
import { ShelfLifeListParam, ContractMode } from "@api/stockApi";
import { loadMoreBrandList, fetchBrandList, fetchVendorList, loadMoreVendorList } from "@redux/productSlice";
import { updateShelfLifeListParam, fetchShelfLifeList, clearShelfLifeListParam, initialState } from "@redux/stockSlice";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { useSelector } from "react-redux";
import { RootState } from "@redux/rootReducer";
import { useAppDispatch } from "src/store";

const Wrapper = styled.form`
  margin-bottom: 20px;
`;
const CheckboxContainer = styled.div`
  margin-bottom: 30px;
`;
const Grid = styled.div`
  display: inline-grid;
  grid-template-columns: auto auto auto auto;
  grid-template-rows: auto;
  column-gap: 30px;
  min-width: 0;
  margin-top: 20px;
`;
const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 250px;
`;
const RowTitle = styled.div`
  margin-right: 15px;
  font-size: 14px;
  color: ${({ theme }) => theme.colorNeutral600};
  white-space: nowrap;
`;
const RowContent = styled.div`
  width: 188px;
`;
const StyledSelect = styled(Select)`
  width: 100%;
`;
const ButtonContainer = styled.div`
  margin-top: 15px;
  display: flex;
  justify-content: flex-end;
`;
const ClearFilterButton = styled(Button)`
  margin-right: 8px;
`;

const contractModeOptions = [
  {
    label: "請選擇",
    value: -1,
  },
  {
    label: "買斷",
    value: ContractMode.BUYOUT,
  },
  {
    label: "寄倉",
    value: ContractMode.CONSIGN_WAREHOUSE,
  },
];

const Filter: FC = () => {
  const dispatch = useAppDispatch();
  const shelfLifeListParam = useSelector((state: RootState) => state.stock.shelfLifeListParam);
  const staffList = useSelector((state: RootState) => state.staffs.bdStaffData);
  const brandListResult = useSelector((state: RootState) => state.product.brandListResult);
  const vendorListResult = useSelector((state: RootState) => state.product.vendorListResult);

  const [localFilter, setLocalFilter] = useState<ShelfLifeListParam>(initialState.shelfLifeListParam);
  const { sku, nameQ, ownerId, contractMode, overCanSellExpiration, tagId, vendorId } = localFilter;

  useEffect(() => {
    setLocalFilter(shelfLifeListParam);
  }, [shelfLifeListParam]);

  const staffOptions = useMemo(() => {
    const options = staffList.map((staff) => (
      <Select.Option key={staff.id} value={staff.id}>
        {staff.name}
      </Select.Option>
    ));
    options.unshift(
      <Select.Option key={-1} value={-1}>
        請選擇
      </Select.Option>,
    );

    return options;
  }, [staffList]);

  const handleOnBrandSearch = useDebounce((value: string) => {
    dispatch(fetchBrandList(value));
  }, 300);

  const handleOnVendorSearch = useDebounce((value: string) => {
    dispatch(fetchVendorList(value));
  }, 300);

  const handleOnBrandLoadMore = useCallback(() => {
    dispatch(loadMoreBrandList());
  }, [dispatch]);

  const handleOnVendorLoadMore = useCallback(() => {
    dispatch(loadMoreVendorList());
  }, [dispatch]);

  const brandOptions = useMemo(() => {
    const { next, results } = brandListResult;
    const options = results.map((brd) => (
      <Select.Option key={brd.id} value={brd.id}>
        {brd.name}
      </Select.Option>
    ));

    options.unshift(
      <Select.Option key={-1} value={-1}>
        請選擇
      </Select.Option>,
    );

    if (next) {
      options.push(
        <Select.Option value="loading..." disabled>
          loading...
          <InfiniteScrollObserver callback={handleOnBrandLoadMore} />
        </Select.Option>,
      );
    }

    return options;
  }, [brandListResult, handleOnBrandLoadMore]);

  const vendorOptions = useMemo(() => {
    const { next, results } = vendorListResult;
    const options = results.map((vdr) => (
      <Select.Option key={vdr.id} value={vdr.id}>
        {vdr.name}
      </Select.Option>
    ));

    options.unshift(
      <Select.Option key={-1} value={-1}>
        請選擇
      </Select.Option>,
    );

    if (next) {
      options.push(
        <Select.Option value="loading..." disabled>
          loading...
          <InfiniteScrollObserver callback={handleOnVendorLoadMore} />
        </Select.Option>,
      );
    }

    return options;
  }, [vendorListResult, handleOnVendorLoadMore]);

  const handleOnChange = (title: keyof ShelfLifeListParam) => (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setLocalFilter((prev) => ({
      ...prev,
      [title]: value,
    }));
  };

  const handleOnSelect = (title: keyof ShelfLifeListParam) => (value: SelectValue) => {
    setLocalFilter((prev) => ({
      ...prev,
      [title]: value,
    }));
  };

  const handleOnCheck = (e: CheckboxChangeEvent) => {
    const { checked } = e.target;
    setLocalFilter((prev) => ({
      ...prev,
      overCanSellExpiration: checked,
    }));
  };

  const handleOnSaveFilter = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    dispatch(updateShelfLifeListParam(localFilter));
    dispatch(fetchShelfLifeList());
  };

  const handleOnClearFilter = () => {
    setLocalFilter(initialState.shelfLifeListParam);
    dispatch(clearShelfLifeListParam());
    dispatch(fetchShelfLifeList());
  };

  useEffect(() => {
    dispatch(fetchBrandList());
    dispatch(fetchVendorList());
  }, [dispatch]);

  return (
    <Wrapper onSubmit={handleOnSaveFilter}>
      <CheckboxContainer>
        <Checkbox checked={overCanSellExpiration} onChange={handleOnCheck}>
          僅顯示達允售期限
        </Checkbox>
      </CheckboxContainer>
      <Grid>
        <Row>
          <RowTitle>SKU</RowTitle>
          <RowContent>
            <Input value={sku} onChange={handleOnChange("sku")} />
          </RowContent>
        </Row>
        <Row>
          <RowTitle>商品名稱</RowTitle>
          <RowContent>
            <Input value={nameQ} onChange={handleOnChange("nameQ")} />
          </RowContent>
        </Row>
        <Row>
          <RowTitle>商開負責人</RowTitle>
          <RowContent>
            <StyledSelect value={ownerId} onChange={handleOnSelect("ownerId")}>
              {staffOptions}
            </StyledSelect>
          </RowContent>
        </Row>
        <Row>
          <RowTitle>合作模式</RowTitle>
          <RowContent>
            <StyledSelect
              value={contractMode}
              options={contractModeOptions}
              onChange={handleOnSelect("contractMode")}
            />
          </RowContent>
        </Row>
      </Grid>
      <Grid>
        <Row>
          <RowTitle>品牌</RowTitle>
          <RowContent>
            <StyledSelect
              showSearch
              filterOption={false}
              value={tagId}
              onChange={handleOnSelect("tagId")}
              onSearch={handleOnBrandSearch}
            >
              {brandOptions}
            </StyledSelect>
          </RowContent>
        </Row>
        <Row>
          <RowTitle>廠商</RowTitle>
          <RowContent>
            <StyledSelect
              showSearch
              filterOption={false}
              value={vendorId}
              onChange={handleOnSelect("vendorId")}
              onSearch={handleOnVendorSearch}
            >
              {vendorOptions}
            </StyledSelect>
          </RowContent>
        </Row>
      </Grid>
      <ButtonContainer>
        <ClearFilterButton onClick={handleOnClearFilter}>清除篩選條件</ClearFilterButton>
        <Button type="primary" htmlType="submit">
          套用
        </Button>
      </ButtonContainer>
    </Wrapper>
  );
};

export default Filter;
